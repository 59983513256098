import { FC, ReactNode } from "react";

import { SortOrder } from "constants/index";

import { Container, Title } from "./styles";

interface IProps {
  title?: string | ReactNode;
  className?: string;
  onClick?: () => void;
  sortOrder?: SortOrder | boolean;
}

export const TableColumn: FC<IProps> = (props) => {
  return (
    <Container onClick={props?.onClick} className={props.className}>
      {props.title && <Title>{props.title}</Title>}
    </Container>
  );
};
