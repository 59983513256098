import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce, isEmpty } from "lodash";

import { AppEvents } from "services/events";
import { ErrorConst, UserRole } from "types/BETypes";
import { showErrorModal } from "helpers/";

import { EModalTypes } from "uikit/Modal";

import {
  ListEmployeesEmployeeResponseDto,
  mutationAdminsControllerInviteTeamMember,
  queryEmployeesControllerGetEmployee,
  queryEmployeesControllerListEmployees,
} from "utils/swagger_react_query";

import { InviteTeamMemberFormType } from "./components";

interface IParams {
  setPageLoader: (state: boolean) => void;
  refetchTeamMembersList: () => void;
}

export const useInviteTeamMemberModal = ({ setPageLoader, refetchTeamMembersList }: IParams) => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState<boolean>(true);

  const [inviteTeamMemberModalIsOpen, setInviteTeamMemberModal] = useState<boolean>(false);

  const [employeesList, setEmployeesList] = useState<ListEmployeesEmployeeResponseDto[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);

  const fetchEmployeesList = async (_searchValue: string, _page: string) => {
    try {
      setLoading(true);
      const data = await queryEmployeesControllerListEmployees({
        page: _page,
        perPage: "8",

        searchString: _searchValue,
      });
      if (isEmpty(employeesList) || _page === "1") {
        setEmployeesList(data.employees);
      } else {
        const updatedList = [...employeesList, ...data.employees];
        if (updatedList.length === data.total) setHasMore(false);
        setEmployeesList(updatedList);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchEmployee = useCallback(
    debounce(async (value: string) => {
      setSearchValue(value);
      await fetchEmployeesList(value, "1");
    }, 500),
    [],
  );

  const handleFetchMoreEmployees = () => {
    setLoading(true);
    const updatedPage = page + 1;
    setPage(updatedPage);
    setTimeout(() => {
      fetchEmployeesList(searchValue, updatedPage.toString());
    }, 500);
  };

  const handleCloseInviteTeamMemberModal = () => {
    setSearchValue("");
    setPage(1);
    setEmployeesList([]);
    setInviteTeamMemberModal(false);
  };

  const handleOpenInviteTeamMemberModal = async () => {
    try {
      setPageLoader(true);
      await fetchEmployeesList(searchValue, page.toString());
      setInviteTeamMemberModal(true);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setPageLoader(false);
    }
  };

  const onInviteTeamMember = async (values: InviteTeamMemberFormType) => {
    try {
      setPageLoader(true);
      await mutationAdminsControllerInviteTeamMember()({
        email: values.email,
        companyRole: UserRole.ADMIN,
      });
      setInviteTeamMemberModal(false);
      await refetchTeamMembersList();
    } catch (error: any) {
      setInviteTeamMemberModal(false);
      showErrorModal(error);
    } finally {
      setPageLoader(false);
    }
  };

  const handleInvitationError = (error: string, primaryEmployeeEmail: string) => {
    if (error === ErrorConst.EMAIL_IS_USED_AS_PERSONAL) {
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        type: EModalTypes.Error,
        title: t("errors.defaultMessage"),
        message: t(`errors.${error}`, { primaryEmail: primaryEmployeeEmail }),
        mainButton: {
          text: t("admin_team_members.modals.yes"),
          onClick: () => {
            onInviteTeamMember({ email: primaryEmployeeEmail });
          },
        },
        secondaryButton: {
          text: t("admin_team_members.modals.no"),
          onClick: () => {
            AppEvents.emit("SetGlobalModal", { isOpen: false });
          },
        },
      });
    }
  };

  return {
    isLoading,
    employeesList,
    page,
    hasMore,
    onInviteTeamMember,
    inviteTeamMemberModalIsOpen,
    handleOpenInviteTeamMemberModal,
    handleSearchEmployee,
    fetchMoreUsers: handleFetchMoreEmployees,
    handleCloseInviteTeamMemberModal,
  };
};
