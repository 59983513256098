import { FC } from "react";

import IconProps from "./IconProps";

const Tooltip: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_1_5935)">
      <circle cx="8" cy="8" r="3" fill="#F29128" />
      <circle cx="8" cy="8" r="3.5" stroke="white" />
    </g>
    <defs>
      <filter
        id="filter0_d_1_5935"
        x="0"
        y="0"
        width="16"
        height="16"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_5935" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_5935" result="shape" />
      </filter>
    </defs>
  </svg>
);

export default Tooltip;
