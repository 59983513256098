import { useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";

import { companyMetadataSelector, userMetadataSelector } from "redux/selectors";
import { useBankAccountLink } from "hooks";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";

import { ListAdminsResponseDto } from "utils/swagger_react_query";

export const useEmployeeSettingsPage = () => {
  const translationPrefix = `settings_pages.employee.index`;
  const [isLoading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const currentUser = useSelector(userMetadataSelector);
  const currentCompany = useSelector(companyMetadataSelector);

  const [teamMembersDetails] = useState<ListAdminsResponseDto>();
  const {
    data: { bankAccounts },
  } = useBankAccountLink({ loadingCallback: setLoading });

  const handleMyProfileCardClick = () => navigate(routes.EMPLOYEE_PROFILE);
  const handleBankAccountsCardClick = () => navigate(routes.EMPLOYEE_SETTINGS_BANK_INFO);

  const optionsList: NavigationCardProps[] = [
    {
      id: "my_profile",
      title: <Trans i18nKey={`${translationPrefix}.options.my_profile.title`} />,
      buttonText: <Trans i18nKey={`${translationPrefix}.options.bank_account.button_text`} />,
      onClick: () => handleMyProfileCardClick(),
    },
    {
      id: "bank_account",
      title: <Trans i18nKey={`${translationPrefix}.options.bank_account.title`} />,
      buttonText: <Trans i18nKey={`${translationPrefix}.options.bank_account.button_text`} />,
      onClick: () => handleBankAccountsCardClick(),
    },
  ];

  return {
    metadata: {
      currentUser,
      currentCompany,
      isLoading,
    },
    pageData: {
      teamMembersDetails,
      bankAccounts,
      optionsList,
    },
  };
};

export default useEmployeeSettingsPage;
