import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { FormikHelpers } from "formik";

import { userMetadataSelector } from "redux/selectors";
import { AppEvents } from "services/events";
import {
  getFileBaseName,
  getFormattedPhoneNumber,
  getPhoneNumberOrNull,
  s3UploadFromDataUrl,
  showErrorModal,
} from "helpers";
import useAuth from "hooks/useAuth";

import { EModalTypes } from "uikit/Modal";

import {
  AdminProfileResponseDto,
  mutationAdminsControllerUpdateAdminProfile,
  queryAdminsControllerAvatarUploadUrl,
  UserResponseDto,
} from "utils/swagger_react_query";

import { AdminProfileFormType } from "./components";

export const useAdminProfilePage = () => {
  const translationPrefix = `my_profile`;

  const [isLoading, setLoading] = useState<boolean>(true);
  const currentUser = useSelector(userMetadataSelector);
  const { getCurrentUser } = useAuth();
  const [svoc, setSvoc] = useState<boolean>(false);
  const [canLeave, setCanLeave] = useState<boolean>(true);

  const getInitialValues = (
    userDetails?: UserResponseDto | AdminProfileResponseDto,
  ): AdminProfileFormType => {
    return {
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
      jobTitle: userDetails?.jobTitle || "",
      phone: userDetails?.phone || "",
      email: userDetails?.email || "",
      avatarUrl: "",
      avatarFileName: "",
      // avatarUrl: userDetails?.avatarUrl || "",
      // avatarFileName: userDetails?.avatarUrl ? getFileBaseName(userDetails.avatarUrl) : "",
    };
  };

  const [initialValues, setInitialValues] = useState<AdminProfileFormType>(
    getInitialValues(currentUser || undefined),
  );

  useEffect(() => {
    fetchAdminInfo();
  }, []);

  const fetchAdminInfo = async () => {
    try {
      setLoading(true);
      const adminDetailsRes = await getCurrentUser();

      const formInitialValues: AdminProfileFormType = {
        firstName: adminDetailsRes?.firstName || "",
        lastName: adminDetailsRes?.lastName || "",
        jobTitle: adminDetailsRes?.jobTitle || "",
        phone: adminDetailsRes?.phone || "",
        email: adminDetailsRes?.email || "",
        avatarUrl: "",
        avatarFileName: "",
        // avatarUrl: adminDetailsRes?.avatarUrl || "",
        // avatarFileName: adminDetailsRes?.avatarUrl
        //   ? getFileBaseName(adminDetailsRes.avatarUrl)
        //   : "",
      };
      setInitialValues(formInitialValues);
      return formInitialValues;
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (
    values: AdminProfileFormType,
    resetForm: FormikHelpers<AdminProfileFormType>["resetForm"],
  ) => {
    try {
      setLoading(true);
      const adminId = currentUser?.userId || "";

      //Avatar upload flow
      // let s3LogoUrl: string | null = values?.avatarUrl || null;
      // /**
      //  * DF: values?.avatarFileName indicates that it was reset
      //  */
      // if (values?.avatarFileName && values?.avatarUrl) {
      //   const { presignedPost } = await queryAdminsControllerAvatarUploadUrl(
      //     { adminId },
      //     { fileName: values.avatarFileName },
      //   );
      //   const { s3AssetUrl } = await s3UploadFromDataUrl(
      //     values.avatarUrl,
      //     presignedPost.url,
      //     presignedPost.fields,
      //   );
      //   s3LogoUrl = s3AssetUrl;
      // }

      const updatedAdminDetails = await mutationAdminsControllerUpdateAdminProfile({ adminId })({
        firstName: values.firstName,
        lastName: values.lastName,
        jobTitle: values?.jobTitle || null,
        phone: getPhoneNumberOrNull(values?.phone || ""),
        avatarUrl: null,
      });
      await getCurrentUser();

      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        type: EModalTypes.Success,
        title: <Trans i18nKey={`${translationPrefix}.profile_update_success_modal.title`} />,
        message: <Trans i18nKey={`${translationPrefix}.profile_update_success_modal.message`} />,
        mainButton: {
          text: <Trans i18nKey="buttons.ok" />,
          onClick: () => undefined,
        },
      });
      resetForm({ values: getInitialValues(updatedAdminDetails) });
      setCanLeave(true);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    metadata: {
      translationPrefix,
      isLoading,
      initialValues,
      svoc,
      canLeave,
      setCanLeave,
    },
    actions: {
      handleSubmit,
    },
  };
};
