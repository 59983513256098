import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import routes from "routes/routes";

import { HELP_CENTER } from "constants/shared";

import { FormLabel } from "uikit";

import {
  Footer,
  FooterLinkExternal,
  FooterLinkInner,
  FooterText,
  FormItem,
  StyledPrimaryBtn,
  Title,
} from "../styles";
import { SignInFormType } from "./validationSchema";
import { ButtonsSeparatorText, Container, EmailInput, StyledGoogleSignInButton } from "./styles";

interface IProps {
  handleGoogleAuth: (e: any) => void;
  isLoading: boolean;
  translationPrefix: string;
  values: FormikProps<SignInFormType>["values"];
  errors: FormikProps<SignInFormType>["errors"];
  handleChange: FormikProps<SignInFormType>["handleChange"];
  navigationLink: routes.SIGN_IN | routes.SIGN_UP;
}

export const SignInForm: FC<IProps> = ({
  handleGoogleAuth,
  isLoading,
  values,
  errors,
  handleChange,
  translationPrefix,
  navigationLink,
}) => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>

      <FormItem>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.email_field.title`} />
        </FormLabel>
        <EmailInput
          name={`email`}
          placeholder={t(`${translationPrefix}.email_field.placeholder`)}
          value={values.email}
          onChange={(e) => {
            e.currentTarget.value = e.currentTarget.value.trim();
            handleChange(e);
          }}
          error={
            errors?.email && (
              <Trans
                i18nKey={errors.email}
                values={{ field: t(`${translationPrefix}.email_field.title`) }}
              />
            )
          }
          errorDataTestId="error-test-id"
        />
      </FormItem>

      <StyledPrimaryBtn type="submit" disabled={isLoading}>
        <Trans i18nKey={`${translationPrefix}.submit_button`} />
      </StyledPrimaryBtn>

      <ButtonsSeparatorText>
        <Trans i18nKey={`${translationPrefix}.or_text`} />
      </ButtonsSeparatorText>

      <StyledGoogleSignInButton handleGoogleAuth={handleGoogleAuth} />

      <Footer>
        <FooterText>
          <Trans i18nKey={`${translationPrefix}.navigate_text`} />{" "}
          <FooterLinkInner to={navigationLink}>
            <Trans i18nKey={`${translationPrefix}.navigate_btn`} />
          </FooterLinkInner>
        </FooterText>
        <FooterLinkExternal href={HELP_CENTER} target="_blank">
          <Trans i18nKey={`buttons.need_help_btn`} />
        </FooterLinkExternal>
      </Footer>
    </Container>
  );
};
