import { FC } from "react";

import {
  getContainerClassName,
  renderAdditionalContent,
  renderBelowContent,
  renderButton,
  renderLabels,
  renderTextBlock,
} from "./renderElements";
import { IProps } from "./types";
import { ContainerLarge, ContainerOuter, MainContent } from "./styles";

const NavigationCardWithDetails: FC<IProps> = (props: IProps) => {
  const translationPrefix = `components.navigation_card_with_details`;

  const { onClick, button, additionalContent, isActive, id } = props;

  const _className = getContainerClassName(props);
  return (
    <ContainerOuter
      className={_className}
      data-test-id={id ? `${id}_card ${id}_card_large` : undefined}
    >
      <ContainerLarge onClick={onClick} className={_className}>
        <MainContent>
          {renderTextBlock(props)}

          {button || renderButton(props)}
        </MainContent>

        {!!additionalContent && renderAdditionalContent(props)}

        {!!isActive && renderLabels(props)}
      </ContainerLarge>

      {renderBelowContent(props)}
    </ContainerOuter>
  );
};

export default NavigationCardWithDetails;
