import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import {
  BankAccountType,
  BonusEligibilityStatus,
  BonusStatus,
  BonusTrackingType,
  BonusType,
  ErrorConst,
  ExtensionBonusEligibilityStatus,
  PeriodUnit,
  SalsaWorkerCategoryEnum,
  SyncteraAccountTransactionDcSignType,
  SyncteraAccountTransactionType,
  SyncteraTransactionStatus,
  UserRole,
  VestingScheduleType,
} from "types/BETypes";
import { CardForm, CardReissueReason } from "types/CardShipping";
import {
  APPLICATION_NAME,
  APPLICATION_PARENT_NAME_FULL,
  BONUS_MAX_GOAL_QUANTITY,
  BONUS_MAX_MILESTONES_QUANTITY,
  MAX_INPUT_LENGTH,
  MAX_INPUT_LENGTH_LONG,
  MAX_TEXTAREA_LENGTH,
} from "constants/index";
import { EComprehensibleCardStatus } from "helpers/assets/bankCards";
import { EComprehensibleUserStatuses } from "helpers/employee/userStatusMap";
import { ExtensionBonusStatuses } from "helpers/index";
import { ITransactionTypes } from "components/TransactionsList/types";

import bonusGoalsTranslations from "./common/bonusGoals";
import bankAccountSectionTranslation from "./components/bankAccountSection";
import bankingDisclosureTranslations from "./components/bankingDisclosure";
import bonusCardTranslations from "./components/bonusCard";
import bonusesListTableTranslations from "./components/bonusesListTable";
import bonusSummaryTranslations from "./components/bonusSummary";
import bonusTemplatesListTable from "./components/bonusTemplatesListTable";
import employeeInfo from "./components/employeeInfo";
import kycVerificationWidget from "./components/kycVerificationWidget";
import navigationCardWithDetailsTranslations from "./components/navigationCardWithDetails";
import paidCardShippingDetailsForm from "./components/paidCardShippingDetailsForm";
import paymentMethodsTranslations from "./components/paymentMethods";
import performanceChallengeTranslations from "./components/performanceChallenges";
import progressBarTranslations from "./components/progressBar";
import adminSetupPageTranslations from "./pages/adminSetupPage";
import authPages from "./pages/authPages";
import bankingPages from "./pages/bankingPages";
import dashboardPageTranslations from "./pages/dashboardPage";
import employeeDashboardTranslations from "./pages/employeeDashboard";
import employeeSetupPageTranslations from "./pages/employeeSetupPage";
import insufficientPermissionsPageTranslations from "./pages/insufficientPermissionsPage";
import myProfileTranslation from "./pages/myProfile";
import page404Translations from "./pages/page404";
import payPagesTranslations from "./pages/payPages";
import peoplePagesTranslations from "./pages/peoplePages";
import settingsPageTranslations from "./pages/settingsPages";

export const translations = {
  "en-US": {
    translation: {
      common: {
        and: "and",
        day: "Day",
        days: "Days",
        year: "Year",
        years: "Years",
        day_one: "Day",
        day_other: "Days",
        year_one: "Year",
        year_other: "Years",
        bonus_one: "bonus",
        bonus_other: "bonuses",
        input: {
          optional: "Optional",
          search_placeholder: "Search",
          placeholder_base: "Enter {{field}}",
        },
        months: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          september: "September",
          october: "October",
          november: "November",
          december: "December",
        },
        roles: {
          [UserRole.SUPERADMIN]: "Admin",
          [UserRole.ADMIN]: "Admin",
          [UserRole.OWNER]: "Owner",
          [UserRole.EMPLOYEE]: "Worker",
        },
        time_units: {
          [`${PeriodUnit.MONTH}_one`]: "Month",
          [`${PeriodUnit.MONTH}_other`]: "Months",
          [`${PeriodUnit.QUARTER}_one`]: "Quarter",
          [`${PeriodUnit.QUARTER}_other`]: "Quarters",
          [`${PeriodUnit.YEAR}_one`]: "Year",
          [`${PeriodUnit.YEAR}_other`]: "Years",
        },
        user_status: {
          [EComprehensibleUserStatuses.EMPLOYED]: "Employed",
          [EComprehensibleUserStatuses.PENDING_EXIT]: "Pending Exit",
          [EComprehensibleUserStatuses.EXITED]: "Exited",
          [EComprehensibleUserStatuses.IN_DEFAULT]: "In Default",
          [EComprehensibleUserStatuses.PROSPECT]: "Prospect",
          [EComprehensibleUserStatuses.INVITED]: "Invited",
          [EComprehensibleUserStatuses.NOT_READY]: "Not Ready",
        },
        worker_employment_type: {
          [SalsaWorkerCategoryEnum.EMPLOYEE]: "Full-time employee",
          [SalsaWorkerCategoryEnum.CONTRACTOR]: "Contractor",
        },
        worker_employment_type_short: {
          [SalsaWorkerCategoryEnum.EMPLOYEE]: "Full-time",
          [SalsaWorkerCategoryEnum.CONTRACTOR]: "Contractor",
        },
        bank_account_types: {
          [BankAccountType.investment]: "Investment",
          [BankAccountType.credit]: "Credit",
          [BankAccountType.depository]: "Depository",
          [BankAccountType.loan]: "Loan",
          [BankAccountType.brokerage]: "Brokerage",
          [BankAccountType.other]: "Other",
        },
        bank_cards: {
          card_titles: {
            [CardForm.PHYSICAL]: "Physical Card",
            [CardForm.VIRTUAL]: "Virtual Card",
          },
          card_statuses: {
            [EComprehensibleCardStatus.ACTIVE]: "Active",
            [EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN]: "Active",
            [EComprehensibleCardStatus.EMPTY]: "",
            [EComprehensibleCardStatus.INACTIVE]: "Inactive",
            [EComprehensibleCardStatus.REISSUED]: "Reissued",
            [EComprehensibleCardStatus.CANCELED]: "Canceled",
            [EComprehensibleCardStatus.EXPIRED]: "Expired",
          },
        },
        industry: {
          accounting: "Accounting",
          administration_Office_Support: "Administration & Office Support",
          advertising_Arts_Media: "Advertising, Arts & Media",
          banking_Financial_Services: "Banking & Financial Services",
          call_Centre_Customer_Service: "Call Centre & Customer Service",
          community_Services_Development: "Community Services & Development",
          consulting_Strategy: "Consulting & Strategy",
          design_Architecture: "Design & Architecture",
          education_Training: "Education & Training",
          engineering: "Engineering",
          farming_Animals_Conservation: "Farming, Animals & Conservation",
          government_Defence: "Government & Defense",
          healthcare_Medical: "Healthcare & Medical",
          hospitality_Tourism: "Hospitality & Tourism",
          human_Resources_Recruitment: "Human Resources & Recruitment",
          information_Communication_Technology: "Information & Communication Technology",
          insurance_Superannuation: "Insurance & Superannuation",
          legal: "Legal",
          manufacturing_Transport_Logistics: "Manufacturing, Transport & Logistics",
          marketing_Communications: "Marketing & Communications",
          mining_Resources_Energy: "Mining, Resources & Energy",
          real_Estate_Property: "Real Estate & Property",
          retail_Consumer_Products: "Retail & Consumer Products",
          sales: "Sales",
          science_Technology: "Science & Technology",
          sport_Recreation: "Sport & Recreation",
          trades_Services: "Trades & Services",
          other: "Other",
        },
        back: "back",
        copied: "Copied!",
        modal: {
          okay: "Okay",
          submit: "Submit",
          cancel: "Cancel",
          are_you_sure: "Are you sure?",
          done: "Done",
          yes: "Yes",
          no: "No",
        },
        buttons: {
          cancel: "Cancel",
        },

        bonusGoals: bonusGoalsTranslations["en-US"],
      },
      buttons: {
        save: "Save",
        cancel: "Cancel",
        submit: "Submit",
        delete: "Delete",
        next: "Next",
        edit: "Edit",
        confirm: "Confirm",
        continue: "Continue",
        close: "Close",
        back: "Back",
        okay: "Okay",
        yes: "Yes",
        no: "No",
        ok: "OK",
        done: "Done",
        apply: "Apply",
        need_help_btn: "Need Help?",
        no_thanks: "No, Thanks",
      },
      errors: {
        defaultTitle: "Error",
        defaultMessage:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        salsaError: "Salsa import failed.",
        INTERNAL_ERROR:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        VALIDATION_ERROR: "Validation error. Please try again.",
        NO_AUTH_TOKEN: "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        NO_AUTH_STATE: "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        TOKEN_NOT_VERIFIED: "The link you followed has expired. Please try to sign in again.",
        SESSION_EXPIRED: "Session expired.",
        AUTH_CODE_EXPIRED: "The link you followed has expired.",
        USER_NOT_FOUND:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        INSUFFICIENT_PERMISSIONS: "You do not have permissions to perform this action.",
        USER_BELONGS_TO_COMPANY: "USER_BELONGS_TO_COMPANY",
        EMAILS_SHOULD_NOT_BE_SAME: "EMAILS_SHOULD_NOT_BE_SAME",
        COMPANY_NOT_FOUND:
          "Something went wrong. If this continues please reach out to {{helpEmail}}.",
        EMAIL_BELONGS_TO_COMPANY: "User with this email already exists",
        USER_ACCOUNT_WAS_DISABLED: "This account was disabled.",
        USER_ALREADY_EXISTS: "User already exists.",
        EMPLOYEE_IS_IN_EXIT_STATE: "This User was terminated from your Company.",
        EMAIL_IS_MISSING: "The link you followed has expired.",
        LINK_TOKEN_EXPIRED: "The link you followed has expired.",
        LEGAL_INFO_CANNOT_BE_UPDATED:
          "After agreement is signed, legal information cannot be updated. Please contact support at <1>{{helpEmail}}</1> if you need any help.",
        EMAIL_ALREADY_TAKEN: "This email is taken by another User",
        EMAIL_IS_USED_AS_PERSONAL:
          "This email is used as Employee personal email.<br/>Employee primary email is {{primaryEmail}}.<br/>We can send invitation to Employee primary email.",
        USER_CANNOT_RECOVER_ACCOUNT: "Sorry, you can not recover your account",
        USER_DOES_NOT_EXIST_WITH_PRIMARY_EMAIL: "User with this primary email does not exist!",
        USER_CANNOT_LOGIN_WITH_PERSONAL_EMAIL:
          "User with this primary email does not exist. If you want to log in with your personal email please click <2>“Recover your account”</2> button",
        EXTERNAL_ACCOUNT_INVALID_CREDENTIALS:
          "Your bank account credentials have changed. Please connect your bank account again.",
        BAD_USER_STATUS: "Login error. Ask your manager to invite you to the system.",
        AMOUNT_CANNOT_BE_ZERO: "Amount cannot be zero",
        FAILED_TO_CREATE_TRANSACTION:
          "Transaction failed. Please check your bank account connection or contact the Keep team at help@keepfinancial.com to address this error.",
        NETWORK_CONNECTION_LOST: "Network connection lost. Please try again",
        DUPLICATE_ACCOUNT: "This account is already connected. Please try another one.",
        USER_NOT_KYC_VERIFIED:
          "Unable to complete the transfer. Please contact <1>{{helpEmail}}</1> for more details.",
        COMPANY_DOES_NOT_HAVE_BONUSES: "No bonuses to export.",
        PHONE_NUMBER_VALIDATION_ERROR: "Invalid phone number",
        TOO_MANY_REQUESTS: "Too many requests. Try again later.",
        IMPORTING_ONLY_EXAMPLE_RECORD:
          "You are trying to upload CSV Template file with example Worker information. Please upload your CSV file with Workers information and try again.",
        SYNCTERA_ERROR:
          "It seems that there is a problem with the chosen payment method. Please try again later or contact us at <1>{{helpEmail}}</1>",
        [ErrorConst.COMPANY_DOMAIN_NOT_FOUND]: "COMPANY_DOMAIN_NOT_FOUND",
        [ErrorConst.COMPANY_DOMAIN_ALREADY_EXISTS]: "This domain is already added and verified.",
        [ErrorConst.COMPANY_DOMAIN_ALREADY_EXISTS_BUT_NOT_VERIFIED]:
          "This domain is already added. Please, verify it.",
        [ErrorConst.COMPANY_DOMAIN_BELONGS_TO_ANOTHER_COMPANY]:
          "Sorry email with this domain cannot be used.",
        [ErrorConst.COMPANY_DOMAIN_VERIFICATION_LINK_EXPIRED]:
          "The link you followed has expired. Please try to sign in again.",
        [ErrorConst.PROHIBITED_DOMAIN]: "Sorry email with this domain is restricted",
        [ErrorConst.USER_HAS_PROSPECT_STATUS]:
          "We can not find your user account in the system. Please contact your employer to resolve the issue.",
        [ErrorConst.IDP_PROSPECT_USER_LOGIN_ERROR]:
          "We can not find your user account in the system. Please contact your employer to resolve the issue.",
        [ErrorConst.ADMIN_ALREADY_INVITED]: "Admin is already invited",
        [ErrorConst.ADMIN_ALREADY_ACTIVE]: "Admin is already active",
        [`${[ErrorConst.INVALID_CREDENTIALS]}_SIGN_IN`]:
          "Invalid credentials. If you have not created an account please click on create account.",
        [`${[ErrorConst.INVALID_CREDENTIALS]}_SIGN_UP`]:
          "Invalid credentials. If you have an account already please click on log in.",

        //BE doesn't return this error
        [ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED]: `You’ve reached the accounts limit. If you need to select a new account for your payroll run, please contact us at {{helpEmail}}.`,
      },
      validationErrors: {
        common: {
          wrong_format: "Wrong {{field}} format",
          is_required: "{{field}} is required",
          too_long: `{{field}} must not exceed {{maxInputLength}} characters`,
          invalid_symbols: "{{field}} should consist of A-z, 0-9, and (. ,‘-)",
        },
        ssn: {
          is_required: "Social Security Number is required",
          wrong_format: "Wrong Social Security Number format",
        },
        start_date: {
          is_required: "Start date is required",
          invalid_date: "Invalid Date.",
        },
        birth_date: {
          is_required: "Date of Birth is required",
          invalid_date: "Invalid Date.",
        },
        address: {
          is_required: "Address is required",
          too_long: `Address must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
        },
        city: {
          is_required: "City is required",
          too_long: `City must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        suite: {
          too_long: `Suite must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        email: {
          is_required: "Email is required",
          wrong_format: "Wrong e-mail format",
          too_long: `Email must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        phone: {
          phone_wrong_format: "Wrong phone format",
          phone_required: "Phone number is required",
          phone_size: "Phone must be exactly 12 characters",
        },
        employment_type: {
          is_required: "Employment type is required",
        },
        job_title: {
          is_required: "Title is required",
          too_long: `Job title must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        department: {
          too_long: `Department must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
        },
        company_name: {
          is_required: "Company Name is required",
          too_long: `Company Name must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        company_url: {
          is_required: "Company URL is required",
          too_long: `Company URL must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        industry: {
          is_required: "Industry is required",
        },
        number_of_employees: {
          is_required: "Number of Employees is required",
        },
        service_agreement_title: {
          is_required: "Title is required",
          too_long: `Title must not exceed ${MAX_INPUT_LENGTH} characters`,
        },
        companyEntityType: {
          is_required: "Company Entity Type is required",
        },
        require_payment_of: {
          is_required: "Require payment of is required",
          can_not_be_higher: "Required Amount Due can not be higher than the Balance Amount Due",
        },
        image: {
          invalid_file_type: "Invalid file type",
        },
        url: {
          wrong_format: "Wrong URL format",
        },
      },
      error_layout: {
        logout: "Log Out",
      },
      copyright: {
        copyright: `© 2024 ${APPLICATION_PARENT_NAME_FULL} Technologies Inc. All rights reserved.`,
        terms_conditions: "Terms & Conditions",
        privacy_policy: "Privacy Policy",
        e_sign_agreement: "eSign Agreement",
      },

      //Pages
      admin_setup_page: adminSetupPageTranslations["en-US"],
      banking_pages: bankingPages["en-US"],
      auth_pages: authPages["en-US"],
      employee_setup_page: employeeSetupPageTranslations["en-US"],
      insufficient_permissions_page: insufficientPermissionsPageTranslations["en-US"],
      page_404: page404Translations["en-US"],
      dashboard_page: dashboardPageTranslations["en-US"],
      employee_dashboard: employeeDashboardTranslations["en-US"],
      pay_page: payPagesTranslations["en-US"],
      people_page: peoplePagesTranslations["en-US"],
      my_profile: myProfileTranslation["en-US"],
      loan_details_page: {
        title: "Loan details",
      },
      settings_pages: settingsPageTranslations["en-US"],
      //
      bonus_status_labels: {
        default: "",
        [BonusType.TIME]: {
          [`${BonusStatus.ACTIVE}_one`]: "Active Bonus",
          [`${BonusStatus.DRAFT}_one`]: "Draft Bonus",
          [`${BonusStatus.PENDING}_one`]: "Pending Bonus",
          [`${BonusStatus.DECLINED}_one`]: "Declined Bonus",
          [`${BonusStatus.COMPLETED}_one`]: "Vested Bonus",
          [`${BonusStatus.ACCEPTED}_one`]: "Accepted Bonus",
          [`${BonusStatus.VOIDED}_one`]: "Voided Bonus",
          [`${BonusStatus.VESTING_STOPPED}_one`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_one`]: "Past Due Date",

          [`${BonusStatus.ACTIVE}_other`]: "Active Bonuses",
          [`${BonusStatus.DRAFT}_other`]: "Draft Bonuses",
          [`${BonusStatus.PENDING}_other`]: "Pending Bonuses",
          [`${BonusStatus.DECLINED}_other`]: "Declined Bonuses",
          [`${BonusStatus.COMPLETED}_other`]: "Vested Bonuses",
          [`${BonusStatus.ACCEPTED}_other`]: "Accepted Bonuses",
          [`${BonusStatus.VOIDED}_other`]: "Voided Bonuses",
          [`${BonusStatus.VESTING_STOPPED}_other`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_other`]: "Past Due Date",

          short: {
            [BonusStatus.ACTIVE]: "Active",
            [BonusStatus.DRAFT]: "Draft",
            [BonusStatus.PENDING]: "Pending",
            [BonusStatus.DECLINED]: "Declined",
            [BonusStatus.COMPLETED]: "Vested",
            [BonusStatus.ACCEPTED]: "Accepted",
            [BonusStatus.VOIDED]: "Voided",
            [BonusStatus.VESTING_STOPPED]: "Stopped",
            [ExtensionBonusStatuses.PAST_DUE]: "Past Due Date",
            [ExtensionBonusStatuses.BLANK]: "Blank",
          },
        },
        [BonusType.UNIT]: {
          [`${BonusStatus.ACTIVE}_one`]: "Active Bonus",
          [`${BonusStatus.DRAFT}_one`]: "Draft Bonus",
          [`${BonusStatus.PENDING}_one`]: "Pending Bonus",
          [`${BonusStatus.DECLINED}_one`]: "Declined Bonus",
          [`${BonusStatus.COMPLETED}_one`]: "Vested Bonus",
          [`${BonusStatus.ACCEPTED}_one`]: "Accepted Bonus",
          [`${BonusStatus.VOIDED}_one`]: "Voided Bonus",
          [`${BonusStatus.VESTING_STOPPED}_one`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_one`]: "Past Due Date",

          [`${BonusStatus.ACTIVE}_other`]: "Active Bonuses",
          [`${BonusStatus.DRAFT}_other`]: "Draft Bonuses",
          [`${BonusStatus.PENDING}_other`]: "Pending Bonuses",
          [`${BonusStatus.DECLINED}_other`]: "Declined Bonuses",
          [`${BonusStatus.COMPLETED}_other`]: "Vested Bonuses",
          [`${BonusStatus.ACCEPTED}_other`]: "Accepted Bonuses",
          [`${BonusStatus.VOIDED}_other`]: "Voided Bonuses",
          [`${BonusStatus.VESTING_STOPPED}_other`]: "Vesting Stopped",
          [`${ExtensionBonusStatuses.PAST_DUE}_other`]: "Past Due Date",

          short: {
            [BonusStatus.ACTIVE]: "Active",
            [BonusStatus.DRAFT]: "Draft",
            [BonusStatus.PENDING]: "Pending",
            [BonusStatus.DECLINED]: "Declined",
            [BonusStatus.COMPLETED]: "Vested",
            [BonusStatus.ACCEPTED]: "Accepted",
            [BonusStatus.VOIDED]: "Voided",
            [BonusStatus.VESTING_STOPPED]: "Stopped",
            [ExtensionBonusStatuses.PAST_DUE]: "Past Due Date",
            [ExtensionBonusStatuses.BLANK]: "Blank",
          },
        },
        [BonusType.CHALLENGE]: {
          [`${BonusStatus.ACTIVE}_one`]: "Active Bonus",
          [`${BonusStatus.DRAFT}_one`]: "Draft Bonus",
          [`${BonusStatus.COMPLETED}`]: "Goal Achieved",
          [`${BonusStatus.NOT_COMPLETED}`]: "Incomplete",
          [`${ExtensionBonusStatuses.PAST_DUE}`]: "Past Due Date",
          [`${BonusStatus.VESTING_STOPPED}_one`]: "Vesting Stopped", //TODO: For some reason, we have VESTING_STOPPED challenges. Investigate later
          [BonusStatus.VOIDED]: "Voided",

          [`${BonusStatus.ACTIVE}_other`]: "Active Bonuses",
          [`${BonusStatus.DRAFT}_other`]: "Draft Bonuses",
          [`${BonusStatus.VESTING_STOPPED}_other`]: "Vesting Stopped", //TODO: For some reason, we have VESTING_STOPPED challenges. Investigate later

          short: {
            [BonusStatus.ACTIVE]: "Active",
            [BonusStatus.DRAFT]: "Draft",
            [BonusStatus.COMPLETED]: "Goal Achieved",
            [BonusStatus.NOT_COMPLETED]: "Incomplete",
            [ExtensionBonusStatuses.PAST_DUE]: "Past Due Date",
            [BonusStatus.VOIDED]: "Voided",
            [BonusStatus.VESTING_STOPPED]: "Vesting Stopped",
          },
        },
      },
      bonus_eligibility_status_labels: {
        [BonusEligibilityStatus.LIVE]: "Live",
        [BonusEligibilityStatus.INACTIVE]: "Inactive",
        [BonusEligibilityStatus.DRAFT]: "Draft",
        [ExtensionBonusEligibilityStatus.PAST_DUE]: "Past Due",
      },
      bonus_tracking_type_labels: {
        [BonusTrackingType.INDIVIDUAL]: "Individual",
        [BonusTrackingType.TEAM]: "Team",
      },
      //
      card_reissue_reasons: {
        [CardReissueReason.EXPIRATION]: "Expiration",
        [CardReissueReason.LOST]: "Lost",
        [CardReissueReason.STOLEN]: "Stolen",
      },
      components: {
        coming_soon_modal: {
          title: "Coming Soon",
          message:
            "This feature will be available soon. If you have any questions or need help, please contact us",
        },
        kyc_verification_widget: kycVerificationWidget["en-US"],
        paid_card_shipping_details_form: paidCardShippingDetailsForm["en-US"],
        role_switcher: {
          roles: {
            [UserRole.OWNER]: "Owner Account",
            [UserRole.ADMIN]: "Admin Account",
            [UserRole.SUPERADMIN]: "Admin Account",
            [UserRole.EMPLOYEE]: "Worker Account",
          },
        },
        accordion: {
          show: "Show",
          hide: "Hide",
        },
        bank_account_section: bankAccountSectionTranslation["en-US"],
        bonuses_list_table: bonusesListTableTranslations["en-US"],
        bonus_templates_list_table: bonusTemplatesListTable["en-US"],
        empty_list: {
          empty_search_list: {
            message:
              "<1>Sorry.</1> <2>Your search produced zero results.<br/>Please try again.</2>",
          },
          empty_workers_list: {
            message: `<2>Please add your workers via uploading a CSV file or add individually.</2> <br /><br />
            <2>Once added, you may invite them to enroll in ${APPLICATION_NAME} Payroll.</2>`,
          },
        },
        pending_exit_tooltip: {
          title: "User Pending Exit",
          message:
            "This user has been marked as exiting the company and cannot receive a new bonus. If you would like to assign a new bonus, please move the employee back into an active status.",
          message_short: "This user has been marked as exiting the company.",
        },
        employee_selection_table: {
          search_placeholder: "Search by Name, Role or Department",
          columns: {
            employee: "Employee",
            department: "Department",
            added: "Added",
          },
          select_all: "Select All",
          add_btn: "Add",
          remove_bth: "Remove",

          employees_list_empty_state_message: "Add members to begin building your group.",
          added_list_empty_state_message: "There are no employees available to be added",
        },
        employee_groups_table: {
          search_placeholder: "Search by Name, Role, Department or Group",
          create_group_btn: "Create Group",
          archived_groups_count_text: "Archived Groups: {{count}}",
          empty_state_message: "No Groups",
          columns: {
            group_name: "Group Name",
            members: "Members",
          },
          context_menu: {
            edit_group_btn: "Edit Group",
            // delete_group_btn: "Delete Group",
            // archive_group_btn: "Archive Group",
          },
          archived_groups_modal: {
            title: "Archived Groups",
            restore_btn: "Restore",
          },
        },
        navigation_card_with_details: navigationCardWithDetailsTranslations["en-US"],
        next_milestone_widget: {
          congratulations_state: {
            title: "Congratulations!",
            text: "You just hit a bonus milestone today.",
            text_completed: "You earned your bonus",
            view_btn: "View",
          },
          upcoming_state: {
            title: "Upcoming...",
            subTitle: "Just {{daysCount}} <1/>",
            text: "Until your next milestone!",
            view_btn: "View Details",
          },
        },
        payment_methods: paymentMethodsTranslations["en-US"],
        repayment_loan_row: {
          one_year: "1 Year Repayment Loan",
          three_year: "3 Year Repayment Loan",
          loan_start_date: "Start Date {{loanStartDate}}",
          interest_rate: "{{interestRate}}% Interest",
          days_remaining: "Days Remaining {{remainingDays}}",
          min_monthly_payment: "Minimum Monthly Payment<br/>",
          next_payment_due_date: "Next Payment Due<br/>",
          remaining_balance: "REMAINING BALANCE<br/>",
          make_payment_btn: "Make a Payment",
          fully_paid_message: "Paid IN FULL",
        },
        how_taxes_work: {
          text: "How do taxes work?",
          tooltip:
            "<1>How do taxes work?</1><2>The amount you enter here is the gross pre-tax amount. Taxes should be set aside by the employer to pay the IRS on behalf of the employee at each vesting milestone.</2>",
        },
        balance_due_bonus_row: {
          unit_vesting_bonus: "Unit Vesting Bonus",
          time_vesting_bonus: "Time Vesting Bonus",
          performance_challenge_bonus: "Performance Challenge",
          bonus_accepted_date: "Bonus accepted on: ",
          days_left: "Days Left",
          total_vested: "Total Vested Amount",
          unvested_amount: "Unvested/Amount Due",
        },
        loan_vesting_schedule: {
          accordion_header: {
            total_principal: "Principal ",
            interest_rate: "Interest Rate ",
            start_date: "Start Date ",
          },
          accordion: {
            title_one: "{{count}} Year Special Payment Plan Schedule",
            title_other: "{{count}} Years Special Payment Plan Schedule",
            mobile_title_one: "{{count}} Year Repayment Loan",
            mobile_title_other: "{{count}} Years Repayment Loan",
          },
          columns: {
            period: "Period",
            date: "Date",
            monthly_payment: "Monthly Payment",
            interest_payment: "Interest Payment",
            principal_payment: "Principal Payment",
            loan_balance: "Loan Balance",
          },
          additional_final_payment: "Additional Final Payment",
          remaining_balance: "Remaining Balance",
        },
        dashboard_bonus_card: {
          reasons: {
            termination: "Exit from company",
            stopped_unit: "Unit Vesting Bonus",
          },
          balance_due_label: "Balance Due",
          bonus_amount_label: "Total Bonus Amount",
          view_payment_options_btn: "View Payment Options",
        },
        schedule_repayment_plan_modal: {
          title: "Special Payment Plan",
          message_1: `A ${APPLICATION_NAME} representative is here to help set up your 1 or 3 year Special Payment Plan and answer any questions you may have.`,
          message_2: "Please Schedule time to configure your plan.",
          schedule_btn: "Schedule a call",
        },
        balance_due_with_date: {
          balance_due: "Balance Due",
          loan_repayment_plan:
            "<1>{{specialPaymentPlanLength}} {{years}} Repayment Plan:</1> {{remainingDays}} {{days}} Remaining.",
          repayment_period_text: "Your <1>Repayment Period</1> ends {{repaymentDate}}",
          repayment_period_tooltip:
            "Your {{days}} day Deferred Interest payback period ends {{repaymentDate}}. In the event you have a balance at the end of the payback period, you will be asked to convert your balance due into a 1 year (5% interest) or 3 year (7% interest) repayment loan. In the event you do not configure your repayment loan and sign the contract before that date, your balance will be deemed “In-default” and the full amount will be due immediately.",
        },
        HRIS_integration_info_row: {
          records_synced: "Records Synced",
          records_count_label: "{{count}} Records",
          records_failed_count_label: "<1>{{count}} Records</1> <2>Failed</2> to Sync",
          last_sync_date: "Last Sync:<br/> {{dateTime}}",
          remove_btn: "Remove",
          tooltips: {
            data_sync_tooltip:
              "This data connection with the HR system syncs every 24 hours at midnight EST.",
            disconnected_tooltip:
              "There appears to be a lost connection with the HR system. Please remove and reconnect.",
            records_failed_tooltip:
              "Please click on the number of records that did not sync link to review the data. Please ensure the data is correct and try again.",
          },
          reconnect_btn: "Reconnect",
          disconnect_btn: "Disconnect",
        },
        bonus_status_tooltip: {
          bonus_one: "bonus",
          bonus_other: "bonuses",
          be_one: "is",
          be_other: "are",
          challenges: {
            [BonusStatus.NOT_COMPLETED]: {
              short:
                "A missed goal is one where the employer responsible for reviewing this goal has determined that the goal has not been met and a reward payment will not be paid.",
              details:
                "A missed goal is one where the employer responsible for reviewing this goal has determined that the goal has not been met and a reward payment will not be paid.",
            },
            [BonusStatus.COMPLETED]: {
              short:
                "Goal achieved means that one or more goals have been reached for this challenge and reward payment has been issued",
              details:
                "Goal achieved means that one or more goals have been reached for this challenge and reward payment has been issued",
            },
            [BonusStatus.ACTIVE]: {
              short:
                "An Active Performance Challenge is considered live until the administrator of the challenge records it as complete or incomplete upon reaching the due date of the challenge.",
              details:
                "An Active Performance Challenge is considered live until the administrator of the challenge records it as complete or incomplete upon reaching the due date of the challenge.",
            },
            [BonusStatus.DRAFT]: {
              short:
                "The bonus is in a draft state and can be modified before sending it to an employee.",
              details:
                "This employee has <1>{{count}}</1> draft {{bonus}}. The bonus will remain in a draft state until it is sent to the employee.",
            },
            [BonusStatus.VOIDED]: {
              short: "The bonus was sent to the employee and was voided by the employer.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that has been voided. The bonus was sent to the employee and was voided by the employer.",
            },
            [ExtensionBonusStatuses.PAST_DUE]: {
              short: "The date deadline to complete all the work required has passed.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that {{be}} past due date. <br/> <br/>The date deadline to complete all the work required has passed. You may update the recorded progress to show the goal has been completed or select Stop Vesting and call a balance due. ",
            },
            [BonusStatus.VESTING_STOPPED]: {
              //TODO: For some reason, we have VESTING_STOPPED challenges. Investigate later
              short:
                "The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that stopped vesting. The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
            },
          },
          bonuses: {
            [BonusStatus.DRAFT]: {
              short:
                "The bonus is in a draft state and can be modified before sending it to an employee.",
              details:
                "This employee has <1>{{count}}</1> draft {{bonus}}. The bonus will remain in a draft state until it is sent to the employee.",
            },
            [BonusStatus.ACTIVE]: {
              short:
                "The bonus has been accepted by the employee and has been fully funded. <br/> <br/>The employee can access the total bonus amount when it is Active.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that has been accepted and has been fully funded. <br/> <br/>The employee can access the total bonus amount when it is Active.",
            },
            [BonusStatus.PENDING]: {
              short:
                "The bonus has been sent to the employee but the employee has not accepted it.",
              details:
                "This employee has <1>{{count}}</1> pending {{bonus}}. A pending bonus is one that has been sent to the employee but the employee has not accepted it.",
            },
            [BonusStatus.ACCEPTED]: {
              short:
                "An employee has accepted a bonus, but the funds have not been yet been approved by Keep.",
              details: `This employee has <1>{{count}}</1> accepted {{bonus}}. An accepted bonus is one where the employee has accepted the terms of the bonus, but the funds have not been reviewed and approved by ${APPLICATION_NAME}.`,
            },
            [BonusStatus.COMPLETED]: {
              short:
                "A fully vested bonus is one where the employee has met all the milestones for the Keep bonus. <br/> <br/>The employee has earned the bonus and can keep the full amount.",
              details:
                "This employee has <1>{{count}}</1> fully vested {{bonus}}. A fully vested bonus is one where the employee has met all the milestones for the Keep bonus. <br/> <br/>The employee has earned the bonus and can keep the full amount.",
            },
            [BonusStatus.VESTING_STOPPED]: {
              short:
                "The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that stopped vesting. The bonus stopped vesting because the employee has not met the terms of the bonus or the employee has left the company. <br/> <br/>Any unvested amount taken by the employee is owed back to the employer.",
            },
            [BonusStatus.VOIDED]: {
              short: "The bonus was sent to the employee and was voided by the employer.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that has been voided. The bonus was sent to the employee and was voided by the employer .",
            },
            [BonusStatus.DECLINED]: {
              short: "The employee declined the bonus that was sent to them.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that has been declined. The employee declined the bonus terms that was sent to them.",
            },
            [ExtensionBonusStatuses.PAST_DUE]: {
              short: "The date deadline to complete all the work required has passed.",
              details:
                "This employee has <1>{{count}}</1> {{bonus}} that {{be}} past due date. <br/> <br/>The date deadline to complete all the work required has passed. You may update the recorded progress to show the goal has been completed or select Stop Vesting and call a balance due. ",
            },
          },
        },
        employee_repayment_period_info_frame: {
          repayment_period_message:
            "Repayment Period Ends: <1>{{repaymentPeriodEndDate}}</1><br/><2>{{daysUntilEndRepaymentPeriod}} remaining</2>",
          repayment_loan_message:
            "<1>Exited</1> - {{years}} Repayment Loan<br/>({{daysUntilEndLoanPeriod}} remaining)",
        },
        validation_modal: {
          title: "Sorry",
          text: " This employee is <1>{{status}}</1>.<br />Therefore you will be unable to complete this action.",
          [EComprehensibleUserStatuses.PENDING_EXIT]: "pending exit",
          [EComprehensibleUserStatuses.EXITED]: "exited",
          messages: {
            [EComprehensibleUserStatuses.PENDING_EXIT]:
              "This employee will no longer be with the company after",
            [EComprehensibleUserStatuses.EXITED]:
              "This employee is no longer with the company since",
          },
        },
        create_termination_error_modal: {
          title: "Sorry",
          text: "This user is the Account Owner for the Keep Account. Therefore, you will be unable to end the Employment and remove this user from the Keep Account without contacting Keep Customer Support.",
          email: "help@keepfinancial.com",
        },
        employee_repayment_cards: {
          make_payment: "Make a payment",
          make_payment_description: "Make a payment or pay off your entire balance.",
          make_payment_btn: "Make a payment",
          or: "OR",
          configure_special_plan: "Special Payment Plan",
          configure_special_plan_description: "Select a 1 or 3 year low interest repayment plan.",
          configure_special_btn: "Select a Plan",

          make_payment_tooltip:
            "Here you may make a payment towards your balance due from your connected bank account. Failure to pay the balance in full or configure a Special Payment Plan by the end of the repayment period will result in the loan balance going into default.",
          configure_special_plan_tooltip:
            "Don’t have all the money right now to pay off your balance due? Not a problem. You may select a 1 or 3 year low interest Special Payment Plan to pay off your remaining balance over time.  Failure to pay the balance in full or configure a Special Payment Plan by the end of the repayment period will result in the loan balance going into default.",
        },
        image_uploader: {
          add_btn: "Add",
          photo: "Photo",
          logo: "Logo",
          remove_btn: "Remove",
          optional: "Optional",
          your_logo: "Your Logo",
        },
        docu_sign_preview_modal: {
          generate_contract_btn: "Generate Contract",
          service_agreement_title: "View Technologies Platform Agreement",
          bonus_contract_title: "View Contract",
          service_agreement_message:
            "You are about to be redirected to DocuSign to review Technologies Platform Agreement.",
          bonus_contract_message: "You are about to be redirected to review Bonus Agreement.",
          primaryBtn: "Open Link",
          secondaryBtn: "Cancel",
        },
        personal_email_modal: {
          title: "Personal Email Address",
          subTitle:
            "Please provide a personal email address that is different<br />than the primary email address you use to sign into your<br />account. This may be used for recovery purposes.",
          personalEmailTitle: "Please enter your new personal email address",
          personalEmailLabel: "Personal Email Address",
          verifyPersonalEmailTitle: "Please re-enter your new personal email address",
          verifyPersonalEmailLabel: "Re-enter your Personal Email Address",
          submit: "Submit",
          cancel: "Cancel",
          successModal: {
            title: "Personal email saved",
            message: "Your profile personal email was successfully saved.",
            ok: "OK",
          },
        },
        contact_info_changed_modal: {
          title: "Employee information updated",
          confirm_button: "Yes, this information is correct",
          cancel_button: "No, this information is incorrect",
        },
        contact_info_changed_incorrect_modal: {
          title: "Please update your information.",
          message: `
                If you continue to have problems, please contact:
                <1></>
              `,
          main_button: "Copy Email Address",
        },
        backArrowBtn: {
          back_to: "Back to ",
        },
        confirm_action: {
          title: "Are you sure?",
          ok: "OK",
          cancel: "Cancel",
        },
        bank_account_info_long: {
          account_number: "Account Number",
          account_type: "Account Type",
          verify_btn: "Verify Account",
          reconnect_btn: "Reconnect Account",
          pending_verification_link: "Pending Verification",
          bank_name_placeholder: "No Bank Name",
          verification_status: {
            [BankAccountVerifyStatus.VERIFICATION_FAILED]: "Verification Failed",
            [BankAccountVerifyStatus.VERIFICATION_EXPIRED]: "Verification Expired",
          },
        },
        bank_account_select: {
          add_new_bank: "Add New Bank",
          verify_message: "Bank pending verification.",
        },
        bank_account_verification_info_modals: {
          [BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION]: {
            title: "Pending automatic verification",
            message:
              "We've received your request to connect your bank account using automatic micro-deposit verification.",
          },
          [BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION]: {
            title: "Pending manual verification",
            message:
              "We’ve received your request to connect your bank account using micro-deposit verification.",
            sub_title: "What’s next:",
            message_2:
              "Over the next 2-3 business days you will receive two small deposits to your bank account. Once these deposits are made, you will get an email with instructions on how to complete your account verification.",
          },
        },
        help_phone: {
          text: "Questions? Talk to a Keep Onboarding Specialist",
          phone: "555-555-5555",
        },
        main_menu: {
          setup: "Setup",
          home: "Home",
          people: "People",
          pay_admin: "Pay",
          pay_employee: "Pay",
          help_center: "Help",
          settings_admin: "Settings",
          settings_employee: "Settings",
          banking_admin: "Banking",
          banking_employee: "Banking",
        },
        account_menu: {
          info: "Name, title, email, phone",
          log_out: "Log out",
        },
        employee_info: employeeInfo["en-US"],
        progress_bar: progressBarTranslations["en-US"],
        banking_disclosure: bankingDisclosureTranslations["en-US"],
        bonut_type_select_modal: {
          title: "Choose Bonus Type",
          cards: {
            [BonusType.TIME]: {
              title: "Time Vesting Bonus",
              text: "Reward employees with a bonus that vests over time.",
            },
            [BonusType.UNIT]: {
              title: "Unit Vesting Bonus",
              text: "Reward employees with a bonus that vests over units of completed work milestones.",
            },
            [BonusType.CHALLENGE]: {
              title: "Performance Challenge",
              text: "Offer employees compensation for completing challenges or hitting KPIs.",
            },
          },
        },
        edit_bonus_form: {
          form_label_main: "Vesting Options",
          form_label_main_tooltip_time:
            "This setting lets you choose from four vesting options. Vesting with equally spaced milestones, vesting with 1 different milestone (a cliff) and multiple equally spaced milestones thereafter, vesting 100% Bonus amount on the end date or a completely custom vesting schedule that lets you pick the dates and bonus amounts per milestone.",
          form_label_main_tooltip_unit: `<1>Vesting Options</1><br/>
            A vesting milestone is a “number of units completed” target. When each milestone is completed by the recipient, that portion of their loan is paid down. You have four vesting milestone options.
          `,
          bonus_amount: "Amount",
          bonus_amount_tooltip:
            "Provide the <1>Net Amount</1>, which is calculated by taking the full amount, minus the anticipated estimate for payroll taxes. <br /><br />When the employee accepts the bonus, Keep will transfer the <1>Net Amount</1> to the employee. At each milestone, the portion that vests is taxable income and payment to the IRS is the responsiblity of the employer.",
          vesting_max_length: "Vesting Max Length",
          first_vesting_milestone: "First Vesting Milestone",
          thereafter_vest_every: "Thereafter Vest Every...",
          start_date: "Start Vesting Date",
          start_date_tooltip:
            "Each bonus has a starting vesting date. You can configure milestones for when the bonus is paid towards an employee loan. At the final vesting date, 100% of the bonus will have been paid down by the employer.<br /><br />This setting lets you configure the bonus start date.",
          end_date: "End Date: {{endDate}}",
          end_date_tooltip:
            "Each bonus has a starting and ending vesting date. Between those dates are vesting milestones where when reached a portion of the total amount are paid off. At the end vesting date 100% of the bonus will have been paid down by the employer. Here you may enter when you wish for the vesting to begin and the final date when it should be paid in full by the employer.",
          end_date_placeholder: "Date",
          total_vesting_period: "Total Vesting Period",

          time_form: {},
          unit_form: {},

          vesting_units: {
            month: "Month",
            quarter: "Quarter",
            year: "Year",
          },

          vesting_units_plural: {
            month: "Months",
            quarter: "Quarters",
            year: "Years",
          },

          vesting_type_options_placeholder: "Please Choose",
          vesting_type_description_custom: "Configure your custom vesting schedule below.",

          vesting_type_options_tooltips: {
            [VestingScheduleType?.UNIT_VEST_100_PERCENTS_ON_END_DATE]: `<1>Vest 100% upon completion of goal</1><br/>
              Recipient must complete 100% off the units by the complete all date. If they do not complete 100% of the units, the full repayment of the entire balance is due.
            `,
            [VestingScheduleType?.UNIT_EQUALLY]: `<1>Equal vesting milestones</1><br/>
              You may set number of completed unit milestones that absolve the user of repayment of that block of units upon completion.
            `,
            [VestingScheduleType?.UNIT_ON_EACH_UNIT]: `<1>Vest upon completed units</1><br/>
              Upon completion of each unit, the value of that unit is no longer owed by the recipient.
            `,
            [VestingScheduleType?.UNIT_CUSTOM]: `<1>Custom milestones</1><br/>
              You may set custom number of unit milestones that add up to the total to be completed before the end date.
            `,
          },

          time_vesting_type_options: {
            vesting_equally: "Vest equally every",
            vesting_equally_after_cliff: "Vest equally after a cliff",
            vesting_100_percent_on_end_date: "Vest 100% on End Date",
            custom_vesting_schedule: "Custom Vesting Schedule",
          },

          unit_vesting_type_options: {
            vesting_100_percent_on_completion: "Vest 100% upon completion of the Goal",
            vesting_equally: "Vest Equally Over",
            vesting_on_each_unit: "Vest on each unit completed",
            custom_vesting: "Custom Vesting",
          },

          forms: {
            vesting_equally: {
              period_label: "Vesting Interval",
            },

            vesting_equally_after_cliff: {
              first_milestone_date: "Set Cliff at",
              first_milestone_date_tooltip:
                "This setting lets you configure the 1st vesting milestone or “cliff” schedule to be different from the milestones that come after it.<br /><br /> This is helpful if you wish to have the employee stay for a while before the first payment. ",
              first_milestone_date_placeholder: "Date",
              first_vest_amount: "Vest",
              therefore_period_label: "Thereafter vest equally every",
            },

            vesting_schedule: {
              title: "Vesting Milestones",
              title_sample: "Vesting Schedule Sample",
              title_custom: "Custom Vesting Milestones",
              calculation_is_impossible:
                "Please complete the form above to view the Vesting Schedule.",
            },

            goal_form: {
              complete_label: "Complete",
              unit_name: "Unit Name",
              unit_name_tooltip: `<1>Unit Name</1><br/>A “Unit” is a “Unit of Work” completed. An example of this may be a nurse working a shift, a sales person closing a deal, a driver completing a delivery. You may give each of these “units” a name such as “Shift”, “closed deal” or “delivery”. When creating a goal, that may look like” Complete 50 closed deals by 2/15/2025.`,
              unit_name_example: "Example: Deliveries, Night Shits, etc.",
              complete_by: "Complete all by",
              complete_by_tooltip:
                "<1>Complete all units by</1><br />Every goal has an end date. All the units must be accomplished by the bonus recipient by the date you define here to become fully vested. You may create vesting milestones under vesting options drop down below.  ",
              by: "By",
            },

            unit_vest_equally_form: {
              quantity_description: "Equal Milestones",
            },

            unit_vest_on_each_unit: {
              description_placeholder: "Please provide your bonus details above",
              description:
                "Bonus vests <1>{{amount}}</1> for each “<1>{{goalName}}</1>” completed.",
            },
          },
          errors: {
            common: "Field is invalid",
            title_required: "Title is required",
            title_exceeds_max: `Title must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
            vesting_type_required: "Please choose bonus type",
            vesting_schedule_type_required: "Please choose a vesting option",
            amount_required: "Amount is required",
            amount_too_large: "Amount is too large",
            amount_cannot_be_zero: "Amount cannot be zero",
            amount_per_goal_is_too_small:
              "The Milestone Vesting Amount should be higher than {{minGoalAmount}}. Please increase your Bonus Amount or reduce your Goals number.",
            number_of_days_required: "Number of days is required",
            start_date_required: "Start date is required",
            end_date_is_smaller_or_equal_to_start_date:
              "End date can not be earlier or equal to the start date",
            end_date_must_be_2_days_apart_from_start_date:
              "End date must be at least 2 days apart from start date",
            stipulations_too_long: `Special stipulations must not exceed ${MAX_TEXTAREA_LENGTH} characters`,
            first_vesting_bigger_than_max:
              "First Vesting Milestone should not be bigger than Vesting Max Length.",
            vesting_length_exceeds_loan:
              "Vesting length exceeds the max length of the loan set above. Please modify your settings.",
            payback_terms_days_cannot_exceed_max: "Number of days cannot exceed {{maxValue}} days",

            vesting_period_required: "Vesting period is required",
            vesting_period_cannot_be_zero: "Value cannot be zero",
            vesting_period_cannot_exceed_max:
              "Total vesting periods cannot exceed {{maxYears}} years",
            vesting_interval_cannot_exceed_period:
              "Vesting Interval can not exceed Total Vesting Period",
            choose_end_date_option: "Vesting length can not be equal to Vesting Interval",

            vesting_equally_after_cliff: {
              first_milestone_required: "Please select period length",
              first_milestone_cannot_be_zero: "Value cannot be zero",
              first_milestone_cannot_exceed_max:
                "First milestone must be within {{maxYears}} years from start date",
              first_milestone_cannot_exceed_total_period:
                "Cliff can not exceed Total Vesting Period",
              first_milestone_cannot_be_equal_to_total_period:
                "Cliff can not be equal to Total Vesting Period",
              first_vesting_amount_required: "Amount is required",
              first_vesting_amount_too_large: "Amount is too large",
              first_vesting_amount_cannot_be_zero: "Amount cannot be zero",
              first_vesting_amount_larger_than_bonus_amount: "Amount can not exceed Bonus amount",
              first_vesting_amount_and_bonus_amount_should_be_same:
                "If there is no vesting after 1st Milestone then 1st Vesting Milestone Amount should be same as Bonus Amount",
            },

            unit_vesting_equally: {
              milestones_quantity_exceeded_max: `Milestones quantity must not exceed ${BONUS_MAX_MILESTONES_QUANTITY}`,
              milestones_quantity_exceeded_goal_quantity: `Milestones quantity must not exceed unit goal quantity`,
              milestones_quantity_required: `Milestones quantity is required`,
              milestones_quantity_cannot_be_zero: `Milestones quantity cannot be zero`,
            },

            goal_quantity_exceeded_max: `Goal quantity must not exceed ${BONUS_MAX_GOAL_QUANTITY}`,
            goal_quantity_cannot_be_zero: `Quantity cannot be zero`,
            goal_quantity_required: `Goal quantity is required`,
            goal_name_exceeded_max: `Goal name must not exceed ${MAX_INPUT_LENGTH_LONG} characters`,
            goal_name_required: `Goal name is required`,
            goal_complete_by_required: `Goal completion date is required`,
            goal_complete_by_date_cannot_exceed_max: "End date cannot exceed {{maxYears}} years",
            goal_date_cannot_be_before_start_date: "Goal End date must be after Start Date",
            goal_date_is_too_close_too_start_date:
              "Goal End date and Start Date must be at least 2 days apart",
          },
        },
        bonus_summary: bonusSummaryTranslations["en-US"],
        bonus_card: bonusCardTranslations["en-US"],
        bonus_details_footer: {
          accordion1_title: "Vesting Milestones",
          accordion2_title: "Bonus Contract",
          accordion1_subtitle_collapsed: "Show Milestones",
          accordion1_subtitle_expanded: "Hide Milestones",
          accordion2_subtitle_collapsed: "View Contract",
          accordion2_subtitle_collapsed_kyc_accepted: "Generate Preview",
          accordion2_subtitle_expanded: "Hide Contract",
          descriptions: {
            [BonusStatus.VESTING_STOPPED]: "<1>Vesting has been <br />stopped for this bonus.</1>",
            [BonusStatus.COMPLETED]: "<3>FULLY VESTED</3>",
            [ExtensionBonusStatuses.PAST_DUE]:
              "Complete all units by date <br /><1>{{date}}</1> <2>has passed.</2>",
          },
        },
        vesting_schedule: {
          cell_title: "Vested Milestone - <1>{{vestingUnit}}</1> {{vestingNumber}}",
        },
        vesting_schedule_detailed: {
          add_milestone: "Add Milestone",
          milestone_title: "Milestone",
          date_title: "Date",
          units_quantity_title: "Number of Units",
          percentage_title: "% Vested",
          amount_title: "$ Vested",
          milestone_value: "Milestone {{number}}",
          unit_quantity_value: "{{number}} units",
          unit_quantity_total: "{{number}} units total",
          reward_value: "Reward Earned",
          start_date: "Start Date",
          start_date_unit_date: ": {{date}}",
          end_date: "End Date",
          end_date_unit_date: "- End Date: {{date}}",
          empty_list_message: "Please define your bonus details above.",
          table_footer: {
            title: "Total Vesting Period",
            years_and_days: "{{years}} Years and {{days}} Days",
            full_days: "({{days}} total days)",
          },
          quantity_tip: "Must add up to <1>{{quantity}}</1>",
          percentage_tip: "Must add up to 100%",
          amount_tip: "Must add up to <1></1>",
          errors: {
            milestones_count_is_zero: "There should be at least 1 milestone",
            milestone_date_is_smaller_than_previous_milestones_date:
              "This milestone must be after the previous milestone.",
            milestone_date_cannot_exceed_max:
              "Total vesting period cannot exceed {{maxYears}} years",
            milestone_date_is_too_close_too_start_date:
              "Milestone date and Start date must be at least 2 days apart",
            amount_cannot_be_zero: "Amount must be greater than zero.",
            milestone_quantity_is_required: "Number of units must be greater than zero.",
            milestone_quantity_cannot_be_zero: "Number of units must be greater than zero.",
          },
          tooltips: {
            admin_employee_has_reached_the_milestone_faster:
              "Employee has reached this milestone faster.",
            admin_employee_has_reached_the_milestone_faster_with_balance:
              "Employee has reached this milestone faster. The last milestone date and amount due have been updated to reflect the reward.",
            employee_employee_has_reached_the_milestone_faster:
              "Congrats! You have reached this milestone faster thanks to your great performance.",
            employee_employee_has_reached_the_milestone_faster_with_balance:
              "Congrats! You have reached this milestone faster thanks to your great performance. The date of your last milestone and amount due has been updated to reflect the reward.",
          },
        },
        navigation_prompt: {
          title: "Are you sure?",
          message: "The changes won’t be saved if you leave the page.",
          confirm: "Leave the page",
          cancel: "Cancel",
        },
        bonus_legal_result: {
          title: "Bonus summary",
          show_more: "Show more...",
          show_less: "Show less",
        },
        bonus_details_status_info: {
          title_pending: "The below bonus is awaiting contract acceptance from the employee.",
          title_declined: "The below bonus has been declined by the employee.",
          title_accepted: "The below bonus has been accepted and is awaiting funding.",
          text_pending: "Bonus Invitation sent {{sentDate}} from {{fullName}}",
          text_declined: "Bonus Invitation sent {{sentDate}} from {{fullName}}",
          text_accepted: "Bonus Invitation accepted on {{acceptedDate}}",
        },
        safe_and_secure: {
          title: "Safe & Secure Transfer Guarantee",
          text: "Keep ensures that your money transfer is safe and secure. Keep uses the highest levels of bank security. Keep uses Plaid, a secure 3rd party service to connect your bank and transfer funds.",
        },
        keep_footer: {
          footer_title: "Keep employers and employees together longer",
          footer_subtitle: "Attracting and keeping top talent has never been easier.",
          footer_1: "Flexible Vesting <br /> Bonus Plans",
          footer_2: "Starting <br /> Bonuses",
          footer_3: "Retention <br /> Bonuses",
          footer_4: "Increase <br /> Productivity",
          footer_5: "Lower <br /> Attrition",
          copyright: `© 2024 ${APPLICATION_PARENT_NAME_FULL} Technologies Inc. All rights reserved.`,
          privacy_policy: "Privacy Policy",
          terms_of_service: "Terms of Service",
          terms_block: "{{one}} | {{two}}",
        },
        transactions_list: {
          transaction: "Transaction",
          date: "Date",
          status: "Status",
          type: "Type",
          bank_name_placeholder: "No Bank Name",
          type_descriptions: {
            [ITransactionTypes.BONUS]: {
              description_1: "<1 /> Bonus",
              description_2: "To: <1>{{bankName}}</1><2>{{last4Digits}}</2>",
            },
            [ITransactionTypes.FORGOING]: {
              description_1: "<1 /> Balance Reduction",
              description_2: "Balance Due reduced by {{companyName}}",
            },
            [ITransactionTypes.REPAYMENT]: {
              description_1: "<1 /> Payment",
              description_2: "To: <1>{{bankName}}</1><2>{{last4Digits}}</2>",
            },
          },

          type_descriptions_banking: {
            [SyncteraAccountTransactionDcSignType.DEBIT]: {
              description_1: "-<1 />",
              description_2: "To: <1>{{bankName}}</1> <2>{{last4Digits}}</2>",
            },
            [SyncteraAccountTransactionDcSignType.CREDIT]: {
              description_1: "<1 />",
              description_2: "{{description}}",
            },
          },

          empty_list_title: "Not Found",
          empty_list_description:
            "We were unable to find any record of your most recent transaction",
          balance_due: "Balance Due",
          loan: "Paid {{loanId}}",
        },
        transaction_status_banking: {
          [SyncteraTransactionStatus.PENDING]: "Pending...",
          [SyncteraTransactionStatus.REPLACED]: "Replaced",
          [SyncteraTransactionStatus.DECLINED]: "Declined",
          [SyncteraTransactionStatus.RELEASED]: "Released",
          [SyncteraTransactionStatus.PARTCLEARED]: "Cleared",
          [SyncteraTransactionStatus.CLEARED]: "Cleared",
          [SyncteraTransactionStatus.EXPIRED]: "Expired",
          [SyncteraTransactionStatus.INTERNAL_ERROR]: "Error",
          [SyncteraTransactionStatus.POSTED]: "Success",
        },
        transaction_type_banking: {
          [SyncteraAccountTransactionType.ACH]: "ACH",
          [SyncteraAccountTransactionType.CARD]: "Card",
          [SyncteraAccountTransactionType.CHECK]: "Check",
          [SyncteraAccountTransactionType.EXTERNAL_CARD]: "External Card",
          [SyncteraAccountTransactionType.INTERNAL_TRANSFER]: "Internal Transfer",
          [SyncteraAccountTransactionType.WIRE]: "Wire",
        },

        transaction_status: {
          //TODO: Remove later, along with old component
          FUNDING_labels: {
            PENDING: "Pending...",
            CANCELED: "Failed",
            RETURNED: "Returned",
            COMPLETE: "Paid",
          },
          DEPOSIT_labels: {
            PENDING: "Pending...",
            CANCELED: "Failed",
            RETURNED: "Returned",
            COMPLETE: "Deposited",
          },
          PAYMENT_labels: {
            PENDING: "Pending...",
            CANCELED: "Failed",
            RETURNED: "Returned",
            COMPLETE: "Received",
          },
          tooltips: {
            PENDING: "ACH Transfers can take up to 48 hours to clear and post in the account.",
            CANCELED:
              "We are unable to process your payment. Please check your account information and try again.",
            COMPLETE: "Your payment has been processed successfully.",
            RETURNED: "",
          },
        },
        restricted_bonus_creation: {
          title: "Cash Plan Limitation",
          message:
            "<1>Unfortunately</1> we are unable to provide vesting cash plans to employees that live in the state of <2>{{state}}</2> at this time. <br /><br />Keep is actively pursuing our {{state}} lending license and will be providing the functionality very soon. We will notify you via email when this becomes available.",
          message_alt:
            "<1>Unfortunately</1> we are unable to provide vesting cash plans to employees that live in the state of <2>{{state}}</2> at this time. Keep is actively pursuing our {{state}} lending license and will be providing the functionality very soon. We will notify you via email when this becomes available.",
        },
        switch: {
          switch_default_checked: "Yes",
          switch_default_unchecked: "No",
        },
        spouse_notification: {
          text: "<1>{{state}} residents</1> are required by law to inform their spouses upon submitting loan applications. Each Keep Vesting Cash Bonus is structured as a 0% loan that would require repayment in the event of exit before fully vesting.",
          default_state: "State",
          switch_label: "Are you currently married?",
          switch_checked: "Yes",
          switch_unchecked: "No",
          email_label: "Spouse email address",
          wrong_email_format: "Wrong email format",
          email_is_required: "Email is required",
        },
        performance_challenge: performanceChallengeTranslations["en-US"],
        contact_info: {
          address: "Address",
          ssn: "Social Security Number",
          phone: "Phone",
          date_of_birth: "Date Of Birth",
        },
        need_help_widget: {
          title: "Need help?",
          message: "Not a problem. For questions about your compensation, please reach out to",
          footer_message: "For general questions, please visit the",
          help_center_link: "Keep help center.",
        },
      },
    },
  },
  nl: {
    translation: {
      errors: {
        defaultTitle: "Error NL",
        defaultMessage: "NL Something went wrong.",
      },
    },
  },
};

export default translations;
