import { FaCheck } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import ChevronBottom from "assets/svg/ChevronBottom";
import styled, { css } from "styled-components";

import { ITheme } from "types";
import { breakpoint } from "helpers/shared/breakpoint";

interface ArrowProps extends ITheme {
  isOpen?: boolean;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
`;

export const InfoBlock = styled.div`
  text-align: right;
  margin-right: 17px;

  ${breakpoint("xs", "xlm")`
    display: none;
  `}
`;

export const InfoCompanyName = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.accountMenuCompanyText};
  line-height: 1.23;
  margin-bottom: 5px;
`;

export const Role = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.accountMenuRoleText};
`;

export const ProfileButton = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DDArrow = styled(ChevronBottom)<ArrowProps>`
  margin-left: 8px;
  transform: rotate(${({ isOpen }) => (isOpen ? "180" : "0")}deg);
  width: 11px;
  height: 9px;

  ${breakpoint("xs", "xlm")`
    path {
      stroke: ${({ theme }) => theme.colors.mainMenuText};
    }
    
  `}
`;

export const MenuContainer = styled.div`
  position: absolute;
  box-sizing: border-box;
  z-index: 1000;
  top: 120%;
  right: 0;
  width: 372px;
  text-align: left;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body2};
  background-color: ${({ theme }) => theme.colors.widgetBg};
  outline: none;
  box-shadow: 0 0 4px ${({ theme }) => theme.colors.widgetShadow};
  border-radius: 8px;

  ${breakpoint("xs", "xl")`
    width: 240px;
  `}
`;

export const MenuBlockCSS = css`
  display: block;
  padding: 20px 24px 20px 18px;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.widgetInnerBorder};
  color: ${({ theme }) => theme.colors.text};

  ${breakpoint("xs", "xl")`
    padding: 16px;
  `}
`;

export const MenuBlock = styled.div`
  ${MenuBlockCSS}
`;

export const ProfileInfo = styled(MenuBlock)`
  display: flex;
  flex-direction: row;
  ${breakpoint("xs", "xl")`
    align-items: center;
  `}
`;

export const PILeft = styled.div`
  margin-right: 23px;

  ${breakpoint("xs", "xl")`
    margin-right: 16px;

    & div {
      width: 32px;
      height: 32px;
    }
  `}
`;

export const PIRight = styled.div`
  margin-right: 23px;
`;

export const PITitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.subtitle};
  color: ${({ theme }) => theme.colors.widgetTitle};
  line-height: 1.5;

  ${breakpoint("xs", "xl")`
    font-weight: ${({ theme }) => theme.fontWeight.semiBold};
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const PIRole = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body2};
  line-height: 1.5;

  ${breakpoint("xs", "xl")`
    font-size: ${({ theme }) => theme.size.caption};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  `}
`;

export const ProfileInfoLink = styled(NavLink)`
  ${MenuBlockCSS}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const PILLeft = styled.div``;

export const PILRight = styled.div``;

export const ArrowRight = styled(ChevronBottom)`
  transform: rotate(270deg);

  ${breakpoint("xs", "xl")`
    display: none;
  `}
`;

export const PILTitle = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 1.4;
`;

export const PILText = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  line-height: 1.4;
`;

export const ProfileLogout = styled(MenuBlock)`
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  line-height: 1.4;
  border-bottom: none;
  cursor: pointer;
`;

export const RoleSwitcherWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyName = styled.p`
  padding: 28px 0 24px 0;
  margin: 0 21px;
  border-bottom: 1px dashed ${({ theme }) => theme.colors.widgetInnerBorder};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.titleAlt};
`;

export const RoleName = styled.p`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 21px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.widgetInnerBorder};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.textAlt};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  cursor: pointer;
  box-sizing: border-box;
  height: 60px;
`;

export const CircleChecked = styled.div`
  background-color: ${({ theme }) => theme.colors.success};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 23px;
  border-radius: 50%;
`;

export const CheckIcon = styled(FaCheck)`
  color: ${({ theme }) => theme.colors.primaryTextAlt};
  position: relative;
  top: 1px;
`;
