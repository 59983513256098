import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  justify-content: center;
`;

export const StepLabel = styled.label`
  display: block;
  box-sizing: border-box;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.stepIndicatorInactive};
  line-height: 1.7;
`;

export const StepContainer = styled.div`
  padding: 0 14px;
  margin: 0 4px;
  border-bottom: 1px solid transparent;
  &.active {
    border-bottom: 1px solid ${({ theme }) => theme.colors.stepIndicatorActive};
    ${StepLabel} {
      color: ${({ theme }) => theme.colors.stepIndicatorActive};
    }
  }
  &.clickable {
    cursor: pointer;
    ${StepLabel} {
      cursor: pointer;
    }
  }
`;
