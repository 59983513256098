import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";

import { userMetadataSelector } from "redux/selectors";
import { useBankAccountLink } from "hooks";

export const useEmployeeBankAccountsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector(userMetadataSelector);

  const [isLoading, setLoading] = useState<boolean>(true);

  const bankAccountLink = useBankAccountLink({ loadingCallback: setLoading });

  const handleBackBtnClick = () => navigate(routes.EMPLOYEE_SETTINGS);

  return {
    metadata: {
      isLoading,
      currentUser,
    },
    pageData: {
      ...bankAccountLink.data,
    },
    actions: {
      ...bankAccountLink.actions,
      handleBackBtnClick,
    },
  };
};
