import { FC } from "react";
import { Trans } from "react-i18next";
import { CircledDots } from "assets/svg";
import moment from "moment";
import queryString from "query-string";

import { defaultDateFormat } from "constants/index";
import { getName } from "helpers";
import DashboardLayout from "layouts/DashboardLayout";

import {
  ContentContainer,
  ContextMenu,
  FormSectionTitleSmall,
  FullScreenLoader,
  PageTitle,
  PageTitleMargined,
} from "uikit";

import useAdminWorkerDetails from "./useAdminWorkerDetails";
import {
  Container,
  FrameContainer,
  MenuButton,
  StartDateLabelText,
  StartDateValueText,
  StyledEmployeeInfo,
  StyledUserStatus,
  TCLeft,
  TCRight,
  TopContainer,
} from "./styles";

interface Props {
  className?: string;
}

const EmptyPage: FC<Props> = ({ className }) => {
  const translationPrefix = `people_page.worker_details`;

  const {
    employeeData,
    isLoading,
    actionsLoading,
    frameId,
    handleBack,
    getContextMenuOptions,
    renderWorkerActionsElements,
  } = useAdminWorkerDetails();

  return (
    <DashboardLayout onBackBtnClick={handleBack} className={className}>
      {(!!isLoading || !!actionsLoading) && <FullScreenLoader />}
      {!!employeeData && (
        <ContentContainer>
          {/* <PageTitleMargined>
              <Trans
                i18nKey={`${translationPrefix}.title`}
                values={{ fullName: getName(employeeData) || "" }}
              />
            </PageTitleMargined> */}

          <TopContainer>
            <TCLeft>
              <PageTitle>
                <Trans
                  i18nKey={`${translationPrefix}.title`}
                  values={{ fullName: getName(employeeData) || "" }}
                />
              </PageTitle>
            </TCLeft>
            <TCRight>
              {employeeData && <StyledUserStatus data={employeeData} />}
              <MenuButton>
                <ContextMenu
                  component={<CircledDots />}
                  options={getContextMenuOptions()}
                  dataTestId="actions-menu"
                />
              </MenuButton>
            </TCRight>
          </TopContainer>

          <StyledEmployeeInfo
            user={employeeData}
            topRightContent={
              !!employeeData?.startDate && (
                <StartDateLabelText>
                  <Trans i18nKey={`components.employee_info.started_at`} />
                  <StartDateValueText>
                    {moment(employeeData?.startDate).format(defaultDateFormat) || "-"}
                  </StartDateValueText>
                </StartDateLabelText>
              )
            }
          />

          <FrameContainer id={frameId}></FrameContainer>

          {renderWorkerActionsElements()}
        </ContentContainer>
      )}
    </DashboardLayout>
  );
};

export default EmptyPage;
