import { FC } from "react";
import { useTranslation } from "react-i18next";

import { Container, StyledCopyright } from "./styles";

interface IProps {
  className?: string;
}

const MenuFooter: FC<IProps> = ({ className }) => {
  const { t } = useTranslation();
  return (
    <Container className={className}>
      <StyledCopyright />
    </Container>
  );
};

export default MenuFooter;
