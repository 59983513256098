import React, { ReactNode } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { get } from "lodash";
import moment from "moment";

import { defaultDateFormat } from "constants/index";

import UILoader from "uikit/Loader/Loader";
import {
  EColumnType,
  IColumn,
  IFooterColumn,
  IInfiniteScrollProps,
  IScrollProps,
} from "uikit/Table/types";

import { TableColumn } from "../TableColumn";
import {
  AdditionalTableHeader,
  EmptyListComponentContainer,
  FooterColumnLabel,
  FooterColumnValue,
  LoaderContainer,
  LoaderWrapper,
  TableContainer,
  TableHeaderWrapper,
  TableInner,
  TBody,
  Td,
  TFCell,
  TFooter,
  THead,
  Tr,
} from "./styles";

interface IProps<T> {
  isLoading: boolean;
  data: T[];
  columns: IColumn[];
  footer?: ReactNode;
  footerColumns?: IFooterColumn[];
  emptyListComponent: ReactNode;
  onRowClick?: (item: T) => void;
  rowClass?: (item: T) => string;
  scrollProps?: IScrollProps;
  infiniteScrollProps?: IInfiniteScrollProps;
  additionalHeaderContent?: React.ReactNode;
  minRowsCount?: number;
  showHeaderOnEmptyList?: boolean;
  showAdditionalHeaderOnEmptyList?: boolean;
}

export const Table = <T,>(props: IProps<T>) => {
  const {
    data,
    columns,
    emptyListComponent,
    isLoading,
    additionalHeaderContent,
    footer,
    footerColumns,
    infiniteScrollProps,
    minRowsCount,
    onRowClick,
    rowClass,
    scrollProps,
    showHeaderOnEmptyList = false,
    showAdditionalHeaderOnEmptyList = true,
  } = props;

  const rowClassName = minRowsCount && data.length < (minRowsCount || 0) ? "bordered_last_row" : "";

  const tBodyClassName = minRowsCount ? "fixed_height" : "";

  const hasNoRecords = !isLoading && !data[0];

  const renderRowTitleByType = (item: string | Date | any, type: EColumnType) => {
    if (type === EColumnType.Text) return item;
    if (type === EColumnType.Date) return item ? moment(item).format(defaultDateFormat) : "";
  };
  const renderHeader = () => {
    return (
      <THead>
        <Tr>
          {columns.map((item: IColumn) => {
            return (
              <TableColumn
                key={item.key}
                title={item.title}
                className={item?.className}
                onClick={item.onClick}
              />
            );
          })}
        </Tr>
      </THead>
    );
  };

  const renderTableMainContent = () => (
    <TableInner>
      {!scrollProps && (!!data?.length || !!showHeaderOnEmptyList) && <>{renderHeader()}</>}
      {isLoading ? (
        <TBody className={tBodyClassName} minRowsCount={minRowsCount}>
          <Tr>
            <LoaderWrapper colSpan={columns.length || 1}>
              <UILoader dataTestId="table-loader" />
            </LoaderWrapper>
          </Tr>
        </TBody>
      ) : (
        data[0] && (
          <TBody className={tBodyClassName} minRowsCount={minRowsCount}>
            {data.map((row: any, indx) => (
              <Tr
                key={indx.toString()}
                className={`${rowClassName} ${rowClass?.(row)}`}
                onClick={() => onRowClick?.(row)}
              >
                {columns.map((column: IColumn) => (
                  <Td key={column.key} className={column.className}>
                    {column.component
                      ? column.component(row, indx)
                      : renderRowTitleByType(get(row, column.key), column.type)}
                  </Td>
                ))}
              </Tr>
            ))}
          </TBody>
        )
      )}
    </TableInner>
  );

  return (
    <>
      <TableContainer
        className={`${
          hasNoRecords && (!additionalHeaderContent || !showAdditionalHeaderOnEmptyList)
            ? "empty-state"
            : ""
        }`}
        maxHeight={scrollProps?.maxHeight || 0}
      >
        {!!additionalHeaderContent && <>{additionalHeaderContent}</>}
        {!!scrollProps && (!!data?.length || !!showHeaderOnEmptyList) && (
          <TableHeaderWrapper>{renderHeader()}</TableHeaderWrapper>
        )}
        {hasNoRecords ? (
          <EmptyListComponentContainer minRowsCount={minRowsCount}>
            <>{emptyListComponent}</>
          </EmptyListComponentContainer>
        ) : (
          <>
            {infiniteScrollProps ? (
              <InfiniteScroll
                {...infiniteScrollProps}
                scrollThreshold={infiniteScrollProps.scrollThreshold || 0.7}
                loader={
                  <LoaderContainer>
                    <UILoader />
                  </LoaderContainer>
                }
              >
                {renderTableMainContent()}
              </InfiniteScroll>
            ) : (
              <>{renderTableMainContent()}</>
            )}
          </>
        )}

        {footer && <>{footer}</>}
        {footerColumns && (
          <TFooter>
            {footerColumns.map((item) => (
              <TFCell key={item.className} className={item.className}>
                <FooterColumnLabel>{item.label}</FooterColumnLabel>
                <FooterColumnValue>{item.value}</FooterColumnValue>
              </TFCell>
            ))}
          </TFooter>
        )}
      </TableContainer>
    </>
  );
};
