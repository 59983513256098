import { FC } from "react";

import {
  getContainerClassName,
  renderAdditionalContent,
  renderBelowContent,
  renderButton,
  renderLabels,
  renderTextBlock,
} from "./renderElements";
import { IProps } from "./types";
import { Container, ContainerOuter, MainContent } from "./styles";

const NavigationCardWithDetails: FC<IProps> = (props: IProps) => {
  const translationPrefix = `components.navigation_card_with_details`;

  const { id, onClick, button, additionalContent, isActive } = props;

  const _className = getContainerClassName(props);

  return (
    <ContainerOuter className={_className} data-test-id={id ? `${id}_card` : undefined}>
      <Container onClick={onClick} className={_className}>
        <MainContent>{renderTextBlock(props)}</MainContent>

        {!!additionalContent && renderAdditionalContent(props)}

        {button || renderButton(props)}

        {!!isActive && renderLabels(props)}
      </Container>

      {renderBelowContent(props)}
    </ContainerOuter>
  );
};

export default NavigationCardWithDetails;
