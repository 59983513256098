import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, FullScreenLoader, Input, PageTitleMargined } from "uikit";

import { useEmployeeProfilePage } from "./useEmployeeProfilePage";
import { EmailSection, FrameContainer } from "./styles";

interface IProps {}

const EmployeeProfilePage: FC<IProps> = ({}) => {
  const { metadata, frameData } = useEmployeeProfilePage();
  const { translationPrefix } = metadata;

  return (
    <DashboardLayout>
      {metadata.isLoading && <FullScreenLoader />}
      <ContentContainer>
        <PageTitleMargined>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </PageTitleMargined>

        <FrameContainer id={frameData.salsaFrameId} />
      </ContentContainer>
    </DashboardLayout>
  );
};

export default EmployeeProfilePage;
