import { FC } from "react";

import IconProps from "./IconProps";

const EyeClosed: FC<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clip-path="url(#clip0_3952_19559)">
      <path
        d="M14 6C12.4 7.778 10.4 8.66667 8 8.66667C5.6 8.66667 3.6 7.778 2 6"
        stroke="#78808F"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M2 9.99989L3.66667 7.46655"
        stroke="#78808F"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14 9.98389L12.3387 7.46655"
        stroke="#78808F"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 11.3334L6.33333 8.66675"
        stroke="#78808F"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99999 11.3334L9.66666 8.66675"
        stroke="#78808F"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3952_19559">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeClosed;
