import { createReducer } from "@reduxjs/toolkit";

import { CompanyResponseDto, UserResponseDto } from "utils/swagger_react_query";

import {
  SET_COMPANY_DATA,
  SET_SALSA,
  SET_WORKER_KYC_ONBOARDING_IN_PROGRESS,
  USER_METADATA_DEFAULT_DATA,
  USER_METADATA_SET_METADATA,
  USER_METADATA_UPDATE_LAST_ACTIVE_ROLE,
  USER_METADATA_UPDATE_PERSONAL_EMAIL,
} from "../constants/ActionTypes";
import { MetaDataAction, MetaDataState } from "../types";

const initialState: MetaDataState = {
  userData: undefined,
  companyData: null,
  salsaData: {
    salsa: null,
    authToken: null,
  },
  workerKycOnboardingInProgress: false,
};

const reducer = createReducer(initialState, (builder) => {
  builder
    .addCase(USER_METADATA_DEFAULT_DATA, (state: MetaDataState) => {
      return state;
    })
    .addCase(USER_METADATA_SET_METADATA, (state: MetaDataState, action: MetaDataAction<any>) => {
      state.userData = action.payload;
    })
    .addCase(
      USER_METADATA_UPDATE_LAST_ACTIVE_ROLE,
      (state: MetaDataState, action: MetaDataAction<UserResponseDto["lastActiveRole"]>) => {
        if (state.userData)
          state.userData.lastActiveRole = action.payload as UserResponseDto["lastActiveRole"];
      },
    )
    .addCase(
      USER_METADATA_UPDATE_PERSONAL_EMAIL,
      (state: MetaDataState, action: MetaDataAction<UserResponseDto["personalEmail"]>) => {
        if (state.userData && state.userData.lastActiveRole === "EMPLOYEE") {
          state.userData.personalEmail = action.payload || "";
        }
      },
    )
    .addCase(
      SET_COMPANY_DATA,
      (state: MetaDataState, action: MetaDataAction<CompanyResponseDto>) => {
        state.companyData = action.payload as CompanyResponseDto;
      },
    )
    .addCase(SET_SALSA, (state: MetaDataState, action: MetaDataAction<any>) => {
      state.salsaData = action.payload;
    })
    .addCase(
      SET_WORKER_KYC_ONBOARDING_IN_PROGRESS,
      (state: MetaDataState, action: MetaDataAction<boolean>) => {
        state.workerKycOnboardingInProgress = action.payload as boolean;
      },
    );
});

export default reducer;
