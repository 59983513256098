import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";

import { BankAccountSection } from "components/BankAccountSection";

import { FullScreenLoader, PageTitleMargined } from "uikit";

import { BackButton, ButtonsContainer, StyledTitle, TitleInner } from "../../styles";
import { EOptionIds, IDirectDepositProps } from "../../types";
import useDirectDeposit from "./useDirectDeposit";
import { AddBankAccountButton, Container } from "./styles";

const DirectDeposit: FC<IDirectDepositProps> = (props) => {
  const { className, onBack } = props;
  const translationPrefix = `components.payment_methods.options.${[EOptionIds.DIRECT_DEPOSIT]}`;
  const { bankAccounts, actions, isLoading } = useDirectDeposit(props);

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container className={className}>
        <StyledTitle>
          <TitleInner>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </TitleInner>
          <AddBankAccountButton onClick={actions.onConnectBankAccount}>
            <PlusThin />
            <Trans i18nKey={`${translationPrefix}.add_bank_account_button`} />
          </AddBankAccountButton>
        </StyledTitle>

        <BankAccountSection
          bankAccounts={bankAccounts}
          //bankAccounts={[]}
          {...actions}
          onConnectBankAccount={undefined}
        />
        {!!onBack && (
          <ButtonsContainer>
            <BackButton onClick={onBack}>
              <Trans i18nKey={`${translationPrefix}.back_button`} />
            </BackButton>
          </ButtonsContainer>
        )}
      </Container>
    </>
  );
};

export default DirectDeposit;
