import { FC, ReactNode } from "react";

import {
  ActionButton,
  ChildrenContainer,
  Container,
  IconContainer,
  MessageContainer,
  TitleContainer,
} from "./styles";

interface ButtonProps {
  text?: string | ReactNode;
  onClick?: () => void;
}

interface Props {
  className?: string;
  icon?: ReactNode;
  title?: ReactNode;
  message?: ReactNode;
  button?: ButtonProps;
  children?: ReactNode;
}

const EmptyList: FC<Props> = ({ className, icon, title, message, button, children }) => {
  return (
    <Container className={className}>
      {icon && <IconContainer>{icon}</IconContainer>}
      {title && <TitleContainer>{title}</TitleContainer>}
      {message && <MessageContainer>{message}</MessageContainer>}
      <ChildrenContainer>{children}</ChildrenContainer>
      {button && <ActionButton onClick={button.onClick}>{button.text || ""}</ActionButton>}
    </Container>
  );
};

export default EmptyList;
