import styled from "styled-components";

import { PrimaryButton } from "uikit";

export const Container = styled.div``;

export const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const AddBankAccountBtn = styled(PrimaryButton)`
  width: 177px;
`;
