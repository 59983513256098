import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import ImgSrc from "assets/png/404.png";
import { useRouteHelpers } from "routes/helpers";
import routes from "routes/routes";

import { HELP_EMAIL } from "constants/index";
import ErrorLayout from "layouts/ErrorLayout";
import {
  StyledExternalLink,
  StyledIcon,
  StyledLink,
  Text,
  Text2,
  Title,
} from "layouts/ErrorLayout/styles";

import { Container } from "./styles";

const Page404: FC = () => {
  const { t } = useTranslation();
  const { getDefaultRoute } = useRouteHelpers();
  return (
    <>
      <ErrorLayout>
        <Container>
          <StyledIcon src={ImgSrc} alt="404" />
          <Title>
            <Trans i18nKey="page_404.title" />
          </Title>
          <Text>
            <Trans i18nKey="page_404.text" />
          </Text>
          <Text2>
            <Trans
              i18nKey={"page_404.text2"}
              values={{
                dashboardLink: t("page_404.dashboard_link"),
                supportLink: HELP_EMAIL,
              }}
            >
              {" "}
              <StyledLink to={getDefaultRoute()}> </StyledLink>
              <StyledExternalLink href={`mailto:${HELP_EMAIL}`}> </StyledExternalLink>{" "}
            </Trans>
          </Text2>
        </Container>
      </ErrorLayout>
    </>
  );
};

export default Page404;
