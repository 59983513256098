import { Trans } from "react-i18next";

import { IProps } from "./types";
import {
  ActiveLabel,
  AdditionalContent,
  AltLabel,
  BelowContentContainer,
  ButtonContainer,
  Description,
  TextContainer,
  Title,
  ViewButton,
} from "./styles";

const translationPrefix = `components.navigation_card_with_details`;

export const getContainerClassName = (props: IProps) => {
  const { isActive, className, onClick } = props;
  return `${className} ${isActive ? "active" : ""} ${onClick ? "clickable" : ""}`;
};

export const renderTextBlock = (props: IProps) => {
  const { title, description } = props;
  return (
    <TextContainer>
      <Title>{title}</Title>
      {description && <Description>{description}</Description>}
    </TextContainer>
  );
};

export const renderAdditionalContent = (props: IProps) => {
  const { additionalContent } = props;
  return <AdditionalContent>{additionalContent}</AdditionalContent>;
};

export const renderButton = (props: IProps) => {
  const translationPrefix = `components.navigation_card_with_details`;
  const {
    id,
    buttonText = <Trans i18nKey={`${translationPrefix}.button_default`}></Trans>,
    buttonIcon,
    isButtonDisabled,
    onButtonClick,
  } = props;

  return (
    <ButtonContainer>
      <ViewButton
        onClick={onButtonClick}
        disabled={isButtonDisabled}
        data-test-id={id ? `${id}_card_view_button` : undefined}
      >
        {buttonIcon}
        {buttonText}
      </ViewButton>
    </ButtonContainer>
  );
};

export const renderLabels = (props: IProps) => {
  const { activeLabel, altLabel } = props;
  if (activeLabel) {
    return <ActiveLabel>{activeLabel}</ActiveLabel>;
  } else if (altLabel) {
    return <AltLabel>{altLabel}</AltLabel>;
  } else {
    return <></>;
  }
};

export const renderBelowContent = (props: IProps) => {
  const { belowContent } = props;
  return <>{!!belowContent && <BelowContentContainer>{belowContent}</BelowContentContainer>}</>;
};
