import { ChevronLeftThin } from "assets/svg";
import styled from "styled-components";

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-bottom: 30px;

  color: ${({ theme }) => theme.colors.backButtonText};
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body2};
  stroke: ${({ theme }) => theme.colors.backButtonText};

  &:hover {
    color: ${({ theme }) => theme.colors.backButtonTextActive};
    stroke: ${({ theme }) => theme.colors.backButtonTextActive};
  }
`;

export const StyledBackIcon = styled(ChevronLeftThin)`
  path {
    stroke: inherit;
  }
`;

export const ButtonText = styled.span`
  text-transform: capitalize;
  line-height: 2;
  margin-left: 16px;
`;
