import styled from "styled-components";

import { breakpoint } from "helpers";

import { kycFormContainerMaxWidth } from "../styles";

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${kycFormContainerMaxWidth}px;
  box-sizing: border-box;
  padding: 28px 60px;
  background-color: ${({ theme }) => theme.colors.contactInfoBg};

  ${breakpoint("xs", "lg")`
    max-width: 100%;
    padding: 20px 28px;
  `}

  ${breakpoint("xs", "md")`
		padding: 20px 12px;
  `}
`;
export const WrapperInner = styled.div``;

export const ShortInputsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > div {
    width: 48%;
  }
`;
