import { FC } from "react";
import { Trans } from "react-i18next";

import { BankAccountSection, IBankAccountSectionProps } from "components/BankAccountSection";
import MicroDepositInfoModal from "components/MicroDepositInfoModal";
import PaymentMethods from "components/PaymentMethods";
import { EWidgetType } from "components/PaymentMethods/types";

import { PageTitle } from "uikit";

import { AddBankAccountBtn, Container, PageHeader } from "./styles";

interface Props {
  parentTranslationPrefix: string;
  className?: string;
  bankAccountSectionProps: Required<IBankAccountSectionProps>;
}

const ConnectBank: FC<Props> = ({
  parentTranslationPrefix,
  className,
  bankAccountSectionProps,
}) => {
  const translationPrefix = `${parentTranslationPrefix}.steps.connect_bank`;
  return (
    <Container className={className}>
      <PaymentMethods widgetType={EWidgetType.ONBOARDING} />
    </Container>
  );
};

export default ConnectBank;
