import React, { FC } from "react";
import Modal from "react-modal";
import SuccessIcon from "assets/gif/check_circle.gif";
import {
  AttentionCircleIcon,
  CloseRoundIcon,
  QuestionmarkCircleIcon,
  SuccessCircledCheckMarkLarge,
  WarningLarge,
} from "assets/svg";

import { Loader } from "uikit";

import { EModalTypes, IModalProps } from "./types";
import {
  Container,
  contentStyle,
  IconContainer,
  Image,
  MainActionButton,
  Message,
  overlayStyle,
  SecondaryActionButton,
  StatusIconContainer,
  StatusIconContainerImage,
  StyledCloseButton,
  Title,
} from "./styles";

import "./styles.scss";

const UIModal: FC<IModalProps> = ({
  className,
  imageURL,
  icon,
  type,
  title,
  message,
  secondaryMessage,
  mainButton,
  secondaryButton,
  isOpen,
  onClose,
  canClose = true,
  showCloseButton = true,
  onCloseOnButtons = true,
  children = null,
  childrenBeforeButtons = true,
  shouldCloseOnOverlayClick = true,
  customContentStyle,
  customOverlayStyle,
}) => {
  const _showCloseButton = showCloseButton && canClose;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => {
        onClose && onClose();
      }}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick && canClose}
      style={{
        content: Object.assign(contentStyle, customContentStyle || {}),
        overlay: Object.assign(overlayStyle, customOverlayStyle || {}),
      }}
      className={className || ""}
    >
      <Container className={`${!_showCloseButton ? "no-close-button" : ""}`}>
        {_showCloseButton && (
          <StyledCloseButton onClick={onClose} type="button">
            <CloseRoundIcon />
          </StyledCloseButton>
        )}

        {(!!icon || !!imageURL || !!type) && (
          <IconContainer>
            {icon}
            {imageURL && <Image src={imageURL} alt="" />}
            {type && (
              <StatusIconContainer>
                {type === EModalTypes.Warning && <AttentionCircleIcon />}
                {type === EModalTypes.Success && <SuccessCircledCheckMarkLarge />}
                {type === EModalTypes.Progress && <Loader />}
                {type === EModalTypes.Confirm && <QuestionmarkCircleIcon />}
              </StatusIconContainer>
            )}
          </IconContainer>
        )}

        {title && <Title>{title}</Title>}

        {message && <Message>{message}</Message>}
        {secondaryMessage && <Message>{secondaryMessage}</Message>}

        {!!childrenBeforeButtons && children}

        {mainButton && (
          <MainActionButton
            onClick={() => {
              mainButton.onClick?.();
              onCloseOnButtons && onClose && onClose();
            }}
            type="button"
            disabled={!!mainButton?.disabled}
          >
            {mainButton.text}
          </MainActionButton>
        )}

        {!!secondaryButton && (
          <SecondaryActionButton
            onClick={() => {
              secondaryButton.onClick?.();
              onCloseOnButtons && onClose && onClose();
            }}
            type="button"
            disabled={!!secondaryButton?.disabled}
          >
            {secondaryButton.text}
          </SecondaryActionButton>
        )}
        {!childrenBeforeButtons && children}
      </Container>
    </Modal>
  );
};

export default UIModal;
