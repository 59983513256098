import { forwardRef, useImperativeHandle, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Formik, FormikProps } from "formik";

import SelectOption from "types/SelectOption";
import { TStates } from "types/States";
import {
  MAX_INPUT_LENGTH,
  MAX_INPUT_LENGTH_LONG,
  StateSelectOptions,
  zipInputFormat,
} from "constants/";
import { getFieldError } from "helpers";

import {
  Input,
  InputContainer,
  NumberInput,
  PageTitleMargined,
  PhoneInput,
  SectionTitle,
} from "uikit";
import CustomSelect from "uikit/Input/Select";

import { UserResponseDto } from "utils/swagger_react_query";

import { IPaidCardShippingDetailsFormRef } from "./types";
import {
  PaidCardShippingDetailsFormType,
  PaidCardShippingDetailsFormValidationSchema,
} from "./validationSchema";
import {
  Container,
  ContainerInner,
  ShippingDetailsSection,
  ShortInputsWrapper,
  StyledSection,
} from "./styles";

interface IProps {
  className?: string;
  userDetails: UserResponseDto | null | undefined;
  initValues: PaidCardShippingDetailsFormType;
  svoc: boolean;
  onSubmit: (values: PaidCardShippingDetailsFormType) => void;
  shouldRenderTitle?: boolean;
}

const PaidCardShippingDetailsForm = forwardRef<IPaidCardShippingDetailsFormRef, IProps>(
  ({ className, userDetails, svoc, initValues, onSubmit, shouldRenderTitle = true }, ref) => {
    const { t } = useTranslation();
    const translationPrefix = `components.paid_card_shipping_details_form`;
    const shippingDetailsTranslationPrefix = `${translationPrefix}.shipping_details`;
    const shippingDetailsFormLabelTranslationPrefix = `${shippingDetailsTranslationPrefix}.form_labels`;

    const formikRef = useRef<FormikProps<PaidCardShippingDetailsFormType>>(null);

    useImperativeHandle(ref, () => ({
      onSubmit: () => {
        formikRef?.current?.submitForm();
      },
    }));

    return (
      <Container className={className}>
        {shouldRenderTitle && (
          <PageTitleMargined>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </PageTitleMargined>
        )}
        <StyledSection>
          <Formik<PaidCardShippingDetailsFormType>
            innerRef={formikRef}
            onSubmit={(values) => onSubmit(values)}
            initialValues={initValues}
            validateOnChange={svoc}
            validationSchema={PaidCardShippingDetailsFormValidationSchema}
          >
            {(formikProps: FormikProps<PaidCardShippingDetailsFormType>) => {
              const { values, setFieldValue, handleChange } = formikProps;
              return (
                <ContainerInner>
                  <ShippingDetailsSection>
                    <SectionTitle>
                      <Trans i18nKey={`${shippingDetailsTranslationPrefix}.title`} />
                    </SectionTitle>

                    <ShortInputsWrapper>
                      <InputContainer>
                        <Input
                          name="firstName"
                          onChange={(e) =>
                            setFieldValue("firstName", e.currentTarget.value.trimStart())
                          }
                          onBlur={(e) =>
                            setFieldValue("firstName", e.currentTarget.value.trimEnd())
                          }
                          value={values.firstName}
                          label={
                            <Trans
                              i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.first_name`}
                            />
                          }
                          error={getFieldError("firstName", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.first_name`),
                            maxInputLength: MAX_INPUT_LENGTH,
                          })}
                          errorDataTestId="error-first-name"
                        />
                      </InputContainer>
                      <InputContainer>
                        <Input
                          name="lastName"
                          onChange={(e) =>
                            setFieldValue("lastName", e.currentTarget.value.trimStart())
                          }
                          onBlur={(e) => setFieldValue("lastName", e.currentTarget.value.trimEnd())}
                          value={values.lastName}
                          label={
                            <Trans
                              i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.last_name`}
                            />
                          }
                          error={getFieldError("lastName", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.last_name`),
                            maxInputLength: MAX_INPUT_LENGTH,
                          })}
                          errorDataTestId="error-last-name"
                        />
                      </InputContainer>
                    </ShortInputsWrapper>

                    <ShortInputsWrapper>
                      <InputContainer>
                        <Input
                          name="address"
                          onChange={handleChange}
                          value={values.address}
                          label={
                            <Trans
                              i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.address`}
                            />
                          }
                          error={getFieldError("address", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.address`),
                            maxInputLength: MAX_INPUT_LENGTH_LONG,
                          })}
                          errorDataTestId="error-address"
                        />
                      </InputContainer>

                      <InputContainer>
                        <Input
                          name="suite"
                          onChange={handleChange}
                          value={values.suite || ""}
                          label={
                            <Trans i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.suite`} />
                          }
                          error={getFieldError("suite", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.suite`),
                            maxInputLength: MAX_INPUT_LENGTH,
                          })}
                          errorDataTestId="error-suit"
                          optional
                        />
                      </InputContainer>
                    </ShortInputsWrapper>

                    <ShortInputsWrapper>
                      <InputContainer>
                        <CustomSelect<SelectOption<TStates>>
                          name="state"
                          onChange={(option) => setFieldValue("state", option)}
                          value={values.state as any}
                          options={StateSelectOptions}
                          label={
                            <Trans i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.state`} />
                          }
                          placeholder={""}
                          error={getFieldError("state.value", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.state`),
                          })}
                          isSearchable={true}
                          errorDataTestId="error-state"
                        />
                      </InputContainer>
                      <InputContainer>
                        <Input
                          name="city"
                          onChange={handleChange}
                          value={values.city || ""}
                          label={
                            <Trans i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.city`} />
                          }
                          error={getFieldError("city", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.city`),
                            maxInputLength: MAX_INPUT_LENGTH,
                          })}
                          errorDataTestId="error-city"
                        />
                      </InputContainer>
                    </ShortInputsWrapper>

                    <ShortInputsWrapper>
                      <InputContainer>
                        <NumberInput
                          name="zip"
                          onChange={(val) =>
                            setFieldValue("zip", val.toString().split(" ").join(""))
                          }
                          value={values.zip}
                          label={
                            <Trans i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.zip`} />
                          }
                          error={getFieldError("zip", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.zip`),
                          })}
                          errorDataTestId="error-zip"
                          format={zipInputFormat}
                        />
                      </InputContainer>
                      <InputContainer>
                        <PhoneInput
                          name="phone"
                          onChange={(value) => setFieldValue("phone", value)}
                          value={values.phone || undefined}
                          label={
                            <Trans i18nKey={`${shippingDetailsFormLabelTranslationPrefix}.phone`} />
                          }
                          error={getFieldError("phone", formikProps, {
                            field: t(`${shippingDetailsFormLabelTranslationPrefix}.phone`),
                          })}
                          errorDataTestId="error-phone"
                        />
                      </InputContainer>
                    </ShortInputsWrapper>
                  </ShippingDetailsSection>
                </ContainerInner>
              );
            }}
          </Formik>
        </StyledSection>
      </Container>
    );
  },
);

PaidCardShippingDetailsForm.displayName = "PaidCardShippingDetailsForm";

export default PaidCardShippingDetailsForm;
