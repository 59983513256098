import { FC } from "react";
import { Trans } from "react-i18next";
import queryString from "query-string";

import { getName } from "helpers";
import DashboardLayout from "layouts/DashboardLayout";

import {
  ContentContainer,
  FormSectionTitleSmall,
  FullScreenLoader,
  PageTitleMargined,
} from "uikit";

import useAdminEditWorker from "./useAdminEditWorker";
import { Container, FrameContainer } from "./styles";

interface Props {
  className?: string;
}

const AdminEditWorker: FC<Props> = ({ className }) => {
  const translationPrefix = `people_page.edit_worker`;

  const { employeeData, isLoading, frameId, handleBack } = useAdminEditWorker();

  const { showEmail } = queryString.parse(location.search);

  return (
    <DashboardLayout onBackBtnClick={handleBack} className={className}>
      {!!isLoading && <FullScreenLoader />}
      {!!employeeData && (
        <ContentContainer>
          <PageTitleMargined>
            <Trans
              i18nKey={`${translationPrefix}.title`}
              values={{ fullName: getName(employeeData) || "" }}
            />
          </PageTitleMargined>

          <FrameContainer id={frameId}></FrameContainer>
        </ContentContainer>
      )}
    </DashboardLayout>
  );
};

export default AdminEditWorker;
