import { ButtonHTMLAttributes, FC, useMemo } from "react";
import { Trans } from "react-i18next";

import { KycStatus } from "types/BETypes";
import { isKycAccepted, isKycPending } from "helpers/bonus/kyc";
import KYCVerificationWidget from "components/KYCVerificationWidget";
import PaidCardShippingDetailsForm from "components/PaidCardShippingDetailsForm";

import { FullScreenLoader } from "uikit";

import { STEPS } from "./constants";
import {
  userDetailsToProfileFormInitialValues,
  userDetailsToShippingDetailsFormInitialValues,
} from "./helpers";
import { IUsePaidBankAccountCreatingWidget } from "./types";
import { usePaidBankAccountCreatingWidget } from "./usePaidBankAccountCreatingWidget";
import { BackBtn, BtnsWrapper, Container, SubmitBtn } from "./styles";

const PaidBankAccountCreatingWidget: FC<IUsePaidBankAccountCreatingWidget> = (props) => {
  const { onBackBtnClickOnKycStep, showBackButton = true } = props;
  const { metadata, pageData, actions } = usePaidBankAccountCreatingWidget(props);

  const renderPrimaryBtn = useMemo(() => {
    const isAccepted = isKycAccepted(metadata.currentUser);
    const isPending = isKycPending(metadata.currentUser);

    if (!!isPending) {
      return <></>;
    }

    const config: Record<STEPS, ButtonHTMLAttributes<HTMLButtonElement>> = {
      [STEPS.KYC_VERIFICATION]: {
        type: !isAccepted ? "submit" : "button",
        disabled: metadata.isPrimaryBtnDisabled[STEPS.KYC_VERIFICATION],
        children: !isAccepted ? (
          <Trans i18nKey="buttons.submit" />
        ) : (
          <Trans i18nKey="buttons.next" />
        ),
        onClick: actions.primaryBtnClickHandler,
      },
      [STEPS.SHIPPING_DETAILS]: {
        type: "submit",
        disabled: metadata.isPrimaryBtnDisabled[STEPS.SHIPPING_DETAILS],
        children: <Trans i18nKey="buttons.submit" />,
        onClick: actions.primaryBtnClickHandler,
      },
    };
    return (
      <SubmitBtn
        type={config[metadata.step.currentStep].type}
        disabled={config[metadata.step.currentStep].disabled}
        onClick={config[metadata.step.currentStep].onClick}
      >
        {config[metadata.step.currentStep].children}
      </SubmitBtn>
    );
  }, [metadata.step, metadata.currentUser?.kycStatus]);

  const renderBackBtn = useMemo(() => {
    if (!showBackButton) return <div></div>;
    const shouldHideOnKYCStep =
      metadata.step.currentStep === STEPS.KYC_VERIFICATION && !onBackBtnClickOnKycStep;
    const config: Record<STEPS, ButtonHTMLAttributes<HTMLButtonElement>> = {
      [STEPS.KYC_VERIFICATION]: {
        type: "button",
        children: <Trans i18nKey="buttons.back" />,
        onClick: actions.backBtnClickHandler,
      },
      [STEPS.SHIPPING_DETAILS]: {
        type: "button",
        children: <Trans i18nKey="buttons.back" />,
        onClick: actions.backBtnClickHandler,
      },
    };
    if (shouldHideOnKYCStep) return;

    return (
      <BackBtn
        onClick={config[metadata.step.currentStep].onClick}
        type={config[metadata.step.currentStep].type}
      >
        {config[metadata.step.currentStep].children}
      </BackBtn>
    );
  }, [onBackBtnClickOnKycStep, metadata.step.currentStep]);

  return (
    <Container
      onSubmit={(e) => {
        e.preventDefault();
        actions.formSubmitter();
      }}
    >
      {metadata.isLoading ||
        (pageData.shippingDetails.isShippingDetailsSubmitting && <FullScreenLoader />)}
      {metadata.step.currentStep === STEPS.KYC_VERIFICATION && (
        <KYCVerificationWidget
          profileData={{
            onSubmit: pageData.verificationStep.handleProfileFormSubmit,
            initValues: userDetailsToProfileFormInitialValues(metadata.currentUser!),
            svoc: pageData.verificationStep.profileForm.svoc,
          }}
          ref={pageData.verificationStep.profileForm.ref}
          userDetails={metadata.currentUser}
          termAndConditionsCheckboxProps={{
            isChecked: pageData.verificationStep.isTermsAndConditionsAccepted,
            handleChange: pageData.verificationStep.handleChangeTermsAndConditions,
          }}
        />
      )}

      {metadata.step.currentStep === STEPS.SHIPPING_DETAILS && (
        <PaidCardShippingDetailsForm
          userDetails={metadata.currentUser}
          initValues={userDetailsToShippingDetailsFormInitialValues(metadata.currentUser)}
          onSubmit={pageData.shippingDetails.handleSubmitShippingDetails}
          svoc={pageData.shippingDetails.svoc}
          ref={pageData.shippingDetails.ref}
        />
      )}

      <BtnsWrapper className={`${metadata.step.currentStep}`}>
        {renderBackBtn}
        {renderPrimaryBtn}
      </BtnsWrapper>
    </Container>
  );
};

export default PaidBankAccountCreatingWidget;
