import { FC, useState } from "react";
import { Trans } from "react-i18next";
import ClockMicroDeposits from "assets/gif/clockMicroDeposits.gif";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";

import {
  Clock,
  FooterMessage,
  Message,
  PrimaryBtn,
  StyledUIModal,
  SubTitle,
  Title,
} from "./styles";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onBtnClick: () => void;
  verificationStatus:
    | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
    | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION;
}

const MicroDepositInfoModal: FC<IProps> = ({ isOpen, onClose, onBtnClick, verificationStatus }) => {
  const translationPrefix = `components.bank_account_verification_info_modals.${verificationStatus}`;

  return (
    <StyledUIModal isOpen={isOpen} onClose={onClose}>
      {<Clock src={isOpen ? ClockMicroDeposits : ""} alt="Clock" id={"clock-md"} />}
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>

      <Message>
        <Trans i18nKey={`${translationPrefix}.message`} />
      </Message>

      {verificationStatus === BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION && (
        <>
          <SubTitle>
            <Trans i18nKey={`${translationPrefix}.sub_title`} default={""} />
          </SubTitle>

          <Message>
            <Trans i18nKey={`${translationPrefix}.message_2`} default={""} />
          </Message>
        </>
      )}

      <PrimaryBtn onClick={onBtnClick}>
        <Trans i18nKey={"components.confirm_action.ok"} />
      </PrimaryBtn>
    </StyledUIModal>
  );
};

export default MicroDepositInfoModal;
