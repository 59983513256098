import { FC } from "react";

import IconProps from "./IconProps";

const Envelope: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 0C20.6569 0 22 1.34315 22 3V13C22 14.6569 20.6569 16 19 16H3C1.34315 16 0 14.6569 0 13V3C0 1.34315 1.34315 0 3 0H19ZM18.2529 2H3.74718L10.3804 7.23673C10.7437 7.52356 11.2563 7.52356 11.6197 7.23673L18.2529 2ZM2 3.1688V13C2 13.5523 2.44772 14 3 14H19C19.5523 14 20 13.5523 20 13V3.16882L12.8589 8.8065C11.769 9.66697 10.231 9.66697 9.14108 8.8065L2 3.1688Z"
      fill="#F0CDBB"
    />
  </svg>
);

export default Envelope;
