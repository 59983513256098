import { FC } from "react";

import IconProps from "./IconProps";

const UploadIconSmall: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="share-2" clip-path="url(#clip0_3797_11371)">
      <path
        id="Vector"
        d="M6.66699 7.5H5.83366C5.39163 7.5 4.96771 7.6756 4.65515 7.98816C4.34259 8.30072 4.16699 8.72464 4.16699 9.16667V15.8333C4.16699 16.2754 4.34259 16.6993 4.65515 17.0118C4.96771 17.3244 5.39163 17.5 5.83366 17.5H14.167C14.609 17.5 15.0329 17.3244 15.3455 17.0118C15.6581 16.6993 15.8337 16.2754 15.8337 15.8333V9.16667C15.8337 8.72464 15.6581 8.30072 15.3455 7.98816C15.0329 7.6756 14.609 7.5 14.167 7.5H13.3337"
        stroke="#7A7A7A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M10 11.6667V2.5"
        stroke="#7A7A7A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_3"
        d="M7.5 5L10 2.5L12.5 5"
        stroke="#7A7A7A"
        stroke-width="1.8"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3797_11371">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UploadIconSmall;
