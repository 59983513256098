import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useRouteHelpers } from "routes/helpers";

import { HELP_EMAIL } from "constants/index";
import ErrorLayout from "layouts/ErrorLayout";
import { StyledExternalLink, StyledLink, Text, Text2, Title } from "layouts/ErrorLayout/styles";

import { Container, StyledIcon } from "./styles";

const InsufficientPermissionsPage: FC = () => {
  const { t } = useTranslation();
  const { getDefaultRoute } = useRouteHelpers();
  const defaultRouteLink = getDefaultRoute();

  return (
    <>
      <ErrorLayout>
        <Container>
          <StyledIcon />
          <Title>
            <Trans i18nKey="insufficient_permissions_page.title" />
          </Title>
          <Text>
            <Trans i18nKey="insufficient_permissions_page.text" />
          </Text>
          <Text2>
            <Trans
              i18nKey={"insufficient_permissions_page.text2"}
              values={{
                dashboardLink: t("insufficient_permissions_page.dashboard_link"),
                supportLink: HELP_EMAIL,
              }}
            >
              {" "}
              <StyledLink to={defaultRouteLink}> </StyledLink>
              <StyledExternalLink href={`mailto:${HELP_EMAIL}`}> </StyledExternalLink>{" "}
            </Trans>
          </Text2>
        </Container>
      </ErrorLayout>
    </>
  );
};

export default InsufficientPermissionsPage;
