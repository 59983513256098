import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, PageTitleMargined } from "uikit";

import useEmployeePayPage from "./useEmployeePayPage";
import { FrameContainer } from "./styles";

interface Props {
  className?: string;
}

const EmptyPage: FC<Props> = ({ className }) => {
  const translationPrefix = `pay_page.employee.index`;

  const { frameId } = useEmployeePayPage();

  return (
    <DashboardLayout className={className}>
      <ContentContainer>
        <PageTitleMargined>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </PageTitleMargined>

        <FrameContainer id={frameId}></FrameContainer>
      </ContentContainer>
    </DashboardLayout>
  );
};

export default EmptyPage;
