import styled, { CSSProperties } from "styled-components";
import { theme } from "styles/theme";

import { CloseModalButton, NoBorderButton, PrimaryButton, SecondaryButton } from "../Buttons";
import { Body2Mixin } from "../Typography/Typography";

export const Container = styled.div`
  position: relative;
  padding: 20px 6px 5px 6px;
  text-align: center;
  background: ${({ theme }) => theme.colors.contentBg};

  &.no-close-button {
    padding-top: 0;
  }
`;

export const StyledCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: -5px;
  right: -5px;
  z-index: 1;
  opacity: 0.8;
`;

export const Image = styled.img`
  display: block;
  margin-bottom: 40px;
`;

export const IconContainer = styled.div`
  text-align: center;
  margin: 24px 0;
`;
export const StatusIconContainer = styled.div`
  text-align: center;
`;

export const StatusIconContainerImage = styled.img`
  height: 100px;
  margin-bottom: 17px;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.modalTitle};
  font-family: ${({ theme }) => theme.fontFamily.alt};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.size.subtitleLarger};
  margin-bottom: 32px;
`;

export const Message = styled.div`
  ${Body2Mixin}
  margin-bottom: 30px;
  line-height: 19px;
`;

export const MainActionButton = styled(PrimaryButton)`
  display: block;
  width: 100%;
  max-width: 273px;
  margin: 0 auto;
`;

export const SecondaryActionButton = styled(NoBorderButton)`
  width: 100%;
  max-width: 273px;
  margin: 15px auto 0 auto;
`;

export const overlayStyle: CSSProperties = {
  position: "fixed",
  display: "flex",
  flexDirection: "column",
  zIndex: theme.zIndex.modal,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
};
export const contentStyle: CSSProperties = {
  position: "relative",
  width: "312px",
  boxSizing: "border-box",
  borderRadius: "8px",
  maxHeight: "90%",
  left: "0",
  right: "0",
  top: "0",
  bottom: "0",
  margin: "auto auto",
  padding: "20px",
  inset: "0",
  background: "rgb(255, 255, 255)",
  overflow: "auto",
};
