import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import routes from "routes/routes";

import { userMetadataSelector } from "redux/selectors";

export const useEmployeePaidBankAccountsPage = () => {
  const navigate = useNavigate();
  const translationPrefix = `settings_pages.employee.kyc_verification_page`;

  const [isLoading, setLoading] = useState<boolean>(false);
  const currentUser = useSelector(userMetadataSelector);

  const handleNavigateToPreviousPage = () => navigate(routes.EMPLOYEE_SETTINGS);

  return {
    metadata: {
      translationPrefix,
      isLoading,
      currentUser,
    },

    actions: {
      handleNavigateToPreviousPage,
    },
  };
};
