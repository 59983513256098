import {
  IBusinessInfoPageOpened,
  IContactInfoPageOpened,
  IEmployeesListFilterAppliedAnalytics,
  ISignInCompletedAnalytics,
  ISignInPageOpened,
  ISignUpCompletedAnalytics,
  IUpdatedProfileInfoAnalytics,
  IViewedBonusAnalytics,
} from "./types";

export const signUpCompletedAnalytics = (params: ISignUpCompletedAnalytics) => {
  const analytics = (window as any).analytics;
  analytics && analytics.identify(params.userId, params);
};

export const signInPageOpened = (params: ISignInPageOpened) => {
  const analytics = (window as any).analytics;
  analytics && analytics.page("Sign in", params);
};

export const businessInfoPageOpened = (params: IContactInfoPageOpened) => {
  const analytics = (window as any).analytics;
  analytics && analytics.page("Business information", params);
};

export const contactInfoPageOpened = (params: IBusinessInfoPageOpened) => {
  const analytics = (window as any).analytics;
  analytics && analytics.page("Contact information", params);
};

export const signInCompletedAnalytics = (params: ISignInCompletedAnalytics) => {
  const analytics = (window as any).analytics;
  analytics && analytics.identify(params.userId, params);
};

export const updatedProfileInfoAnalytics = (params: IUpdatedProfileInfoAnalytics) => {
  const analytics = (window as any).analytics;
  analytics && analytics.identify(params.userId, params);
};

export const viewedBonusAnalytics = (params: IViewedBonusAnalytics) => {
  const analytics = (window as any).analytics;
  analytics && analytics.page("Bonus viewed", params);
};

export const employeesListFilterAppliedAnalytics = (
  params: IEmployeesListFilterAppliedAnalytics,
) => {
  const analytics = (window as any).analytics;
  analytics && analytics.track("Employee list filter applied", params);
};
