import React, { CSSProperties, ReactNode } from "react";

export interface IModalProps {
  className?: string;
  imageURL?: string;
  type?: EModalTypes;
  title?: string | React.ReactNode;
  message?: string | React.ReactNode;
  secondaryMessage?: string | React.ReactNode;
  mainButton?: {
    text?: string | React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
  };
  secondaryButton?: {
    text?: string | React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
  };
  isOpen: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  canClose?: boolean;
  onCloseOnButtons?: boolean;
  icon?: ReactNode;
  childrenBeforeButtons?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  customContentStyle?: CSSProperties;
  customOverlayStyle?: CSSProperties;
  children?: ReactNode;
}

export enum EModalTypes {
  Success = "Success",
  Progress = "Progress",
  Warning = "Warning",
  Confirm = "Confirm",
  Error = "Error",
}
