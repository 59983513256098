import { Trans } from "react-i18next";
import { CheckmarkRoundFilled } from "assets/svg";

import { AppEvents } from "services/events";
import SelectedBank from "components/SelectedBank";

import { EModalTypes } from "uikit/Modal";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import { COMPANY_ACCOUNT_PROMOTION_POINTS } from "../constants";
import { EOptionIds, EWidgetType, IProps } from "../types";
import {
  CAPCIconContainer,
  CAPCItem,
  CAPCText,
  CompanyAccountPromotionContent,
  DDModalSelectedBankContainer,
  MessageEmphasized,
} from "./styles";

import "./styles.scss";

const translationPrefix = `components.payment_methods`;

export const showCompanyAccountPromotionModal = (
  callback?: () => void,
  cancelCallBack?: () => void,
) => {
  const _translationPrefix = `${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}.modals.company_promoting_warning`;
  AppEvents.emit("SetGlobalModal", {
    className: "company-account-promotion-modal",
    isOpen: true,
    title: <Trans i18nKey={`${_translationPrefix}.title`} />,
    type: EModalTypes.Success,
    message: <Trans i18nKey={`${_translationPrefix}.message`} />,
    mainButton: {
      text: <Trans i18nKey={`${_translationPrefix}.submitButton`} />,
      onClick: callback,
    },
    secondaryButton: {
      text: <Trans i18nKey={`${_translationPrefix}.cancelButton`} />,
      onClick: cancelCallBack,
    },

    children: (
      <CompanyAccountPromotionContent>
        {COMPANY_ACCOUNT_PROMOTION_POINTS.map((item) => {
          return (
            <CAPCItem key={item.id}>
              <CAPCIconContainer>
                <CheckmarkRoundFilled />
              </CAPCIconContainer>
              <CAPCText>{item.text}</CAPCText>
            </CAPCItem>
          );
        })}
      </CompanyAccountPromotionContent>
    ),
  });
};

export const showCompanyAccountChosenModal = (callback?: () => void) => {
  const _translationPrefix = `${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.modals.method_selected`;
  AppEvents.emit("SetGlobalModal", {
    className: "payment-method-selected-modal company-account-selected-modal",
    isOpen: true,
    title: <Trans i18nKey={`${_translationPrefix}.title`} />,
    type: EModalTypes.Success,
    message: (
      <MessageEmphasized>
        <Trans i18nKey={`${_translationPrefix}.message`} />
      </MessageEmphasized>
    ),
    mainButton: {
      text: <Trans i18nKey={`common.modal.done`} />,
      onClick: callback,
    },
  });
};

export const showDirectDepositChosenModal = (
  bankAccount?: GetPlaidBankAccountsResponseDto,
  callback?: () => void,
) => {
  const _translationPrefix = `${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}.modals.method_selected`;
  AppEvents.emit("SetGlobalModal", {
    className: "payment-method-selected-modal direct-deposit-selected-modal",
    isOpen: true,
    title: <Trans i18nKey={`${_translationPrefix}.title`} />,
    type: EModalTypes.Success,
    message: (
      <MessageEmphasized>
        <Trans i18nKey={`${_translationPrefix}.message`} />
      </MessageEmphasized>
    ),
    mainButton: {
      text: <Trans i18nKey={`common.modal.done`} />,
      onClick: callback,
    },
    children: (
      <>
        {!!bankAccount?.accountName && (
          <DDModalSelectedBankContainer>
            <SelectedBank bank={bankAccount} />
          </DDModalSelectedBankContainer>
        )}
      </>
    ),
  });
};

export const showPaperCheckChosenModal = (callback?: () => void) => {
  const _translationPrefix = `${translationPrefix}.options.${EOptionIds.PAPER_CHECK}.modals.method_selected`;
  AppEvents.emit("SetGlobalModal", {
    className: "payment-method-selected-modal paper-check-selected-modal",
    isOpen: true,
    title: <Trans i18nKey={`${_translationPrefix}.title`} />,
    type: EModalTypes.Success,
    message: (
      <MessageEmphasized>
        <Trans i18nKey={`${_translationPrefix}.message`} />
      </MessageEmphasized>
    ),
    mainButton: {
      text: <Trans i18nKey={`common.modal.done`} />,
      onClick: callback,
    },
  });
};
