import { FC, useEffect } from "react";
import { Trans } from "react-i18next";
import { useDispatch } from "react-redux";

import reduxActions from "redux/actions";
import { userMetadataSelector, workerKycOnboardingInProgressSelector } from "redux/selectors";
import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, FullScreenLoader, PageTitleMargined } from "uikit";

import { useEmployeeSetup } from "./useEmployeeSetup";

interface IProps {}

const EmployeeSetupPage: FC<IProps> = () => {
  const translationPrefix = `employee_setup_page`;
  const { metadata, renderContent } = useEmployeeSetup();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(reduxActions.userMetaData.setWorkerKycOnboardingInProgress(false));
    };
  }, []);

  return (
    <DashboardLayout>
      {metadata.isLoading && <FullScreenLoader />}
      <ContentContainer>
        <PageTitleMargined>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </PageTitleMargined>
        {renderContent()}
      </ContentContainer>
    </DashboardLayout>
  );
};

export default EmployeeSetupPage;
