import { t } from "i18next";

import { IAuthLayoutProps } from "layouts/AuthLayout/AuthLayout";

const translationPrefix = `auth_pages.shared`;

export const authDefaultLeftSectionParams = (): IAuthLayoutProps["leftSectionParams"] => {
  return {
    title: t(`${translationPrefix}.default_layout_left_section.title`),
    description: t(`${translationPrefix}.default_layout_left_section.description`),
    checkListItems: [
      t(`${translationPrefix}.default_layout_left_section.features.0`),
      t(`${translationPrefix}.default_layout_left_section.features.1`),
      t(`${translationPrefix}.default_layout_left_section.features.2`),
    ],
  };
};

export const employeeAuthLeftSectionParams = (): IAuthLayoutProps["leftSectionParams"] => {
  return {
    title: t(`auth_pages.employee.layout_left_section.title`),
    description: t(`auth_pages.employee.layout_left_section.description`),
    checkListItems: [
      t(`auth_pages.employee.layout_left_section.features.0`),
      t(`auth_pages.employee.layout_left_section.features.1`),
      t(`auth_pages.employee.layout_left_section.features.2`),
    ],
  };
};
