import { UserStatus } from "types/BETypes";
import { APPLICATION_NAME } from "constants/index";
import { EAcknowledgementModalTypes } from "components/KYCVerificationWidget/useKycStatusModals";

const kycVerificationWidget = {
  "en-US": {
    title: "",

    verification_step: {
      title: "Please Verify Information",
      terms_and_conditions_checkbox: `<2>I agree</2> to the terms and conditions for setting up a <3>${APPLICATION_NAME} Bank Account</3>. <br/> <1>View full terms and conditions</1>`,

      verification_statuses: {
        failed: {
          title: "Trouble verifying your identity",
          description:
            "Previous verification has failed. Please review and correct you information and try again",
        },
        contact_info_title: "Please verify this information is correct.",
        contact_info_accepted_title: "Information Verified",
        contact_info_pending_title: "Pending Verification...",
        contact_info_failed_title: "Trouble verifying your identity",
      },

      modals: {
        [EAcknowledgementModalTypes.PROGRESS]: {
          title: "Verifying Information",
          message: "This may take a few moments. Thank you for your patience.",
        },
        [EAcknowledgementModalTypes.SUCCESS]: {
          message: "Your information has been verified.",
          main_button: "Continue",
        },
        [EAcknowledgementModalTypes.ERROR]: {
          title: `
                Sorry <br />
                <1>Trouble verifying your identity</1>
              `,
          message: `
                Please review your information to ensure it is correct.<br />
                If you continue to have problems, please contact:
                <1></1>
              `,
          main_button: "Copy Email Address",
        },
        [EAcknowledgementModalTypes.INCORRECT_INFO]: {
          title: "Please update your information.",
          message: `
                If you continue to have problems, please contact:
                <1></1>
              `,
          main_button: "Copy Email Address",
        },
        [EAcknowledgementModalTypes.MISSING_INFO]: {
          title: "Missing required info",
          message: `
                Missing required info for verification. Please contact the following to update your information.
                <1></>
              `,
          main_button: "Copy Email Address",
        },
        [EAcknowledgementModalTypes.VERIFICATION_IS_TAKING_TOO_LONG]: {
          title: "What’s taking so long?",
          message: `Verifying the information is taking longer than expected. You will be notified via email when the process is complete.<br />
              It may take up to a few hours. Sorry for the wait.`,
        },
      },
    },
    profile_form: {
      form_labels: {
        first_name: "First Name",
        last_name: "Last Name",
        phone: "Phone Number",
        social_security_number: "Social Security Number",
        birth_date: "Birth Date",
        address: "Address",
        suite: "Apartment, suite, etc.",
        city: "City",
        state: "State",
        zip: "Zip",
      },
    },
  },
};

export default kycVerificationWidget;
