import { CardStatus, CardStatusReason } from "types/CardShipping";

import { BankCardResDto } from "utils/swagger_react_query";

export enum EComprehensibleCardStatus { //More options will be added later
  EMPTY = "EMPTY",
  ACTIVE = "ACTIVE",
  ACTIVE_WITHOUT_PIN = "ACTIVE_WITHOUT_PIN",
  INACTIVE = "INACTIVE",
  CANCELED = "CANCELED",
  REISSUED = "REISSUED",
  EXPIRED = "EXPIRED",
}

export const getComprehensibleCardStatus = (data?: Partial<BankCardResDto>) => {
  if (!data) return EComprehensibleCardStatus.EMPTY;

  if (data.cardStatus === CardStatus.TERMINATED && !!data.reissueReason) {
    return EComprehensibleCardStatus.REISSUED;
  }
  if (data.cardStatus === CardStatus.TERMINATED && data.statusReason === CardStatusReason.REQ) {
    return EComprehensibleCardStatus.CANCELED;
  } else if (
    data.cardStatus === CardStatus.TERMINATED &&
    data.statusReason === CardStatusReason.EXP
  ) {
    return EComprehensibleCardStatus.EXPIRED;
  } else if (
    data.cardStatus === CardStatus.REJECTED ||
    data.cardStatus === CardStatus.IMAGE_REJECTED
  ) {
    return EComprehensibleCardStatus.INACTIVE;
  } else if (data.cardStatus === CardStatus.ACTIVE) {
    if (!data.isPinSet) {
      return EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN;
    }
    return EComprehensibleCardStatus.ACTIVE;
  } else if (data.cardStatus === CardStatus.UNACTIVATED) {
    return EComprehensibleCardStatus.INACTIVE;
  }

  return EComprehensibleCardStatus.EMPTY;
};
