import moment from "moment";

import { CardForm, CardShipmentExpedited, CardShipmentExpeditedBoolean } from "types/CardShipping";
import { TStates } from "types/States";
import { StateSelectOptions } from "constants/";
import { getPhoneNumberOrNull } from "helpers";
import { KYCProfileFormType } from "components/KYCVerificationWidget";
import { PaidCardShippingDetailsFormType } from "components/PaidCardShippingDetailsForm";

import { IssueBankCardReqDto, KycVerifyReqDto, UserResponseDto } from "utils/swagger_react_query";

interface IStates extends Omit<typeof StateSelectOptions, "value"> {
  label: string;
  value: NonNullable<TStates>;
}

export const userDetailsToProfileFormInitialValues = (
  userDetails: UserResponseDto | undefined | null,
): KYCProfileFormType => {
  const state = (StateSelectOptions.find(
    (state) => state.value === userDetails?.state,
  ) as IStates) || {
    label: "",
    value: "",
  };

  const result: KYCProfileFormType = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    phone: getPhoneNumberOrNull(userDetails?.phone || "")!,
    address: userDetails?.address || "",
    city: userDetails?.city || "",
    state: state,
    zip: userDetails?.zip || "",
    birthDate: userDetails?.birthDate ? new Date(userDetails?.birthDate) : (null as any),
    suite: userDetails?.suite || "",
    socialSecurityNumber: userDetails?.socialSecurityNumber || "",
  };

  return result;
};

export const profileFormValuesToRequestPayload = (values: KYCProfileFormType): KycVerifyReqDto => {
  const result: KycVerifyReqDto = {
    phone: values.phone,
    address: values.address,
    city: values.city,
    state: values.state.value,
    zip: values.zip,
    birthDate: moment(values.birthDate).toISOString(),
    suite: values.suite || undefined,
    socialSecurityNumber: values.socialSecurityNumber,
  };

  return result;
};

export const userDetailsToShippingDetailsFormInitialValues = (
  userDetails: UserResponseDto | undefined | null,
): PaidCardShippingDetailsFormType => {
  const state = (StateSelectOptions.find(
    (state) => state.value === userDetails?.state,
  ) as IStates) || {
    label: "",
    value: "",
  };

  const result: PaidCardShippingDetailsFormType = {
    firstName: userDetails?.firstName || "",
    lastName: userDetails?.lastName || "",
    address: userDetails?.address || "",
    city: userDetails?.city || "",
    state: state,
    zip: userDetails?.zip || "",
    suite: userDetails?.suite || "",
    phone: getPhoneNumberOrNull(userDetails?.phone || "")!,
  };

  return result;
};

export const shippingDetailsFormValuesToRequestPayload = (
  values: PaidCardShippingDetailsFormType,
): IssueBankCardReqDto => {
  const result: IssueBankCardReqDto = {
    //use IssueBankCardReqDto to create new fields
    form: CardForm.PHYSICAL,
    shipping: {
      address: {
        addressLine1: values.address,
        addressLine2: values.suite || undefined,
        city: values.city,
        state: values.state.value,
        postalCode: values.zip,
        countryCode: "US",
      },
      recipientFirstName: values.firstName,
      recipientLastName: values.lastName,
      phoneNumber: values.phone,
    },
  };

  return result;
};
