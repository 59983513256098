import styled from "styled-components";

import { breakpoint } from "helpers";

import { PrimaryButton, SecondaryButton } from "uikit";

export const Container = styled.form``;

export const BtnsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;

  ${breakpoint("xs", "md")`
    flex-direction: column-reverse;
		justify-content: flex-start;
  `}
`;

export const SubmitBtn = styled(PrimaryButton)`
  width: 180px;

  ${breakpoint("xs", "md")`
    width: 100%;
    max-width: 100%;
  `}
`;

export const BackBtn = styled(SecondaryButton)`
  width: 90px;
  margin-right: 24px;

  ${breakpoint("xs", "md")`
    margin-top: 16px;
    width: 100%;
    max-width: 100%;
  `}
`;
