import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { find } from "lodash";
import routes from "routes/routes";

import reduxActions from "redux/actions";
import { userMetadataSelector, workerKycOnboardingInProgressSelector } from "redux/selectors";
import { PayDistributionType, PaymentCardStatus, SalsaOnboardingStatus } from "types/BETypes";
import { CardForm } from "types/CardShipping";
import { showErrorModal } from "helpers";
import { isKycAccepted } from "helpers/bonus/kyc";
import { getDeferredData } from "helpers/employee/employeeRequests";
import { useBankAccountLink } from "hooks";
import useAuth from "hooks/useAuth";
import { CARD_TABS } from "pages/Banking/employee/EmployeeCardsDetailsPage/constants";
import ModalLikeContent, {
  IModalLikeContentProps,
} from "components/ModalLikeContent/ModalLikeContent";
import StepIndicator from "components/StepIndicator";
import { Step } from "components/StepIndicator/types";

import {
  BankCardResDto,
  queryBankCardsControllerListBankCards,
  UserResponseDto,
} from "utils/swagger_react_query";

import { STEPS } from "./constants";
import ConnectBank from "./steps/ConnectBank";
import SetupPayroll from "./steps/SetupPayroll";
import { TabsContainer, TabsContentContainer } from "./styles";

export const useEmployeeSetup = () => {
  const { t } = useTranslation();
  const translationPrefix = `employee_setup_page`;
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [cardsData, setCardsData] = useState<BankCardResDto[] | undefined>(undefined);
  const { getCurrentUser, saveUserToStore } = useAuth();
  const currentUser = useSelector(userMetadataSelector);
  const isWorkerKycOnboardingInProgress = useSelector(workerKycOnboardingInProgressSelector);
  const shouldRequestCards =
    isKycAccepted(currentUser) &&
    currentUser?.defaultPayDistribution?.type === PayDistributionType.PARTNER_ACCOUNT;
  const dispatch = useDispatch();

  const defaultStep =
    currentUser?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED
      ? STEPS.CONNECT_BANK
      : STEPS.SETUP_PAYROLL;

  const [currentStep, setCurrentStep] = useState<number>(defaultStep);
  //const [currentStep, setCurrentStep] = useState<number>(STEPS.CONNECT_BANK);
  const [isContinueToOnboardingDisabled, setContinueToOnboardingDisabled] = useState<
    Record<STEPS, boolean>
  >({
    [STEPS.SETUP_PAYROLL]: false,
    [STEPS.CONNECT_BANK]: false,
  });

  const bankAccountLink = useBankAccountLink({ loadingCallback: setLoading });
  const [isMicroDepositInfoModalOpen, setMicroDepositInfoModal] = useState<boolean>(false);

  const handleOnboardingDisabledChange = (isDisabled: boolean, stepIndex: STEPS) => {
    setContinueToOnboardingDisabled((prev) => ({ ...prev, [stepIndex]: isDisabled }));
  };

  useEffect(() => {
    if (
      currentStep === STEPS.SETUP_PAYROLL &&
      currentUser?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED
    ) {
      handleOnboardingDisabledChange(false, STEPS.SETUP_PAYROLL);
    }
  }, [currentUser?.salsaOnboardingStatus, currentStep]); //TODO: Dubious code, do we really need that logic?

  const moveToOnboardingStep = () => {
    setCurrentStep(STEPS.SETUP_PAYROLL);
  };

  const moveToBankAccountsStep = () => {
    if (!isContinueToOnboardingDisabled[STEPS.SETUP_PAYROLL]) {
      setCurrentStep(STEPS.CONNECT_BANK);
    }
  };

  const steps: Step[] = [
    {
      index: STEPS.SETUP_PAYROLL,
      label: (
        <Trans
          i18nKey={`${translationPrefix}.steps_template`}
          values={{
            index: STEPS.SETUP_PAYROLL,
            name: t(`${translationPrefix}.steps.setup_payroll.title`),
          }}
        />
      ),
    },
    {
      index: STEPS.CONNECT_BANK,
      label: (
        <Trans
          i18nKey={`${translationPrefix}.steps_template`}
          values={{
            index: STEPS.CONNECT_BANK,
            name: t(`${translationPrefix}.steps.connect_bank.title`),
          }}
        />
      ),
    },
  ];

  const onSalsaOnboardingComplete = async () => {
    setLoading(true);

    const request = async () => {
      const data = await getCurrentUser(undefined, undefined, false);
      return data;
    };

    const condition = (data?: UserResponseDto | null) => {
      return data?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED;
    };

    //Our user might not have updated status right away, we need to wait for it to become completed
    const result = await getDeferredData(request, condition, 2000);

    if (condition(result)) {
      saveUserToStore(result);
      setCurrentStep(STEPS.CONNECT_BANK);
    } else {
      showErrorModal();
    }
    setLoading(false);
  };

  const navigateToBankingShipping = () => {
    navigate(routes.EMPLOYEE_BANKING_SHIPPING);
  };

  const navigateToBankingVirtualCard = () => {
    navigate(routes.EMPLOYEE_BANKING_CARDS, { state: { selectedTab: CARD_TABS.VIRTUAL } });
  };

  const renderTabs = () => {
    return (
      <>
        <TabsContainer>
          <StepIndicator steps={steps} currentStep={currentStep} />
        </TabsContainer>
        <TabsContentContainer>
          {currentStep === STEPS.SETUP_PAYROLL && (
            <SetupPayroll
              parentTranslationPrefix={translationPrefix}
              onContinue={moveToBankAccountsStep}
              currentUser={currentUser as UserResponseDto}
              onSalsaOnboardingComplete={onSalsaOnboardingComplete}
            />
          )}
          {currentStep === STEPS.CONNECT_BANK && (
            <>
              <ConnectBank
                parentTranslationPrefix={translationPrefix}
                bankAccountSectionProps={{
                  onRemoveBankAccount: bankAccountLink.actions.handleRemoveBankAccount,
                  onUpdateDefaultBankAccount: bankAccountLink.actions.handleSetDefaultBankAccount,
                  onConnectBankAccount: bankAccountLink.actions.openModal,
                  bankAccounts: bankAccountLink.data.bankAccounts,
                  onVerifyBankAccount: bankAccountLink.actions.handleVerifyBankAccount,
                }}
              />
            </>
          )}
        </TabsContentContainer>
      </>
    );
  };

  const renderNoPhysicalCardContent = () => {
    const _translationPrefix = `${translationPrefix}.setup_complete_states.no_physical_card`;
    const config: IModalLikeContentProps = {
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${_translationPrefix}.main_button`} />,
        onClick: navigateToBankingShipping,
      },
    };
    return <ModalLikeContent {...config} />;
  };

  const renderNoVirtualCardContent = () => {
    const _translationPrefix = `${translationPrefix}.setup_complete_states.no_virtual_card`;
    const config: IModalLikeContentProps = {
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${_translationPrefix}.main_button`} />,
        onClick: navigateToBankingVirtualCard,
      },
    };
    return <ModalLikeContent {...config} />;
  };

  const renderDefaultFinishedContent = () => {
    const _translationPrefix = `${translationPrefix}.setup_complete_states.onboarding_complete`;
    const config: IModalLikeContentProps = {
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
    };
    return <ModalLikeContent {...config} />;
  };

  const renderContent = () => {
    if (
      !isWorkerKycOnboardingInProgress &&
      isKycAccepted(currentUser) &&
      currentUser?.defaultPayDistribution?.type === PayDistributionType.PARTNER_ACCOUNT
    ) {
      if (!cardsData && !!shouldRequestCards && !isWorkerKycOnboardingInProgress) {
        return <></>;
      }

      const hasPhysicalCard = find(cardsData, (item) => item.form === CardForm.PHYSICAL);
      const hasVirtualCard = find(cardsData, (item) => item.form === CardForm.VIRTUAL);

      if (!hasPhysicalCard) {
        return renderNoPhysicalCardContent();
      } else if (!hasVirtualCard) {
        return renderNoVirtualCardContent();
      } else {
        return renderDefaultFinishedContent();
      }
    } else if (!cardsData && !!shouldRequestCards && !isWorkerKycOnboardingInProgress) {
      //it is imperative it stays here, after Paid success checks
      return <></>;
    } else if (currentUser?.defaultPayDistribution?.type === PayDistributionType.EXTERNAL_ACCOUNT) {
      return renderDefaultFinishedContent();
    } else if (currentUser?.defaultPayDistribution?.type === PayDistributionType.CHECK) {
      return renderDefaultFinishedContent();
    } else {
      return <>{renderTabs()}</>;
    }

    return <></>;
  };

  const requestCards = async () => {
    if (!shouldRequestCards) return;
    try {
      setLoading(true);
      const result = await queryBankCardsControllerListBankCards();
      setCardsData(result.cards);
    } catch (error: any) {
      if (error?.data?.error !== "USER_HAS_NO_PARTNER_BANK_ACCOUNT") {
        showErrorModal(error);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    requestCards();
  }, [currentUser]);

  return {
    metadata: {
      translationPrefix,
      currentUser,
      isLoading: isLoading,
    },
    pageData: {
      currentStep,
      steps,
      cardsData,
      bankAccountStep: {
        ...bankAccountLink,
      },
    },
    actions: {
      moveToOnboardingStep,
      moveToBankAccountsStep,
      onSalsaOnboardingComplete,
    },
    renderContent,
  };
};
