import { FC } from "react";
import { Trans } from "react-i18next";

import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import NavigationCardWithDetails from "components/NavigationCardWithDetails";

import { FullScreenLoader } from "uikit";

import { StyledDescription, StyledTitle, TitleInner } from "../../styles";
import { IRootStateProps } from "../../types";
import useRootState from "./useRootState";
import { Container, StyledLargeCard } from "./styles";

const RootState: FC<IRootStateProps> = (props) => {
  const { className, showTitle } = props;
  const translationPrefix = `components.payment_methods`;
  const { isLoading, options } = useRootState(props);

  return (
    <>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <Container className={className}>
          {showTitle && (
            <>
              <StyledTitle>
                <TitleInner>
                  <Trans i18nKey={`${translationPrefix}.title`} />
                </TitleInner>
              </StyledTitle>
              <StyledDescription>
                <Trans i18nKey={`${translationPrefix}.description`} />
              </StyledDescription>
            </>
          )}
          {options.map((item) => {
            return item.isRecommended ? (
              <StyledLargeCard {...item} key={item.id} />
            ) : (
              <NavigationCardWithDetails {...item} key={item.id} buttonIcon={undefined} />
            );
          })}

          {/* <BankingDisclosureContainer>
            <BankingDisclosureComponent />
          </BankingDisclosureContainer> */}
        </Container>
      )}
    </>
  );
};

export default RootState;
