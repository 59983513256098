import { ErrorCircledCrossLarge } from "assets/svg";
import styled from "styled-components";

import { breakpoint } from "helpers";

import { Body2, Checkbox, CustomLink, LinkTextMixin } from "uikit";

export const kycFormContainerMaxWidth = 628;
export const contactInfoContainerMaxWidth = 684;

export const ContactInfoTitle = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  text-align: center;
  margin-bottom: 24px;
`;

export const ContactInfoTitleAlt = styled(ContactInfoTitle)`
  display: flex;
  align-items: center;
  text-align: initial;
  svg {
    margin-right: 21px;
  }
`;

export const ContactInfoTitleError = styled(ContactInfoTitleAlt)`
  color: ${({ theme }) => theme.colors.danger2};
`;

export const ErrorIcon = styled(ErrorCircledCrossLarge)`
  width: 30px;
  height: 30px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const KycErrorContainer = styled.div`
  width: 100%;
  max-width: ${kycFormContainerMaxWidth}px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.contactInfoBg};
  padding: 28px 24px;
  margin-bottom: 24px;

  ${breakpoint("xs", "lg")`
    max-width: 100%;
		padding: 20px 16px;
  `}
`;

export const KycErrorTitle = styled.p`
  color: ${({ theme }) => theme.colors.danger2};
  margin-bottom: 0;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  text-align: center;
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  text-align: initial;
  svg {
    margin-right: 21px;
  }
`;

export const KycErrorDescription = styled.p`
  margin-top: 8px;
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.text};
`;

export const CheckboxWrapper = styled.div`
  box-sizing: border-box;
  margin-top: 16px;
  padding: 16px 24px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 5px;
  max-width: ${kycFormContainerMaxWidth}px;

  ${breakpoint("xs", "lg")`
    width: 100%;
    max-width: 100%;
  `}
`;

export const CheckboxText = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.8;
`;

export const CheckboxTextEmphasized = styled.span`
  color: ${({ theme }) => theme.colors.primary};
`;
export const CheckboxTextEmphasizedAlt = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const CheckboxTextLink = styled.a`
  ${LinkTextMixin};
  font-size: ${({ theme }) => theme.size.caption};
`;

export const ContactInfoContainer = styled.div`
  width: 100%;
  max-width: ${contactInfoContainerMaxWidth}px;
`;

export const ContactInfoBottomContainer = styled.div`
  margin-top: 28px;
  padding: 20px 32px;
  background-color: ${({ theme }) => theme.colors.contactInfoFooterBg};
`;

export const CIBCText = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.body2};
`;

export const CIBCLink = styled.span`
  ${LinkTextMixin}
  font-size: inherit;
`;

export const TermsContainer = styled.div`
  max-width: 560px;
`;

export const Term = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const TermText = styled(Body2)`
  margin-left: 17px;
  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  line-height: 1.3;
`;

export const StyledCheckbox = styled(Checkbox)`
  & > span {
    width: 17px;
    height: 17px;
  }

  svg {
    width: 13px;
  }
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const RegDDLink = styled(CustomLink)`
  &,
  &:link,
  &:active,
  &:hover,
  &:visited {
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    text-decoration: underline;
  }
`;

export const BoldUnderline = styled(Bold)`
  text-decoration: underline;
`;

export const ErrorText = styled.span`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.danger};
`;

export const ErrorUnderline = styled(ErrorText)`
  text-decoration: underline;
`;
