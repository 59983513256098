import { ReactNode } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Tooltip from "react-tooltip";
import CheckIcon from "assets/gif/check_circle.gif";
import HourglassIcon from "assets/gif/hourglass.gif";
import IdIcon from "assets/gif/id.gif";
import copy from "copy-to-clipboard";

import { companyMetadataSelector } from "redux/selectors";
import { AppEvents } from "services/events";

import { DotsSpinnerLoader, Emphasized } from "uikit";
import { IModalProps } from "uikit/Modal/types";

import {
  DescriptionWithNoTitle,
  ErrorIcon,
  ErrorSubtitle,
  ImageIcon,
  StyledCompanyContactInfo,
  StyledTitle,
  TooltipContainer,
} from "./modalStyles";

export enum EAcknowledgementModalTypes {
  PROGRESS = "progress",
  SUCCESS = "success",
  ERROR = "error",
  INCORRECT_INFO = "incorrect_info",
  MISSING_INFO = "missing_info",
  VERIFICATION_IS_TAKING_TOO_LONG = "verification_is_taking_too_long",
}

export interface IShowModalProps {
  type: EAcknowledgementModalTypes;
  onMainButtonClick?: () => void;
  onSecondaryButtonClick?: () => void;
  onCloseClick?: () => void;
}

export const useKycStatusModals = (originTranslationPrefix: string) => {
  const prefix = `${originTranslationPrefix}.verification_step.modals`;
  const copyTooltipId = "verification-modal-copy-tooltip";

  const { t } = useTranslation();
  const companyInfo = useSelector(companyMetadataSelector);

  const hideModal = () => {
    AppEvents.emit("CloseGlobalModal", {});
  };

  const renderTooltip = (children: ReactNode) => {
    return (
      <TooltipContainer data-for={copyTooltipId} data-html={false} data-tip={t("common.copied")}>
        {children}
        <Tooltip
          id={copyTooltipId}
          place="top"
          type="dark"
          effect="float"
          event="click"
          afterShow={() => {
            copy(companyInfo?.contactEmail || "");
            setTimeout(Tooltip.hide, 2000);
          }}
        />
      </TooltipContainer>
    );
  };

  const getModalConfig = (type: EAcknowledgementModalTypes): Partial<IModalProps> | undefined => {
    if (type === EAcknowledgementModalTypes.PROGRESS) {
      return {
        icon: <DotsSpinnerLoader />,
        title: <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.PROGRESS}.title`}></Trans>,
        message: (
          <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.PROGRESS}.message`}></Trans>
        ),
        canClose: false,
      };
    }

    if (type === EAcknowledgementModalTypes.SUCCESS) {
      return {
        icon: <ImageIcon src={CheckIcon} />,
        onClose: hideModal,
        message: (
          <DescriptionWithNoTitle>
            <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.SUCCESS}.message`}>
              {" "}
              <Emphasized />
            </Trans>
          </DescriptionWithNoTitle>
        ),
        mainButton: {
          text: (
            <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.SUCCESS}.main_button`}></Trans>
          ),
          onClick: () => AppEvents.emit("SetGlobalModal", { isOpen: false }),
        },
      };
    }

    if (type === EAcknowledgementModalTypes.ERROR) {
      return {
        icon: <ErrorIcon />,
        title: (
          <StyledTitle>
            <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.ERROR}.title`}>
              {" "}
              <ErrorSubtitle></ErrorSubtitle>
            </Trans>
          </StyledTitle>
        ),
        message: (
          <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.ERROR}.message`}>
            {" "}
            <StyledCompanyContactInfo
              companyName={companyInfo?.name}
              contactEmail={companyInfo?.contactEmail || ""}
            />
          </Trans>
        ),
        mainButton: {
          text: (
            <>
              {renderTooltip(
                <Trans
                  i18nKey={`${prefix}.${EAcknowledgementModalTypes.ERROR}.main_button`}
                ></Trans>,
              )}
            </>
          ),
        },
        secondaryButton: {
          onClick: hideModal,
        },
        onCloseOnButtons: false,
      };
    }

    if (type === EAcknowledgementModalTypes.INCORRECT_INFO) {
      return {
        icon: <ImageIcon src={IdIcon} />,
        title: (
          <StyledTitle>
            <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.INCORRECT_INFO}.title`}></Trans>
          </StyledTitle>
        ),
        message: (
          <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.INCORRECT_INFO}.message`}>
            {" "}
            <StyledCompanyContactInfo
              companyName={companyInfo?.name}
              contactEmail={companyInfo?.contactEmail || ""}
            />
          </Trans>
        ),
        mainButton: {
          text: (
            <>
              {renderTooltip(
                <Trans
                  i18nKey={`${prefix}.${EAcknowledgementModalTypes.INCORRECT_INFO}.main_button`}
                ></Trans>,
              )}
            </>
          ),
        },
        secondaryButton: {
          onClick: hideModal,
        },
        onCloseOnButtons: false,
      };
    }

    if (type === EAcknowledgementModalTypes.MISSING_INFO) {
      return {
        icon: <ImageIcon src={IdIcon} />,
        title: (
          <StyledTitle>
            <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.MISSING_INFO}.title`}></Trans>
          </StyledTitle>
        ),
        message: (
          <Trans i18nKey={`${prefix}.${EAcknowledgementModalTypes.MISSING_INFO}.message`}>
            {" "}
            <StyledCompanyContactInfo
              companyName={companyInfo?.name}
              contactEmail={companyInfo?.contactEmail || ""}
            />
          </Trans>
        ),
        mainButton: {
          text: (
            <>
              {renderTooltip(
                <Trans
                  i18nKey={`${prefix}.${EAcknowledgementModalTypes.MISSING_INFO}.main_button`}
                ></Trans>,
              )}
            </>
          ),
        },
        secondaryButton: {
          onClick: hideModal,
        },
        onCloseOnButtons: false,
      };
    }

    if (type === EAcknowledgementModalTypes.VERIFICATION_IS_TAKING_TOO_LONG) {
      return {
        icon: <ImageIcon src={HourglassIcon} />,
        title: (
          <Trans
            i18nKey={`${prefix}.${EAcknowledgementModalTypes.VERIFICATION_IS_TAKING_TOO_LONG}.title`}
          ></Trans>
        ),
        message: (
          <Trans
            i18nKey={`${prefix}.${EAcknowledgementModalTypes.VERIFICATION_IS_TAKING_TOO_LONG}.message`}
          ></Trans>
        ),
        mainButton: {
          onClick: () => undefined,
        },
      };
    }
  };

  const showModal = ({
    type,
    onMainButtonClick,
    onSecondaryButtonClick,
    onCloseClick,
  }: IShowModalProps) => {
    const modalProps = getModalConfig(type);
    if (!modalProps) return;

    AppEvents.emit("SetGlobalModal", {
      customContentStyle: { width: 506 },
      isOpen: true,
      ...modalProps,
      mainButton: modalProps.mainButton
        ? {
            ...(modalProps.mainButton || {}),
            text: modalProps.mainButton?.text || <Trans i18nKey={"common.modal.okay"}></Trans>,
            onClick: onMainButtonClick || modalProps.mainButton?.onClick,
          }
        : undefined,
      secondaryButton: modalProps.secondaryButton
        ? {
            ...(modalProps.secondaryButton || {}),
            text: modalProps.secondaryButton?.text || (
              <Trans i18nKey={"common.modal.cancel"}></Trans>
            ),
            onClick: onSecondaryButtonClick || modalProps.secondaryButton?.onClick,
          }
        : undefined,
      onClose: onCloseClick || hideModal,
    });
  };

  return {
    EAcknowledgementModalTypes,
    showModal,
    hideModal,
  };
};
