import { FC } from "react";

import IconProps from "./IconProps";

const Logo: FC<IconProps> = ({ className }) => (
  <svg
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g filter="url(#filter0_d_313_14564)">
      <circle cx="33.7773" cy="31.3652" r="27.5" fill="white" />
    </g>
    <path
      d="M30.6798 16.3474C31.4603 15.7852 32.3261 15.3574 33.253 15.0274C34.192 14.7097 35.0944 14.5508 35.9969 14.5508C37.3505 14.5508 38.6066 14.8563 39.7651 15.4674C40.9237 16.0786 42.2042 17.0074 43.0334 18.083C44.7652 20.3074 47.3871 27.653 42.3261 33.483C41.07 34.9374 39.9115 35.6952 38.4969 36.3674C37.0822 37.0397 35.5578 37.3697 33.9359 37.3697C32.6676 37.3697 31.5822 37.1741 30.692 36.783V45.7174L24.2773 47.5508V15.0519H30.692V16.3474H30.6798ZM30.6798 32.0897C30.6798 32.7863 30.7895 33.4219 31.0091 34.0086C31.2286 34.5952 31.5091 35.0963 31.8627 35.5119C32.2164 35.9274 32.6188 36.2574 33.0578 36.4897C33.5091 36.7219 33.9481 36.8441 34.3749 36.8441C34.9725 36.8441 35.5334 36.6486 36.0578 36.2574C36.5822 35.8663 37.0456 35.3286 37.4359 34.6319C37.8261 33.9352 38.1432 33.1163 38.3749 32.1752C38.6066 31.2341 38.7164 28.0563 38.7164 26.9441C38.7164 25.7219 38.5822 22.4097 38.3139 21.3097C38.0456 20.2097 37.6798 19.2441 37.2408 18.413C36.7895 17.5819 36.2651 16.9097 35.6554 16.4086C35.0456 15.9074 34.3871 15.663 33.692 15.663C33.2042 15.663 32.6798 15.8097 32.1066 16.103C31.5334 16.3963 31.07 16.7752 30.692 17.2519V32.1019L30.6798 32.0897Z"
      fill="#25BBA5"
    />
    <circle cx="41.916" cy="44.1895" r="3.36133" fill="#F96331" />
    <defs>
      <filter
        id="filter0_d_313_14564"
        x="0.277344"
        y="0.865234"
        width="67"
        height="67"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_313_14564" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_313_14564"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export default Logo;
