import { ReactNode } from "react";
import { Props as ScrollProps } from "react-infinite-scroll-component";
import { Props as IPaginationProps } from "uikit/Pagination";

export interface IColumn {
  key: string;
  type: EColumnType;
  title?: string | ReactNode;
  sortable?: boolean;
  className?: string;
  component?: (row?: any, index?: number) => ReactNode;
  onClick?: () => void;
}

export interface IFooterColumn {
  className?: string;
  label: string | ReactNode;
  value: string | ReactNode;
}

export interface IScrollProps {
  maxHeight: number;
}

export interface IInfiniteScrollProps extends Partial<ScrollProps> {
  next: () => void;
  totalCount: number;
  hasMore: boolean;
  loader?: ReactNode;
  dataLength: number;
  scrollableId?: string;
  maxHeight?: string;
}

export enum EColumnType {
  Date = "Date",
  Text = "Text",
  Component = "Component",
}

export interface ITablePaginationProps extends IPaginationProps {}

export interface ITablePaginationPropsExtended extends Omit<ITablePaginationProps, "onPageChange"> {
  limit: Readonly<number>;
  page: number;
}
