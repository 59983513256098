import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import moment from "moment";

import SelectOption from "types/SelectOption";
import { TStates } from "types/States";
import {
  MAX_INPUT_LENGTH,
  MAX_INPUT_LENGTH_LONG,
  StateSelectOptions,
  zipInputFormat,
} from "constants/";
import { getFieldError } from "helpers";

import { DatePicker, Input, NumberInput, PhoneInput, SsnInput } from "uikit";
import CustomSelect from "uikit/Input/Select";

import { KYCProfileFormType } from "./validationSchema";
import { ShortInputsWrapper, Wrapper, WrapperInner } from "./styles";

interface IProps {
  originTranslationPrefix: string;
}

export const KYCProfileForm: FC<IProps> = ({ originTranslationPrefix }) => {
  const { t } = useTranslation();
  const translationPrefix = `${originTranslationPrefix}.profile_form`;
  const formLabelTranslationPrefix = `${translationPrefix}.form_labels`;

  const formikProps = useFormikContext<KYCProfileFormType>();
  const { values, setFieldValue, handleChange } = formikProps;

  return (
    <>
      <Wrapper>
        <WrapperInner>
          <Input
            name="firstName"
            onChange={(e) => setFieldValue("firstName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("firstName", e.currentTarget.value.trimEnd())}
            value={values.firstName}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.first_name`} />}
            error={getFieldError("firstName", formikProps, {
              field: t(`${formLabelTranslationPrefix}.first_name`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-first-name"
            disabled
          />

          <Input
            name="lastName"
            onChange={(e) => setFieldValue("lastName", e.currentTarget.value.trimStart())}
            onBlur={(e) => setFieldValue("lastName", e.currentTarget.value.trimEnd())}
            value={values.lastName}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.last_name`} />}
            error={getFieldError("lastName", formikProps, {
              field: t(`${formLabelTranslationPrefix}.last_name`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-last-name"
            disabled
          />

          <PhoneInput
            name="phone"
            onChange={(value) => setFieldValue("phone", value)}
            value={values.phone || undefined}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.phone`} />}
            error={getFieldError("phone", formikProps, {
              field: t(`${formLabelTranslationPrefix}.phone`),
            })}
            errorDataTestId="error-phone"
          />

          <SsnInput
            onSetFormField={(value: string) => setFieldValue("socialSecurityNumber", value)}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.social_security_number`} />}
            error={getFieldError("socialSecurityNumber", formikProps, {
              field: t(`${formLabelTranslationPrefix}.social_security_number`),
            })}
            value={values.socialSecurityNumber || ""}
          />

          <DatePicker
            useMothsYearsHeader={true}
            name="birthDate"
            selected={values.birthDate || null}
            onChange={(e) => setFieldValue("birthDate", e)}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.birth_date`} />}
            error={getFieldError("birthDate", formikProps, {
              field: t(`${formLabelTranslationPrefix}.birth_date`),
            })}
            maxDate={new Date()}
            minDate={moment().subtract(100, "year").toDate()}
            errorDataTestId="error-birthdate"
          />

          <Input
            name="address"
            onChange={handleChange}
            value={values.address}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.address`} />}
            error={getFieldError("address", formikProps, {
              field: t(`${formLabelTranslationPrefix}.address`),
              maxInputLength: MAX_INPUT_LENGTH_LONG,
            })}
            errorDataTestId="error-address"
          />

          <Input
            name="suite"
            optional
            onChange={handleChange}
            value={values.suite || ""}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.suite`} />}
            error={getFieldError("suite", formikProps, {
              field: t(`${formLabelTranslationPrefix}.suite`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-suit"
          />

          <Input
            name="city"
            onChange={handleChange}
            value={values.city || ""}
            label={<Trans i18nKey={`${formLabelTranslationPrefix}.city`} />}
            error={getFieldError("city", formikProps, {
              field: t(`${formLabelTranslationPrefix}.city`),
              maxInputLength: MAX_INPUT_LENGTH,
            })}
            errorDataTestId="error-city"
          />

          <ShortInputsWrapper>
            <CustomSelect<SelectOption<TStates>>
              name="state"
              onChange={(option) => setFieldValue("state", option)}
              value={values.state as any}
              options={StateSelectOptions}
              label={<Trans i18nKey={`${formLabelTranslationPrefix}.state`} />}
              placeholder={""}
              error={getFieldError("state.value", formikProps, {
                field: t(`${formLabelTranslationPrefix}.state`),
              })}
              isSearchable={true}
              errorDataTestId="error-state"
            />

            <NumberInput
              name="zip"
              onChange={(val) => setFieldValue("zip", val.toString().split(" ").join(""))}
              value={values.zip}
              label={<Trans i18nKey={`${formLabelTranslationPrefix}.zip`} />}
              error={getFieldError("zip", formikProps, {
                field: t(`${formLabelTranslationPrefix}.zip`),
              })}
              errorDataTestId="error-zip"
              format={zipInputFormat}
            />
          </ShortInputsWrapper>
        </WrapperInner>
      </Wrapper>
    </>
  );
};
