import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, PageTitleMargined } from "uikit";

import useAdminDocumentsPage from "./useAdminDocumentsPage";
import { FrameContainer } from "./styles";

interface Props {
  className?: string;
}

const AdminDocumentsPage: FC<Props> = () => {
  const translationPrefix = `settings_pages.admin.documents_page.index`;

  const { frameId } = useAdminDocumentsPage();

  return (
    <DashboardLayout>
      <ContentContainer>
        <PageTitleMargined>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </PageTitleMargined>

        <FrameContainer id={frameId}></FrameContainer>
      </ContentContainer>
    </DashboardLayout>
  );
};

export default AdminDocumentsPage;
