import { useSelector } from "react-redux";

import { companyMetadataSelector, userMetadataSelector } from "redux/selectors";
import { UserRole } from "types/BETypes";
import { isAdminOnboarded, isWorkerOnboarded } from "helpers";

import routes from "./routes";
import { TRouteCondition, TRouteDisableCondition, TRouteResult } from "./types";

export const getEmployeePagesBlockCondition: TRouteDisableCondition = (user) => {
  return user?.lastActiveRole === UserRole.EMPLOYEE && !isWorkerOnboarded(user);
};

export const getAdminPagesBlockCondition: TRouteDisableCondition = (user, company) => {
  return user?.lastActiveRole !== UserRole.EMPLOYEE && !isAdminOnboarded(company);
};

export const useRouteHelpers = () => {
  const currentUser = useSelector(userMetadataSelector);
  const currentCompany = useSelector(companyMetadataSelector);

  const _getEmployeePagesBlockCondition: TRouteDisableCondition = (
    user = currentUser,
    company = currentCompany,
  ) => {
    return getEmployeePagesBlockCondition(user, company);
  };

  const _getAdminPagesBlockCondition: TRouteDisableCondition = (
    user = currentUser,
    company = currentCompany,
  ) => {
    return getAdminPagesBlockCondition(user, company);
  };

  const shouldShowEmployeeOnboardingPage: TRouteCondition = (user, company) => {
    return !!user?.showSuccessfulOnboarding || !user?.isOnboardingComplete;
  };

  const shouldShowAdminOnboardingPage: TRouteCondition = (user, company) => {
    return !!company?.showSuccessfulOnboarding || !company?.isOnboardingComplete;
  };

  const getDefaultEmployeeRoute: TRouteResult = (user = currentUser, company = currentCompany) => {
    if (!user || !company) return routes.ROOT;
    return shouldShowEmployeeOnboardingPage(user, company)
      ? routes.EMPLOYEE_SETUP
      : routes.EMPLOYEE_PAY;
  };

  const getDefaultAdminRoute: TRouteResult = (user = currentUser, company = currentCompany) => {
    if (!user || !company) return routes.ROOT;
    return shouldShowAdminOnboardingPage(user, company) ? routes.ADMIN_SETUP : routes.ADMIN_PAY;
  };

  const getDefaultRoute: TRouteResult = (user = currentUser, company = currentCompany) => {
    if (!user) return routes.ROOT;
    return user?.lastActiveRole === UserRole.EMPLOYEE
      ? getDefaultEmployeeRoute(user, company)
      : getDefaultAdminRoute(user, company);
  };

  return {
    getEmployeePagesBlockCondition: _getEmployeePagesBlockCondition,
    getAdminPagesBlockCondition: _getAdminPagesBlockCondition,
    getDefaultEmployeeRoute,
    getDefaultAdminRoute,
    getDefaultRoute,
    isAdminOnboarded,
    isWorkerOnboarded,
    shouldShowEmployeeOnboardingPage,
    shouldShowAdminOnboardingPage,
  };
};
