import { FC, ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CloseIcon, HamburgerMenuIcon } from "assets/svg";

import { userMetadataSelector } from "redux/selectors/metadataSelectors";
import MainMenu from "components/MainMenu";

import {
  CloseBtn,
  MenuBackground,
  PageName,
  ResponsiveMenuWrapper,
  ShowBtn,
  SideMenuHeader,
  SideMenuListWrapper,
  SideMenuWrapper,
  StyledLogo,
} from "./styles";

interface IProps {
  pageName?: ReactNode | string;
}

export const ResponsiveMenu: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(false);

  const userInfo = useSelector(userMetadataSelector);

  useEffect(() => {
    const root: HTMLElement | null = document.getElementById("root");
    if (!root) return;
    if (isOpen) {
      root.style.overflow = "hidden";
    } else {
      root.style.overflow = "";
    }
  }, [isOpen]);

  return (
    <>
      <ResponsiveMenuWrapper>
        <ShowBtn onClick={() => setOpen(true)}>
          <HamburgerMenuIcon />
        </ShowBtn>

        <PageName>
          {typeof props.pageName === "string" ? t(props.pageName || "") : props.pageName}
        </PageName>
      </ResponsiveMenuWrapper>
      <>
        <MenuBackground isOpen={isOpen} onClick={() => setOpen(false)} />
        <SideMenuWrapper className={`role-${userInfo?.lastActiveRole}`} isOpen={isOpen}>
          <SideMenuHeader>
            <CloseBtn onClick={() => setOpen(false)}>
              <CloseIcon />
            </CloseBtn>
            <StyledLogo />
          </SideMenuHeader>
          <SideMenuListWrapper>
            <MainMenu needRenderMenuForResponsive={true} />
          </SideMenuListWrapper>
        </SideMenuWrapper>
      </>
    </>
  );
};
