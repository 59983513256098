import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormikProps } from "formik";
import moment from "moment";

import { businessInfoPageOpened } from "services/analytics";
import { MAX_INPUT_LENGTH } from "constants/index";

import { FormLabel, Input } from "uikit";

import { Description, FormItem, Title } from "../styles";
import { BusinessDetailsFormType } from "./validationSchema";
import { Container } from "./styles";

interface IProps {
  values: FormikProps<BusinessDetailsFormType>["values"];
  errors: FormikProps<BusinessDetailsFormType>["errors"];
  handleChange: FormikProps<BusinessDetailsFormType>["handleChange"];
}

export const BusinessDetails: FC<IProps> = ({ values, errors, handleChange }) => {
  const { t } = useTranslation();
  const translationPrefix = "auth_pages.admin.sign_up_page.business_details_step";

  useEffect(() => {
    businessInfoPageOpened({ timestamp: moment().toString() });
  }, []);
  return (
    <Container>
      <Title>
        <Trans i18nKey={`${translationPrefix}.title`} />
      </Title>
      <Description>
        <Trans i18nKey={`${translationPrefix}.description`} />
      </Description>

      <FormItem>
        <FormLabel>
          <Trans i18nKey={`${translationPrefix}.company_name_field.title`} />
        </FormLabel>
        <Input
          placeholder={t(`${translationPrefix}.company_name_field.placeholder`)}
          name={`businessName`}
          value={values?.businessName}
          onChange={handleChange}
          error={
            errors?.businessName && (
              <Trans
                i18nKey={errors.businessName}
                values={{
                  field: t(`${translationPrefix}.company_name_field.title`),
                  maxInputLength: MAX_INPUT_LENGTH,
                }}
              />
            )
          }
          autoComplete="organization"
          errorDataTestId="error-company-name"
        />
      </FormItem>
    </Container>
  );
};
