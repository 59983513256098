import { FC, useState } from "react";
import { PlaidAccount } from "react-plaid-link";
import { find } from "lodash";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { showErrorModal } from "helpers";
import { useBankAccountLink } from "hooks";
import useAuth from "hooks/useAuth";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import { showDirectDepositChosenModal } from "../../modals/showModals";
import { EWidgetType, IDirectDepositProps } from "../../types";

const useDirectDeposit = (props: IDirectDepositProps) => {
  const { widgetType = EWidgetType.SETTINGS } = props;
  const [isLoading, setLoading] = useState<boolean>(true);

  const refetchUserData = async () => {
    await getCurrentUser();
  };

  const showChosenAccountModal = async (chosenBankAccount?: GetPlaidBankAccountsResponseDto) => {
    if (chosenBankAccount) {
      showDirectDepositChosenModal(chosenBankAccount);
    }
  };

  const getChosenBankAccount = (bankAccountsList?: GetPlaidBankAccountsResponseDto[]) => {
    return find(bankAccountsList, (item: GetPlaidBankAccountsResponseDto) => item.isDefault);
  };

  const handleSuccessfulAddedCallback = async (
    bankAccountsList?: GetPlaidBankAccountsResponseDto[],
    newAccounts?: PlaidAccount[],
    oldBankAccountsList?: GetPlaidBankAccountsResponseDto[],
  ) => {
    const chosenAccount = getChosenBankAccount(bankAccountsList);
    const chosenAccountOldState = getChosenBankAccount(oldBankAccountsList);
    await refetchUserData();
    if (
      widgetType !== EWidgetType.ONBOARDING &&
      chosenAccount &&
      newAccounts?.[0].verification_status !==
        BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION &&
      newAccounts?.[0].verification_status !==
        BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION &&
      !chosenAccountOldState?.renewLoginRequired
    ) {
      showChosenAccountModal(chosenAccount);
    }
  };

  const bankAccountLink = useBankAccountLink({
    loadingCallback: setLoading,
    onSuccessAddedCallback: handleSuccessfulAddedCallback,
  });
  const { getCurrentUser } = useAuth();
  const {
    openModal,
    handleSetDefaultBankAccount,
    handleRemoveBankAccount,
    handleVerifyBankAccount,
  } = bankAccountLink.actions;

  const handleSetDefaultBankAccountCustom = async (bankAccountId: string) => {
    try {
      const chosenBankAccount = find(
        bankAccountLink?.data?.bankAccounts,
        (item: GetPlaidBankAccountsResponseDto) => item.bankAccountId === bankAccountId,
      );
      await handleSetDefaultBankAccount(bankAccountId);
      await refetchUserData();
      if (widgetType !== EWidgetType.ONBOARDING) {
        showChosenAccountModal(chosenBankAccount);
      }
    } catch (error) {
      showErrorModal(error);
    }
  };

  return {
    isLoading,
    bankAccounts: bankAccountLink?.data?.bankAccounts,
    actions: {
      onConnectBankAccount: openModal,
      onUpdateDefaultBankAccount: handleSetDefaultBankAccountCustom,
      onRemoveBankAccount: handleRemoveBankAccount,
      onVerifyBankAccount: handleVerifyBankAccount,
      //...bankAccountLink.actions,
    },
  };
};

export default useDirectDeposit;
