import SlidingPane from "react-sliding-pane";
import styled from "styled-components";

import { CloseModalButton } from "uikit";

export const AddWorkerPane = styled(SlidingPane)`
  width: 680px !important;
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.slidePane};

  .slide-pane__header {
    display: none;
  }

  .slide-pane__content {
    padding: 24px 40px 24px 32px;
  }
`;

export const AddWorkerCloseButton = styled(CloseModalButton)`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
`;
