import { ISalsa } from "redux/types";

import { SET_SALSA } from "../constants/ActionTypes";

export const setSalsa = (values: ISalsa) => (dispatch: any) => {
  dispatch({
    type: SET_SALSA,
    payload: values,
  });
};
