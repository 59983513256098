import {
  BonusStatus,
  ComparisonOperators,
  FilterBonusDetails,
  FilterBonusStatus,
  FilterEmployeeStatus,
} from "types/BETypes";
import SelectOption from "types/SelectOption";
import { EComprehensibleUserStatuses } from "helpers/employee/userStatusMap";

import { ComprehensibleUserStatusOptions } from "../shared";

const employeesPagePrefix = "employees_page.filter_modal";

export const ComprehensibleUserStatusFilterOptions: SelectOption<
  FilterEmployeeStatus | EComprehensibleUserStatuses
>[] = [
  {
    value: FilterEmployeeStatus.ALL,
    label: `${employeesPagePrefix}.user_status_options.${FilterEmployeeStatus.ALL}`,
  },
  {
    value: FilterEmployeeStatus.WITH_BALANCE_DUE,
    label: `${employeesPagePrefix}.user_status_options.${FilterEmployeeStatus.WITH_BALANCE_DUE}`,
  },
  ...ComprehensibleUserStatusOptions,
];

export const BonusStatusFilterOptions: SelectOption<FilterBonusStatus | BonusStatus>[] = [
  {
    value: FilterBonusStatus.ALL,
    label: `${employeesPagePrefix}.bonus_status_options.${FilterBonusStatus.ALL}`,
  },
  {
    value: FilterBonusStatus.WITH_BONUS,
    label: `${employeesPagePrefix}.bonus_status_options.${FilterBonusStatus.WITH_BONUS}`,
  },
  {
    value: FilterBonusStatus.NO_BONUS,
    label: `${employeesPagePrefix}.bonus_status_options.${FilterBonusStatus.NO_BONUS}`,
  },
  { value: BonusStatus.DRAFT, label: `bonus_details.bonus_status_label.${BonusStatus.DRAFT}` },
  { value: BonusStatus.PENDING, label: `bonus_details.bonus_status_label.${BonusStatus.PENDING}` },
  {
    value: BonusStatus.ACCEPTED,
    label: `bonus_details.bonus_status_label.${BonusStatus.ACCEPTED}`,
  },
  { value: BonusStatus.ACTIVE, label: `bonus_details.bonus_status_label.${BonusStatus.ACTIVE}` },
  {
    value: BonusStatus.COMPLETED,
    label: `${employeesPagePrefix}.bonus_status_options.${FilterBonusStatus.COMPLETED}`,
  },
  {
    value: BonusStatus.NOT_COMPLETED,
    label: `${employeesPagePrefix}.bonus_status_options.${FilterBonusStatus.NOT_COMPLETED}`,
  },
  {
    value: BonusStatus.VESTING_STOPPED,
    label: `bonus_details.bonus_status_label.${BonusStatus.VESTING_STOPPED}`,
  },
  {
    value: FilterBonusStatus.PAST_DUE_DATE,
    label: `${employeesPagePrefix}.bonus_status_options.${FilterBonusStatus.PAST_DUE_DATE}`,
  },
  { value: BonusStatus.VOIDED, label: `bonus_details.bonus_status_label.${BonusStatus.VOIDED}` },
  {
    value: BonusStatus.DECLINED,
    label: `bonus_details.bonus_status_label.${BonusStatus.DECLINED}`,
  },
];

export const bonusDetailsOptionFilterOptions: SelectOption<FilterBonusDetails>[] = [
  {
    value: FilterBonusDetails.ISSUED_AMOUNT,
    label: `${employeesPagePrefix}.bonus_details_options.${FilterBonusDetails.ISSUED_AMOUNT}`,
  },
  {
    value: FilterBonusDetails.TOTAL_AMOUNT,
    label: `${employeesPagePrefix}.bonus_details_options.${FilterBonusDetails.TOTAL_AMOUNT}`,
  },
  {
    value: FilterBonusDetails.VESTED_BALANCE,
    label: `${employeesPagePrefix}.bonus_details_options.${FilterBonusDetails.VESTED_BALANCE}`,
  },
  {
    value: FilterBonusDetails.UNVESTED_BALANCE,
    label: `${employeesPagePrefix}.bonus_details_options.${FilterBonusDetails.UNVESTED_BALANCE}`,
  },
];

export const ComparisonOperatorsOptions = [
  {
    value: ComparisonOperators.GREATER,
    label: `${employeesPagePrefix}.bonus_sum_operator_options.${ComparisonOperators.GREATER}`,
  },
  {
    value: ComparisonOperators.LESS,
    label: `${employeesPagePrefix}.bonus_sum_operator_options.${ComparisonOperators.LESS}`,
  },
];
