import { useState } from "react";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";

import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { ErrorConst } from "types/BETypes";
import { showErrorModal } from "helpers/index";
import { useVerifyCompanyDomain } from "hooks";
import useAuth from "hooks/useAuth";

import { SignInFormType } from "../components";
import { ICheckEmailPageLocationState } from "../types";

export const useSingIn = (mode: EMagicLinkFlow) => {
  const navigate = useNavigate();
  const [svoc, setSvoc] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  useVerifyCompanyDomain();

  const [tooManyAttemptsErrorModalIsOpen, setOpenTooManyAttemptsErrorModal] =
    useState<boolean>(false);

  const { logout, googleAuth, sendMagicLink } = useAuth();

  const formInitialValues: SignInFormType = {
    email: "",
  };

  const handleSubmit = async (values: SignInFormType) => {
    setLoading(true);
    setSvoc(true);
    const _email = values.email.trim();
    await handleSendMagicLink(_email);
  };

  const handleSendMagicLink = async (email: string, withRedirect: boolean = true) => {
    setLoading(true);
    try {
      const result = await sendMagicLink(email, mode);
      if (!result?.success) throw result.errorMessage;
      if (result?.success) {
        if (withRedirect) {
          navigate(routes.CHECK_EMAIL, {
            state: { email: email, magicLinkFlow: mode } as ICheckEmailPageLocationState,
          });
        }
      }
    } catch (error: any) {
      if (error === ErrorConst.TOO_MANY_ATTEMPTS) {
        setOpenTooManyAttemptsErrorModal(true);
      } else if (error === ErrorConst.INVALID_CREDENTIALS) {
        if (mode === EMagicLinkFlow.SIGN_IN) {
          showErrorModal(`${ErrorConst.INVALID_CREDENTIALS}_SIGN_IN`);
        } else {
          showErrorModal(`${ErrorConst.INVALID_CREDENTIALS}_SIGN_UP`);
        }
      } else {
        showErrorModal(error);
      }
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const handleGoogleAuth = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    try {
      e.preventDefault();
      const result = await googleAuth(mode);
      await logout(false, false);
      if (result.success) {
        const link = document.createElement("a");
        link.href = result.url!;
        link.click();
      } else {
        throw new Error();
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    metadata: {
      svoc,
      formInitialValues,
      isLoading,
      setLoading,
    },
    actions: {
      handleSubmit,
      handleGoogleAuth,
      handleSendMagicLink,
    },
    modals: {
      tooManyAttemptsErrorModalIsOpen,
      setOpenTooManyAttemptsErrorModal,
    },
  };
};
