import { FC } from "react";

import { FullScreenLoader } from "uikit";

import CompanyBankAccount from "./components/CompanyBankAccount";
import DirectDeposit from "./components/DirectDeposit";
import RootState from "./components/RootState";
import { EOptionIds, EWidgetType, IProps } from "./types";
import usePaymentMethods from "./usePaymentMethods";
import { Container } from "./styles";

const PaymentMethods: FC<IProps> = (props) => {
  const { className, widgetType = EWidgetType.SETTINGS } = props;
  const translationPrefix = `components.payment_methods`;
  const {
    isLoading,
    currentMethodFlow,
    handleCompanyBankAccountClick,
    handleCompanyBankAccountSelect,
    handleDirectDepositClick,
    handlePaperCheckSelect,
    handleCompanyBankAccountKycVerificationSuccess,
    handleCompanyBankAccountShippingDetailsSuccess,
    handleCompanyBankAccountBackButtonClick,
    handleDirectDepositBackButtonClick,
  } = usePaymentMethods(props);

  const renderCurrentFlow = () => {
    switch (currentMethodFlow) {
      case EOptionIds.COMPANY_BANK_ACCOUNT:
        return (
          <CompanyBankAccount
            onBack={handleCompanyBankAccountBackButtonClick}
            onShippingDetailsSuccess={handleCompanyBankAccountShippingDetailsSuccess}
            onKycVerificationSuccess={handleCompanyBankAccountKycVerificationSuccess}
            widgetType={widgetType}
          />
        );
      case EOptionIds.DIRECT_DEPOSIT:
        return (
          <DirectDeposit onBack={handleDirectDepositBackButtonClick} widgetType={widgetType} />
        );
      case EOptionIds.PAPER_CHECK:
        return <></>;
      default:
        return (
          <RootState
            onDirectDepositClick={handleDirectDepositClick}
            onCompanyBankAccountClick={handleCompanyBankAccountClick}
            onCompanyBankAccountSelect={handleCompanyBankAccountSelect}
            onPaperCheckSelect={handlePaperCheckSelect}
            showTitle={currentMethodFlow === EOptionIds.DEFAULT}
            widgetType={widgetType}
          />
        );
    }
  };

  return (
    <Container className={className}>
      {!!isLoading && <FullScreenLoader />}
      {renderCurrentFlow()}
    </Container>
  );
};

export default PaymentMethods;
