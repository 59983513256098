import PaymentContactBg from "assets/png/employee_payment_contact_hires.png";
import OrangeBg from "assets/png/orange_round_bg.png";
import { CloseIconThin, LogoFull } from "assets/svg";
import styled from "styled-components";

import { NavigationCardWithDetailsLarge } from "components/NavigationCardWithDetails";
import {
  ContainerLarge as NCLargeCardContainer,
  hContainerPadding,
  vLargeContainerPadding,
} from "components/NavigationCardWithDetails/styles";

import { NoBorderButton } from "uikit";

export const Container = styled.div``;

export const StyledLargeCard = styled(NavigationCardWithDetailsLarge)`
  ${NCLargeCardContainer} {
    height: 320px;
    background-image: url("${OrangeBg}");
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: top right;
  }
`;

//Large company bank account card content
export const CBACardContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-image: url("${PaymentContactBg}");
  background-repeat: no-repeat;
  background-position: right bottom -34px;
  background-size: 191px auto;
  padding-top: ${vLargeContainerPadding}px;
  padding-bottom: ${vLargeContainerPadding}px;
`;

export const CBALogo = styled(LogoFull)`
  position: absolute;
  top: 32px;
  right: 450px;
`;

export const CBAMainContent = styled.div`
  padding: 0 200px 0 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const CBASubcard = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  transform: rotate(-6deg);
  border-radius: 16px;
  line-height: 1.71;
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBg};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardText};
  margin-bottom: 16px;

  &:nth-of-type(1) {
    margin-bottom: 32px;
  }

  &:nth-of-type(2) {
    margin-right: 58px;
  }

  &:nth-of-type(3) {
    margin-right: 10px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CBASIconContainer = styled.div`
  margin-right: 12px;
`;

export const CBASTextContainer = styled.div``;

export const CardBottomContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  top: -10px;
  height: 68px;
  width: 100%;
  border-radius: 0 0 8px 8px;
  padding: 10px ${hContainerPadding}px 0 ${hContainerPadding}px;
  box-sizing: border-box;
`;

//Direct deposit below card
export const CBCInfo = styled(CardBottomContainer)`
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentInfoBg};
`;

export const CBCIBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentinfoBlockBg};
  margin-right: 24px;
  padding: 0 24px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;

  &:last-of-type {
    margin-right: 0;
  }
`;

export const CBCINumber = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 12px;

  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentinfoBlockNumberText};
`;

export const CBCIText = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const CBCIBlockError = styled(CBCIBlock)`
  ${CBCINumber} {
    background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorText};
  }
  ${CBCIText} {
    color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorText};
  }
`;

export const CBCIBlockWarning = styled(CBCIBlock)`
  ${CBCINumber} {
    background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentinfoText};
  }
  ${CBCIText} {
    color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentinfoText};
  }
`;

export const CBCText = styled.p`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.75;
`;

export const CBCIconContainer = styled.div`
  margin-right: 12px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

//Company Account below warning card
export const CBCWarning = styled(CardBottomContainer)`
  justify-content: center;
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentWarningBg};
`;

export const CBCWIconContainer = styled(CBCIconContainer)`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentWarningText};
    }
  }
`;

export const CBCWText = styled(CBCText)`
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentWarningText};
`;

//Company Account below error card
export const CBCError = styled(CardBottomContainer)`
  justify-content: center;
  background: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorBg};
`;

export const CBCEIconContainer = styled(CBCIconContainer)`
  svg {
    path {
      fill: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorText};
    }
  }
`;

export const CBCEErrorText = styled(CBCText)`
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorText};
  margin-right: 20px;
`;

export const CBCEButton = styled(NoBorderButton)`
  width: auto;
  min-width: auto;
  padding: 0;
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorTextAlt};
  text-decoration: underline;
  line-height: 1.5;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.paymentMethodsSubcardBelowContentErrorTextAltHover};
  }
`;

export const ACContentButton = styled(NoBorderButton)`
  width: auto;
  min-width: auto;
  padding: 0;
  color: ${({ theme }) => theme.colors.primary};
  text-decoration: underline;

  &:hover,
  &:active {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const CardSelectedBankContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 18px;
`;
export const CSBCText = styled.span`
  display: inline-block;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.71;
  margin-right: 16px;
`;
