import { BankAccountsLogo } from "constants/index";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

export const getBankLogo = (bankAccount: GetPlaidBankAccountsResponseDto) => {
  if (bankAccount?.institution?.logo) {
    return `data:image/png;base64,${bankAccount.institution?.logo}`;
  } else if (bankAccount?.institution?.name && BankAccountsLogo?.[bankAccount?.institution?.name]) {
    return BankAccountsLogo?.[bankAccount?.institution?.name];
  } else {
    return "";
  }
};
