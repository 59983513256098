import styled from "styled-components";

import { FrameContainerBase } from "uikit/Containers/Containers";

export const FrameContainer = styled(FrameContainerBase)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const EmailSection = styled.div`
  margin-bottom: 20px;
  max-width: 360px;
`;
