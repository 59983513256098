import { forwardRef, useImperativeHandle, useRef } from "react";
import { Trans } from "react-i18next";
import { CheckmarkRoundFilledDark, ClockSmall } from "assets/svg";
import { Formik, FormikProps } from "formik";

import { KycStatus } from "types/BETypes";
import { TERMS_AND_CONDITIONS } from "constants/";
import ContactInfo from "components/ContactInfo";

import { Checkbox, PageTitleMargined } from "uikit";

import { UserResponseDto } from "utils/swagger_react_query";

import {
  KYCProfileForm,
  KYCProfileFormType,
  KYCProfileFormValidationSchema,
} from "./KYCProfileForm";
import { IKYCProfileFormRef } from "./types";
import {
  CheckboxText,
  CheckboxTextEmphasized,
  CheckboxTextEmphasizedAlt,
  CheckboxTextLink,
  CheckboxWrapper,
  ContactInfoContainer,
  ContactInfoTitleAlt,
  ContactInfoTitleError,
  Container,
  Content,
  ErrorIcon,
  KycErrorContainer,
  KycErrorDescription,
  KycErrorTitle,
} from "./styles";

export interface IKYCVerificationWidgetProps {
  className?: string;
  userDetails: UserResponseDto | null | undefined;
  profileData: {
    initValues: KYCProfileFormType;
    onSubmit: (values: KYCProfileFormType) => void;
    svoc: boolean;
  };
  termAndConditionsCheckboxProps: {
    isChecked: boolean;
    handleChange: () => void;
  };
}

const KYCVerificationWidget = forwardRef<IKYCProfileFormRef, IKYCVerificationWidgetProps>(
  ({ className, profileData, userDetails, termAndConditionsCheckboxProps }, ref) => {
    const translationPrefix = `components.kyc_verification_widget`;
    const verificationStepTranslationPrefix = `${translationPrefix}.verification_step`;
    const canVerifyKyc = !userDetails?.kycStatus;

    const isKycAccepted = userDetails?.kycStatus === KycStatus.ACCEPTED;
    const isKycRejected = userDetails?.kycStatus === KycStatus.REJECTED;
    const isKycPending =
      userDetails?.kycStatus === KycStatus.PENDING ||
      userDetails?.kycStatus === KycStatus.REVIEW ||
      userDetails?.kycStatus === KycStatus.PROVISIONAL;

    const formikRef = useRef<FormikProps<KYCProfileFormType>>(null);

    useImperativeHandle(ref, () => ({
      onSubmit: () => {
        formikRef?.current?.submitForm();
      },
    }));

    const renderAccepted = () => {
      return (
        <>
          <ContactInfoContainer>
            <ContactInfo
              user={userDetails}
              address={userDetails}
              topContent={
                <ContactInfoTitleAlt>
                  <CheckmarkRoundFilledDark />
                  <Trans
                    i18nKey={`${verificationStepTranslationPrefix}.verification_statuses.contact_info_accepted_title`}
                  ></Trans>
                </ContactInfoTitleAlt>
              }
            />
          </ContactInfoContainer>
        </>
      );
    };

    const renderPending = () => {
      return (
        <>
          <ContactInfoContainer>
            <ContactInfo
              user={userDetails}
              address={userDetails}
              topContent={
                <ContactInfoTitleAlt>
                  <ClockSmall />
                  <Trans
                    i18nKey={`${verificationStepTranslationPrefix}.verification_statuses.contact_info_pending_title`}
                  ></Trans>
                </ContactInfoTitleAlt>
              }
            />
          </ContactInfoContainer>
        </>
      );
    };

    const renderFailed = () => {
      return (
        <>
          <ContactInfoContainer>
            <ContactInfo
              user={userDetails}
              address={userDetails}
              topContent={
                <ContactInfoTitleError>
                  <ErrorIcon />
                  <Trans
                    i18nKey={`${verificationStepTranslationPrefix}.verification_statuses.contact_info_failed_title`}
                  ></Trans>
                </ContactInfoTitleError>
              }
            />
          </ContactInfoContainer>
        </>
      );
    };

    return (
      <Container className={className}>
        <PageTitleMargined>
          <Trans i18nKey={`${verificationStepTranslationPrefix}.title`} />
        </PageTitleMargined>

        {canVerifyKyc || isKycRejected ? (
          <Content>
            {!!isKycRejected && (
              <KycErrorContainer>
                <KycErrorTitle>
                  <ErrorIcon />
                  <Trans
                    i18nKey={`${verificationStepTranslationPrefix}.verification_statuses.failed.title`}
                  />
                </KycErrorTitle>
                <KycErrorDescription>
                  <Trans
                    i18nKey={`${verificationStepTranslationPrefix}.verification_statuses.failed.description`}
                  />
                </KycErrorDescription>
              </KycErrorContainer>
            )}

            <Formik<KYCProfileFormType>
              innerRef={formikRef}
              onSubmit={(values) => profileData.onSubmit(values)}
              initialValues={profileData.initValues}
              validationSchema={KYCProfileFormValidationSchema}
              validateOnChange={profileData.svoc}
            >
              <KYCProfileForm originTranslationPrefix={translationPrefix} />
            </Formik>

            <CheckboxWrapper>
              <Checkbox
                checked={termAndConditionsCheckboxProps.isChecked}
                onCheck={termAndConditionsCheckboxProps.handleChange}
                onUncheck={termAndConditionsCheckboxProps.handleChange}
              >
                <CheckboxText>
                  <Trans
                    i18nKey={`${verificationStepTranslationPrefix}.terms_and_conditions_checkbox`}
                    components={{
                      1: <CheckboxTextLink href={TERMS_AND_CONDITIONS} target="_blank" />,
                      2: <CheckboxTextEmphasized />,
                      3: <CheckboxTextEmphasizedAlt />,
                    }}
                  />
                </CheckboxText>
              </Checkbox>
            </CheckboxWrapper>
          </Content>
        ) : (
          <Container>
            <Content>
              {!!isKycAccepted && <>{renderAccepted()}</>}
              {!!isKycPending && <>{renderPending()}</>}
            </Content>
          </Container>
        )}
      </Container>
    );
  },
);

KYCVerificationWidget.displayName = "VerificationStep";

export default KYCVerificationWidget;
