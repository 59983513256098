import * as yup from "yup";

import { MAX_INPUT_LENGTH, MAX_INPUT_LENGTH_LONG, userNameRegExp } from "constants/";
import { validatePhoneNumber } from "helpers";

export const KYCProfileFormValidationSchema = yup.object({
  firstName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .test("invalid first name", "validationErrors.common.invalid_symbols", function (value) {
      const val = value?.trim();
      return !val || userNameRegExp.test(value?.trim() || "");
    })
    .required("validationErrors.common.is_required"),
  lastName: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .test("invalid last name", "validationErrors.common.invalid_symbols", function (value) {
      const val = value?.trim();
      return !val || userNameRegExp.test(value?.trim() || "");
    })
    .required("validationErrors.common.is_required"),
  phone: yup
    .string()
    .test(
      "invalid phone",
      "validationErrors.common.wrong_format",
      (value) => !value || validatePhoneNumber(value),
    ),
  socialSecurityNumber: yup
    .string()
    .length(11, "validationErrors.common.wrong_format")
    .required("validationErrors.common.is_required"),
  birthDate: yup
    .date()
    .nullable()
    .typeError("validationErrors.common.is_required")
    .required("validationErrors.common.is_required"),
  address: yup
    .string()
    .max(MAX_INPUT_LENGTH_LONG, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
  suite: yup.string().optional().max(MAX_INPUT_LENGTH, "validationErrors.common.too_long"),
  city: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .required("validationErrors.common.is_required"),
  state: yup
    .object()
    .shape({
      label: yup.string().required("validationErrors.common.is_required"),
      value: yup.string().required("validationErrors.common.is_required"),
    })
    .required("validationErrors.common.is_required"),
  zip: yup
    .string()
    .length(5, "validationErrors.common.wrong_format")
    .required("validationErrors.common.is_required"),
});

export type KYCProfileFormType = yup.InferType<typeof KYCProfileFormValidationSchema>;
