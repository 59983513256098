import { FC } from "react";

import IconProps from "./IconProps";

const RefreshIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="#F96331" />
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#F96331" />
    <g clip-path="url(#clip0_3984_14954)">
      <path
        d="M36 26.9997C35.7554 25.2399 34.9391 23.6094 33.6766 22.3592C32.4142 21.109 30.7758 20.3086 29.0137 20.0813C27.2516 19.8539 25.4636 20.2122 23.9252 21.101C22.3868 21.9897 21.1832 23.3596 20.5 24.9997M20 20.9997V24.9997H24"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 29C20.2446 30.7598 21.0609 32.3903 22.3234 33.6405C23.5858 34.8907 25.2242 35.6911 26.9863 35.9184C28.7484 36.1458 30.5364 35.7875 32.0748 34.8988C33.6132 34.01 34.8168 32.6401 35.5 31M36 35V31H32"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3984_14954">
        <rect width="24" height="24" fill="white" transform="translate(16 16)" />
      </clipPath>
    </defs>
  </svg>
);

export default RefreshIcon;
