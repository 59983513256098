import styled from "styled-components";

import { breakpoint } from "helpers";

import UIModal from "uikit/Modal";

export const Modal = styled(UIModal)`
  overflow: auto;
  outline: none;
  padding: 20px !important;
  width: 614px !important;

  ${breakpoint("xs", "md")`
    padding: 15px!important;
    width: 90%!important;
    max-width: 450px;
  `}

  & > div:nth-child(1) {
    padding: 10px;

    h3 {
      font-size: ${({ theme }) => theme.size.title};
      margin-bottom: 20px;

      ${breakpoint("xs", "md")`
        font-size: ${({ theme }) => theme.size.subtitleLarge};
      `}
    }

    & > p:nth-child(3) {
      color: ${({ theme }) => theme.colors.text};
      margin-bottom: 40px;
      padding: 0 70px;

      ${breakpoint("xs", "md")`
        padding: 0;
      `}
    }
  } ;
`;

export const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  & > div:nth-child(1) {
    margin-bottom: 20px;
  }
`;
