import styled from "styled-components";

import { breakpoint } from "helpers";

import { H3, InputContainer, InputGroupContainerHalved, Section } from "uikit";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSection = styled(Section)`
  width: 100%;
  max-width: 760px;
`;

export const ContainerInner = styled.div`
  width: 100%;
`;

export const ShippingDetailsSection = styled.div``;

export const ShortInputsWrapper = styled(InputGroupContainerHalved)`
  ${breakpoint("xs", "md")`
    flex-direction: column;

    ${InputContainer} {
      width: 100%;
    }
  `}
`;
