import styled from "styled-components";

import { breakpoint } from "helpers";

export const Container = styled.div``;

export const Header = styled.div`
  margin-bottom: 44px;
  max-width: 870px;

  ${breakpoint("xs", "md")`
    margin-bottom: 40px;
  `}
`;
