import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { LinkTextMixin, PrimaryButton } from "uikit";
import UIModal from "uikit/Modal/Modal";

export const StyledUIModal = styled(UIModal)`
  overflow: auto;
  outline: none;
  padding: 19px !important;
  width: 436px !important;

  & > div:nth-child(1) {
    padding: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${breakpoint("xs", "lg")`
      padding-top: 12px;
    `}
  }

  ${breakpoint("xs", "lg")`
      padding: 18px 21px !important;
      width: 340px !important;
  `}
`;

export const Clock = styled.img`
  margin-bottom: 32px;

  ${breakpoint("xs", "lg")`
    margin-bottom: 16px;
  `}
`;

export const Title = styled.p`
  font-size: ${({ theme }) => theme.size.title};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-family: ${({ theme }) => theme.fontFamily.alt};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 32px;

  ${breakpoint("xs", "lg")`
    margin-bottom: 16px;
  `}
`;

export const Message = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  margin-bottom: 24px;
  padding: 0 6px;
`;

export const SubTitle = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 32px;
`;

export const PrimaryBtn = styled(PrimaryButton)`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 180px;
  margin-bottom: 32px;
  margin-top: 8px;
`;

export const FooterMessage = styled.p`
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text};

  a {
    ${LinkTextMixin};
    font-size: ${({ theme }) => theme.size.caption};
  }
`;
