import { FC } from "react";

import IconProps from "./IconProps";

const HelpCenter: FC<IconProps> = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.44372 6.89104C8.32437 6.89104 8.21254 6.93753 8.12936 7.02072C8.04617 7.1039 7.99968 7.21572 7.99968 7.33508V12.8901C7.99968 13.0094 8.04617 13.1212 8.12936 13.2044C8.21255 13.2876 8.32437 13.3341 8.44372 13.3341H11.7775C11.9543 13.3341 12.1238 13.4043 12.2488 13.5293L14.4447 15.7252V14.0007C14.4447 13.6325 14.7431 13.3341 15.1113 13.3341H16.2219C16.3412 13.3341 16.453 13.2876 16.5362 13.2044C16.6194 13.1212 16.6659 13.0094 16.6659 12.8901V7.33508C16.6659 7.21572 16.6194 7.1039 16.5362 7.02072C16.453 6.93753 16.3412 6.89104 16.2219 6.89104H8.44372ZM8.44372 14.6672C7.97233 14.6672 7.52098 14.4813 7.1867 14.1471C6.85243 13.8128 6.66656 13.3614 6.66656 12.8901V7.33508C6.66656 6.86369 6.85243 6.41234 7.1867 6.07806C7.52098 5.74379 7.97233 5.55792 8.44372 5.55792H16.2219C16.6933 5.55792 17.1446 5.74379 17.4789 6.07806C17.8132 6.41234 17.999 6.86369 17.999 7.33508V12.8901C17.999 13.3614 17.8132 13.8128 17.4789 14.1471C17.1446 14.4813 16.6933 14.6672 16.2219 14.6672H15.7778V17.3344C15.7778 17.604 15.6154 17.8471 15.3663 17.9502C15.1173 18.0534 14.8306 17.9964 14.6399 17.8057L11.5014 14.6672H8.44372Z"
      fill="#5F5F5F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.77813 1.3341C1.65878 1.3341 1.54696 1.38059 1.46377 1.46378C1.38058 1.54697 1.33409 1.65879 1.33409 1.77814V7.33508C1.33409 7.45444 1.38058 7.56626 1.46377 7.64945C1.54696 7.73263 1.65878 7.77913 1.77813 7.77913H2.88873C3.25686 7.77913 3.55529 8.07755 3.55529 8.44568V10.1702L4.64058 9.08496C4.90088 8.82465 5.32293 8.82465 5.58323 9.08496C5.84354 9.34526 5.84354 9.7673 5.58323 10.0276L3.36006 12.2508C3.16943 12.4414 2.88273 12.4984 2.63365 12.3953C2.38458 12.2921 2.22218 12.0491 2.22218 11.7795V9.11224H1.77813C1.30674 9.11224 0.85539 8.92637 0.521116 8.5921C0.186843 8.25783 0.000976562 7.80647 0.000976562 7.33508V1.77814C0.000976562 1.30675 0.186843 0.855397 0.521116 0.521124C0.855389 0.186851 1.30674 0.000984192 1.77813 0.000984192H9.55628C10.0277 0.000984192 10.479 0.186851 10.8133 0.521124C11.1476 0.855397 11.3334 1.30675 11.3334 1.77814V4.00131C11.3334 4.36944 11.035 4.66787 10.6669 4.66787C10.2987 4.66787 10.0003 4.36944 10.0003 4.00131V1.77814C10.0003 1.65879 9.95383 1.54696 9.87064 1.46378C9.78745 1.38059 9.67563 1.3341 9.55628 1.3341H1.77813Z"
      fill="#5F5F5F"
    />
  </svg>
);

export default HelpCenter;
