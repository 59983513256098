import { FC } from "react";

import IconProps from "./IconProps";

const AttentionTriangleIcon: FC<IconProps> = ({ className }) => (
  <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_2335_24557)">
      <path d="M26.2341 28H3.76575C2.40316 28 1.1843 27.336 0.505884 26.2181C-0.132292 25.1739 -0.166788 23.9027 0.413894 22.8245L11.6481 1.94649C12.292 0.749088 13.5799 0 14.9999 0C16.42 0 17.7079 0.743413 18.3518 1.94649L29.586 22.8245C30.1667 23.9027 30.1322 25.1682 29.494 26.2181C28.8156 27.336 27.5967 28 26.2341 28ZM14.9999 2.17916C14.3848 2.17916 13.8616 2.47426 13.5971 2.96798L2.36292 23.846C2.06395 24.3964 2.25943 24.8618 2.39741 25.0944C2.67338 25.5484 3.18507 25.8208 3.76575 25.8208H26.2341C26.8148 25.8208 27.3265 25.5484 27.6025 25.0944C27.7405 24.8618 27.9359 24.3964 27.637 23.846L16.4028 2.96798C16.1383 2.47426 15.6151 2.17916 14.9999 2.17916Z" fill="#F29127" />
      <path d="M15 19.5103C14.4596 19.5103 13.9996 19.2947 13.9767 19.0109L13.166 9.38061C13.0855 8.41587 13.9019 7.57031 15 7.57031C16.0982 7.57031 16.9146 8.41587 16.8341 9.38061L16.0234 19.0109C16.0004 19.289 15.5405 19.5103 15 19.5103Z" fill="#F29127" />
      <path d="M15 23.7779C15.8732 23.7779 16.5811 23.0792 16.5811 22.2173C16.5811 21.3554 15.8732 20.6567 15 20.6567C14.1268 20.6567 13.4189 21.3554 13.4189 22.2173C13.4189 23.0792 14.1268 23.7779 15 23.7779Z" fill="#F29127" />
    </g>
    <defs>
      <clipPath id="clip0_2335_24557">
        <rect width="30" height="28" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AttentionTriangleIcon;
