import styled from "styled-components";

import { LinkTextMixin } from "uikit";

export const Container = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.modalContactInfoBg};
`;

export const CompanyName = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  line-height: 1.5;
`;

export const ContactEmail = styled.a`
  ${LinkTextMixin}
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.5;
`;
