import { FC, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CloseIcon, UploadIcon } from "assets/svg";
import CsvTableIcon from "assets/svg/CsvTableIcon.svg";

import { HELP_EMAIL } from "constants/index";

import { NoBorderButton, PrimaryButton, SecondaryButton } from "uikit";

import {
  BtnPairsWrapper,
  Content,
  InputError,
  RemoveFileButton,
  StepSection,
  UploadedFileList,
} from "./styles";

type ArrayWithAtLeastOneString = {
  0: string;
} & Array<string>;

interface IProps {
  onSubmit: (file: File) => void;
  onClose: () => void;
  onDownloadCsvTemplate: () => void;
  files: File[];
  setFiles: (e: Event) => void;
  removeFile: (file: string | number) => void;
  templateWasDownloaded: boolean;
  acceptedFormats?: ArrayWithAtLeastOneString;
  uploadError?: string | null;
}

export const UploadContent: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const [inputError, setInputError] = useState<string | null>(null);
  const translationPrefix = "people_page.index.csv_import_modal.upload";
  const acceptedFormats = props.acceptedFormats || ["csv"];
  const formatsNormalized = `.${acceptedFormats.map((item) => item.toUpperCase()).join(" / .")}`;

  const inputRef = useRef<any>();

  return (
    <>
      <Content>
        <StepSection>
          <img src={CsvTableIcon} />
          <div>
            <p>{t(`${translationPrefix}.step_1.title`)}</p>
            <p>{t(`${translationPrefix}.step_1.message`)}</p>
            <SecondaryButton
              onClick={() => props.onDownloadCsvTemplate()}
              disabled={props.templateWasDownloaded}
            >
              {t(`${translationPrefix}.step_1.btnTitle`)}
            </SecondaryButton>
          </div>
        </StepSection>
        <div>
          <StepSection>
            <UploadIcon />
            <div>
              <p>{t(`${translationPrefix}.step_2.title`)}</p>
              <p>{t(`${translationPrefix}.step_2.message`)}</p>
              {props.files.length === 0 && (
                <>
                  <SecondaryButton onClick={() => inputRef.current.click()}>
                    {t(`${translationPrefix}.step_2.btnTitle`)}
                  </SecondaryButton>

                  <InputError>{inputError || ""}</InputError>
                </>
              )}
            </div>
          </StepSection>
          <UploadedFileList>
            <input
              ref={inputRef}
              type="file"
              multiple={false}
              accept={`.${acceptedFormats.join(", .")}`}
              style={{ display: "none" }}
              onChange={(e: any) => {
                const chunks = (e.currentTarget.value || "").split(".");
                const extension = chunks.pop();
                if (acceptedFormats.includes(extension)) {
                  props.setFiles(e);
                  inputRef.current.value = null;
                  setInputError(null);
                } else {
                  setInputError(
                    t(`${translationPrefix}.step_2.errors.wrong_file_format`, {
                      formatList: formatsNormalized,
                    }),
                  );
                }
              }}
            />
            {props.files.map((it) => (
              <div key={it.name}>
                <img src={CsvTableIcon} />
                <p>{it.name}</p>
                <RemoveFileButton onClick={() => props.removeFile(it.name)}>
                  <CloseIcon />
                </RemoveFileButton>
              </div>
            ))}
          </UploadedFileList>
          {props.uploadError && (
            <InputError>
              <Trans
                i18nKey={`errors.${props.uploadError}`}
                defaults={t("errors.defaultMessage")}
                values={{
                  helpEmail: HELP_EMAIL,
                }}
              />
            </InputError>
          )}
        </div>
      </Content>

      <BtnPairsWrapper>
        <NoBorderButton onClick={props.onClose}>
          {t(`${translationPrefix}.cancelBtn`)}
        </NoBorderButton>
        <PrimaryButton
          disabled={props.files.length === 0}
          onClick={() => props.onSubmit(props.files[0])}
        >
          {t(`${translationPrefix}.importBtn`)}
        </PrimaryButton>
      </BtnPairsWrapper>
    </>
  );
};
