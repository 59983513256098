import Countdown, { CountdownRenderProps } from "react-countdown";
import { Trans } from "react-i18next";
import moment from "moment";

import { UserRole, UserStatus } from "types/BETypes";
import { defaultDateFormat } from "constants/";

import { ContextMenu, Emphasized, IContextMenuOption, TooltipMessage } from "uikit";
import { EColumnType } from "uikit/Table/types";

import { AdminResponseDto } from "utils/swagger_react_query";

import { IColumnExtended, IUseColumns, TeamMembersTableContextMenuAction } from "./types";
import {
  CompanyRoleColumn,
  CreatedAtColumn,
  LastSignedInRow,
  OwnUserLabel,
  RAIEmail,
  RAIRole,
  RAIStartDate,
  ResponsiveAdminInfoWrapper,
  ResponsiveOwnLabel,
  StatusColumn,
  StyledListAdminInfo,
  StyledTooltip,
} from "./styles";

export const useColumns = ({
  translationPrefix,
  currentUser,
  actions,
  resendInvitationPermission,
}: IUseColumns) => {
  const tableTranslationPrefix = `${translationPrefix}.table`;
  const allowedRolesToDisplay = [UserRole.ADMIN, UserRole.OWNER];

  const getUserRoleText = (roles: string[]) => {
    const role = roles.find((it) => allowedRolesToDisplay.includes(it as UserRole));
    return <Trans i18nKey={`common.roles.${role}`} />;
  };

  const getContextMenuOptions = (row: AdminResponseDto): IContextMenuOption[] => {
    const timeout = resendInvitationPermission.find((it) => it[row.adminId])?.[row.adminId];
    const isResendPermitted = !timeout;

    const renderResendInvitationTimeout = () => {
      const renderer = ({ seconds }: CountdownRenderProps) => (
        <TooltipMessage>
          <Trans
            values={{ time: seconds }}
            components={{ 1: <Emphasized /> }}
            i18nKey={`${tableTranslationPrefix}.remaining_time_to_resend_email`}
          />
        </TooltipMessage>
      );

      if (isResendPermitted) return <></>;
      return (
        <Countdown
          date={timeout || Date.now()}
          renderer={renderer}
          autoStart={true}
          onTick={({ seconds }) =>
            actions.handleUpdateResendInvitationTimeout(row.adminId, seconds)
          }
          onComplete={() => actions.handleUpdateResendInvitationTimeout(row.adminId, 0)}
        />
      );
    };

    const config: IContextMenuOption[] = [
      {
        label: (
          <StyledTooltip
            id="timeout"
            tooltipHTML={!isResendPermitted && renderResendInvitationTimeout()}
          >
            <Trans i18nKey={`${tableTranslationPrefix}.context_menu.resend_invitation_btn`} />
          </StyledTooltip>
        ),
        value: TeamMembersTableContextMenuAction.RESEND_INVITATION,
        onClick: () => actions.onResendInvitation(row),
        hide: row.status !== UserStatus.INVITED,
        disabled: !isResendPermitted,
      },
      {
        label: <Trans i18nKey={`${tableTranslationPrefix}.context_menu.disable_account_btn`} />,
        value: TeamMembersTableContextMenuAction.DISABLE_ACCOUNT,
        onClick: () => actions.onDisabledAccount(row),
        hide:
          row.status === UserStatus.INVITED ||
          [UserRole.ADMIN, UserRole.SUPERADMIN].includes(currentUser?.lastActiveRole as UserRole),
      },
    ];

    const filteredConfig = config.filter((it) => !it.hide);
    return filteredConfig;
  };

  const renderContentAfterName = (row: AdminResponseDto) => (
    <ResponsiveAdminInfoWrapper>
      <RAIRole>{getUserRoleText(row.companyRoles as unknown as UserRole[])}</RAIRole>
      <RAIEmail>{row?.email}</RAIEmail>
      <RAIStartDate>
        <Trans
          i18nKey={`components.employee_info.mobile_start_date`}
          values={{ date: moment(row?.createdAt).format(defaultDateFormat) }}
        />
      </RAIStartDate>
    </ResponsiveAdminInfoWrapper>
  );

  const renderContentAfterAvatar = () => (
    <ResponsiveOwnLabel>
      <Trans i18nKey={`${tableTranslationPrefix}.you_label`} />
    </ResponsiveOwnLabel>
  );

  const columnsConfig: IColumnExtended[] = [
    {
      key: "userInfo",
      title: <Trans i18nKey={`${tableTranslationPrefix}.columns.name`} />,
      className: "userInfo",
      type: EColumnType.Component,
      component: (row) => {
        return (
          <StyledListAdminInfo
            id={row?.adminId}
            firstName={row?.firstName}
            lastName={row?.lastName}
            avatar={row?.avatarUrl}
            contentAfterName={renderContentAfterName(row)}
            contentAfterAvatar={row?.ownUser && renderContentAfterAvatar()}
          />
        );
      },
    },
    {
      key: "ownUser",
      className: "ownUser",
      type: EColumnType.Component,
      component: (row) => {
        if (row.ownUser)
          return (
            <OwnUserLabel>
              <Trans i18nKey={`${tableTranslationPrefix}.you_label`} />
            </OwnUserLabel>
          );
        return <></>;
      },
    },
    {
      key: "email",
      title: <Trans i18nKey={`${tableTranslationPrefix}.columns.email`} />,
      type: EColumnType.Text,
      className: "email",
    },
    {
      key: "createdAt",
      title: <Trans i18nKey={`${tableTranslationPrefix}.columns.created`} />,
      type: EColumnType.Component,
      className: "createdAt",
      component: (row) => {
        const createdAt = row.createdAt ? moment(row?.createdAt).format(defaultDateFormat) : "-";
        return <CreatedAtColumn>{createdAt}</CreatedAtColumn>;
      },
    },
    {
      key: "lastSignedIn",
      title: <Trans i18nKey={`${tableTranslationPrefix}.columns.last_sign_in`} />,
      type: EColumnType.Component,
      className: "lastSignedIn",
      component: (row) => {
        const lastSignedIn = row.lastSignedIn
          ? moment(row?.lastSignedIn).format(defaultDateFormat)
          : "-";
        return <LastSignedInRow>{lastSignedIn}</LastSignedInRow>;
      },
    },
    {
      key: "companyRole",
      title: <Trans i18nKey={`${tableTranslationPrefix}.columns.role`} />,
      type: EColumnType.Component,
      className: "companyRole",
      component: (row) => {
        return (
          <CompanyRoleColumn>
            {getUserRoleText(row.companyRoles as unknown as UserRole[])}
          </CompanyRoleColumn>
        );
      },
    },
    {
      key: "status",
      title: <Trans i18nKey={`${tableTranslationPrefix}.columns.status`} />,
      type: EColumnType.Component,
      className: "status",
      component: (row) => {
        const className = row.status === UserStatus.PENDING_EXIT ? UserStatus.ACTIVE : row.status;
        return (
          <StatusColumn className={className}>
            <Trans i18nKey={`${tableTranslationPrefix}.status.${row.status}`} />
          </StatusColumn>
        );
      },
    },
    {
      key: "contextMenu",
      className: "contextMenu",
      type: EColumnType.Component,
      component: (row) => {
        const contextMenuOptions = getContextMenuOptions(row);
        const shouldShowContextMenu =
          !row.ownUser && row.lastActiveRole !== UserRole.OWNER && contextMenuOptions.length > 0;

        if (shouldShowContextMenu) return <ContextMenu options={contextMenuOptions} />;
        return <></>;
      },
    },
  ];

  return { columns: columnsConfig };
};
