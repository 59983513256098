import { FC } from "react";

import IconProps from "./IconProps";

const HamburgerMenuIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 18H21" stroke="#007D7B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 12H21" stroke="#007D7B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M3 6H21" stroke="#007D7B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default HamburgerMenuIcon;
