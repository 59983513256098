import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import NumberFormat, { NumberFormatProps } from "react-number-format";

import { renderLabel } from "./helpers";
import { BaseInputProps } from "./types";
import {
  Container,
  ErrorContainer,
  InputBase,
  InputDescription,
  InputWrapper,
  NumberArrowDown,
  NumberArrows,
  NumberArrowUp,
  Units,
} from "./styles";

export type Props = {
  className?: string;
  name?: string;
  onChange?: (value: string) => void;
  onIncrement?: () => void;
  onDecrement?: () => void;
  value?: string;
  label?: ReactNode | string;
  readOnly?: boolean;
  alwaysShowErrorBlock?: boolean;
  optional?: boolean;
  units?: ReactNode | string;
} & NumberFormatProps &
  BaseInputProps;

const NumberInput: FC<Props> = (props) => {
  const {
    className,
    label,
    error,
    onFocus,
    onBlur,
    readOnly,
    value,
    onChange,
    onIncrement,
    onDecrement,
    alwaysShowErrorBlock = true,
    optional = false,
    units,
    description,
    descriptionPosition,
    ...rest
  } = props;
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    onFocus?.(e);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(e);
  };

  const isFloatingLabel = !props.readOnly && label && (isFocused || value);

  return (
    <Container
      className={`number-input ${className} ${isFloatingLabel ? "with-floating-label" : ""} ${
        optional ? "optional" : ""
      }`}
    >
      <InputWrapper>
        {!!units && <Units>{units}</Units>}
        <NumberFormat
          thousandSeparator={false}
          allowLeadingZeros={false}
          allowNegative={false}
          allowEmptyFormatting={true}
          customInput={InputBase}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onValueChange={({ value }) => onChange?.(value)}
          value={value}
          {...rest}
        />
        {(!!onIncrement || !!onDecrement) && (
          <NumberArrows>
            <NumberArrowUp onClick={onIncrement}></NumberArrowUp>
            <NumberArrowDown onClick={onDecrement}></NumberArrowDown>
          </NumberArrows>
        )}
        {renderLabel(label, optional)}
      </InputWrapper>
      {!!description && (
        <InputDescription className={`${descriptionPosition}`}>{description}</InputDescription>
      )}
      {(alwaysShowErrorBlock || error) && (
        <ErrorContainer data-testid={props.errorDataTestId}>{error} </ErrorContainer>
      )}
    </Container>
  );
};

export default NumberInput;
