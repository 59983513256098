import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import { defaultLocale } from "../constants/index";
import translations from "./translationNext";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: translations,
    //lng: "nl",
    lng: defaultLocale,
    //fallbackLng: "nl",
    fallbackLng: defaultLocale,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
