import { DefaultTheme } from "styled-components";

import { Breakpoints } from "../types";
import colors from "./colors";

declare module "styled-components" {
  type Theme = typeof theme;
  export interface DefaultTheme {
    defaultRadius: string;

    colors: typeof colors;

    fontFamily: {
      default: string;
      alt: string;
      dmsans: string;
      lora: string;
      plusJakartaSans: string;
    };

    fontWeight: {
      /** 800*/
      extraBold: number;
      /** 700*/
      bold: number;
      /** 600*/
      semiBold: number;
      /** 500*/
      medium: number;
      /** 400*/
      regular: number;
    };

    fontStyle: {
      normal: string;
      italic: string;
      oblique: string;
    };

    size: {
      /** "24px" */
      title: string;

      /** "26px" */
      titleMedium: string;

      /** "28px" */
      titleSemiLarge: string;

      /** "32px" */
      titleSemiLargeAlt: string;

      /** "36px" */
      titleLarge: string;

      /** "40px" */
      titleLarger: string;

      /** "18px" */
      subtitle: string;

      /** "20px" */
      subtitleLarge: string;

      /** "22px" */
      subtitleLarger: string;

      /** "16px" */
      body1: string;

      /** "14px" */
      body2: string;

      /** "12px" */
      caption: string;

      /** "10px" */
      small: string;
    };

    zIndex: {
      modal: number;
      slidePane: number;
      loader: number;
    };

    breakpoints: Breakpoints;
    contentPadding: Breakpoints;
  }
}

const theme: DefaultTheme = {
  defaultRadius: "24px",
  colors,

  fontFamily: {
    default: "Plus Jakarta Sans",
    alt: "Plus Jakarta Sans",
    lora: "Lora",
    dmsans: "DM Sans",
    plusJakartaSans: "Plus Jakarta Sans",
  },

  fontWeight: {
    extraBold: 800,
    bold: 700,
    semiBold: 600,
    medium: 500,
    regular: 400,
  },

  fontStyle: {
    normal: "normal",
    italic: "italic",
    oblique: "oblique",
  },

  size: {
    title: "24px",
    titleMedium: "26px",
    titleSemiLarge: "28px",
    titleSemiLargeAlt: "32px",
    titleLarge: "36px",
    titleLarger: "40px",
    subtitle: "18px",
    subtitleLarge: "20px",
    subtitleLarger: "22px",
    body1: "16px",
    body2: "14px",
    caption: "12px",
    small: "10px",
  },

  zIndex: {
    loader: 100000,
    modal: 10000,
    slidePane: 100,
  },

  breakpoints: {
    xs: 0,
    sm: 360,
    md: 768,
    lg: 1024,
    xlm: 1280,
    xl: 1440,
    xxl: 1600,
  },
  contentPadding: {
    xs: 12,
    sm: 16,
    md: 20,
    lg: 40,
    xlm: 20,
    xl: 80,
    xxl: 80,
  },
};

export { theme };
