import styled from "styled-components";

import { breakpoint } from "helpers";

export const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const OptionsSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
  margin-bottom: 20px;
`;

export const CountText = styled.p`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.textAlt};

  ${breakpoint("xs", "lg")`
    width: 100%;
  `}
`;
