import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";
import Copyright from "components/Copyright";
import { CopyrightText, Link, LinksContainer } from "components/Copyright/styles";

export const Container = styled.footer``;

export const StyledCopyright = styled(Copyright)`
  margin-top: 20px;
  margin-bottom: 0;
  flex-direction: column-reverse;

  ${LinksContainer} {
    margin-bottom: 20px;
  }

  ${CopyrightText} {
    font-size: ${({ theme }) => theme.size.small};
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    color: ${({ theme }) => theme.colors.mainMenuFooterText};
    margin-bottom: 0;
    line-height: 1.3;
  }

  ${Link} {
    font-size: ${({ theme }) => theme.size.small};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    line-height: 2.2;

    &,
    &.active,
    &.hover,
    &.visited {
      color: ${({ theme }) => theme.colors.mainMenuFooterLink};
      text-decoration: underline;
    }
  }
`;
