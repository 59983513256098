import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 560px;
  text-align: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
