import { FC } from "react";
import { CheckmarkWhite } from "assets/svg";

import { getHiddenNumber } from "helpers";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import { Container, IconContainer, Text } from "./styles";

interface Props {
  className?: string;
  bank?: Partial<GetPlaidBankAccountsResponseDto>;
}

const SelectedBank: FC<Props> = ({ className, bank }) => {
  const { accountName, last4Digits } = bank || {};
  const getCardNumber = () => {
    const hiddenNumberMask = getHiddenNumber("*".repeat(12), 4);
    return `${hiddenNumberMask} ${last4Digits}`;
  };
  return (
    <Container className={className}>
      <IconContainer>
        <CheckmarkWhite />
      </IconContainer>
      <Text>{accountName || getCardNumber()}</Text>
    </Container>
  );
};

export default SelectedBank;
