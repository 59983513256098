import { FC } from "react";
import { Trans } from "react-i18next";
import { PlusThin } from "assets/svg";

import { BankingDisclosureContainer } from "pages/Banking/shared/styles";
import { BankAccountSection } from "components/BankAccountSection";
import BankingDisclosureComponent from "components/BankingDisclosureComponent";
import PaidBankAccountCreatingWidget from "components/PaidBankAccountCreatingWidget";

import { FullScreenLoader, PageTitleMargined } from "uikit";

import {
  BackButton,
  ButtonsContainer,
  StyledDescription,
  StyledTitle,
  TitleInner,
} from "../../styles";
import { EOptionIds, EWidgetType, ICompanyBankAccountProps } from "../../types";
import useCompanyBankAccount from "./useCompanyBankAccount";
import { Container, WidgetContainer } from "./styles";

const CompanyBankAccount: FC<ICompanyBankAccountProps> = (props) => {
  const { className, onBack, onKycVerificationSuccess, onShippingDetailsSuccess, widgetType } =
    props;
  const translationPrefix = `components.payment_methods.options.${[
    EOptionIds.COMPANY_BANK_ACCOUNT,
  ]}`;

  const { isLoading } = useCompanyBankAccount();

  return (
    <>
      {isLoading && <FullScreenLoader />}
      <Container className={className}>
        <StyledTitle>
          <TitleInner>
            <Trans i18nKey={`${translationPrefix}.title`} />
          </TitleInner>
        </StyledTitle>
        <StyledDescription>
          <Trans i18nKey={`${translationPrefix}.description`} />
        </StyledDescription>
      </Container>

      <WidgetContainer>
        <PaidBankAccountCreatingWidget
          onBackBtnClickOnKycStep={onBack}
          onShippingDetailsSuccess={onShippingDetailsSuccess}
          onKycVerificationSuccess={onKycVerificationSuccess}
          useOnboardingLogic={widgetType === EWidgetType.ONBOARDING}
        />
        {/* <BankingDisclosureContainer>
          <BankingDisclosureComponent />
        </BankingDisclosureContainer> */}
      </WidgetContainer>

      {/* {!!onBack && (
        <ButtonsContainer>
          <BackButton onClick={onBack}>
            <Trans i18nKey={`${translationPrefix}.back_button`} />
          </BackButton>
        </ButtonsContainer>
      )} */}
    </>
  );
};

export default CompanyBankAccount;
