import React, { FC } from "react";

import IconProps from "./IconProps";

const MenuCornerIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.861328 13.237V13.2067C7.76256 12.7419 13.2206 7.01175 13.2517 0V13.237H0.861328Z" fill="white" />
  </svg>
);

export default MenuCornerIcon;