import styled from "styled-components";

import { Content } from "components/KYCVerificationWidget/styles";

export const Container = styled.div``;

export const WidgetContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
