import { ReactNode } from "react";
import { Trans } from "react-i18next";

import { Label, OptionalText } from "./styles";

export const renderLabel = (label?: ReactNode | string, optional?: boolean) => {
  return (
    <>
      {label && (
        <Label>
          {label}
          {!!optional && (
            <OptionalText>
              {" "}
              {"("}
              <Trans i18nKey={"common.input.optional"} />
              {")"}
            </OptionalText>
          )}
        </Label>
      )}
    </>
  );
};
