import { NavLink } from "react-router-dom";
import { ChevronRight, Logo } from "assets/svg";
import MenuCornerIcon from "assets/svg/MenuCornerIcon";
import styled, { css, ExecutionContext } from "styled-components";

import { UserRole } from "types/BETypes";
import { breakpoint } from "helpers/shared/breakpoint";

interface IMenuBlockProps extends ExecutionContext {
  needRenderMenuForResponsive?: boolean;
}

interface IMenu extends ExecutionContext {
  isOpen?: boolean;
}

const menuWidth = 250;
const menuWidthXLM = 220;

const menuBlockCollapsedStyles = css`
  width: 80px;
  min-width: 80px;
  padding: 42px 0 0 0;
`;

const MenuLinkMixin = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 27px;
  height: 42px;
  box-sizing: border-box;
  border-radius: 21px 0 0 21px;

  font-family: ${({ theme }) => theme.fontFamily.default};
  font-size: ${({ theme }) => theme.size.caption};
  font-weight: ${({ theme }) => theme.fontWeight.bold};

  &:active,
  &:focus {
    outline: 0;
    border: none;
    -moz-outline-style: none;
  }

  &,
  &:visited {
    color: ${({ theme }) => theme.colors.mainMenuText};
    background-color: ${({ theme }) => theme.colors.mainMenuButtonBg};

    svg {
      path {
        fill: ${({ theme }) => theme.colors.mainMenuText};
      }
    }
  }

  &:hover,
  &:active,
  &.active {
    color: ${({ theme }) => theme.colors.mainMenuTextActive};
    background-color: ${({ theme }) => theme.colors.mainMenuButtonBgActive};
    position: relative;

    svg {
      path {
        fill: ${({ theme }) => theme.colors.mainMenuTextActive};
      }
    }

    &.role-${UserRole.EMPLOYEE} {
      color: ${({ theme }) => theme.colors.mainMenuTextActiveAlt};
      background-color: ${({ theme }) => theme.colors.mainMenuButtonBgActiveAlt};

      svg {
        path {
          fill: ${({ theme }) => theme.colors.mainMenuTextActiveAlt};
        }
      }
    }
  }

  .tooltip {
    display: none;
    ${breakpoint("md", "xl")`
      &.collapsed, & {
        display: block;
      }
    `}

    &.collapsed {
      display: block;
    }
  }

  &.active {
    .corner {
      display: block;
    }
  }

  ${breakpoint("xs", "lg")`
    max-width: 224px;
    height: 42px
  `}

  ${breakpoint("lg", "xlm")`
    border-radius: 0;
  `}

  &.collapsed {
    border-radius: 0;
  }
`;

export const CollapseBtn = styled.div`
  display: flex;
  display: none; //TODO: Remove later, right now we don't have designs for expanded content
  top: 0;
  right: 0;
  position: absolute;
  padding: 7px 5px;

  border-radius: 0px 0px 0px 2px;
  background-color: ${({ theme }) => theme.colors.mainMenuCollapseBtnBg};
  cursor: pointer;
  box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.colors.text}40;

  &:hover {
    opacity: 0.7;
  }

  &.role-${UserRole.EMPLOYEE} {
    background-color: ${({ theme }) => theme.colors.mainMenuEmployeeCollapseBtnBg};
  }

  ${breakpoint("lg", "xl")`
    display: none;
  `}
`;

export const CollapseBtnIcon = styled(ChevronRight)`
  transform: rotate(180deg);
  height: 9px;
  width: 5px;
  -webkit-transition: all 350ms linear;
  transition: all 350ms linear;

  ${breakpoint("lg")`
    &.collapsed, & {
      transform: rotate(360deg);
      transition-delay: 50ms, 0.3s;
    }
  `}
`;

export const StyledLogo = styled(Logo)`
  width: 65px;
  height: 65px;
  margin-bottom: 40px;
  -webkit-transition: all 350ms linear;
  transition: all 350ms linear;

  &.collapsed {
    margin-bottom: 20px;
    margin-left: 8px;
  }

  ${breakpoint("lg", "xlm")`
      margin-bottom: 20px;
      margin-left: 8px;
  `}
`;

export const MenuBlock = styled.div<IMenuBlockProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${menuWidth}px;
  min-width: ${menuWidth}px;
  max-height: 100vh;
  //min-height: 927px;
  box-sizing: border-box;
  padding: 42px 0 24px 0;
  background: ${({ theme }) => theme.colors.mainMenuBg};
  // -webkit-transition: all 300ms linear;
  // transition: all 300ms linear;

  transition-property: width, max-width, flex, display;
  transition-duration: 0.3s;

  &.role-${UserRole.EMPLOYEE} {
    background: ${({ theme }) => theme.colors.mainMenuEmployeeBg};
  }

  ${breakpoint("xlm")`
    width: ${menuWidthXLM}px;
    min-width: ${menuWidthXLM}px;
  `}

  ${breakpoint("xs", "lg")`
    display: ${({ needRenderMenuForResponsive }: IMenuBlockProps) =>
      needRenderMenuForResponsive ? "flex" : "none"};
    padding: 0;
    min-width: 100%;
    width: 100%;
    min-height: auto;
    background: transparent !important;
  `}

  &.collapsed {
    ${menuBlockCollapsedStyles}
  }

  ${breakpoint("lg", "xlm")`
    ${menuBlockCollapsedStyles}
  `}
`;

export const MBTop = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-transition: padding-left 400ms linear;
  transition: padding-left 400ms linear;

  &.collapsed {
    padding: 0;
  }
  ${breakpoint("lg", "xl")`
      padding: 0;
  `}
`;

export const List = styled.p`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${breakpoint("xs", "xlm")`
    max-width: 224px;
    padding-left: 0px;
  `}
`;

export const MenuLink = styled(NavLink)`
  ${MenuLinkMixin}
`;

export const MenuBaseLink = styled.a`
  ${MenuLinkMixin}
`;

export const IconContainer = styled.div`
  width: 18px;
  margin-right: 29px;

  ${breakpoint("xs", "xlm")`
    width: 16px;
  `}
`;

export const LabelContainer = styled.span`
  display: block;

  &.collapsed {
    display: none;
  }

  ${breakpoint("lg", "xlm")`
      display: none;
  `}
`;

export const MiscContainer = styled.div`
  display: block;
`;

export const Corner = styled(MenuCornerIcon)`
  right: -1px;
  z-index: 0;
  position: absolute;
  display: none;
  & > path {
    fill: white !important;
  }
`;

export const TopCorner = styled(Corner)`
  top: -13px;
`;

export const BottomCorner = styled(Corner)`
  bottom: -13px;
  transform: rotate(270deg);
`;

//RESPONSIVE MENU

export const ResponsiveMenuWrapper = styled.div`
  display: none;

  ${breakpoint("xs", "lg")`
    display: flex;
    align-items: center;
 `}
`;

export const PageName = styled.p`
  color: ${({ theme }) => theme.colors.titleMobile};
  font-weight: ${({ theme }) => theme.fontWeight.medium};

  ${breakpoint("md", "lg")`
      font-size: ${({ theme }) => theme.size.subtitle};
   `}

  ${breakpoint("xs", "md")`
      font-size: ${({ theme }) => theme.size.body2};
   `}
`;

export const ShowBtn = styled.span`
  margin-right: 20px;
  cursor: pointer;

  svg {
    path {
      stroke: ${({ theme }) => theme.colors.mainMenuText};
    }
  }

  ${breakpoint("md", "lg")`
      height: 32px;
      svg {
         width: 32px;
         height: 32px;
      }
      `}

  ${breakpoint("xs", "md")`
      height: 24px;
      svg {
         width: 24px;
         height: 24px;
      }
   `}
`;

export const MenuBackground = styled.div<IMenu>`
  display: none;

  ${breakpoint("xs", "lg")`
		display: block;
		overflow: hidden;
		position: fixed;
		z-index: 1000;
		cursor: pointer;
		-webkit-transition: all 350ms ease-in-out;
		transition: all 350ms ease-in-out;

		${(props: IMenu) =>
      props.isOpen &&
      `
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			transition-delay: 50ms, 0.3s;
			background: rgba(0, 0, 0, 0.5);
		`}
  `}
`;

export const SideMenuWrapper = styled.div<IMenu>`
  display: none;

  ${breakpoint("xs", "lg")`
		position: absolute;
		left: -100%;
		top: 0;
		width: 240px;
		overflow-y: scroll;
		overflow-x: hidden;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 40px 0px 24px 0px;
		z-index: 1100;
		background:  ${({ theme }) => theme.colors.mainMenuBg};
		border-radius: 0px 16px 0px 0px;
		box-sizing: border-box;
		-webkit-transition: all 400ms ease-in-out;
		transition: all 400ms ease-in-out; 

		&.role-${UserRole.EMPLOYEE}{
			background:  ${({ theme }) => theme.colors.mainMenuEmployeeBg};
		}

		${(props: IMenu) =>
      props.isOpen &&
      `
				left: 0;
				transition-delay: 50ms, 0.3s;
		`}

		/* width */
		::-webkit-scrollbar {
				width: 0;
		}
	`}
`;

export const SideMenuHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 28px 0;
`;

export const CloseBtn = styled.span`
  position: absolute;
  top: 14px;
  right: 14px;
  cursor: pointer;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.mainMenuText};
    }
  }
`;

export const SideMenuListWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  right: -8px;
  width: 224px;
  flex: 1 0 auto;
`;

export const SideMenuFooter = styled.div`
  padding: 0 56px 0 40px;
  flex: 0 0 auto;
`;

export const FooterCopyright = styled.p`
  color: ${({ theme }) => theme.colors.titleText};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.size.small};
  margin-bottom: 24px;
`;

export const FooterLink = styled.a`
  display: block;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.mainMenuTextActive};
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 13px;
`;

export const MBMainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 20px;
`;

export const MenuFooterContainer = styled.div`
  padding-right: 20px;
`;
