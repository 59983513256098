import { CardForm } from "types/CardShipping";
import { IMarqetaBootstrapConfig } from "types/Marqeta";
import { EComprehensibleCardStatus } from "helpers/assets/bankCards";

export enum CARD_TABS {
  PHYSICAL = 0,
  VIRTUAL = 1,
}

export enum PinModalType {
  SET_PIN = "SET_PIN",
  RESET_PIN = "RESET_PIN",
}

export enum ReissueCardModalStep {
  REASON = "REASON",
  SHIPPING_DETAILS = "SHIPPING_DETAILS",
}

export interface IGetMarqetaConfigParams {
  clientAccessToken: string;
  onSuccess?: () => void;
  onFailure?: (error: any) => void;
  cardId: string;
}

export enum MARQETA_ELEMENT_IDS {
  CARD_PAN = "mq-card-pan",
  CARD_EXP = "mq-card-exp",
  CARD_CVV = "mq-card-cvv",
}

export const getMarqetaConfig = ({
  clientAccessToken,
  onSuccess,
  onFailure,
  cardId,
}: IGetMarqetaConfigParams): IMarqetaBootstrapConfig => {
  const config: IMarqetaBootstrapConfig = {
    clientAccessToken: (clientAccessToken as string) || "",
    component: {
      showPan: {
        cardPan: {
          domId: `${MARQETA_ELEMENT_IDS.CARD_PAN}_${cardId}`,
          format: true,
          styles: {
            span: {
              color: "#5A5A5A",
              background: "transparent",
              "font-size": "14px",
              "font-family": "Arial",
              "letter-spacing": "0px",
              "font-weight": "normal",
            },
            "span:hover": {
              color: "#5A5A5A",
              background: "transparent",
              "font-size": "14px",
              "font-family": "Arial",
              "letter-spacing": "0px",
              "font-weight": "normal",
            },
          },
        },
        cardExp: {
          domId: `${MARQETA_ELEMENT_IDS.CARD_EXP}_${cardId}`,
          format: true,
          styles: {
            span: {
              color: "#5A5A5A",
              background: "transparent",
              "font-size": "14px",
              "font-family": "Arial",
              "letter-spacing": "0px",
              "font-weight": "normal",
            },
            "span:hover": {
              color: "#5A5A5A",
              background: "transparent",
              "font-size": "14px",
              "font-family": "Arial",
              "letter-spacing": "0px",
              "font-weight": "normal",
            },
          },
        },
        cardCvv: {
          domId: `${MARQETA_ELEMENT_IDS.CARD_CVV}_${cardId}`,
          styles: {
            span: {
              color: "#5A5A5A",
              background: "transparent",
              "font-size": "14px",
              "font-family": "Arial",
            },
            "span:hover": {
              color: "#5A5A5A",
              background: "#transparent",
              "font-size": "14px",
              "font-family": "Arial",
            },
          },
        },
      },
    },
    callbackEvents: {
      onSuccess,
      onFailure,
    },
  };
  return config;
};

export enum CardControl {
  REISSUE = "REISSUE",
  ACTIVATE = "ACTIVATE",
  SET_PIN = "SET_PIN",
  RESET_PIN = "RESET_PIN",
  CANCEL = "CANCEL",
  REVEAL_DETAILS = "REVEAL_DETAILS",
}

export const getCardControlsByTypeAndComprehensibleCardStatus = (
  type: CardForm,
  status: EComprehensibleCardStatus,
  actions: Partial<{
    onCancel: () => void;
    onReissue: () => void;
    onActivate: () => void;
    onSetPin: () => void;
    onResetPin: () => void;
    onRevealDetails: () => void;
  }>,
) => {
  const actionsConfig = {
    [CardControl.REISSUE]: actions?.onReissue,
    [CardControl.ACTIVATE]: actions?.onActivate,
    [CardControl.SET_PIN]: actions?.onSetPin,
    [CardControl.RESET_PIN]: actions?.onResetPin,
    [CardControl.CANCEL]: actions?.onCancel,
    [CardControl.REVEAL_DETAILS]: actions?.onRevealDetails,
  };

  const emptyActionsConfig = {
    [CardControl.REISSUE]: undefined,
    [CardControl.ACTIVATE]: undefined,
    [CardControl.SET_PIN]: undefined,
    [CardControl.RESET_PIN]: undefined,
    [CardControl.CANCEL]: undefined,
    [CardControl.REVEAL_DETAILS]: undefined,
  };

  type IBaseRules = Record<EComprehensibleCardStatus, typeof actionsConfig>;

  const baseRules: IBaseRules = {
    [EComprehensibleCardStatus.ACTIVE]: {
      ...emptyActionsConfig,
      [CardControl.RESET_PIN]: actionsConfig.RESET_PIN,
      [CardControl.REVEAL_DETAILS]: actionsConfig.REVEAL_DETAILS,
      [CardControl.CANCEL]: actionsConfig.CANCEL,
      [CardControl.REISSUE]: actionsConfig.REISSUE,
    },
    [EComprehensibleCardStatus.REISSUED]: {
      ...emptyActionsConfig,
      [CardControl.REVEAL_DETAILS]: actionsConfig.REVEAL_DETAILS,
    },
    [EComprehensibleCardStatus.CANCELED]: {
      ...emptyActionsConfig,
      [CardControl.REVEAL_DETAILS]: actionsConfig.REVEAL_DETAILS,
    },
    [EComprehensibleCardStatus.ACTIVE_WITHOUT_PIN]: {
      ...emptyActionsConfig,
      [CardControl.SET_PIN]: actionsConfig.SET_PIN,
      [CardControl.REVEAL_DETAILS]: actionsConfig.REVEAL_DETAILS,
      [CardControl.CANCEL]: actionsConfig.CANCEL,
      [CardControl.REISSUE]: actionsConfig.REISSUE,
    },
    [EComprehensibleCardStatus.EXPIRED]: {
      ...emptyActionsConfig,
      [CardControl.REVEAL_DETAILS]: actionsConfig.REVEAL_DETAILS,
    },

    [EComprehensibleCardStatus.EMPTY]: emptyActionsConfig,
    [EComprehensibleCardStatus.INACTIVE]: emptyActionsConfig,
  };

  const virtualCardControls = {
    ...baseRules,
  };

  const physicalCardControls = {
    ...baseRules,
    [EComprehensibleCardStatus.INACTIVE]: {
      ...emptyActionsConfig,
      [CardControl.ACTIVATE]: actionsConfig.ACTIVATE,
    },
  };

  const controls =
    type === CardForm.PHYSICAL ? physicalCardControls[status] : virtualCardControls[status];

  return controls;
};
