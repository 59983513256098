import { FC } from "react";

import IconProps from "./IconProps";

const AttentionCircleSmallAltIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM7.2 11.2C7.2 11.6418 7.55817 12 8 12C8.44183 12 8.8 11.6418 8.8 11.2V8C8.8 7.55817 8.44183 7.2 8 7.2C7.55817 7.2 7.2 7.55817 7.2 8V11.2ZM8 4C8.44183 4 8.8 4.35817 8.8 4.8V4.808C8.8 5.24983 8.44183 5.608 8 5.608C7.55817 5.608 7.2 5.24983 7.2 4.808V4.8C7.2 4.35817 7.55817 4 8 4Z"
      fill="#1164EE"
    />
  </svg>
);

export default AttentionCircleSmallAltIcon;
