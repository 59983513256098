import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import PaymentMethods from "components/PaymentMethods";
import { EWidgetType } from "components/PaymentMethods/types";

import { ContentContainer } from "uikit";

import useEmployeeBankInfoPage from "./useEmployeeBankInfoPage";

interface Props {
  className?: string;
}

const EmployeeBankInfoPage: FC<Props> = ({ className }) => {
  const {} = useEmployeeBankInfoPage();

  return (
    <DashboardLayout className={className}>
      <ContentContainer>
        <PaymentMethods widgetType={EWidgetType.SETTINGS} />
      </ContentContainer>
    </DashboardLayout>
  );
};

export default EmployeeBankInfoPage;
