import { FC } from "react";

import { CompanyName, ContactEmail,Container } from "./styles";

interface Props {
  className?: string;
  companyName?: string;
  contactEmail?: string;
}

const CompanyContactInfo: FC<Props> = ({ className, companyName, contactEmail }) => {
  return (
    <Container className={className}>
      <CompanyName>{companyName}</CompanyName>
      <ContactEmail href={`mailto:${contactEmail}`}>{contactEmail}</ContactEmail>
    </Container>
  );
};

export default CompanyContactInfo;
