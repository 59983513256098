import styled from "styled-components";

//Modals
export const CompanyAccountPromotionContent = styled.div`
  margin: 0 auto 32px auto;
  display: inline-block;
  width: auto;
`;

export const CAPCItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  width: auto;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const CAPCIconContainer = styled.div`
  margin-right: 16px;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const CAPCText = styled.div`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  line-height: 1.71;
`;

export const DDModalSelectedBankContainer = styled.div`
  display: inline-block;
  margin: 0 auto 32px auto;
`;

export const MessageEmphasized = styled.div`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;
