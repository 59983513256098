import styled, { css } from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { ButtonTextMixin } from "../Typography/Typography";

export const buttonHeight = 44;
export const buttonRadius = 22;
export const buttonHeightLarge = 56;
export const buttonRadiusLarge = 32;
export const buttonPaddingV = 2;
export const buttonPaddingH = 14;

export const ButtonBaseMixin = css`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  max-width: 350px;
  height: ${buttonHeight}px;
  padding: ${buttonPaddingV}px ${buttonPaddingH}px;
  border-radius: ${buttonRadius}px;
  ${ButtonTextMixin}
`;

export const LargeButtonMixin = css`
  height: ${buttonHeightLarge}px;
  border-radius: ${buttonRadiusLarge}px;
`;

export const ButtonBase = styled.button`
  ${ButtonBaseMixin}
`;

export const PrimaryButtonMixin = css`
  ${ButtonBaseMixin}
  border: none;
  color: ${({ theme }) => theme.colors.primaryText};
  background-color: ${({ theme }) => theme.colors.primary};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primaryAlt};
    color: ${({ theme }) => theme.colors.primaryTextAlt};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.primaryAlt};
    color: ${({ theme }) => theme.colors.primaryTextAlt};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.primaryDisabled};
    color: ${({ theme }) => theme.colors.primaryTextDisabled};
  }

  ${breakpoint("xs", "lg")`
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const PrimaryButtonLargeMixin = css`
  ${PrimaryButtonMixin}
  ${LargeButtonMixin}
`;

export const PrimaryButtonDangerMixin = css`
  color: ${({ theme }) => theme.colors.dangerText};
  background-color: ${({ theme }) => theme.colors.danger2};

  &:hover {
    background-color: ${({ theme }) => theme.colors.danger2Alt};
    color: ${({ theme }) => theme.colors.dangerTextAlt};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.danger2Alt};
    color: ${({ theme }) => theme.colors.dangerTextAlt};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.danger2Disabled};
    color: ${({ theme }) => theme.colors.dangerTextDisabled};
  }
`;

export const CancelButtonMixin = css`
  color: ${({ theme }) => theme.colors.cancelButtonText};
  background-color: ${({ theme }) => theme.colors.cancelButtonBg};
  border: none;
  padding-left: 22px;
  padding-right: 22px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.cancelButtonBgAlt};
    color: ${({ theme }) => theme.colors.cancelButtonTextAlt};
    border-color: ${({ theme }) => theme.colors.cancelButtonTextAlt};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.cancelButtonBgAlt};
    color: ${({ theme }) => theme.colors.cancelButtonTextAlt};
    border-color: ${({ theme }) => theme.colors.cancelButtonTextAlt};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.cancelButtonBgDisabled};
    color: ${({ theme }) => theme.colors.cancelButtonTextDisabled};
    border-color: ${({ theme }) => theme.colors.cancelButtonTextDisabled};
  }

  ${breakpoint("xs", "xl")`
    width: 100%;
    min-height: 32px;
    
    &:hover {
      background-color:${({ theme }) => theme.colors.cancelButtonBgAlt};
      color: ${({ theme }) => theme.colors.cancelButtonTextAlt};
      border-color: ${({ theme }) => theme.colors.cancelButtonTextAlt};
    }
  `}
`;

export const PrimaryButton = styled(ButtonBase)`
  ${PrimaryButtonMixin}
`;

export const PrimaryButtonLarge = styled(ButtonBase)`
  ${PrimaryButtonLargeMixin}
`;

export const PrimaryButtonDesktop = styled(PrimaryButton)`
  ${breakpoint("xs", "md")`
    display: none;
  `}
`;
export const PrimaryButtonMobile = styled(PrimaryButton)`
  ${breakpoint("md")`
    display: none;
  `}
`;

export const SecondaryButton = styled(ButtonBase)`
  color: ${({ theme }) => theme.colors.secondaryButtonText};
  background-color: ${({ theme }) => theme.colors.secondaryButtonBg};
  border: 1px solid ${({ theme }) => theme.colors.secondaryButtonText};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryButtonBgAlt};
    color: ${({ theme }) => theme.colors.secondaryButtonTextAlt};
    border-color: ${({ theme }) => theme.colors.secondaryButtonTextAlt};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondaryButtonBgAlt};
    color: ${({ theme }) => theme.colors.secondaryButtonTextAlt};
    border-color: ${({ theme }) => theme.colors.secondaryButtonTextAlt};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondaryButtonBgDisabled};
    color: ${({ theme }) => theme.colors.secondaryButtonTextDisabled};
    border-color: ${({ theme }) => theme.colors.secondaryButtonTextDisabled};
  }

  ${breakpoint("xs", "xl")`
    width: 100%;
  `}
`;

export const SecondaryButtonAlt = styled(SecondaryButton)`
  color: ${({ theme }) => theme.colors.secondaryButtonAltText};
  background-color: ${({ theme }) => theme.colors.secondaryButtonAltBg};
  border: 2px solid ${({ theme }) => theme.colors.secondaryButtonAltText};

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondaryButtonAltBgAlt};
    color: ${({ theme }) => theme.colors.secondaryButtonAltTextAlt};
    border-color: ${({ theme }) => theme.colors.secondaryButtonAltTextAlt};
  }

  &:active {
    background-color: ${({ theme }) => theme.colors.secondaryButtonAltBgAlt};
    color: ${({ theme }) => theme.colors.secondaryButtonAltTextAlt};
    border-color: ${({ theme }) => theme.colors.secondaryButtonAltTextAlt};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.secondaryButtonAltBgDisabled};
    color: ${({ theme }) => theme.colors.secondaryButtonAltTextDisabled};
    border-color: ${({ theme }) => theme.colors.secondaryButtonAltTextDisabled};
  }

  ${breakpoint("xs", "xl")`
    width: 100%;
    height: 32px;
    
    &:hover {
      background-color:${({ theme }) => theme.colors.secondaryButtonAltBgAlt};
      color: ${({ theme }) => theme.colors.secondaryButtonAltTextAlt};
      border-color: ${({ theme }) => theme.colors.secondaryButtonAltTextAlt};
    }
  `}
`;

export const CancelButton = styled(ButtonBase)`
  ${CancelButtonMixin}
`;

export const CancelButtonLarge = styled(CancelButton)`
  ${LargeButtonMixin}
`;

export const NoBorderButton = styled(ButtonBase)`
  border: none;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.noBorderButtonText};
  background: ${({ theme }) => theme.colors.noBorderButtonBg};
  border-radius: 0;

  &:hover {
    background: ${({ theme }) => theme.colors.noBorderButtonBgAlt};
    color: ${({ theme }) => theme.colors.noBorderButtonTextAlt};
  }

  &:active {
    background: ${({ theme }) => theme.colors.noBorderButtonBgAlt};
    color: ${({ theme }) => theme.colors.noBorderButtonTextAlt};
  }

  &:disabled {
    background: ${({ theme }) => theme.colors.noBorderButtonBgAlt};
    color: ${({ theme }) => theme.colors.noBorderButtonTextDisabled};
  }
`;

// TODO: apply to all buttons that require reset in project
export const resetButtonStyles = css`
  border: none;
  padding: 0;
  background-color: transparent;
`;

export const iconedButtonMixin = css`
  ${breakpoint("xs", "xlm")`
    justify-content: center;
  `}
`;

export const CloseModalButton = styled.button`
  width: 23px;
  height: 23px;
  padding: 0;
  border: none;
  border-radius: 50%;
`;

export const PrimaryButtonIconed = styled(PrimaryButton)`
  ${iconedButtonMixin}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
  min-height: ${buttonHeight}px;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.primaryText};
    }

    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: 12px;
    }
  }

  &:disabled {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.primaryTextDisabled};
      }
    }
  }
`;

export const SecondaryButtonIconed = styled(SecondaryButton)`
  ${iconedButtonMixin}
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  box-sizing: border-box;
  min-height: ${buttonHeight}px;

  svg {
    path {
      fill: ${({ theme }) => theme.colors.secondaryButtonText};
    }

    &:first-child {
      margin-right: 12px;
    }

    &:last-child {
      margin-left: 12px;
    }
  }
`;
