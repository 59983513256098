import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";

import { companyMetadataSelector, userMetadataSelector } from "redux/selectors";
import { AppEvents } from "services/events";
import { RESEND_TEAM_MEMBER_INVITATION_TIMEOUT } from "constants/";
import { getActionTimeout, getName, showErrorModal } from "helpers";

import { ITablePaginationPropsExtended } from "uikit/Table/types";

import {
  AdminResponseDto,
  mutationAdminsControllerDisableAccount,
  mutationAdminsControllerResendInvitation,
  queryAdminsControllerGetAdminsList,
} from "utils/swagger_react_query";

export const useTeamMembers = () => {
  const translationPrefix = "settings_pages.admin.team_members_page";
  const modalsTranslationPrefix = `${translationPrefix}.modals`;
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isListLoading, setListLoading] = useState<boolean>(true);
  const currentUser = useSelector(userMetadataSelector);
  const companyInfo = useSelector(companyMetadataSelector);

  const teamMembersListLimit = 20;
  const [teamMembersList, setTeamMembersList] = useState<AdminResponseDto[]>([]);
  const [teamMembersListPaginationData, setTeamMembersListPaginationData] =
    useState<ITablePaginationPropsExtended>({
      limit: teamMembersListLimit,
      totalCount: 0,
      page: 1,
    });

  const [resendInvitationPermission, setResendInvitationPermission] = useState<
    Record<string, number>[]
  >([]);
  const [inviteTeamMemberModalIsOpen, setInviteTeamMemberModal] = useState<boolean>(false);

  const fetchTeamMembers = async (_page: number) => {
    try {
      setListLoading(true);
      const teamMembersListResponse = await queryAdminsControllerGetAdminsList({
        page: _page.toString(),
        perPage: String(teamMembersListPaginationData.limit),
      });
      setTeamMembersList(teamMembersListResponse.admins);
      setTeamMembersListPaginationData((prevValues) => ({
        ...prevValues,
        totalCount: teamMembersListResponse.total,
      }));
    } catch (error) {
      showErrorModal(error);
    } finally {
      setListLoading(false);
    }
  };

  useEffect(() => {
    if (companyInfo?.companyId) fetchTeamMembers(teamMembersListPaginationData.page);
  }, []);

  useEffect(() => {
    fetchTeamMembers(teamMembersListPaginationData.page);
  }, [teamMembersListPaginationData.page]);

  const handleResendInvitation = async (userInfo: AdminResponseDto) => {
    try {
      setListLoading(true);
      await mutationAdminsControllerResendInvitation()({ email: userInfo.email });
      await fetchTeamMembers(teamMembersListPaginationData.page);

      //Success modal
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        title: <Trans i18nKey={`${modalsTranslationPrefix}.invitation_resent_success.title`} />,
        message: (
          <Trans i18nKey={`${modalsTranslationPrefix}.invitation_resent_success.message`}>
            {{ email: userInfo.email }}
          </Trans>
        ),
        mainButton: {
          text: <Trans i18nKey={"buttons.ok"} />,
          onClick: () => undefined,
        },
      });
      setResendInvitationPermission((prevState) => [
        ...prevState,
        { [userInfo.adminId]: getActionTimeout(RESEND_TEAM_MEMBER_INVITATION_TIMEOUT, true) },
      ]);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setListLoading(false);
    }
  };

  const showConfirmationDisableAccountModal = (user: AdminResponseDto) => {
    AppEvents.emit("SetGlobalModal", {
      isOpen: true,
      title: <Trans i18nKey={`components.confirm_action.title`} />,
      message: (
        <Trans
          i18nKey={`${modalsTranslationPrefix}.disable_user_confirmation.message`}
          values={{ fullName: getName(user) }}
        />
      ),
      mainButton: {
        text: <Trans i18nKey={`${modalsTranslationPrefix}.disable_user_confirmation.submit_btn`} />,
        onClick: () => handleDisableAccount(user),
      },
      secondaryButton: {
        text: <Trans i18nKey={"buttons.cancel"} />,
        onClick: () => AppEvents.emit("SetGlobalModal", { isOpen: false }),
      },
    });
  };

  const handleDisableAccount = async (user: AdminResponseDto) => {
    try {
      setListLoading(true);
      await mutationAdminsControllerDisableAccount({ adminId: user.adminId })();
      await fetchTeamMembers(teamMembersListPaginationData.page);

      //Success modal
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        title: <Trans i18nKey={`${modalsTranslationPrefix}.disable_user_success.title`} />,
        message: (
          <Trans
            i18nKey={`${modalsTranslationPrefix}.disable_user_success.message`}
            values={{ fullName: getName(user) }}
          />
        ),
        mainButton: {
          text: <Trans i18nKey={"buttons.ok"} />,
          onClick: () => () => AppEvents.emit("SetGlobalModal", { isOpen: false }),
        },
      });
    } catch (error) {
      showErrorModal(error);
    } finally {
      setListLoading(false);
    }
  };

  const onPageChange = (page: number) => {
    setListLoading(true);
    try {
      setTeamMembersListPaginationData((prevValues) => ({ ...prevValues, page }));
    } catch (error) {
      showErrorModal(error);
    } finally {
      setListLoading(false);
    }
  };

  const onBackBtnClick = () => {
    navigate(routes.ADMIN_SETTINGS);
  };

  const handleUpdateResendInvitationTimeout = (userId: string, remainingTime: number) => {
    if (remainingTime === 0) {
      setResendInvitationPermission((prevState) =>
        prevState.filter((item) => Object.keys(item)[0] !== userId),
      );
      return;
    }

    setResendInvitationPermission((prevState) =>
      prevState.map((item) => {
        const itemKey = Object.keys(item)[0];
        if (itemKey === userId) {
          return { [userId]: item[itemKey] + remainingTime };
        }
        return item;
      }),
    );
  };

  return {
    metadata: {
      translationPrefix,
      isLoading,
      setLoading,
      currentUser,
      companyInfo,
      resendInvitationPermission,
      isListLoading,
    },
    pageData: {
      teamMembersList: {
        data: teamMembersList,
        pagination: {
          ...teamMembersListPaginationData,
          onPageChange,
        },
      },
    },
    actions: {
      handleResendInvitation,
      showConfirmationDisableAccountModal,
      onBackBtnClick,
      refetchTeamMembersList: () => fetchTeamMembers(teamMembersListPaginationData.page),
      handleUpdateResendInvitationTimeout,
    },
    inviteTeamMemberModal: {
      inviteTeamMemberModalIsOpen,
      setInviteTeamMemberModal,
    },
  };
};
