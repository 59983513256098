import { CardShipmentExpedited, CardShippingMethod } from "types/CardShipping";

const paidCardShippingDetailsForm = {
  "en-US": {
    title: "Please provide your shipping details",

    shipping_details: {
      title: "Shipping Details",
      form_labels: {
        first_name: "First Name",
        last_name: "Last Name",
        address: "Address",
        suite: "Apartment, suite, etc.",
        state: "State",
        city: "City",
        phone: "Phone Number",
        zip: "Zip",
      },
    },
    shipment_details: {
      title: "Shipment Details",
      form_labels: {
        shipment_method: "Shipment Method",
        shipment_expedited: "Shipment Expedited",
      },
      shipment_methods: {
        [CardShippingMethod.INTERNATIONAL]: "International",
        [CardShippingMethod.INTERNATIONAL_GROUND]: "International Ground",
        [CardShippingMethod.INTERNATIONAL_PRIORITY]: "International Priority",
        [CardShippingMethod.LOCAL_MAIL]: "Local Mail",
        [CardShippingMethod.LOCAL_PRIORITY]: "Local Priority",
        [CardShippingMethod.OVERNIGHT]: "Overnight",
        [CardShippingMethod.TWO_DAY]: "Two Day",
      },
      shipment_expedited: {
        [CardShipmentExpedited.REGULAR_DELIVERY]: "Regular Delivery",
        [CardShipmentExpedited.EXPEDITED_DELIVERY]: "Expedited Delivery",
      },
    },
  },
};

export default paidCardShippingDetailsForm;
