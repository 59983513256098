import { NumberOfEmployees } from "types/BETypes";

export const NumberOfEmployeesSelectOptions: { label: string, value: NumberOfEmployees }[] = [
  {
    label: "1-50",
    value: NumberOfEmployees.FROM_1_TO_50,
  },
  {
    label: "51-100",
    value: NumberOfEmployees.FROM_51_TO_100,
  },
  {
    label: "101-250",
    value: NumberOfEmployees.FROM_101_TO_250,
  },
  {
    label: "251-500",
    value: NumberOfEmployees.FROM_251_TO_500,
  },
  {
    label: "501-1000",
    value: NumberOfEmployees.FROM_501_TO_1000,
  },
  {
    label: "1001-5000",
    value: NumberOfEmployees.FROM_1001_TO_5000,
  },
  {
    label: "5001-10000",
    value: NumberOfEmployees.FROM_5001_TO_10000,
  },
  {
    label: "10001-20000",
    value: NumberOfEmployees.FROM_10001_TO_20000,
  },
  {
    label: "20000+",
    value: NumberOfEmployees.FROM_20000,
  },

];
