import { FC } from "react";

import IconProps from "./IconProps";

const EyeOpen: FC<IconProps> = ({ className }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="eye" clip-path="url(#clip0_3952_19587)">
      <path
        id="Vector"
        d="M6.66669 8.00008C6.66669 8.3537 6.80716 8.69284 7.05721 8.94289C7.30726 9.19294 7.6464 9.33341 8.00002 9.33341C8.35364 9.33341 8.69278 9.19294 8.94283 8.94289C9.19288 8.69284 9.33335 8.3537 9.33335 8.00008C9.33335 7.64646 9.19288 7.30732 8.94283 7.05727C8.69278 6.80722 8.35364 6.66675 8.00002 6.66675C7.6464 6.66675 7.30726 6.80722 7.05721 7.05727C6.80716 7.30732 6.66669 7.64646 6.66669 8.00008Z"
        stroke="#78808F"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        id="Vector_2"
        d="M14 8C12.4 10.6667 10.4 12 8 12C5.6 12 3.6 10.6667 2 8C3.6 5.33333 5.6 4 8 4C10.4 4 12.4 5.33333 14 8Z"
        stroke="#78808F"
        stroke-width="1.4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3952_19587">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeOpen;
