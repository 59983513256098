import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2px 6px 2px 12px;
  box-sizing: border-box;
  border-radius: 4px;
  min-height: 28px;
  background: ${({ theme }) => theme.colors.selectedBankWidgetBg};
`;

export const IconContainer = styled.div`
  svg {
    margin-right: 6px;
    path {
      fill: ${({ theme }) => theme.colors.selectedBankWidgetIcon};
    }
  }
`;

export const Text = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: 1.71;
`;
