import { getI18n, Trans } from "react-i18next";
import routes from "routes/routes";

import { AppEvents } from "services/events";
import { BEValidationErrorConst, ErrorConst } from "types/BETypes";
import { HELP_EMAIL } from "constants/index";

import { CustomLinkSmall } from "uikit";
import { EModalTypes, IModalProps } from "uikit/Modal/types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const showErrorModal = (
  error?: any,
  callback?: () => any,
  config?: Partial<IModalProps>,
) => {
  const validationErrorMessage = error?.data?.validationError?.map((it: string) => {
    if (it.includes(BEValidationErrorConst.PHONE_NUMBER_VALIDATION_ERROR)) {
      return ErrorConst.PHONE_NUMBER_VALIDATION_ERROR;
    }
    return "";
  })?.[0];

  const errorMessage =
    validationErrorMessage ||
    error?.data?.error ||
    (typeof error === "string" && error) ||
    (typeof error?.message === "string" && error?.message) ||
    (typeof error?.error === "string" && error?.error) ||
    "";

  AppEvents.emit("SetGlobalModal", {
    type: EModalTypes.Error,
    title: <Trans i18nKey={"errors.defaultTitle"}> </Trans>,
    isOpen: true,
    mainButton: {
      text: <Trans i18nKey={"common.modal.okay"}> </Trans>,
      onClick: () => {
        callback ? callback() : AppEvents.emit("SetGlobalModal", { isOpen: false });
      },
    },
    onClose: callback || undefined,
    message: (
      <Trans
        i18nKey={`errors.${errorMessage}`}
        values={{ helpEmail: HELP_EMAIL }}
        defaults={getI18n().t("errors.defaultMessage")}
      >
        {" "}
        <CustomLinkSmall href={`mailto: ${HELP_EMAIL}`}>{HELP_EMAIL}</CustomLinkSmall>
        <CustomLinkSmall href={routes.RECOVERY_ACCOUNT}></CustomLinkSmall>
      </Trans>
    ),
    ...config,
  });
};
