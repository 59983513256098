import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

const avatarWrapperWidth = 32;
const avatarWrapperHeight = 32;

const avatarWrapperWidthMobile = 24;
const avatarWrapperHeightMobile = 24;

export const Wrapper = styled.div<{ clickable: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;

  ${({ clickable }) =>
    clickable &&
    `
    cursor: pointer;
  `}

  & svg > path {
    stroke: ${({ theme }) => theme.colors.avatarArrow};
    fill: ${({ theme }) => theme.colors.avatarArrow};
  }
`;

export const LeftSection = styled.div``;

export const AvatarWrapper = styled.div`
  margin: 0 10px 0 0;
  width: ${avatarWrapperWidth}px;
  height: ${avatarWrapperHeight}px;

  & div {
    width: ${avatarWrapperWidth}px;
    height: ${avatarWrapperHeight}px;
    font-size: ${({ theme }) => theme.size.body2} !important;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  ${breakpoint("xs", "xl")`
		margin: 0 9px 0 0;
		width: ${avatarWrapperWidthMobile}px;
		height: ${avatarWrapperHeightMobile}px;

		& div {
			width: ${avatarWrapperWidthMobile}px;
			height: ${avatarWrapperHeightMobile}px;
			font-size: ${({ theme }) => theme.size.caption} !important;
		}
  `}
`;

export const ContentWrapper = styled.div`
  width: calc(100% - ${avatarWrapperWidth}px);
  padding-right: 10px;
  box-sizing: border-box;

  ${breakpoint("lg")`
    padding-right: 18px;
  `}
`;

export const NameWrapper = styled.div`
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.accentMain};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 1px;

  ${breakpoint("xs", "xl")`
    font-size: ${({ theme }) => theme.size.caption};
  `}
`;

export const AvatarPlaceholder = styled.div`
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.contentBg};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 21px;
    height: 22px;
  }
`;

export const ContentAfterNameContainer = styled.div`
  color: ${({ theme }) => theme.colors.text};
`;
