import { isEmail } from "class-validator";
import * as yup from "yup";

import { filePathRegExp, MAX_INPUT_LENGTH, userNameRegExp } from "constants/";
import { validatePhoneNumber } from "helpers/";

export const AdminProfileValidationSchema = yup.object({
  firstName: yup
    .string()
    .required("validationErrors.common.is_required")
    .test("long first name", "validationErrors.common.too_long", function (value) {
      return (value?.trim() || "").length < MAX_INPUT_LENGTH;
    })
    .test("invalid first name", "validationErrors.common.wrong_format", function (value) {
      return userNameRegExp.test(value?.trim() || "");
    }),
  lastName: yup
    .string()
    .required("validationErrors.common.is_required")
    .test("long last name", "validationErrors.common.too_long", function (value) {
      return (value?.trim() || "").length < MAX_INPUT_LENGTH;
    })
    .test("invalid last name", "validationErrors.common.wrong_format", function (value) {
      return userNameRegExp.test(value?.trim() || "");
    }),

  jobTitle: yup
    .string()
    .nullable()
    .test("long last name", "validationErrors.common.too_long", function (value) {
      return (value?.trim() || "").length < MAX_INPUT_LENGTH;
    }),

  phone: yup
    .string()
    .nullable()
    .test(
      "wrong phone format",
      "validationErrors.common.wrong_format",
      (value) => !value || validatePhoneNumber(value ?? ""),
    ),
  email: yup
    .string()
    .max(MAX_INPUT_LENGTH, "validationErrors.common.too_long")
    .test(
      "invalid email",
      "validationErrors.common.wrong_format",
      (value) => !value || isEmail(value),
    )
    .required("validationErrors.common.is_required"),
  avatarUrl: yup.string().optional().nullable(true),
  avatarFileName: yup
    .string()
    .optional()
    .nullable(true)
    .test(
      "invalid file",
      "validationErrors.image.invalid_file_type",
      (value) => !value || filePathRegExp.test(value),
    ),
});

export type AdminProfileFormType = yup.InferType<typeof AdminProfileValidationSchema>;
