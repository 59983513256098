import { useEffect, useState } from "react";

const useCompanyBankAccount = () => {
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return {
    isLoading,
  };
};

export default useCompanyBankAccount;
