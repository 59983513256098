import { PayDistributionType, SalsaOnboardingStatus, UserRole } from "types/BETypes";

import { GetCompanyByIdResponseDto, UserProfileResponseDto } from "utils/swagger_react_query";

interface IUser {
  firstName?: string | null;
  lastName?: string | null;
}

export const getName = (user?: IUser | null) => {
  return user ? `${user.firstName} ${user.lastName}` : "";
};

export const getRoleName = (role: string, t: any) => {
  // t comes from react-i18next
  switch (role) {
    case "super_admin":
      return t("common.roles.super_admin");
    default:
      return t("common.roles.employee");
  }
};

export const getHiddenEmail = (email: string) => {
  if (!email) return "";
  const [username, domain] = email.split("@");

  const usernameHidden = username
    .split(".")
    .map((it) => it.charAt(0) + "***")
    .join(".");

  const [subdomain, tld] = domain.split(".");

  const subdomainHidden = subdomain ? subdomain.replace(/-/g, ".").charAt(0) + "***" : "";

  const tldHidden = tld ? tld.charAt(0) + "***" : "";

  return `${usernameHidden}@${subdomainHidden}.${tldHidden}`;
};

export const isWorkerOnboarded = (user?: UserProfileResponseDto | null) => {
  if (!user) return false;

  // const result =
  //   user.lastActiveRole === UserRole.EMPLOYEE &&
  //   user.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED &&
  //   !!user.defaultPayDistribution?.type;
  return !!user.isOnboardingComplete;
};

export const isAdminOnboarded = (company?: GetCompanyByIdResponseDto | null) => {
  if (!company) return false;

  // const result =
  //   user.lastActiveRole === UserRole.EMPLOYEE &&
  //   user.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED &&
  //   !!user.defaultPayDistribution?.type;
  return !!company.isOnboardingComplete;
};
