import { FC } from "react";
import {
  AttentionCircleIcon,
  QuestionmarkCircleIcon,
  SuccessCircledCheckMarkLarge,
} from "assets/svg";

import { Loader } from "uikit";
import {
  IconContainer,
  Image,
  MainActionButton,
  Message,
  SecondaryActionButton,
  StatusIconContainer,
  Title,
} from "uikit/Modal/styles";
import { EModalTypes, IModalProps } from "uikit/Modal/types";

import { ButtonsContainer, Container, ContainerInner } from "./styles";

export interface IModalLikeContentProps extends Partial<IModalProps> {
  className?: string;
}

const ModalLikeContent: FC<IModalLikeContentProps> = ({
  className,
  imageURL,
  icon,
  type = EModalTypes.Success,
  title,
  message,
  secondaryMessage,
  mainButton,
  secondaryButton,
  onClose,
  onCloseOnButtons = true,
  children = null,
  childrenBeforeButtons = true,
}) => {
  return (
    <Container className={className}>
      <ContainerInner>
        {(!!icon || !!imageURL || !!type) && (
          <IconContainer>
            {icon}
            {imageURL && <Image src={imageURL} alt="" />}
            {type && (
              <StatusIconContainer>
                {type === EModalTypes.Warning && <AttentionCircleIcon />}
                {type === EModalTypes.Success && <SuccessCircledCheckMarkLarge />}
                {type === EModalTypes.Progress && <Loader />}
                {type === EModalTypes.Confirm && <QuestionmarkCircleIcon />}
              </StatusIconContainer>
            )}
          </IconContainer>
        )}

        {title && <Title>{title}</Title>}

        {message && <Message>{message}</Message>}
        {secondaryMessage && <Message>{secondaryMessage}</Message>}

        {!!childrenBeforeButtons && children}

        <ButtonsContainer>
          {mainButton && (
            <MainActionButton
              onClick={() => {
                mainButton.onClick?.();
                onCloseOnButtons && onClose && onClose();
              }}
              type="button"
              disabled={!!mainButton?.disabled}
            >
              {mainButton.text}
            </MainActionButton>
          )}

          {!!secondaryButton && (
            <SecondaryActionButton
              onClick={() => {
                secondaryButton.onClick?.();
                onCloseOnButtons && onClose && onClose();
              }}
              type="button"
              disabled={!!secondaryButton?.disabled}
            >
              {secondaryButton.text}
            </SecondaryActionButton>
          )}
          {!childrenBeforeButtons && children}
        </ButtonsContainer>
      </ContainerInner>
    </Container>
  );
};

export default ModalLikeContent;
