import { FC } from "react";
import { Trans } from "react-i18next";
import { BankIconPlaceholder } from "assets/svg";

import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { BankAccountType } from "types/BETypes";
import { getBankLogo } from "helpers/index";

import { GetPlaidBankAccountsResponseDto } from "utils/swagger_react_query";

import {
  AccountNumberSection,
  BankTypeSection,
  InfoSection,
  PendingVerifyLink,
  SecondaryBtn,
  SecondaryBtnWrapper,
  Text,
  VerificationStatusSection,
  VerifyBtn,
  VerifyBtnWrapper,
  VSSText,
  Wrapper,
} from "./styles";

interface IProps {
  className?: string;
  bankAccountInfo: GetPlaidBankAccountsResponseDto;
  secondaryBtn?: {
    needRender: boolean;
    label?: string;
    onClick?: () => void;
  };
  onVerify?: (bankAccountId: string, verificationToken: string) => void;
  onPendingVerificationClick?: (
    verificationStatus:
      | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
      | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
  ) => void;
}

const BankAccountInfoLong: FC<IProps> = ({
  className,
  bankAccountInfo,
  secondaryBtn,
  onVerify,
  onPendingVerificationClick,
}) => {
  const translationPrefix = `components.bank_account_info_long`;
  const bankLogo = getBankLogo(bankAccountInfo);
  const shouldShowContentToVerify =
    (bankAccountInfo?.verificationStatus === BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION ||
      bankAccountInfo.renewLoginRequired) &&
    onVerify;
  const isBankAccountExpiredOrFailed = [
    BankAccountVerifyStatus.VERIFICATION_FAILED,
    BankAccountVerifyStatus.VERIFICATION_EXPIRED,
  ].includes(bankAccountInfo?.verificationStatus as BankAccountVerifyStatus);

  const shouldShowVerificationExplanationModal =
    [
      BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION,
      BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
    ].includes(bankAccountInfo?.verificationStatus as BankAccountVerifyStatus) &&
    onPendingVerificationClick;

  const renderBankName = () => {
    if (bankAccountInfo?.institution?.name) return bankAccountInfo?.institution?.name;
    return <Trans i18nKey={`${translationPrefix}.bank_name_placeholder`} />;
  };

  const renderPendingVerifyLink = () => {
    if (shouldShowVerificationExplanationModal) {
      return (
        <PendingVerifyLink
          onClick={() =>
            onPendingVerificationClick(
              bankAccountInfo?.verificationStatus as
                | BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION
                | BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION,
            )
          }
        >
          <Trans i18nKey={`${translationPrefix}.pending_verification_link`} />
        </PendingVerifyLink>
      );
    } else if (bankAccountInfo?.institution?.name) {
      return <Text>{bankAccountInfo.accountName}</Text>;
    }
  };

  const verifyBtnLabel = bankAccountInfo.renewLoginRequired ? "reconnect_btn" : "verify_btn";

  return (
    <Wrapper className={className}>
      <InfoSection>
        {!!bankLogo ? <img src={bankLogo} alt="Bank logo" /> : <BankIconPlaceholder />}
        <div>
          <Text>{renderBankName()}</Text>
          {renderPendingVerifyLink()}
        </div>
        <AccountNumberSection>
          <Text>
            <Trans i18nKey={`${translationPrefix}.account_number`} />
          </Text>
          <Text>**********{(bankAccountInfo?.last4Digits as string) || ""}</Text>
        </AccountNumberSection>
        {!shouldShowContentToVerify && (
          <BankTypeSection>
            <Text>
              <Trans i18nKey={`${translationPrefix}.account_type`} />
            </Text>
            <Text>
              <Trans
                i18nKey={`common.bank_account_types.${
                  BankAccountType[bankAccountInfo.type as BankAccountType]
                }`}
              />
            </Text>
          </BankTypeSection>
        )}

        {isBankAccountExpiredOrFailed && (
          <VerificationStatusSection>
            <VSSText>
              <Trans
                i18nKey={`${translationPrefix}.verification_status.${bankAccountInfo.verificationStatus}`}
              />
            </VSSText>
          </VerificationStatusSection>
        )}
      </InfoSection>

      {shouldShowContentToVerify && (
        <VerifyBtnWrapper>
          <VerifyBtn
            onClick={() =>
              onVerify(bankAccountInfo.bankAccountId, bankAccountInfo?.accessToken || "")
            }
          >
            <Trans i18nKey={`${translationPrefix}.${verifyBtnLabel}`} />
          </VerifyBtn>
        </VerifyBtnWrapper>
      )}

      <SecondaryBtnWrapper>
        {secondaryBtn && secondaryBtn.needRender && (
          <SecondaryBtn onClick={secondaryBtn.onClick}>{secondaryBtn.label}</SecondaryBtn>
        )}
      </SecondaryBtnWrapper>
    </Wrapper>
  );
};

export default BankAccountInfoLong;
