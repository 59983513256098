import { FC } from "react";

import IconProps from "./IconProps";

const OnboardingIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clip-path="url(#clip0_313_14561)">
      <path
        d="M9.10086 11.0106L7.42585 12.673C7.2792 12.8185 7.2792 13.0549 7.42585 13.2005L8.14981 13.919C8.29646 14.0645 8.53469 14.0645 8.68133 13.919L12.8741 9.7573C13.0208 9.61176 13.0208 9.37595 12.8741 9.2298L8.68133 5.06814C8.53469 4.9226 8.29646 4.9226 8.14981 5.06814L7.42585 5.78662C7.2792 5.93216 7.2792 6.16858 7.42585 6.31412L9.10086 7.97645C9.33723 8.21103 9.17016 8.61325 8.83541 8.61325H0.375593C0.168305 8.61325 0 8.78029 0 8.986V10.0017C0 10.2074 0.168305 10.3745 0.375593 10.3745H8.83479C9.16954 10.3745 9.33723 10.7761 9.10024 11.0113L9.10086 11.0106Z"
        fill="#007D7B"
      />
      <path
        d="M14.8131 2.42011C14.8032 2.41029 14.792 2.40107 14.7791 2.39002L14.758 2.37037C13.0112 0.841911 10.7657 0 8.43543 0C5.90033 0 3.53849 0.959202 1.74158 2.71119C1.59122 2.85795 1.59865 3.10175 1.7521 3.24483L2.4971 3.94182C2.64561 4.0806 2.87455 4.07262 3.0212 3.93199C4.48026 2.5288 6.38854 1.76058 8.43481 1.76058C10.3301 1.76058 12.1561 2.44406 13.578 3.68513C13.5873 3.69311 13.5972 3.70294 13.6083 3.71153L13.6244 3.72689C15.2778 5.19332 16.2251 7.29472 16.2251 9.49254C16.2251 10.2829 16.1051 11.0615 15.8693 11.807C15.8378 11.9059 15.805 12.0054 15.7697 12.1024C14.7599 14.9088 12.2192 16.8898 9.21384 17.1858C8.96138 17.211 8.69965 17.2245 8.43481 17.2245C7.8476 17.2245 7.27214 17.1619 6.71525 17.0378C5.33045 16.7314 4.06135 16.054 3.02182 15.0531C2.87517 14.9118 2.64561 14.9045 2.4971 15.0433L1.7521 15.7396C1.59865 15.8833 1.59122 16.1271 1.74158 16.2739C3.52055 18.0019 5.93746 18.9857 8.43481 18.9857C8.51525 18.9857 8.59507 18.9851 8.67489 18.9826C8.91869 18.9771 9.15877 18.9624 9.39267 18.9378C12.8046 18.6013 15.7295 16.4943 17.131 13.4534C17.2968 13.0929 17.441 12.7196 17.5629 12.3345C17.8525 11.4189 17.9998 10.4622 17.9998 9.49254C17.9998 6.7967 16.8383 4.21877 14.8125 2.4195L14.8131 2.42011Z"
        fill="#007D7B"
      />
    </g>
    <defs>
      <clipPath id="clip0_313_14561">
        <rect width="18" height="18.9863" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default OnboardingIcon;
