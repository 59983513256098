import { Trans } from "react-i18next";
import { find, findIndex, findLast, findLastIndex, floor } from "lodash";
import moment from "moment";
import styled from "styled-components";
import colors from "styles/colors";
import { v4 as uuidv4 } from "uuid";

import {
  BonusChallengeRewardType,
  BonusType,
  ChallengeGoalConditionOperator,
  VestingMilestoneType,
} from "types/BETypes";
import { defaultDateFormat } from "constants/index";
import {
  getDaysBetweenDates,
  getFormattedNumber,
  getFormattedSum,
  getNoonDate,
  NumberType,
} from "helpers/index";
import {
  ProgressTooltipContent,
  PTCLabel,
  PTCValueAlt,
  PTCValueSum,
  PTCValueSumAlt,
} from "components/BonusCardDetailedSmall/tooltipStyles";
import {
  IProgressItem,
  ProgressItemMarkerType,
  ProgressItemType,
} from "components/ProgressBar/types";

import { BonusResponseDto, VestingScheduleItemDto } from "utils/swagger_react_query";

import { getClosestChallengeGoalByOperator } from "./performanceChallenges";

export enum ProgressItemActivityType {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  EMPTY = "EMPTY",
  LEADING = "LEADING",
  LEADING_FINISHED = "LEADING_FINISHED",
  LEADING_NOT_STARTED_YET = "LEADING_NOT_STARTED_YET",
  BOOSTER_VESTED = "BOOSTER_VESTED",
  BOOSTER_REWARD = "BOOSTER_REWARD",
}

export const getPerformanceItemTooltip = (tooltipData: {
  data: IProgressItem;
  type?: ProgressItemType;
  rewardType?: BonusChallengeRewardType;
  rewardAmount?: number;
  numbersFormat?: NumberType;
  value?: number;
  index?: number;
}) => {
  const {
    data,
    type,
    rewardType = BonusChallengeRewardType.FLAT_PAYMENT_UPON_COMPLETION,
    rewardAmount = 0,
    numbersFormat = NumberType.NUMBER,
    value = 0,
    index = 0,
  } = tooltipData;
  if (!data) return undefined;
  const formattedValue = getFormattedNumber(value, numbersFormat);
  const translationPrefix = `components.bonus_card.progress_bar_tooltips.${
    BonusType.CHALLENGE
  }.${rewardType}.${type || data.type}.${
    data.inactive ? ProgressItemActivityType.INACTIVE : ProgressItemActivityType.ACTIVE
  }`;
  return (
    <ProgressTooltipContent>
      <Trans
        i18nKey={`${translationPrefix}`}
        values={{
          progress: formattedValue,
          tierAmount: getFormattedSum(rewardAmount),
          index: index,
        }}
        components={{
          1: <PTCLabel />,
          2: <PTCValueAlt />,
          3: <PTCValueSum sum={rewardAmount} />,
        }}
      />
    </ProgressTooltipContent>
  );
};

export const getTimeItemTooltip = (tooltipData: {
  type: ProgressItemType;
  index?: number;
  amount?: number;
  activityType?: ProgressItemActivityType;
  date?: Date;
  nextMilestoneDate?: Date;
  daysTillNextMilestone?: number;
}) => {
  const {
    type,
    index = 0,
    amount = 0,
    activityType = ProgressItemActivityType.INACTIVE,
    date,
    nextMilestoneDate,
    daysTillNextMilestone,
  } = tooltipData;
  const translationPrefix = `components.bonus_card.progress_bar_tooltips.${BonusType.TIME}.${type}.${activityType}`;

  return (
    <ProgressTooltipContent>
      <Trans
        i18nKey={`${translationPrefix}`}
        values={{
          date: date ? moment(date).format(defaultDateFormat) : undefined,
          nextMilestoneDate: nextMilestoneDate
            ? moment(nextMilestoneDate).format(defaultDateFormat)
            : undefined,
          daysTillNextMilestone: daysTillNextMilestone,
          index: index,
        }}
        components={{
          1: <PTCLabel />,
          2: <PTCValueAlt />,
          3: <PTCValueSum sum={amount} />,
          4: <PTCValueSumAlt sum={amount} />,
        }}
      />
    </ProgressTooltipContent>
  );
};

export const getUnitItemTooltip = (tooltipData: {
  type: ProgressItemType;
  index?: number;
  amount?: number;
  activityType?: ProgressItemActivityType;
  quantity?: number;
  nextMilestoneQuantity?: number;
}) => {
  const {
    type,
    index = 0,
    amount = 0,
    activityType = ProgressItemActivityType.INACTIVE,
    quantity,
    nextMilestoneQuantity,
  } = tooltipData;
  const translationPrefix = `components.bonus_card.progress_bar_tooltips.${BonusType.UNIT}.${type}.${activityType}`;

  return (
    <ProgressTooltipContent>
      <Trans
        i18nKey={`${translationPrefix}`}
        values={{
          quantity,
          index,
          nextMilestoneQuantity,
        }}
        components={{
          1: <PTCLabel />,
          2: <PTCValueAlt />,
          3: <PTCValueSum sum={amount} />,
          4: <PTCValueSumAlt sum={amount} />,
        }}
      />
    </ProgressTooltipContent>
  );
};

export const getProgressRangeColor = (data: Partial<BonusResponseDto>) => {
  return data.status
    ? (colors.progressBarStatusBg as any)[data.status] || colors.performanceDetailsProgressBg
    : colors.performanceDetailsProgressBg;
};
export const getChallengeProgressBarData = (data: Partial<BonusResponseDto>): IProgressItem[] => {
  const showStep =
    Number(data?.unitGoals?.[0]?.quantityCompleted) < Number(data?.unitGoals?.[0]?.quantity);
  const result: IProgressItem[] = [];

  const goal = data?.challengeGoals?.[0];
  const progressValue = goal?.progressValue || 0;
  const progressValueRangeOffset = (goal?.progressValue || 0) - (goal?.conditionRangeFrom || 0);
  const progressRangeColor = getProgressRangeColor(data);

  const sortedGoals = [goal, ...(goal?.stretchGoals || [])].sort(
    (a, b) => (a?.conditionValue || 0) - (b?.conditionValue || 0),
  );
  const isProgressRangeInactive = !getClosestChallengeGoalByOperator(
    sortedGoals,
    progressValue,
    goal?.conditionOperator as ChallengeGoalConditionOperator,
  );

  // Current Progress Point
  const currentProgressPoint: IProgressItem = {
    type: ProgressItemType.POINT,
    value: Math.max(
      goal?.conditionRangeFrom || 0,
      Math.min(progressValue, goal?.conditionRangeTo || 0),
    ),
    markerType: ProgressItemMarkerType.LEADING,
  };
  currentProgressPoint.tooltip = getPerformanceItemTooltip({
    data: currentProgressPoint,
    rewardType: goal?.rewardType as BonusChallengeRewardType,
    rewardAmount: (goal?.potentialIssuedAmount || 0) / 100,
    numbersFormat: goal?.type as NumberType,
    value: currentProgressPoint.value as number,
    type: ProgressItemType.RANGE,
  });

  // Current Progress Bar Range
  const currentProgressRange: IProgressItem = {
    type: ProgressItemType.RANGE,
    value: progressValueRangeOffset,
    color: progressRangeColor,
    inactive: isProgressRangeInactive,
    id: uuidv4(),
    isMainProgress: true,
  };

  currentProgressRange.tooltip = getPerformanceItemTooltip({
    data: currentProgressRange,
    rewardType: goal?.rewardType as BonusChallengeRewardType,
    rewardAmount: (goal?.potentialIssuedAmount || 0) / 100,
    numbersFormat: goal?.type as NumberType,
    value: currentProgressRange.value as number,
  });

  //Goal + Stretch Goals points
  const items: IProgressItem[] =
    sortedGoals?.map((item, index) => {
      const result: IProgressItem = {
        value: item?.conditionValue || 0,
        label: (item?.conditionValue || 0).toString(),
        type: ProgressItemType.POINT,
        inactive: (item?.conditionValue || 0) > progressValue,
      };
      result.tooltip = getPerformanceItemTooltip({
        data: result,
        rewardType: goal?.rewardType as BonusChallengeRewardType,
        rewardAmount: (item?.rewardAmount || 0) / 100,
        numbersFormat: goal?.type as NumberType,
        value: item?.conditionValue || 0,
        index: index + 1,
      });
      return result;
    }) || [];

  if ((goal?.progressValue || 0) > (goal?.conditionRangeFrom || 0)) {
    items.push(currentProgressRange);
  }

  result.push(...items, currentProgressPoint);

  if (showStep) {
    result.push({
      type: ProgressItemType.RANGE,
      value: 1,
      color: colors.bonusCardDetailedSmallPartBar,
    } as IProgressItem);
  }

  return result;
};

const getBoosters = (
  sortedMilestones: VestingScheduleItemDto[] = [],
  data: Partial<BonusResponseDto>,
) => {
  const currentDate = new Date(getNoonDate());
  //Milestones, not affected by boosters
  const ogMilestones = sortedMilestones.filter((item) => {
    return !item.modified;
  });

  // Boosters
  const lastOgMilestone = ogMilestones[ogMilestones.length - 1];

  const boosterMilestones = sortedMilestones.filter((item) => {
    return item.modified && moment(new Date(getNoonDate(item.date))).isAfter(currentDate);
  });

  if (!boosterMilestones?.length)
    return {
      ogMilestones,
    };

  const boosterEdgeMilestone = boosterMilestones?.[0];

  const boosterEdgeTotalSum =
    (boosterEdgeMilestone?.amount || 0) + (boosterEdgeMilestone?.displayedAmount || 0);
  const boosterEdgeWholeTimespan =
    getDaysBetweenDates(
      new Date(getNoonDate(lastOgMilestone ? lastOgMilestone?.date : data?.vestingStartDate)),
      new Date(getNoonDate(boosterEdgeMilestone.date)),
    ) || 0;
  const boosterEdgeCompletionPercentage = boosterEdgeTotalSum
    ? ((boosterEdgeMilestone?.displayedAmount || 0) / boosterEdgeTotalSum) * 100
    : 0;

  // "Forgiven" days
  const boosterEdgeCompletedTimespan = floor(
    (boosterEdgeWholeTimespan * boosterEdgeCompletionPercentage) / 100,
  );

  const boosterFullyForgivenMilestones = boosterMilestones.slice(1);
  const boosterFullyForgivenMilestonesTimespan = boosterFullyForgivenMilestones?.length
    ? getDaysBetweenDates(
        new Date(getNoonDate(boosterEdgeMilestone?.date)),
        new Date(
          getNoonDate(
            boosterFullyForgivenMilestones[boosterFullyForgivenMilestones.length - 1]?.date,
          ),
        ),
      )
    : 0;

  const boosterTotalOffset = boosterEdgeCompletedTimespan + boosterFullyForgivenMilestonesTimespan;

  const boosterRange: IProgressItem = {
    type: ProgressItemType.RANGE,
    value: boosterTotalOffset,
    color: getProgressRangeColor(data),
    isBooster: true,
    id: uuidv4(),
  };

  const rewardMilestones = (data?.vestingSchedule || [])
    .filter((item) => item.type === VestingMilestoneType.REWARD)
    .sort((a, b) => new Date(a.date || "").getTime() - new Date(b.date || "").getTime());

  const boosterMilestonesPoints =
    boosterMilestones?.map((item, index) => {
      const date = new Date(getNoonDate(item.date));
      const result: IProgressItem = {
        type: ProgressItemType.POINT,
        value: date,
        tooltip: getTimeItemTooltip({
          type: ProgressItemType.POINT,
          activityType: item.amount
            ? ProgressItemActivityType.ACTIVE
            : ProgressItemActivityType.BOOSTER_VESTED,
          amount: (item.amount || item.displayedAmount || 0) / 100,
          date: date,
          index: (ogMilestones?.length || 0) + index + 1,
        }),
      };
      return result;
    }) || [];

  const boosterRewardDatePoints =
    rewardMilestones?.map((item, index) => {
      const date = new Date(getNoonDate(item.date));
      const result: IProgressItem = {
        type: ProgressItemType.POINT,
        value: date,
        markerType: ProgressItemMarkerType.BOOSTER_REWARD,
        tooltip: getTimeItemTooltip({
          type: ProgressItemType.POINT,
          activityType: ProgressItemActivityType.BOOSTER_REWARD,
          amount: (item.amount || item.displayedAmount || 0) / 100,
          date: date,
          index: index + 1,
        }),
      };
      return result;
    }) || [];

  return {
    boosterTotalOffset,
    boosterRange,
    ogMilestones,
    boosterMilestones,
    boosterMilestonesPoints,
    boosterRewardDatePoints,
  };

  // Boosters - End
};

export const getTimeProgressBarData = (data: Partial<BonusResponseDto>): IProgressItem[] => {
  let result: IProgressItem[] = [];

  const startDate = new Date(getNoonDate(data?.vestingStartDate));
  const currentDate = new Date(getNoonDate());

  const sortedMilestones = (data?.vestingSchedule || [])
    .filter((item) => item.type === VestingMilestoneType.ORIGINAL_MILESTONE)
    .sort((a, b) => new Date(a.date || "").getTime() - new Date(b.date || "").getTime());

  const lastVestedMilestone = findLast(
    sortedMilestones || [],
    (item: VestingScheduleItemDto) => !!item.vested,
  );
  const lastVestedMilestoneDate = lastVestedMilestone
    ? new Date(getNoonDate(lastVestedMilestone.date))
    : undefined;

  //First unvested and last milestones
  const firstUnvestedMilestone = find(
    sortedMilestones || [],
    (item: VestingScheduleItemDto) => !item.vested,
  );
  const firstUnvestedMilestoneDate = firstUnvestedMilestone
    ? new Date(getNoonDate(firstUnvestedMilestone.date))
    : undefined;

  const lastMilestone = sortedMilestones[sortedMilestones.length - 1];
  const lastMilestoneDate = new Date(getNoonDate(lastMilestone.date));

  const totalRange = getDaysBetweenDates(startDate, lastMilestoneDate);

  const startDateIsAfterCurrentDate = currentDate < startDate;
  const currentMarkerDate = moment.max(moment(getNoonDate()), moment(startDate)).toDate();
  const currentDateIsBeforeLastMilestone = currentMarkerDate < lastMilestoneDate;

  const leadingPointDate = currentDateIsBeforeLastMilestone ? currentMarkerDate : lastMilestoneDate;

  //Boosters
  const boosters = getBoosters(sortedMilestones, data);
  const {
    ogMilestones,
    boosterMilestonesPoints,
    boosterRewardDatePoints,
    boosterRange,
    boosterTotalOffset,
  } = boosters;

  const daysUntilEndDateOffset = lastMilestoneDate
    ? getDaysBetweenDates(currentMarkerDate, lastMilestoneDate)
    : 0;

  const daysUntilBoosterOrEndDateOffset = lastMilestoneDate
    ? Math.max(daysUntilEndDateOffset - (boosterTotalOffset || 0), 0)
    : 0;

  // Current Progress Bar Range
  const progressValueRangeOffset = lastVestedMilestone
    ? getDaysBetweenDates(startDate, new Date(lastVestedMilestoneDate || ""))
    : 0;
  const progressValueRangeOffsetAfterBoosters = Math.min(
    totalRange - (boosterTotalOffset || 0),
    progressValueRangeOffset,
  );

  const progressRangeColor = getProgressRangeColor(data);

  if (progressValueRangeOffsetAfterBoosters) {
    const currentProgressRange: IProgressItem = {
      type: ProgressItemType.RANGE,
      value: progressValueRangeOffsetAfterBoosters,
      color: progressRangeColor,
      isMainProgress: true,
      id: uuidv4(),
      tooltip: getTimeItemTooltip({
        type: ProgressItemType.RANGE,
        activityType: ProgressItemActivityType.ACTIVE,
        amount: (data.vestedAmount || 0) / 100,
        nextMilestoneDate: firstUnvestedMilestoneDate,
      }),
    };

    result.push(currentProgressRange);
  }

  if (currentDateIsBeforeLastMilestone) {
    //Time until next milestone

    const startingPoint = lastVestedMilestoneDate || startDate;

    const timeFromPreviousMilestoneOffset =
      getDaysBetweenDates(startingPoint, currentMarkerDate) || 0;
    //TODO: change this daysToSpare logic later, it's not reliable. We need to definethe edge of booster and calculate from it
    const daysToSpare =
      totalRange - (boosterTotalOffset || 0) - (progressValueRangeOffsetAfterBoosters || 0);
    const timeFromPreviousMilestoneOffsetCalculated = Math.min(
      daysToSpare,
      timeFromPreviousMilestoneOffset,
    );

    const daysTillNextMilestone = firstUnvestedMilestoneDate
      ? getDaysBetweenDates(currentMarkerDate, firstUnvestedMilestoneDate) || 0
      : 0;

    if (timeFromPreviousMilestoneOffsetCalculated > 0) {
      result.push({
        type: ProgressItemType.RANGE,
        value: timeFromPreviousMilestoneOffsetCalculated,
        color: colors.performanceDetailsProgressBgInactive,
        id: uuidv4(),
        tooltip: getTimeItemTooltip({
          type: ProgressItemType.RANGE,
          activityType: ProgressItemActivityType.INACTIVE,
          daysTillNextMilestone: daysTillNextMilestone,
        }),
      });
    }

    //Time until end date
    result.push({
      type: ProgressItemType.RANGE,
      value: daysUntilBoosterOrEndDateOffset,
      color: "transparent",
      id: uuidv4(),

      tooltip: getTimeItemTooltip({
        type: ProgressItemType.RANGE,
        activityType: ProgressItemActivityType.EMPTY,
        amount: (data.unvestedAmount || 0) / 100,
        nextMilestoneDate: firstUnvestedMilestoneDate,
      }),
    });

    //Booster Range
    if (boosterRange) {
      result.push(boosterRange);
    }
  }

  //Milestone points
  const milestones: IProgressItem[] =
    ogMilestones?.map((item, index) => {
      const _daysValue = getDaysBetweenDates(
        new Date(getNoonDate(data.vestingStartDate)),
        new Date(getNoonDate(item.date)),
      );
      const isInactive = (_daysValue || 0) > progressValueRangeOffset;
      const date = new Date(getNoonDate(item.date));
      const result: IProgressItem = {
        type: ProgressItemType.POINT,
        value: date,
        inactive: isInactive,
        color: date === lastMilestoneDate ? "transparent" : undefined,
        tooltip: getTimeItemTooltip({
          type: ProgressItemType.POINT,
          activityType: isInactive
            ? ProgressItemActivityType.INACTIVE
            : ProgressItemActivityType.ACTIVE,
          amount: (item.amount || item.displayedAmount || 0) / 100,
          date: date,
          index: index + 1,
        }),
      };
      return result;
    }) || [];

  result = [
    ...result,
    ...(milestones || []),
    ...(boosterMilestonesPoints || []),
    ...(boosterRewardDatePoints || []),
  ];

  //Current time point
  const currentProgressActivityType =
    daysUntilEndDateOffset < 0
      ? ProgressItemActivityType.LEADING_FINISHED
      : startDateIsAfterCurrentDate
      ? ProgressItemActivityType.LEADING_NOT_STARTED_YET
      : ProgressItemActivityType.LEADING;

  const currentTimePoint: IProgressItem = {
    type: ProgressItemType.POINT,
    value: leadingPointDate,
    markerType: ProgressItemMarkerType.LEADING,
    tooltip: getTimeItemTooltip({
      type: ProgressItemType.POINT,
      activityType: currentProgressActivityType,
      date: currentMarkerDate,
      nextMilestoneDate: firstUnvestedMilestoneDate,
    }),
  };

  result.push(currentTimePoint);

  return result;
};

export const getUnitProgressBarData = (data: Partial<BonusResponseDto>): IProgressItem[] => {
  let result: IProgressItem[] = [];

  const totalQuantity = data.unitGoals?.[0].quantity || 0;
  const progressValueRangeOffset = Math.min(
    data.unitGoals?.[0].quantityCompleted || 0,
    totalQuantity,
  );

  const progressRangeColor = getProgressRangeColor(data);

  const sortedMilestones = data?.vestingSchedule || [];

  const unitMarkers = Array.from(Array(totalQuantity).keys(), (index, val) => val + 1);

  const firstUnvestedMilestoneIndex = sortedMilestones?.length
    ? findIndex(sortedMilestones || [], (item: VestingScheduleItemDto) => !item.vested)
    : findIndex(unitMarkers || [], (item) => item > progressValueRangeOffset);

  const firstUnVestedMilestoneQuantity = sortedMilestones?.length
    ? sortedMilestones
        .slice(0, firstUnvestedMilestoneIndex + 1)
        .reduce((curr, next) => curr + (next.unitsQuantity || 0), 0)
    : unitMarkers[firstUnvestedMilestoneIndex] || 0;

  const lastVestedMilestoneIndex = sortedMilestones?.length
    ? findLastIndex(sortedMilestones || [], (item: VestingScheduleItemDto) => !!item.vested)
    : findLastIndex(unitMarkers || [], (item) => item <= progressValueRangeOffset);

  const lastVestedMilestoneQuantity = sortedMilestones?.length
    ? sortedMilestones
        .slice(0, lastVestedMilestoneIndex + 1)
        .reduce((curr, next) => curr + (next.unitsQuantity || 0), 0)
    : unitMarkers[lastVestedMilestoneIndex] || 0;

  const fulfilledRangeOffset = lastVestedMilestoneQuantity;

  //Fulfilled range (green by default)
  if (fulfilledRangeOffset) {
    result.push({
      type: ProgressItemType.RANGE,
      value: fulfilledRangeOffset || 0,
      color: progressRangeColor,
      isMainProgress: true,
      tooltip: getUnitItemTooltip({
        type: ProgressItemType.RANGE,
        activityType: ProgressItemActivityType.ACTIVE,
        amount: (data.vestedAmount || 0) / 100,
        nextMilestoneQuantity: firstUnVestedMilestoneQuantity || 0,
      }),
    });
  }

  //markers, each representing 1 unit
  const markers: IProgressItem[] =
    unitMarkers.map((value, index) => {
      const isInactive = value > fulfilledRangeOffset;
      const result: IProgressItem = {
        type: ProgressItemType.POINT,
        value: value,
        inactive: isInactive,
        color: index === unitMarkers.length - 1 ? "transparent" : undefined,
        tooltip: getUnitItemTooltip({
          type: ProgressItemType.POINT,
          activityType: isInactive
            ? ProgressItemActivityType.INACTIVE
            : ProgressItemActivityType.ACTIVE,
          quantity: value || 0,
        }),
      };
      return result;
    }) || [];

  result = [...result, ...(markers || [])];

  //Time until next milestone
  if (fulfilledRangeOffset < progressValueRangeOffset) {
    result.push({
      type: ProgressItemType.RANGE,
      value: progressValueRangeOffset - fulfilledRangeOffset,
      color: colors.performanceDetailsProgressBgInactive,
      tooltip: getUnitItemTooltip({
        type: ProgressItemType.RANGE,
        activityType: ProgressItemActivityType.INACTIVE,
        nextMilestoneQuantity: firstUnVestedMilestoneQuantity || 0,
      }),
    });
  }

  //Gap between progress and end
  if (progressValueRangeOffset < totalQuantity) {
    result.push({
      type: ProgressItemType.RANGE,
      value: totalQuantity - progressValueRangeOffset,
      color: "transparent",
      id: uuidv4(),
      tooltip: getUnitItemTooltip({
        type: ProgressItemType.RANGE,
        activityType: ProgressItemActivityType.EMPTY,
        amount: (data.unvestedAmount || 0) / 100,
        nextMilestoneQuantity: firstUnVestedMilestoneQuantity || 0,
      }),
    });
  }

  //Current progress point
  const currentProgressActivityType =
    progressValueRangeOffset >= totalQuantity
      ? ProgressItemActivityType.LEADING_FINISHED
      : ProgressItemActivityType.LEADING;
  const currentProgressPoint: IProgressItem = {
    type: ProgressItemType.POINT,
    value: progressValueRangeOffset,
    markerType: ProgressItemMarkerType.LEADING,
    tooltip: getUnitItemTooltip({
      type: ProgressItemType.POINT,
      activityType: currentProgressActivityType,
      quantity: progressValueRangeOffset,
      nextMilestoneQuantity: firstUnVestedMilestoneQuantity || 0,
    }),
  };

  result.push(currentProgressPoint);

  return result;
};

export const getProgressBarData = (data: Partial<BonusResponseDto>): IProgressItem[] => {
  let result: IProgressItem[] = [];

  //TIME BONUSES
  if (data.type === BonusType.TIME) {
    result = getTimeProgressBarData(data);
  }

  //UNIT BONUSES
  if (data.type === BonusType.UNIT) {
    result = getUnitProgressBarData(data);
  }

  //CHALLENGES
  if (data.type === BonusType.CHALLENGE) {
    result = getChallengeProgressBarData(data);
  }

  return result;
};
