import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import CashIcon from "assets/png/icons/cash_icon.png";
import CongratulationsIcon from "assets/png/icons/congratulations_icon.png";
import MoneyFaceIcon from "assets/png/icons/money_face_icon.png";
import { ClockSmall, CloseIconThin, PlusThin } from "assets/svg";
import { find } from "lodash";

import { userMetadataSelector } from "redux/selectors";
import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { KycStatus, PayDistributionType } from "types/BETypes";
import { isKycAccepted, isKycPending, isKycRejected } from "helpers/bonus/kyc";
import { IProps as NavigationCardProps } from "components/NavigationCardWithDetails";
import { COMPANY_ACCOUNT_PROMOTION_POINTS } from "components/PaymentMethods/constants";
import SelectedBank from "components/SelectedBank";

import {
  GetPlaidBankAccountsResponseDto,
  queryPlaidControllerListBankAccounts,
  useQueryPlaidControllerListBankAccounts,
} from "utils/swagger_react_query";

import { CompanyEmphasized } from "../../styles";
import { EOptionIds, EWidgetType, IRootStateProps } from "../../types";
import {
  ACContentButton,
  CardSelectedBankContainer,
  CBACardContent,
  CBALogo,
  CBAMainContent,
  CBASIconContainer,
  CBASTextContainer,
  CBASubcard,
  CBCEButton,
  CBCEErrorText,
  CBCEIconContainer,
  CBCError,
  CBCIBlockError,
  CBCIBlockWarning,
  CBCInfo,
  CBCINumber,
  CBCIText,
  CBCWarning,
  CBCWIconContainer,
  CBCWText,
  CSBCText,
} from "./styles";

interface NavigationCardPropsExtended extends NavigationCardProps {
  isRecommended?: boolean;
}

const useRootState = (props: IRootStateProps) => {
  const translationPrefix = `components.payment_methods`;
  const {
    widgetType,
    showTitle = false,
    onCompanyBankAccountClick,
    onCompanyBankAccountSelect,
    onDirectDepositClick,
    onPaperCheckSelect,
  } = props;

  const currentUser = useSelector(userMetadataSelector);
  const selectedMethod = currentUser?.defaultPayDistribution?.type;
  const [isLoading, setLoading] = useState<boolean>(true);

  const activeOption = EOptionIds.COMPANY_BANK_ACCOUNT; //Test, remove later

  const { data: bankAccountsData, isLoading: bankAccountsLoading } =
    useQueryPlaidControllerListBankAccounts({
      enabled: true,
      //enabled: false, //TODO: Remove later
      cacheTime: 0,
    });

  const renderLargeCompanyBankAccountCardContent = () => {
    return (
      <CBACardContent>
        <CBALogo />
        <CBAMainContent>
          {COMPANY_ACCOUNT_PROMOTION_POINTS.map((item) => (
            <CBASubcard key={item.id}>
              <CBASIconContainer>{item.icon}</CBASIconContainer>
              <CBASTextContainer>{item.text}</CBASTextContainer>
            </CBASubcard>
          ))}
        </CBAMainContent>
      </CBACardContent>
    );
  };

  const getDirectDepositAlertsCount = () => {
    if (!bankAccountsData) return 0;
    return (
      (bankAccountsData || []).filter(
        (item: GetPlaidBankAccountsResponseDto) =>
          item.verificationStatus === BankAccountVerifyStatus.VERIFICATION_FAILED ||
          item.verificationStatus === BankAccountVerifyStatus.VERIFICATION_EXPIRED,
      )?.length || 0
    );
  };

  const getDirectDepositPendingCount = () => {
    if (!bankAccountsData) return 0;
    return (
      (bankAccountsData || []).filter((item: GetPlaidBankAccountsResponseDto) => {
        return (
          item.verificationStatus === BankAccountVerifyStatus.PENDING_AUTOMATIC_VERIFICATION ||
          item.verificationStatus === BankAccountVerifyStatus.PENDING_MANUAL_VERIFICATION
        );
      })?.length || 0
    );
  };

  const renderDirectDepositInfoBelowContent = () => {
    const _translationPrefix = `${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}`;
    const alerts = getDirectDepositAlertsCount();
    const pendings = getDirectDepositPendingCount();
    return (
      <>
        {!!(alerts || !!pendings) && (
          <CBCInfo>
            {!!alerts && (
              <CBCIBlockError>
                <CBCINumber>{alerts}</CBCINumber>
                <CBCIText>
                  <Trans i18nKey={`${_translationPrefix}.alert_label`} count={1} />
                </CBCIText>
              </CBCIBlockError>
            )}

            {!!pendings && (
              <CBCIBlockWarning>
                <CBCINumber>{pendings}</CBCINumber>
                <CBCIText>
                  <Trans i18nKey={`${_translationPrefix}.pending_verification_label`} />
                </CBCIText>
              </CBCIBlockWarning>
            )}
          </CBCInfo>
        )}
      </>
    );
  };

  const renderCompanyAccountWarningBelowContent = () => {
    const _translationPrefix = `${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}`;
    return (
      <CBCWarning>
        <CBCWIconContainer>
          <ClockSmall />
        </CBCWIconContainer>
        <CBCWText>
          <Trans i18nKey={`${_translationPrefix}.pending_verification_label`} />
        </CBCWText>
      </CBCWarning>
    );
  };

  const renderCompanyAccountErrorBelowContent = () => {
    const _translationPrefix = `${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}`;
    return (
      <CBCError>
        <CBCEIconContainer>
          <CloseIconThin />
        </CBCEIconContainer>
        <CBCEErrorText>
          <Trans i18nKey={`${_translationPrefix}.verification_failed_label`} />
        </CBCEErrorText>
        <CBCEButton onClick={onCompanyBankAccountClick}>
          <Trans i18nKey={`${_translationPrefix}.restart_verification_label`} />
        </CBCEButton>
      </CBCError>
    );
  };

  const renderCompanyAccountBelowBlock = () => {
    if (
      currentUser?.kycStatus === KycStatus.PENDING ||
      currentUser?.kycStatus === KycStatus.REVIEW ||
      currentUser?.kycStatus === KycStatus.PROVISIONAL
    ) {
      return renderCompanyAccountWarningBelowContent();
    } else if (currentUser?.kycStatus === KycStatus.REJECTED) {
      return renderCompanyAccountErrorBelowContent();
    } else {
      return undefined;
    }
  };

  //-------------------------------------------------------------------Company Account option config--------------------------------------------------//
  const getCompanyAccountCardConfig = () => {
    const isRecommended = widgetType === EWidgetType.ONBOARDING;
    //const isRecommended = true;
    const isActive = selectedMethod === PayDistributionType.PARTNER_ACCOUNT;

    let config: NavigationCardPropsExtended = {
      id: EOptionIds.COMPANY_BANK_ACCOUNT,
      title: (
        <Trans
          i18nKey={`${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.title`}
          components={{
            1: <CompanyEmphasized />,
          }}
        />
      ),
      description: (
        <Trans
          i18nKey={`${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.description`}
          components={{
            1: <CompanyEmphasized />,
          }}
        />
      ),
      onButtonClick: onCompanyBankAccountClick,
      isRecommended: isRecommended,
      altLabel:
        isRecommended && !isActive ? (
          <Trans i18nKey={`${translationPrefix}.recommended_label`} />
        ) : undefined,
      isActive: isActive,
      activeLabel: <Trans i18nKey={`${translationPrefix}.selected_label`} />,
      additionalContent: isRecommended ? renderLargeCompanyBankAccountCardContent() : undefined,
      belowContent: renderCompanyAccountBelowBlock(),
    };

    //Defining button texts and functionality
    if (
      !currentUser?.kycStatus &&
      currentUser?.defaultPayDistribution?.type !== PayDistributionType.PARTNER_ACCOUNT
    ) {
      config = {
        ...config,
        buttonText: (
          <Trans
            i18nKey={`${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.buttons.create`}
          />
        ),
        buttonIcon: <PlusThin />,
        onButtonClick: onCompanyBankAccountClick,
      };
    } else if (isKycPending(currentUser) || isKycRejected(currentUser)) {
      config = {
        ...config,
        buttonText: (
          <Trans
            i18nKey={`${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.buttons.info_alt`}
          />
        ),
        onButtonClick: onCompanyBankAccountClick,
      };
    } else if (isKycAccepted(currentUser)) {
      config = {
        ...config,
        buttonText: (
          <Trans
            i18nKey={`${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.buttons.select`}
          />
        ),
        isButtonDisabled: isActive,
        additionalContent: (
          <ACContentButton onClick={onCompanyBankAccountClick}>
            <Trans
              i18nKey={`${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.buttons.info`}
            />
          </ACContentButton>
        ),
        onButtonClick: onCompanyBankAccountSelect,
      };
    } else {
      config = {
        ...config,
        buttonText: (
          <Trans
            i18nKey={`${translationPrefix}.options.${EOptionIds.COMPANY_BANK_ACCOUNT}.buttons.info`}
          />
        ),
        onButtonClick: onCompanyBankAccountClick,
      };
    }

    return config;
  };

  //-------------------------------------------------------------------Direct Deposit option config--------------------------------------------------//
  const getDirectDepositCardConfig = () => {
    const isActive = selectedMethod === PayDistributionType.EXTERNAL_ACCOUNT;
    //const isActive = true;

    const defaultBank: GetPlaidBankAccountsResponseDto = find(
      bankAccountsData || [],
      (item: GetPlaidBankAccountsResponseDto) => !!item.isDefault,
    );

    let config: NavigationCardPropsExtended = {
      id: EOptionIds.DIRECT_DEPOSIT,
      title: (
        <Trans
          i18nKey={`${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}.title`}
          components={{
            1: <CompanyEmphasized />,
          }}
        />
      ),
      description: (
        <>
          <Trans
            i18nKey={`${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}.description`}
            components={{
              1: <CompanyEmphasized />,
            }}
          />
          {!!isActive && !!defaultBank && (
            <CardSelectedBankContainer>
              <CSBCText>
                <Trans
                  i18nKey={`${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}.selected_bank_label`}
                />
              </CSBCText>
              <SelectedBank bank={defaultBank} />
            </CardSelectedBankContainer>
          )}
        </>
      ),

      onButtonClick: () => {
        onDirectDepositClick?.(bankAccountsData || []);
      },
      isActive: isActive,
      activeLabel: <Trans i18nKey={`${translationPrefix}.selected_label`} />,
      belowContent: renderDirectDepositInfoBelowContent(),
    };

    //Defining button texts and functionality
    if (!bankAccountsData?.length) {
      config = {
        ...config,
        buttonText: (
          <Trans
            i18nKey={`${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}.buttons.add`}
          />
        ),
        buttonIcon: <PlusThin />,
      };
    } else {
      config = {
        ...config,
        buttonText: (
          <Trans
            i18nKey={`${translationPrefix}.options.${EOptionIds.DIRECT_DEPOSIT}.buttons.info`}
          />
        ),
      };
    }

    return config;
  };

  //-------------------------------------------------------------------Paper Check option config--------------------------------------------------//
  const getPaperCheckCardConfig = () => {
    const isActive = selectedMethod === PayDistributionType.CHECK;

    const config: NavigationCardPropsExtended = {
      id: "paper_check",
      title: (
        <Trans
          i18nKey={`${translationPrefix}.options.${EOptionIds.PAPER_CHECK}.title`}
          components={{
            1: <CompanyEmphasized />,
          }}
        />
      ),
      description: (
        <Trans
          i18nKey={`${translationPrefix}.options.${EOptionIds.PAPER_CHECK}.description`}
          components={{
            1: <CompanyEmphasized />,
          }}
        />
      ),
      buttonText: (
        <Trans i18nKey={`${translationPrefix}.options.${EOptionIds.PAPER_CHECK}.buttons.select`} />
      ),
      onButtonClick: onPaperCheckSelect,
      isActive: isActive,
      isButtonDisabled: isActive,
      activeLabel: <Trans i18nKey={`${translationPrefix}.selected_label`} />,
    };

    return config;
  };

  //---------------------------------------------------------------------Payment Options------------------------------------------------------------//
  const options: NavigationCardPropsExtended[] = [
    //getCompanyAccountCardConfig(),
    getDirectDepositCardConfig(),
    getPaperCheckCardConfig(),
  ];

  useEffect(() => {}, [bankAccountsData]);

  return {
    isLoading: !!bankAccountsLoading,
    options,
  };
};

export default useRootState;
