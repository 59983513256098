import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";
import WorkersList from "components/WorkersWidgets/WorkersList";
import { ETableVariants } from "components/WorkersWidgets/WorkersList/types";

import { ContentContainer, PageTitleMargined } from "uikit";

import { WorkersTableContainer } from "./styles";

interface IProps {}

const AdminPeople: FC<IProps> = () => {
  const translationPrefix = `people_page.index`;

  const { t } = useTranslation();

  return (
    <DashboardLayout>
      <ContentContainer>
        <PageTitleMargined>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </PageTitleMargined>

        <WorkersTableContainer>
          <WorkersList type={ETableVariants.PEOPLE_LIST} />
        </WorkersTableContainer>
      </ContentContainer>
    </DashboardLayout>
  );
};

export default AdminPeople;
