import { ReactNode } from "react";
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from "react-router-dom";
import { ProtectedRoute, ServiceRoute, UnauthorizedRoute } from "routes/routeGuards";

import { Pages } from "permissions";
import IconsPage from "pages/_debug/IconsPage";
import {
  AdminSignUpPage,
  CheckEmailPage,
  EmployeeSignUp,
  SignInPage,
  SignUpPage,
} from "pages/Auth";
import {
  EmployeeBankingKycPage,
  EmployeeBankingPage,
  EmployeeBankingShippingPage,
} from "pages/Banking";
import { EmployeeCardsDetailsPage, EmployeeTransferFundsPage } from "pages/Banking/employee";
import InsufficientPermissionsPage from "pages/InsufficientPermissionsPage";
import { AdminProfilePage, EmployeeProfilePage } from "pages/MyProfile";
import Page404 from "pages/Page404";
import { AdminPayPage, EmployeePayPage } from "pages/Pay";
import { AdminEditWorkerPage, AdminPeoplePage, AdminWorkerDetailsPage } from "pages/People";
import {
  AdminBankAccountsPage,
  AdminDocumentsPage,
  AdminPayrollAndBenefitsPage,
  AdminSettingsPage,
  AdminTeamMembersPage,
  EmployeeBankAccountsPage,
  EmployeeBankInfoPage,
  EmployeePaidBankAccountsPage,
  EmployeeSettingsPage,
} from "pages/Settings";
import { AdminSetupPage, EmployeeSetupPage } from "pages/Setup";

import {
  getAdminPagesBlockCondition,
  getEmployeePagesBlockCondition,
  useRouteHelpers,
} from "./helpers";
import routes from "./routes";
import { IRouterConfigItem } from "./types";

export const useRouterConfig = () => {
  const { getDefaultRoute, shouldShowEmployeeOnboardingPage, shouldShowAdminOnboardingPage } =
    useRouteHelpers();

  const RouterConfig: IRouterConfigItem[] = [
    {
      path: "/",
      page: <Navigate to={getDefaultRoute()} />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />, //Using Protected Route, because most of routing logic is put there
    },
    //Auth
    {
      path: routes.SIGN_IN,
      page: <SignInPage />,
      guard: (routeProps) => <UnauthorizedRoute {...routeProps} />,
    },
    {
      path: routes.SIGN_IN_COMPLETE,
      page: <SignInPage />,
    },

    {
      path: routes.SIGN_UP,
      page: <SignUpPage />,
      guard: (routeProps) => <UnauthorizedRoute {...routeProps} />,
    },
    {
      path: routes.SIGN_UP_COMPLETE,
      page: <SignUpPage />,
    },

    //Admin pages
    {
      path: routes.ADMIN_SIGN_UP,
      page: <AdminSignUpPage />,
    },
    {
      path: routes.CHECK_EMAIL,
      page: <CheckEmailPage />,
      guard: (routeProps) => <UnauthorizedRoute {...routeProps} />,
    },

    {
      path: routes.ADMIN_SETUP,
      page: <AdminSetupPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_SETUP,
      disableCondition: (...args) => !shouldShowAdminOnboardingPage(...args),
    },
    {
      path: routes.PEOPLE,
      page: <AdminPeoplePage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PEOPLE,
    },
    {
      path: routes.PEOPLE_EDIT_WORKER,
      page: <AdminEditWorkerPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PEOPLE_EDIT_WORKER,
    },
    {
      path: routes.PEOPLE_WORKER_DETAILS,
      page: <AdminWorkerDetailsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PEOPLE_WORKER_DETAILS,
    },
    {
      path: routes.ADMIN_PAY,
      page: <AdminPayPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_PAY,
    },
    {
      path: routes.ADMIN_PROFILE,
      page: <AdminProfilePage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_PROFILE,
    },
    {
      path: routes.ADMIN_SETTINGS,
      page: <AdminSettingsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_SETTINGS,
    },
    {
      path: routes.ADMIN_BANK_ACCOUNTS,
      page: <AdminBankAccountsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_BANK_ACCOUNTS,
      disableCondition: getAdminPagesBlockCondition,
    },
    {
      path: routes.DOCUMENTS,
      page: <AdminDocumentsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.DOCUMENTS,
    },
    {
      path: routes.ADMIN_TEAM_MEMBERS,
      page: <AdminTeamMembersPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.ADMIN_TEAM_MEMBERS,
    },
    {
      path: routes.PAYROLL_AND_BENEFITS,
      page: <AdminPayrollAndBenefitsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.PAYROLL_AND_BENEFITS,
      disableCondition: getAdminPagesBlockCondition,
    },

    //Employee pages
    {
      path: routes.EMPLOYEE_SIGN_UP,
      page: <EmployeeSignUp />,
    },
    {
      path: routes.EMPLOYEE_PAY,
      page: <EmployeePayPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_PAY,
      disableCondition: getEmployeePagesBlockCondition,
    },
    {
      path: routes.EMPLOYEE_PROFILE,
      page: <EmployeeProfilePage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_PROFILE,
    },
    {
      path: routes.EMPLOYEE_SETUP,
      page: <EmployeeSetupPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETUP,
      disableCondition: (...args) => !shouldShowEmployeeOnboardingPage(...args),
    },
    {
      path: routes.EMPLOYEE_SETTINGS,
      page: <EmployeeSettingsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETTINGS,
      disableCondition: getEmployeePagesBlockCondition,
    },
    {
      path: routes.EMPLOYEE_BANK_ACCOUNTS,
      page: <EmployeeBankAccountsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_BANK_ACCOUNTS,
      disableCondition: getEmployeePagesBlockCondition,
    },
    {
      path: routes.EMPLOYEE_SETTINGS_BANK_INFO,
      page: <EmployeeBankInfoPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_SETTINGS,
      disableCondition: getEmployeePagesBlockCondition,
    },
    {
      path: routes.EMPLOYEE_PAID_BANK_ACCOUNTS,
      page: <EmployeePaidBankAccountsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
      permissions: Pages.EMPLOYEE_PAID_BANK_ACCOUNTS,
      disableCondition: getEmployeePagesBlockCondition,
    },
    // {
    //   path: routes.EMPLOYEE_BANKING,
    //   page: <EmployeeBankingPage />,
    //   guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    //   permissions: Pages.EMPLOYEE_BANKING,
    //   disableCondition: getEmployeePagesBlockCondition,
    // },
    // {
    //   path: routes.EMPLOYEE_BANKING_KYC,
    //   page: <EmployeeBankingKycPage />,
    //   guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    //   permissions: Pages.EMPLOYEE_BANKING,
    //   disableCondition: getEmployeePagesBlockCondition,
    // },
    // {
    //   path: routes.EMPLOYEE_BANKING_SHIPPING,
    //   page: <EmployeeBankingShippingPage />,
    //   guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    //   permissions: Pages.EMPLOYEE_BANKING,
    //   disableCondition: getEmployeePagesBlockCondition,
    // },
    // {
    //   path: routes.EMPLOYEE_BANKING_CARDS,
    //   page: <EmployeeCardsDetailsPage />,
    //   guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    //   permissions: Pages.EMPLOYEE_BANKING,
    //   disableCondition: getEmployeePagesBlockCondition,
    // },
    // {
    //   path: routes.EMPLOYEE_TRANSFER_FUNDS,
    //   page: <EmployeeTransferFundsPage />,
    //   guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    //   permissions: Pages.EMPLOYEE_BANKING,
    //   disableCondition: getEmployeePagesBlockCondition,
    // },

    //Service
    {
      path: routes.PAGE_404,
      page: <Page404 />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    },
    {
      path: routes.INSUFFICIENT_PERMISSIONS,
      page: <InsufficientPermissionsPage />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    },
    {
      path: routes.DEBUG_ICONS_PAGE,
      page: <IconsPage />,
      guard: (routeProps) => <ServiceRoute {...routeProps} />,
    },
    {
      path: "*",
      page: <Page404 />,
      guard: (routeProps) => <ProtectedRoute {...routeProps} />,
    },
  ];

  const renderRouterConfig = () => {
    return RouterConfig.map((route) => {
      const { page, guard } = route;
      let Page: ReactNode = page;

      if (guard) {
        Page = guard({ ...route, children: Page });
      }

      return (
        <Route key={route.path} path={route.path} element={Page}>
          {route.children?.map((child) => {
            const { page, guard } = child;

            let ChildPage: ReactNode = child.page;

            if (guard) {
              ChildPage = guard({ ...child, children: page });
            }

            return <Route key={child.path} path={child.path} element={ChildPage} />;
          })}
        </Route>
      );
    });
  };

  const getCreatedRouter = () => {
    const Router = createBrowserRouter(createRoutesFromElements(renderRouterConfig()));
    return Router;
  };

  return {
    RouterConfig,
    renderRouterConfig,
    getCreatedRouter,
  };
};

export default useRouterConfig;
