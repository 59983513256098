import { FC } from "react";

import IconProps from "./IconProps";

const CheckmarkRoundEmpty: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9.53848"
      cy="9.53848"
      r="9.03848"
      fill="white"
      stroke="#DCDCDC"
    />
  </svg>
);

export default CheckmarkRoundEmpty;
