import { FC } from "react";
import { Trans } from "react-i18next";
import { GmailIcon } from "assets/svg";

import { Button, Text } from "./styles";

export interface Props {
  handleGoogleAuth: (e: any) => void;
  className?: string;
}

const GoogleSignInButton: FC<Props> = ({ handleGoogleAuth, className }) => {
  return (
    <Button onClick={(e) => handleGoogleAuth(e)} className={className}>
      <GmailIcon />
      <Text>
        <Trans i18nKey={`auth_pages.shared.components.google_sign_in_button.text`} />
      </Text>
    </Button>
  );
};

export default GoogleSignInButton;
