import { FC } from "react";
import { Trans } from "react-i18next";
import { Formik } from "formik";

import { ListEmployeesEmployeeResponseDto } from "utils/swagger_react_query";

import { Modal, Title } from "../styles";
import { InviteTeamMemberForm } from "./InviteTeamMemberForm";
import { InviteTeamMemberFormType, InviteTeamMemberValidationSchema } from "./validationSchema";

interface IProps {
  translationPrefix: string;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: InviteTeamMemberFormType) => void;
  employeesList: ListEmployeesEmployeeResponseDto[];
  handleSearch: (value: string) => void;
  fetchMoreUsers: () => void;
  hasMore: boolean;
  isLoading: boolean;
}

export const InviteTeamMemberModal: FC<IProps> = ({
  translationPrefix,
  isOpen,
  onClose,
  onSubmit,
  employeesList,
  handleSearch,
  fetchMoreUsers,
  hasMore,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()}>
      <Title>
        <Trans i18nKey={`${translationPrefix}.modals.invite_team_member.title`} />
      </Title>
      <Formik<InviteTeamMemberFormType>
        onSubmit={(values) => onSubmit(values)}
        validateOnChange={false}
        initialValues={{ email: "" }}
        validationSchema={InviteTeamMemberValidationSchema}
      >
        <InviteTeamMemberForm
          translationPrefix={translationPrefix}
          onCancel={() => onClose()}
          employeesList={employeesList}
          handleSearch={handleSearch}
          fetchMoreUsers={fetchMoreUsers}
          hasMore={hasMore}
          isLoading={isLoading}
        />
      </Formik>
    </Modal>
  );
};
