import { FC } from "react";

import IconProps from "./IconProps";

const People: FC<IconProps> = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_6573_250)">
      <path
        d="M1.46283 17.0283C1.81327 13.9673 2.8354 12.4567 4.88761 11.9814C5.2115 11.9071 5.44248 11.623 5.45575 11.2912C5.46637 10.9593 5.25398 10.662 4.9354 10.5664C3.55487 10.1469 2.62832 8.89647 2.62832 7.45223C2.62832 5.65754 4.08849 4.19736 5.88318 4.19736C7.67788 4.19736 9.13805 5.65754 9.13805 7.45223C9.13805 8.89647 8.2115 10.1469 6.83097 10.5664C6.51239 10.662 6.3 10.9593 6.31062 11.2912C6.32124 11.623 6.55487 11.9071 6.87876 11.9814C8.93097 12.4593 9.95575 13.9673 10.3035 17.0283C10.3487 17.4319 10.7124 17.7213 11.1159 17.6735C11.5195 17.6283 11.8062 17.2646 11.7611 16.8611C11.5434 14.9443 10.9858 12.4407 8.80089 11.169C9.92124 10.2956 10.6062 8.94426 10.6062 7.44957C10.6062 4.8478 8.48761 2.72922 5.88584 2.72922C3.28407 2.72922 1.16018 4.8478 1.16018 7.44957C1.16018 8.94426 1.84513 10.2956 2.96549 11.169C0.780531 12.4434 0.223008 14.9469 0.00530927 16.8637C-0.0398235 17.2673 0.249557 17.6283 0.650442 17.6761C0.679646 17.6788 0.706194 17.6814 0.735398 17.6814C1.10442 17.6814 1.42035 17.4053 1.46283 17.031V17.0283Z"
        fill="#5F5F5F"
      />
      <path
        d="M15.0345 8.43982C16.1548 7.56637 16.8398 6.21504 16.8398 4.72035C16.8398 2.11858 14.7239 0 12.1195 0C10.7947 0 9.52299 0.562832 8.63095 1.54248C8.3575 1.84248 8.37874 2.30442 8.67874 2.57788C8.97874 2.85133 9.44069 2.83009 9.71414 2.53009C10.3301 1.85575 11.2062 1.46814 12.1195 1.46814C13.9141 1.46814 15.3743 2.92832 15.3743 4.72301C15.3743 6.16726 14.4478 7.4177 13.0672 7.83717C12.7487 7.93274 12.5363 8.23009 12.5469 8.56195C12.5575 8.8938 12.7911 9.17787 13.115 9.25221C15.1672 9.73009 16.192 11.2381 16.5398 14.2991C16.5823 14.6734 16.9009 14.9496 17.2672 14.9496C17.2938 14.9496 17.323 14.9496 17.3522 14.9442C17.7557 14.8991 18.0425 14.5354 17.9973 14.1319C17.7796 12.215 17.2221 9.7115 15.0371 8.43982H15.0345Z"
        fill="#5F5F5F"
      />
    </g>
    <defs>
      <clipPath id="clip0_6573_250">
        <rect width="18" height="17.6788" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default People;
