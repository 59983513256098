export const peoplePage = {
  "en-US": {
    admin: {
      index: {
        title: "",
      },
    },
    employee: {
      index: {
        title: "Payment History",
      },
    },
  },
};

export default peoplePage;
