import { FC } from "react";

import IconProps from "./IconProps";

const PayIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clip-path="url(#clip0_5015_16871)">
      <path
        d="M17.076 2.93444C15.2616 1.12113 12.7643 0 10.0061 0C7.2479 0 4.73845 1.12113 2.92647 2.93444C1.12181 4.738 0 7.24348 0 10.0049C0 12.7663 1.12181 15.262 2.92647 17.0753C4.74089 18.8886 7.2357 20 10.0061 20C12.7765 20 15.264 18.8886 17.076 17.0753C18.8806 15.262 20.0024 12.7663 20.0024 10.0049C20.0024 7.24348 18.8806 4.74043 17.076 2.93444ZM15.5249 15.5228C14.1154 16.9413 12.1619 17.8089 10.0061 17.8089C7.85026 17.8089 5.88953 16.9413 4.4775 15.5228C3.06792 14.1116 2.18998 12.1594 2.18998 10.0049C2.18998 7.85035 3.06548 5.89569 4.4775 4.48696C5.88709 3.06849 7.84051 2.19108 10.0061 2.19108C12.1717 2.19108 14.1154 3.06605 15.5249 4.48696C16.9345 5.89812 17.8125 7.85035 17.8125 10.0049C17.8125 12.1594 16.937 14.1141 15.5249 15.5228Z"
        fill="#007D7B"
      />
      <path
        d="M9.37408 15.0364V13.8177C8.52052 13.7836 7.67916 13.5423 7.20117 13.2694L7.58161 11.7753C8.12301 12.0654 8.87902 12.3359 9.71062 12.3359C10.4398 12.3359 10.9422 12.0483 10.9422 11.5438C10.9422 11.0393 10.53 10.7541 9.57893 10.4348C8.20837 9.97177 7.27677 9.33078 7.27677 8.0951C7.27677 6.96178 8.06692 6.0795 9.44236 5.81384V4.59766H10.7007V5.72854C11.547 5.75778 12.125 5.94545 12.5566 6.14774L12.1762 7.59303C11.8518 7.44679 11.2568 7.15188 10.3374 7.15188C9.51308 7.15188 9.24239 7.51747 9.24239 7.87575C9.24239 8.29251 9.68867 8.56792 10.7739 8.97007C12.2981 9.50626 12.9078 10.2106 12.9078 11.3707C12.9078 12.5309 12.1079 13.4717 10.6373 13.7251V15.0388H9.37895L9.37408 15.0364Z"
        fill="#007D7B"
      />
    </g>
    <defs>
      <clipPath id="clip0_5015_16871">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default PayIcon;
