import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import routes from "routes/routes";

import actions from "redux/actions";
import { salsaSelector } from "redux/selectors";
import { getSalsaAuthToken } from "services/salsa";
import { UserRole } from "types/BETypes";
import { ICreateInstanceParams, SalsaExtendedWindow } from "types/Salsa";
import { showErrorModal } from "helpers";

import { UserResponseDto } from "utils/swagger_react_query";

import { useScript } from "./useScript";

export const useConnectSalsa = () => {
  const salsaData = useSelector(salsaSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useScript(process.env.REACT_APP_SALSA_SCRIPT_URL || "", "Salsa");

  // const showSalsaError = () => {
  //   showErrorModal("salsaError", () => {
  //     navigate(routes.SIGN_IN); //Pick adequate route later
  //   });
  // };

  const connectSalsa = async (user: UserResponseDto, forceDispatchSalsaData?: boolean) => {
    if (user) {
      let _salsa = null;
      if ((window as unknown as SalsaExtendedWindow).Salsa) {
        if (!salsaData?.salsa || !salsaData?.authToken || forceDispatchSalsaData) {
          const _payload: ICreateInstanceParams = {
            env: process.env.REACT_APP_SALSA_ENV_KEY || "",
          };
          _salsa = (window as unknown as SalsaExtendedWindow).Salsa(
            process.env.REACT_APP_SALSA_PUBLIC_CLIENT_KEY || "",
            _payload,
          );

          const _tokenRes = await getSalsaAuthToken(user.lastActiveRole as UserRole);
          if (_tokenRes) {
            dispatch(
              actions.salsaData.setSalsa({
                salsa: _salsa || salsaData?.salsa,
                authToken: _tokenRes,
              }),
            );
          }
        }
      }
    }
  };

  return {
    salsa: salsaData?.salsa,
    connectSalsa,
  };
};
