import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, FullScreenLoader } from "uikit";

import { useAdminSetup } from "./useAdminSetup";

interface IProps {}

const AdminSetupPage: FC<IProps> = ({}) => {
  const translationPrefix = `admin_setup_page`;

  const { isLoading, renderContent } = useAdminSetup();

  return (
    <DashboardLayout>
      {isLoading && <FullScreenLoader />}
      <ContentContainer>{renderContent()}</ContentContainer>
    </DashboardLayout>
  );
};

export default AdminSetupPage;
