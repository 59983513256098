import { FC, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormikProps } from "formik";

import { MAX_INPUT_LENGTH } from "constants/";
import { getFieldError, getFileBaseName } from "helpers/";

import { ImageUploader, Input, PhoneInput } from "uikit";

import { AdminProfileFormType } from "./validationSchema";
import { SubmitBtn, Wrapper } from "./styles";

interface IProps {
  translationPrefix: string;
  formikProps: FormikProps<AdminProfileFormType>;
  onTouch: (state: boolean) => void;
}

export const AdminProfileForm: FC<IProps> = ({ translationPrefix, formikProps, onTouch }) => {
  const { t } = useTranslation();
  const contactTranslationPrefix = `${translationPrefix}.admin_form_labels`;
  const {
    handleChange,
    values,
    errors,
    setFieldValue,
    setFieldError,
    setValues,
    initialValues,
    dirty,
  } = formikProps;

  useEffect(() => {
    onTouch(!dirty);
  }, [dirty]);

  return (
    <Wrapper>
      <div>
        <Input
          label={<Trans i18nKey={`${contactTranslationPrefix}.first_name`} />}
          name="firstName"
          value={values.firstName}
          onChange={(e) => setFieldValue("firstName", e.currentTarget.value.trimStart())}
          onBlur={(e) => setFieldValue("firstName", e.currentTarget.value.trimEnd())}
          error={getFieldError("firstName", formikProps, {
            field: t(`${contactTranslationPrefix}.first_name`),
            maxInputLength: MAX_INPUT_LENGTH,
          })}
        />

        <Input
          label={<Trans i18nKey={`${contactTranslationPrefix}.last_name`} />}
          name="lastName"
          value={values.lastName}
          onChange={(e) => setFieldValue("lastName", e.currentTarget.value.trimStart())}
          onBlur={(e) => setFieldValue("lastName", e.currentTarget.value.trimEnd())}
          error={getFieldError("lastName", formikProps, {
            field: t(`${contactTranslationPrefix}.last_name`),
            maxInputLength: MAX_INPUT_LENGTH,
          })}
        />

        <Input
          optional
          label={<Trans i18nKey={`${contactTranslationPrefix}.job_title`} />}
          name="jobTitle"
          value={values?.jobTitle || ""}
          onChange={handleChange}
          error={getFieldError("jobTitle", formikProps, {
            field: t(`${contactTranslationPrefix}.job_title`),
            maxInputLength: MAX_INPUT_LENGTH,
          })}
        />

        <PhoneInput
          optional
          label={<Trans i18nKey={`${contactTranslationPrefix}.phone`} />}
          name="phone"
          value={values?.phone || ""}
          onChange={(value) => setFieldValue("phone", value)}
          error={getFieldError("phone", formikProps, {
            field: t(`${contactTranslationPrefix}.phone`),
          })}
        />

        <Input
          name="email"
          onChange={(e) => setFieldValue("email", e.currentTarget.value.split(" ").join(""))}
          value={values.email}
          label={<Trans i18nKey={`${contactTranslationPrefix}.email`} />}
          error={getFieldError("email", formikProps, {
            field: t(`${contactTranslationPrefix}.email`),
            maxInputLength: MAX_INPUT_LENGTH,
          })}
          disabled={true}
        />

        <SubmitBtn type="submit" disabled={!dirty}>
          <Trans i18nKey={`${translationPrefix}.save_changes_btn`} />
        </SubmitBtn>
      </div>

      <div></div>
    </Wrapper>
  );
};
