import { HELP_EMAIL } from "constants/";

export const authPages = {
  "en-US": {
    admin: {
      sign_up_page: {
        sign_in_step: {
          title: "Get Started",
          email_field: {
            placeholder: "Enter your Email",
            title: "Email",
          },
          submit_button: "Sign Up",
          or_text: "Or",
          navigate_text: "Already have an account?",
          navigate_btn: "Log in",
        },
        business_details_step: {
          title: "Company Information",
          description: "Let's set up your company account.",
          company_name_field: {
            title: "Company Name",
            placeholder: "Enter your Company Name",
          },
        },
        contact_details_step: {
          title: "Personal Information",
          description: "Tell us a little about yourself.",
          first_name: "First Name",
          last_name: "Last Name",
          job_title: "Title",
          phone: "Phone Number",
        },
      },
    },
    employee: {
      sign_up_page: {
        title: "Get Started ",
        description: "Welcome to Paid. Let’s get you set up.",
        email_label: "Create an account using the email:",
        accept_invitation_btn: "Accept Invitation",
      },
      layout_left_section: {
        title: "Keep more of the money you earn.",
        description:
          "Paid is a financial wellness resource for employees - payroll deposits, seamless banking services, and more",
        features: {
          0: "Get your pay up to 2 days early",
          1: "Free Paid account, debit & virtual Cards",
          2: "Earn money on your deposits",
        },
      },
    },

    shared: {
      error_modals: {
        account_was_disabled: {
          title: "Your account was disabled",
        },
        session_has_expired: {
          title: "Session has expired.",
          message: "Your session has expired. Please Login again to access your Keep dashboard.",
        },
        too_many_attempts: {
          title: "Too many log-in attempts",
          message: `Please check your email including your spam and trash folders for the login link. You can try again in 30 mins or contact us at <1>${HELP_EMAIL}</1> for help.`,
        },
      },
      terms_and_policy_disclaimer:
        "By continuing you are accepting our <1>Terms of Services</1>, <2>Privacy Policy</2> and <3>eSign agreement</3>.",
      default_layout_left_section: {
        title: "Payroll that pays you back.",
        description:
          "A simple payroll platform that works for every business. Start running your payroll in minutes!",
        features: {
          0: "Comprehensive and flexible payroll",
          1: "Automated tax filing",
          2: "Employee banking and benefits",
        },
      },
      sign_in_page: {
        title: "Sign In",
        description: "Please Enter your Details",
        email_field: {
          placeholder: "Enter your Email",
          title: "Email",
        },
        submit_button: "Sign In",
        or_text: "Or",
        navigate_text: "Need a Company Account?",
        navigate_btn: "Create Account",
      },
      check_email_page: {
        title: "Check your Email",
        description: "We sent you an email. Click on the link in the email to sign in.",
        email_label: "Your Email:",
        buttons: [
          "Google Accept Invitation",
          "Yahoo Accept Invitation",
          "Outlook Accept Invitation",
        ],
        instructions: "Didn’t receive an email? Check your spam folder or",
        resend_email: "Resend Email {{time}}",
        open_gmail: "Open Gmail",
        open_yahoo: "Open Yahoo",
        open_outlook: "Open Outlook",
      },
      components: {
        google_sign_in_button: {
          text: "Continue with Google",
        },
      },
    },
  },
};

export default authPages;
