import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";
import { BankAccountSection, IBankAccountSectionProps } from "components/BankAccountSection";

import { ContentContainer, FullScreenLoader, PageTitleMargined } from "uikit";

import { Header } from "./styles";

interface IProps {
  isLoading: boolean;
  bankAccountSectionProps: IBankAccountSectionProps;
  handleBackBtnClick: () => void;
}

const AdminBankAccountsPage: FC<IProps> = ({
  isLoading,
  bankAccountSectionProps,
  handleBackBtnClick,
}) => {
  const translationPrefix = "settings_pages.shared.bank_accounts_page";

  return (
    <DashboardLayout showResponsiveMenu={false} onBackBtnClick={() => handleBackBtnClick()}>
      {isLoading ? (
        <FullScreenLoader />
      ) : (
        <ContentContainer>
          <Header>
            <PageTitleMargined>
              <Trans i18nKey={`${translationPrefix}.title`} />
            </PageTitleMargined>
          </Header>

          <BankAccountSection {...bankAccountSectionProps} />
        </ContentContainer>
      )}
    </DashboardLayout>
  );
};

export default AdminBankAccountsPage;
