import { FC } from "react";

import DashboardLayout from "layouts/DashboardLayout";
import PaidBankAccountCreatingWidget from "components/PaidBankAccountCreatingWidget";

import { FullScreenLoader } from "uikit";

import { useEmployeePaidBankAccountsPage } from "./useEmployeePaidBankAccountsPage";
import { Container } from "./styles";

interface IProps {}

const EmployeePaidBankAccountsPage: FC<IProps> = ({}) => {
  const { metadata, actions } = useEmployeePaidBankAccountsPage();

  return (
    <DashboardLayout onBackBtnClick={actions.handleNavigateToPreviousPage}>
      {metadata.isLoading && <FullScreenLoader />}
      <Container>
        <PaidBankAccountCreatingWidget />
      </Container>
    </DashboardLayout>
  );
};

export default EmployeePaidBankAccountsPage;
