export const getFormattedPhoneNumber = (value: string) => {
  if (value?.slice(0, 2) === "+1") return value;
  if (value?.slice(0, 1) === "+") return `+1${value.slice(1)}`;
  return `+1${value}`;
};

export const getPhoneNumberOrNull = (value: string) => {
  //We have prefilled the country code in phone number input, so if the user doesn't enter any number, we should return null and send null to API.
  if (value?.length <= 2) return null;
  return value;
};
