export const SUPPORT_EMAIL = "help@keepfinancial.com";

export const API_KEY_HEADER_NAME = "X-Keep-Api-Key";

export const AUTH_TOKEN_COOKIE_NAME = "hellopaid-auth-token";

export const TARGET_COUNTRY_ALPHA2 = "US";

export const KEEP_DATE_FORMAT = "MM/dd/yyyy";
export const SYNCTERA_DATE_FORMAT = "yyyy-MM-dd";

export const MIN_BONUS_START_DATE = "2020-01-01T00:00:00.000Z";

export const PAYBACK_MAX_DAYS = 120;
export const MAX_TEXT_LENGTH = 2000;

export const MAX_BONUS_AMOUNT = 1_000_000_000_00;

export const MINUTE = 1000 * 60;
export const HOUR = MINUTE * 60;
export const DAY = HOUR * 24;

export const PERIOD_BETWEEN_APPROVAL_AND_FUNDING = 0;
export const FUNDING_DUE_BUSINESS_DAYS = 5;

export const WELCOME_EMAIL_MAX_LIMIT = 5;

export const BONUS_PENDING_REMINDERS_LIMIT = 10;

export const AVAILABLE_FUNDS_REMINDERS_LIMIT = 10;

export const NUMBER_OF_LAST_ADDED_ADMINS = 4;

export const DEFAULT_TERMINATION_FORGOING_AMOUNT_PERCENT = 0;
export const DEFAULT_TERMINATION_ZERO_INTEREST_DAYS = 60;

export const BONUS_AMOUNT_LIMIT_IN_NM_STATE = 10000_00;
export const sideLenderBonusStates = [
  "CA",
  "ID",
  "SD",
  "OH",
  "AL",
  "MO",
  "MS",
  "NV",
  "ND",
  "WA",
  "WI",
  "AL",
  "AZ",
  "GA",
  "IN",
  "OR",
  "WY",
  "VT",
];
export const spouseNotifRequiredStates = ["WI"];

export enum UserRole {
  OWNER = "OWNER",
  SUPERADMIN = "SUPERADMIN",
  ADMIN = "ADMIN",
  EMPLOYEE = "EMPLOYEE",
}

export enum KeepAdminRole {
  SUPERADMIN = "SUPERADMIN",
  ADMIN = "ADMIN",
}

export enum PerformanceRewardStatus {
  ACHIEVED = "ACHIEVED",
  NOT_ACHIEVED = "NOT_ACHIEVED",
  UNRESOLVED = "UNRESOLVED",
}

export enum RewardActionActionEnum {
  REDUCE_BALANCE_DUE = "reduceBalanceDue",
  REDUCE_SCHEDULE = "reduceSchedule",
}

export enum RewardActionArgMeasure {
  MONTH = "month",
  YEAR = "year",
  QUARTER = "quarter",
  CURRENCY = "currency",
}

export interface PerformanceRewardRewardAction<
  M extends RewardActionArgMeasure = RewardActionArgMeasure,
> {
  action: RewardActionActionEnum;
  arg: {
    measure: M;
    value: number;
  };
}

export enum Op {
  AND = "and",
  OR = "or",
}

export interface PerformanceRewardGoalsExpressionArgs {
  args: string[];
  $op: Op;
}

export interface PerformanceRewardGoalsExpression {
  $op: Op;
  args: PerformanceRewardGoalsExpressionArgs;
}

export interface CsvDataSchema {
  [key: string]: string | undefined;
}

interface KYCInputDataAddress {
  address?: string;
  city?: string;
  suite?: string;
  zip?: string;
  state?: string;
}

export interface KYCInputData {
  firstName: string;
  lastName: string;
  birthDate: Date;
  email: string;
  phone: string;
  address: string | KYCInputDataAddress;
  _address?: KYCInputDataAddress;
  socialSecurityNumber: string;
}

export enum KycStatus {
  PROVIDER_FAILURE = "PROVIDER_FAILURE",
  PENDING = "PENDING",
  PROVISIONAL = "PROVISIONAL",
  ACCEPTED = "ACCEPTED",
  REVIEW = "REVIEW",
  REJECTED = "REJECTED",
  UNVERIFIED = "UNVERIFIED",
  VENDOR_ERROR = "VENDOR_ERROR",
}

export enum KycErrors {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  FAIL = "FAIL",
  VALIDATION_FAIL = "VALIDATION_FAIL",
}

export enum UserStatus {
  PROSPECT = "PROSPECT",
  INVITED = "INVITED",
  ACTIVE = "ACTIVE",
  PENDING_EXIT = "PENDING_EXIT",
  EXITED = "EXITED",
  /**
   * Employee doesn't owe employer any bonus amount
   */
  NO_BALANCE_DUE = "NO_BALANCE_DUE",
  /**
   * Employee has a balance due on their forgivable loan and fails to make their minimum amount due payment within X days. The loan balance is considered “in-default” and is reported to the credit bureau
   */
  IN_DEFAULT = "IN_DEFAULT",
}

export const employeeExitStatuses = [
  UserStatus.PENDING_EXIT,
  UserStatus.NO_BALANCE_DUE,
  UserStatus.EXITED,
  UserStatus.IN_DEFAULT,
];

export enum EmployeeExitReason {
  RESIGNATION = "Resignation",
  TERMINATION = "Termination",
  DEATH = "Death",
  LONG_TERM_DISABILITY = "Long Term Disability",
  MILITARY_LEAVE = "Military Leave",
}

export enum EmployeeAddingMethod {
  MANUALLY = "MANUALLY",
  CSV_IMPORT = "CSV_IMPORT",
  HRIS = "HRIS",
}

export enum MaritalStatus {
  SINGLE = "SINGLE",
  MARRIED = "MARRIED",
}

export enum EmailType {
  PRIMARY = "PRIMARY",
  PERSONAL = "PERSONAL",
}

export enum MagicLinkAction {
  RECOVER_ACCOUNT = "RECOVER_ACCOUNT",
}

export enum DocumentType {
  SERVICE_AGREEMENT = "SERVICE_AGREEMENT",
  BONUS_CONTRACT = "BONUS_CONTRACT",
  REFINANCE_LOAN_AGREEMENT = "REFINANCE_LOAN_AGREEMENT",
}

export enum Currency {
  USD = "USD",
}

export enum PeriodUnit {
  MONTH = "month",
  QUARTER = "quarter",
  YEAR = "year",
}

export const periodUnitMonthsNumber: Record<PeriodUnit, number> = {
  [PeriodUnit.MONTH]: 1,
  [PeriodUnit.QUARTER]: 3,
  [PeriodUnit.YEAR]: 12,
};

export enum SpecialPaymentPlan {
  ONE_YEAR = "ONE_YEAR",
  THREE_YEARS = "THREE_YEARS",
}

export interface SpecialPaymentPlanConfig {
  lengthUnit: PeriodUnit;
  lengthValue: number;
  interestRate: number;
  lengthInMonth: number;
}

export const specialPaymentPlanConfigs: Record<SpecialPaymentPlan, SpecialPaymentPlanConfig> = {
  [SpecialPaymentPlan.ONE_YEAR]: {
    lengthUnit: PeriodUnit.YEAR,
    lengthValue: 1,
    interestRate: 5,
    lengthInMonth: 12,
  },
  [SpecialPaymentPlan.THREE_YEARS]: {
    lengthUnit: PeriodUnit.YEAR,
    lengthValue: 3,
    interestRate: 7,
    lengthInMonth: 36,
  },
};

export enum LoanSchedulePaymentStatus {
  NOT_PAYED = "NOT_PAYED",
  PAYED_FULL = "PAYED_FULL",
  PAYED_LESS = "PAYED_LESS",
  PAYED_MORE = "PAYED_MORE",
  SCHEDULE = "SCHEDULE",
}

export enum VestingScheduleType {
  EQUALLY = "EQUALLY",
  EQUALLY_AFTER_CLIFF = "EQUALLY_AFTER_CLIFF",
  CUSTOM = "CUSTOM",
  VEST_100_PERCENTS_ON_END_DATE = "VEST_100_PERCENTS_ON_END_DATE",
  UNIT_ON_EACH_UNIT = "UNIT_ON_EACH_UNIT",
  UNIT_EQUALLY = "UNIT_EQUALLY",
  UNIT_CUSTOM = "UNIT_CUSTOM",
  UNIT_VEST_100_PERCENTS_ON_END_DATE = "UNIT_VEST_100_PERCENTS_ON_END_DATE",
}

export enum TransactionEntityType {
  FUNDING = "FUNDING",
  WITHDRAWAL = "WITHDRAWAL",
  REPAYMENT = "REPAYMENT",
}

export enum TransactionType {
  TRANSFER = "TRANSFER",
}

export enum InternalTransactionStatus {
  /** ACHs PENDING|REPLACED|RELEASED */
  PENDING = "PENDING",
  /** ACHs DECLINED|EXPIRED|INTERNAL_ERROR */
  CANCELED = "CANCELED",
  /** ACHs PARTCLEARD|CLEARED */
  COMPLETE = "COMPLETE",
  RETURNED = "RETURNED",
}

export enum BonusType {
  TIME = "TIME",
  UNIT = "UNIT",
  CHALLENGE = "CHALLENGE",
}

export enum BonusStatus {
  /**
   * When an administrator creates a bonus but has not yet sent the bonus invitation to the employee.
   */
  DRAFT = "DRAFT",
  /**
   * When an employee is sent a bonus invitation but has yet to sign the contract, the bonus stays in a “Pending Bonus” state.
   */
  PENDING = "PENDING",
  /**
   * When an employee reviews the bonus terms and signs the contract, that bonus is updated into an “Accepted Bonus” state.
   */
  ACCEPTED = "ACCEPTED",
  /**
   * When a bonus has been accepted by an employee, the bonus is considered “Active” and the vesting schedule begins.
   */
  ACTIVE = "ACTIVE",
  /**
   * When the bonus has completed all the vesting milestones, it is considered a “Vested Bonus”.
   * Example: Employee receives a $20,000 bonus, that vests over 4 years. At the end of the 4 years, the loan would
   * have forgiven the full $20,000 to the employee and the loan would be considered a “Vested Bonus”.
   * Also, when challenge bonus is achieved
   */
  COMPLETED = "COMPLETED",
  /**
   * When challenge bonus is not achieved
   */
  NOT_COMPLETED = "NOT_COMPLETED",
  /**
   * When bonus was ACTIVE while employee exit
   */
  VESTING_STOPPED = "VESTING_STOPPED",
  /**
   * When the employee declines the Bonus, we show this stage.
   */
  DECLINED = "DECLINED",
  /**
   * When admin or employee void bonus via docusign or via app
   */
  VOIDED = "VOIDED",
}

export enum BonusEligibilityStatus {
  DRAFT = "DRAFT",
  LIVE = "LIVE",
  INACTIVE = "INACTIVE",
}

export enum BonusTrackingType {
  TEAM = "TEAM",
  INDIVIDUAL = "INDIVIDUAL",
}

export enum ExtensionBonusEligibilityStatus {
  PAST_DUE = "PAST_DUE",
}

export type EligibilityBonusStatusExtended =
  | BonusEligibilityStatus
  | ExtensionBonusEligibilityStatus;

export enum BonusLender {
  KEEP_CAPITAL = "Keep Capital",
  THREAD_BANK = "Thread Bank",
}

export interface LenderInfo {
  state: string;
  street: string;
  name: string;
  phone: string;
}

export const LenderAddress: Record<BonusLender, LenderInfo> = {
  [BonusLender.KEEP_CAPITAL]: {
    state: "Atlanta, GA, 30309",
    street: "925B Peachtree St NE #349",
    name: "Keep Capital, LLC",
    phone: "1-801-871-5337",
  },
  [BonusLender.THREAD_BANK]: {
    state: "TN, 37857",
    street: "210 East Main Street Rogersville",
    name: "Thread Bank",
    phone: "1-801-871-5337",
  },
};

export enum BonusFundingStatus {
  NOT_APPROVED = "NOT_APPROVED",
  APPROVED = "APPROVED",
  PAYMENT_DUE = "PAYMENT_DUE",
  FUNDED = "FUNDED",
  PAST_DUE = "PAST_DUE",
}

export enum ErrorConst {
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  REQUEST_IS_EMPTY = "REQUEST_IS_EMPTY",
  EMAIL_IS_MISSING = "EMAIL_IS_MISSING",

  AUTH_CODE_EXPIRED = "AUTH_CODE_EXPIRED",
  NO_AUTH_TOKEN = "NO_AUTH_TOKEN",
  TOKEN_NOT_VERIFIED = "TOKEN_NOT_VERIFIED",
  KEEP_API_KEY_NOT_VERIFIED = "KEEP_API_KEY_NOT_VERIFIED",
  SESSION_EXPIRED = "SESSION_EXPIRED",
  USER_EMAIL_NOT_FOUND = "USER_EMAIL_NOT_FOUND",
  USER_NOT_FOUND = "USER_NOT_FOUND",
  USER_WAS_NOT_INVITED = "USER_WAS_NOT_INVITED",
  USER_HAS_ALREADY_ACCEPT_INVITATION = "USER_HAS_ALREADY_ACCEPT_INVITATION",
  USER_INFO_NOT_FILLED = "USER_INFO_NOT_FILLED",
  INSUFFICIENT_PERMISSIONS = "INSUFFICIENT_PERMISSIONS",
  EMAIL_BELONGS_TO_COMPANY = "EMAIL_BELONGS_TO_COMPANY",
  EMAIL_BELONGS_TO_ANOTHER_USER = "EMAIL_BELONGS_TO_ANOTHER_USER",
  EMAIL_CANNOT_BE_CHANGED = "EMAIL_CANNOT_BE_CHANGED",
  COMPANY_NOT_FOUND = "COMPANY_NOT_FOUND",
  EMPLOYEE_HAS_BONUS = "EMPLOYEE_HAS_BONUS",
  UNSUPPORTED_EMPLOYEE_STATE = "UNSUPPORTED_EMPLOYEE_STATE",
  BONUS_NOT_FOUND = "BONUS_NOT_FOUND",
  BONUS_NOT_DRAFT = "BONUS_NOT_DRAFT",
  BONUS_IS_DRAFT = "BONUS_IS_DRAFT",
  BONUS_ALREADY_VOIDED = "BONUS_ALREADY_VOIDED",
  BONUS_NOT_FILLED = "BONUS_NOT_FILLED",
  BONUS_NOT_ACCEPTED = "BONUS_NOT_ACCEPTED",
  VESTING_PERIOD_TOO_SHORT = "VESTING_PERIOD_TOO_SHORT",
  MILESTONES_AMOUNT_NOT_EQUAL_BONUS_AMOUNT = "MILESTONES_AMOUNT_NOT_EQUAL_BONUS_AMOUNT",
  MILESTONES_MUST_BE_CONSISTENT = "MILESTONES_MUST_BE_CONSISTENT",
  MILESTONES_INTERVAL_MUST_BE_GTE_24H = "MILESTONES_INTERVAL_MUST_BE_GTE_24H",
  MILESTONE_DATE_CANNOT_BE_BEYOND_VESTING_PERIOD = "MILESTONE_DATE_CANNOT_BE_BEYOND_VESTING_PERIOD",
  LAST_MILESTONE_DATE_NOT_EQUAL_VESTING_END_DATE = "LAST_MILESTONE_DATE_NOT_EQUAL_VESTING_END_DATE",
  BONUS_START_DATE_CANNOT_BE_EARLIER_THAN_1_JAN_2020 = "BONUS_START_DATE_CANNOT_BE_EARLIER_THAN_1_JAN_2020",
  BONUS_CANNOT_BE_DELETED = "BONUS_CANNOT_BE_DELETED",
  BONUS_CANNOT_BE_VOIDED = "BONUS_CANNOT_BE_VOIDED",
  BONUS_NOT_UNIT_TYPE = "BONUS_NOT_UNIT_TYPE",
  BONUS_UNIT_GOAL_NOT_FOUND = "BONUS_UNIT_GOAL_NOT_FOUND",
  BONUS_UNIT_GOALS_CANNOT_BE_UPDATED = "BONUS_UNIT_GOALS_CANNOT_BE_UPDATED",
  BONUS_SHOULD_CONTAIN_ONE_UNIT_GOAL = "BONUS_SHOULD_CONTAIN_ONE_UNIT_GOAL",
  MILESTONES_UNITS_NOT_EQUAL_BONUS_UNITS = "MILESTONES_UNITS_NOT_EQUAL_BONUS_UNITS",
  BONUS_END_DATE_NOT_EQUAL_UNIT_GOAL_END_DATE = "BONUS_END_DATE_NOT_EQUAL_UNIT_GOAL_END_DATE",
  DOCUMENT_NOT_FOUND = "DOCUMENT_NOT_FOUND",
  DOCUMENT_INVALID_STATUS = "DOCUMENT_INVALID_STATUS",
  DOCUMENT_INVALID_TYPE = "DOCUMENT_INVALID_TYPE",
  DOCUMENT_ALREADY_SIGNED = "DOCUMENT_ALREADY_SIGNED",
  DOCUMENT_ALREADY_SENT = "DOCUMENT_ALREADY_SENT",
  DOCUMENT_HAS_NO_RECIPIENTS = "DOCUMENT_HAS_NO_RECIPIENTS",
  DOCUMENT_RECIPIENT_NOT_FOUND = "DOCUMENT_RECIPIENT_NOT_FOUND",
  DOCUMENT_WRONG_SIGNERS_QUANTITY = "DOCUMENT_WRONG_SIGNERS_QUANTITY",
  DOCUMENT_MISSING_RECIPIENT = "DOCUMENT_MISSING_RECIPIENT",
  LEGAL_INFO_CANNOT_BE_UPDATED = "LEGAL_INFO_CANNOT_BE_UPDATED",
  SERVICE_AGREEMENT_NOT_SIGNED = "SERVICE_AGREEMENT_NOT_SIGNED",
  NO_SUCH_DEV_TEST = "NO_SUCH_DEV_TEST",
  BANK_ACCOUNT_NOT_FOUND = "BANK_ACCOUNT_NOT_FOUND",
  CANNOT_REMOVE_DEFAULT_BANK_ACCOUNT = "CANNOT_REMOVE_LAST_BANK_ACCOUNT",
  USER_HAS_NO_SYNCTERA_ACCOUNT = "USER_HAS_NO_SYNCTERA_ACCOUNT",
  USER_IS_NOT_AN_ADMIN = "USER_IS_NOT_AN_ADMIN",
  USER_BELONGS_TO_ANOTHER_ORGANIZATION = "USER_BELONGS_TO_ANOTHER_ORGANIZATION",
  USER_ALREADY_EXISTS = "USER_ALREADY_EXISTS",
  USER_ACCOUNT_WAS_DISABLED = "USER_ACCOUNT_WAS_DISABLED",
  NOT_ENOUGH_MONEY = "NOT_ENOUGH_MONEY",
  NOT_ENOUGH_INTERNAL_MONEY = "NOT_ENOUGH_INTERNAL_MONEY",
  CANNOT_WITHDRAW_LESS_THAN_AMOUNT_AVAILABLE = "CANNOT_WITHDRAW_LESS_THAN_AMOUNT_AVAILABLE",
  TRANSACTION_NOT_FOUND = "TRANSACTION_NOT_FOUND",
  TRANSACTION_CANNOT_BE_CANCELED = "TRANSACTION_CANNOT_BE_CANCELED",
  BONUS_NOT_INCLUDED_IN_FUND_WAITING_LIST = "BONUS_NOT_INCLUDED_IN_FUND_WAITING_LIST",
  TOTAL_AMOUNT_DOES_NOT_MATCH_ESTIMATED = "TOTAL_AMOUNT_DOES_NOT_MATCH_ESTIMATED",
  ADMIN_CAN_UPDATE_ONLY_HIS_OWN_PROFILE = "ADMIN_CAN_UPDATE_ONLY_HIS_OWN_PROFILE",
  LINK_TOKEN_EXPIRED = "LINK_TOKEN_EXPIRED",
  SYNCTERA_ERROR = "SYNCTERA_ERROR",
  FAILED_TO_CREATE_TRANSACTION = "FAILED_TO_CREATE_TRANSACTION",
  USER_NOT_EMPLOYEE = "USER_NOT_EMPLOYEE",
  OWNER_PERMISSIONS_CAN_NOT_BE_CHANGED = "OWNER_PERMISSION_CAN_NOT_BE_CHANGED",
  OWNER_CAN_NOT_BE_DISABLED = "OWNER_CAN_NOT_BE_DISABLED",
  ENVELOPE_DOES_NOT_EXISTS = "ENVELOPE_DOES_NOT_EXISTS",
  ONBOARDING_NOT_COMPLETED = "ONBOARDING_NOT_COMPLETED",
  SERVICE_AGREEMENT_IS_NOT_COMPLETED = "SERVICE_AGREEMENT_IS_NOT_COMPLETED",
  EXTERNAL_ACCOUNT_INVALID_CREDENTIALS = "EXTERNAL_ACCOUNT_INVALID_CREDENTIALS",
  FAILED_TO_GET_ACCOUNT_BALANCE = "FAILED_TO_GET_ACCOUNT_BALANCE",
  SAME_EMAIL_ASSOCIATED_WITH_COMPANY_TWICE = "SAME_EMAIL_ASSOCIATED_WITH_COMPANY_TWICE",
  EMAIL_DOES_NOT_BELONG_TO_A_COMPANY_FROM_INVITATION = "EMAIL_DOES_NOT_BELONG_TO_A_COMPANY_FROM_INVITATION",
  BAD_USER_STATUS = "BAD_USER_STATUS",
  DOCUSIGN_WEBHOOK_BAD_CREDENTIALS = "DOCUSIGN_WEBHOOK_BAD_CREDENTIALS",
  USER_HAS_NO_ROLE = "USER_HAS_NO_ROLE",
  EMAIL_ALREADY_TAKEN = "EMAIL_ALREADY_TAKEN",
  EMAIL_IS_USED_AS_PERSONAL = "EMAIL_IS_USED_AS_PERSONAL",
  TERMINATION_STARTED = "TERMINATION_STARTED",
  TERMINATION_NOT_FOUND = "TERMINATION_NOT_FOUND",
  OWNER_CANNOT_BE_TERMINATED = "OWNER_CANNOT_BE_TERMINATED",
  SPECIAL_PAYMENT_PLAN_CONFIGURED = "SPECIAL_PAYMENT_PLAN_CONFIGURED",
  SPECIAL_PAYMENT_PLAN_NOT_CONFIGURED = "SPECIAL_PAYMENT_PLAN_NOT_CONFIGURED",
  LOAN_STARTED = "LOAN_STARTED",
  EMPLOYER_NOT_ACKNOWLEDGED_WITH_TERMINATION_TERMS = "EMPLOYER_NOT_ACKNOWLEDGED_WITH_TERMINATION_TERMS",
  BAD_WITHDRAWAL_DESTINATIONS = "BAD_WITHDRAWAL_DESTINATIONS",
  REQUIRED_PAYMENT_AMOUNT_GREATER_THAN_BALANCE_DUE = "REQUIRED_PAYMENT_AMOUNT_GREATER_THAN_BALANCE_DUE",
  EMPLOYEE_IS_IN_EXIT_STATE = "EMPLOYEE_IS_IN_EXIT_STATE",
  USER_CANNOT_RECOVER_ACCOUNT = "USER_CANNOT_RECOVER_ACCOUNT",
  USER_CANNOT_LOGIN_WITH_PERSONAL_EMAIL = "USER_CANNOT_LOGIN_WITH_PERSONAL_EMAIL",
  MARITAL_STATUS_NOT_SPECIFIED = "MARITAL_STATUS_NOT_SPECIFIED",
  EMPLOYEE_NOT_ACCEPTED_ACKNOWLEDGEMENTS = "EMPLOYEE_NOT_ACCEPTED_ACKNOWLEDGEMENTS",
  NO_COMPANY_ID_QUERY_PARAMETER = "NO_COMPANY_ID_QUERY_PARAMETER",
  SPOUSE_EMAIL_NOT_PROVIDED = "USER_CANNOT_LOGIN_WITH_PERSONAL_EMAIL",
  INVITATION_NOT_FOUND = "INVITATION_NOT_FOUND",
  MAX_RECORD_LIMIT = "MAX_RECORD_LIMIT",
  PERFORMANCE_REWARD_NOT_FOUND = "PERFORMANCE_REWARD_NOT_FOUND",
  PERFORMANCE_REWARD_CANNOT_BE_UPDATED = "PERFORMANCE_REWARD_CANNOT_BE_UPDATED",
  PERFORMANCE_REWARD_CANNOT_BE_DELETED = "PERFORMANCE_REWARD_CANNOT_BE_DELETED",
  PERFORMANCE_REWARD_CANNOT_BE_CREATED = "PERFORMANCE_REWARD_CANNOT_BE_CREATED",
  PERFORMANCE_REWARD_CANNOT_BE_APPROVED_OR_DECLINED = "PERFORMANCE_REWARD_CANNOT_BE_APPROVED_OR_DECLINED",
  PERFORMANCE_REWARD_IS_COMPLETE = "PERFORMANCE_REWARD_IS_COMPLETE",
  PERFORMANCE_REWARD_WRONG_STATUS = "PERFORMANCE_REWARD_WRONG_STATUS",
  PERFORMANCE_REWARD_INVALID_GOAL_DUE_DATE = "PERFORMANCE_REWARD_INVALID_GOAL_DUE_DATE",
  WRONG_FILE_FORMAT = "WRONG_FILE_FORMAT",
  UNSUPPORTED_LENDER = "UNSUPPORTED_LENDER",
  RECORD_ID_REPEATED = "RECORD_ID_REPEATED",
  BANK_ACCOUNT_IS_NOT_VERIFIED = "BANK_ACCOUNT_IS_NOT_VERIFIED",
  USER_NOT_KYC_VERIFIED = "USER_NOT_KYC_VERIFIED",
  MISSING_ENV_VARIABLE = "MISSING_ENV_VARIABLE",
  INVALID_DATE = "INVALID_DATE",
  COMPANY_DOES_NOT_HAVE_BONUSES = "COMPANY_DOES_NOT_HAVE_BONUSES",
  BAD_RES_CONTEXT = "BAD_RES_CONTEXT",
  FIND_ONE_EXPECTED_NOT_NULL = "FIND_ONE_EXPECTED_NOT_NULL",
  NO_ENV_VARIABLE = "NO_ENV_VARIABLE",
  RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",
  FILE_NOT_FOUND = "FILE_NOT_FOUND",
  TOO_MANY_ATTEMPTS = "TOO_MANY_ATTEMPTS",
  TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
  MERGEDEV_API_ERROR = "MERGEDEV_API_ERROR",
  HRIS_INTEGRATION_NOT_FOUND = "HRIS_INTEGRATION_NOT_FOUND",
  HRIS_INTEGRATION_ALREADY_LINKED = "HRIS_INTEGRATION_ALREADY_LINKED",
  HRIS_INTEGRATION_NOT_LINKED = "HRIS_INTEGRATION_NOT_LINKED",
  HRIS_INTEGRATION_IS_SYNCING = "HRIS_INTEGRATION_IS_SYNCING",
  HRIS_INTEGRATION_WRONG_LINK_STATUS = "HRIS_INTEGRATION_WRONG_LINK_STATUS",
  HRIS_INTEGRATION_WRONG_WEBHOOK_EVENT = "HRIS_INTEGRATION_WRONG_WEBHOOK_EVENT",
  USER_SYNCED_WITH_HRIS = "USER_SYNCED_WITH_HRIS",
  USER_DOES_NOT_EXIST_WITH_PRIMARY_EMAIL = "USER_DOES_NOT_EXIST_WITH_PRIMARY_EMAIL",
  PHONE_NUMBER_VALIDATION_ERROR = "PHONE_NUMBER_VALIDATION_ERROR",
  PROHIBITED_DOMAIN = "PROHIBITED_DOMAIN",
  COMPANY_DOES_NOT_HAVE_WORKOS_ORGANIZATION = "COMPANY_DOES_NOT_HAVE_WORKOS_ORGANIZATION",
  COMPANY_DOMAIN_NOT_FOUND = "COMPANY_DOMAIN_NOT_FOUND",
  COMPANY_DOMAIN_ALREADY_EXISTS = "COMPANY_DOMAIN_ALREADY_EXISTS",
  COMPANY_DOMAIN_ALREADY_EXISTS_BUT_NOT_VERIFIED = "COMPANY_DOMAIN_ALREADY_EXISTS_BUT_NOT_VERIFIED",
  COMPANY_DOMAIN_BELONGS_TO_ANOTHER_COMPANY = "COMPANY_DOMAIN_BELONGS_TO_ANOTHER_COMPANY",
  COMPANY_DOMAINS_MAX_LIMIT_REACHED = "COMPANY_DOMAINS_MAX_LIMIT_REACHED",
  LAST_COMPANY_DOMAIN_CANNOT_BE_DELETED = "LAST_COMPANY_DOMAIN_CANNOT_BE_DELETED",
  COMPANY_DOES_NOT_HAVE_IDP = "COMPANY_DOES_NOT_HAVE_IDP",
  IDP_CONNECTION_NOT_COMPLETED = "IDP_CONNECTION_NOT_COMPLETED",
  COMPANY_DOMAIN_ALREADY_VERIFIED = "COMPANY_DOMAIN_ALREADY_VERIFIED",
  COMPANY_DOMAIN_VERIFICATION_LINK_ALREADY_SENT = "COMPANY_DOMAIN_VERIFICATION_LINK_ALREADY_SENT",
  COMPANY_DOMAIN_VERIFICATION_LINK_EXPIRED = "COMPANY_DOMAIN_VERIFICATION_LINK_EXPIRED",
  USER_HAS_PROSPECT_STATUS = "USER_HAS_PROSPECT_STATUS",
  IDP_PROSPECT_USER_LOGIN_ERROR = "IDP_PROSPECT_USER_LOGIN_ERROR",
  ADMIN_ALREADY_INVITED = "ADMIN_ALREADY_INVITED",
  ADMIN_ALREADY_ACTIVE = "ADMIN_ALREADY_ACTIVE",
  BANK_ACCOUNT_LIMIT_EXCEEDED = "BANK_ACCOUNT_LIMIT_EXCEEDED",
  USER_HAS_NO_PARTNER_BANK_ACCOUNT = "USER_HAS_NO_PARTNER_BANK_ACCOUNT",
  INVALID_CREDENTIALS = "INVALID_CREDENTIALS",

  // BE don't have this error
  ACCESS_DENIED = "access_denied",
}

export enum BEValidationErrorConst {
  PHONE_NUMBER_VALIDATION_ERROR = "must be a valid phone number",
}

export enum NumberOfEmployees {
  FROM_1_TO_50 = "1-50",
  FROM_51_TO_100 = "51-100",
  FROM_101_TO_250 = "101-250",
  FROM_251_TO_500 = "251-500",
  FROM_501_TO_1000 = "501-1000",
  FROM_1001_TO_5000 = "1001-5000",
  FROM_5001_TO_10000 = "5001-10000",
  FROM_10001_TO_20000 = "10001-20000",
  FROM_20000 = "20000+",
}

export enum CompanyEntityType {
  SOLE_PROPRIETORSHIP = "SOLE_PROPRIETORSHIP",
  PARTNERSHIP = "PARTNERSHIP",
  CORPORATION = "CORPORATION",
  S_CORPORATION = "S_CORPORATION",
  LLC = "LLC",
}

export enum Department {
  HUMAN_RESOURCES = "Human resources",
  ENGINEERING = "Engineering",
  SALES = "Sales",
  CREATIVE = "Creative",
  SUPPORT = "Support",
  MARKETING = "Marketing",
}

export enum Industry {
  ACCOUNTING = "Accounting",
  ADMINISTRATION_N_OFFICE_SUPPORT = "Administration & Office Support",
  ADVERTISING_N_ARTS_N_MEDIA = "Advertising, Arts & Media",
  BANKING_N_FINANCIAL_SERVICES = "Banking & Financial Services",
  CALL_CENTRE_N_CUSTOMER_SERVICE = "Call Centre & Customer Service",
  COMMUNITY_SERVICES_N_DEVELOPMENT = "Community Services & Development",
  CONSULTING_N_STRATEGY = "Consulting & Strategy",
  DESIGN_N_ARCHITECTURE = "Design & Architecture",
  EDUCATION_N_TRAINING = "Education & Training",
  ENGINEERING = "Engineering",
  FARMING_N_ANIMALS_N_CONSERVATION = "Farming, Animals & Conservation",
  GOVERNMENT_N_DEFENCE = "Government & Defence",
  HEALTHCARE_N_MEDICAL = "Healthcare & Medical",
  HOSPITALITY_N_TOURISM = "Hospitality & Tourism",
  HUMAN_RESOURCES_N_RECRUITMENT = "Human Resources & Recruitment",
  INFORMATION_N_COMMUNICATION_TECHNOLOGY = "Information & Communication Technology",
  INSURANCE_N_SUPERANNUATION = "Insurance & Superannuation",
  LEGAL = "Legal",
  MANUFACTURING_N_TRANSPORT_N_LOGISTICS = "Manufacturing, Transport & Logistics",
  MARKETING_N_COMMUNICATIONS = "Marketing & Communications",
  MINING_N_RESOURCES_N_ENERGY = "Mining, Resources & Energy",
  REAL_ESTATE_N_PROPERTY = "Real Estate & Property",
  RETAIL_N_CONSUMER_PRODUCTS = "Retail & Consumer Products",
  SALES = "Sales",
  SCIENCE_N_TECHNOLOGY = "Science & Technology",
  SPORT_N_RECREATION = "Sport & Recreation",
  TRADES_N_SERVICES = "Trades & Services",
  OTHER = "Other",
}

export enum BankAccountType {
  investment = "investment",
  credit = "credit",
  depository = "depository",
  loan = "loan",
  brokerage = "brokerage",
  other = "other",
}

export enum HRISIntegrationLinkStatus {
  COMPLETE = "COMPLETE",
  INCOMPLETE = "INCOMPLETE",
  RELINK_NEEDED = "RELINK_NEEDED",
  DISCONNECTED = "DISCONNECTED",
}

export enum HRISIntegrationSyncStatus {
  PENDING = "PENDING",
  DONE = "DONE",
  SYNCING = "SYNCING",
}

export const RepaymentPeriodDaysNumber = {
  60: 60,
  90: 90,
  120: 120,
};

export enum FilterEmployeeStatus {
  ALL = "ALL",
  EMPLOYED = "EMPLOYED",
  PENDING_EXIT = "PENDING_EXIT",
  EXITED = "EXITED",
  WITH_BALANCE_DUE = "WITH_BALANCE_DUE",
  NOT_EXITING = "NOT_EXITING",
}

export enum FilterBonusStatus {
  ALL = "ALL",
  WITH_BONUS = "WITH_BONUS",
  NO_BONUS = "NO_BONUS",
  DRAFT = "DRAFT",
  PENDING = "PENDING",
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
  NOT_COMPLETED = "NOT_COMPLETED",
  VESTING_STOPPED = "VESTING_STOPPED",
  PAST_DUE_DATE = "PAST_DUE_DATE",
  VOIDED = "VOIDED",
  DECLINED = "DECLINED",
}

export enum FilterBonusDetails {
  TOTAL_AMOUNT = "TOTAL_AMOUNT",
  VESTED_BALANCE = "VESTED_BALANCE",
  UNVESTED_BALANCE = "UNVESTED_BALANCE",
  ISSUED_AMOUNT = "ISSUED_AMOUNT",
}

export enum ComparisonOperators {
  GREATER = "GREATER",
  LESS = "LESS",
}

export enum RepaymentTransactionType {
  BALANCE_DUE = "BALANCE_DUE",
  LOAN = "LOAN",
}

export enum BonusChallengeGoalType {
  NUMBER = "NUMBER",
  PERCENTAGE = "PERCENTAGE",
  MONETARY = "MONETARY",
  TEXT = "TEXT",
  STATUS = "STATUS",
}

export enum BonusChallengeRewardType {
  FLAT_PAYMENT_UPON_COMPLETION = "FLAT_PAYMENT_UPON_COMPLETION",
  PAY_TIERS = "PAY_TIERS",
}

export enum ChallengeGoalConditionOperator {
  EQUAL_TO = "EQUAL_TO",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
}

export enum IdentityProviderConnectionStatus {
  active = "active",
  inactive = "inactive",
  draft = "draft",
}

export enum CompanyDomainStatus {
  NOT_VERIFIED = "NOT_VERIFIED",
  VERIFIED = "VERIFIED",
}

export enum CompanyIdentityProviderStatus {
  ACTIVE = "active",
  INACTIVE = "inactive",
  DRAFT = "draft",
}

export enum EmployeesGroupStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
}

export enum BonusAssignmentType {
  GROUP = "GROUP",
  USER = "USER",
}

export enum VestingMilestoneType {
  ORIGINAL_MILESTONE = "ORIGINAL_MILESTONE",
  REWARD = "REWARD",
}

export enum SearchStringExtension {
  JOB_TITLE = "JOB_TITLE",
  GROUP_NAME = "GROUP_NAME",
  DEPARTMENT = "DEPARTMENT",
}

export enum SalsaWorkerCategoryEnum {
  EMPLOYEE = "EMPLOYEE",
  CONTRACTOR = "CONTRACTOR",
}

export enum SalsaOnboardingStatus {
  INVITED = "INVITED",
  STARTED = "STARTED",
  COMPLETED = "COMPLETED",
  INVALIDATED = "INVALIDATED",
}

export enum SalsaWorkerPaymentReadinessEnum {
  ONBOARDING = "ONBOARDING",
  PARTIALLY_READY = "PARTIALLY_READY",
  NOT_READY = "NOT_READY",
  READY = "READY",
}

export enum PayDistributionType {
  CHECK = "CHECK",
  EXTERNAL_ACCOUNT = "EXTERNAL_ACCOUNT",
  PARTNER_ACCOUNT = "PARTNER_ACCOUNT",
}

export enum PaymentCardStatus {
  ACTIVE = "ACTIVE",
  SUSPENDED = "SUSPENDED",
  TERMINATED = "TERMINATED",
}

export enum SyncteraTransactionStatus {
  PENDING = "PENDING",
  REPLACED = "REPLACED",
  DECLINED = "DECLINED",
  RELEASED = "RELEASED",
  PARTCLEARED = "PARTCLEARED",
  CLEARED = "CLEARED",
  EXPIRED = "EXPIRED",
  INTERNAL_ERROR = "INTERNAL_ERROR",
  POSTED = "POSTED",
}

export enum SyncteraAccountBalanceType {
  ACCOUNT_BALANCE = "ACCOUNT_BALANCE",
  AVAILABLE_BALANCE = "AVAILABLE_BALANCE",
}

export enum SyncteraAccountTransactionType {
  ACH = "ach",
  WIRE = "wire",
  CARD = "card",
  INTERNAL_TRANSFER = "internal_transfer",
  CHECK = "check",
  EXTERNAL_CARD = "external_card",
}

export enum SyncteraAccountTransactionDcSignType {
  DEBIT = "debit",
  CREDIT = "credit",
}
