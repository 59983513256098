import { FC } from "react";

import IconProps from "./IconProps";

const CircledDots: FC<IconProps> = ({ className }) => (
  <svg className={className} width="35" height="35" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="17.5" cy="17.5" r="16.5" stroke="#C5C5C5" strokeWidth="2"/>
    <circle cx="10.5" cy="17.5" r="2.5" fill="#C5C5C5"/>
    <circle cx="17.5" cy="17.5" r="2.5" fill="#C5C5C5"/>
    <circle cx="24.5" cy="17.5" r="2.5" fill="#C5C5C5"/>
  </svg>
);

export default CircledDots;
