import {
  APPLICATION_NAME,
  APPLICATION_PARENT_NAME,
  APPLICATION_PARENT_NAME_FULL_ALT_UPPERCASE,
} from "constants/index";

export const bankAccountSectionTranslation = {
  "en-US": {
    point_1: `1. ${APPLICATION_NAME} is a d/b/a of ${APPLICATION_PARENT_NAME_FULL_ALT_UPPERCASE}, Inc. ("${APPLICATION_PARENT_NAME}"). ${APPLICATION_PARENT_NAME} is a financial technology company and not a bank. Banking services are provided by ${APPLICATION_PARENT_NAME}'s bank partner, Thread Bank, Member FDIC ("Thread"). Mastercard® debit cards are issued by ${APPLICATION_PARENT_NAME}'s bank partner pursuant to a license from Mastercard Inc. and may be used everywhere Mastercard debit cards are accepted.<br />
    Accounts are eligible for pass-through deposit insurance only to the extent pass-through insurance is permitted by the rules and regulations of the FDIC, and if the requirements for pass-through insurance are satisfied. There may be a risk that pass-through deposit insurance is not available because conditions have not been satisfied. In such cases, funds may not be fully insured in the event the insured depository institution where the funds have been deposited were to fail.`,
    point_2: `2.Deposit sweep networks may be offered through select ${APPLICATION_NAME}s bank partner Thread Bank, Member FDIC. 
      Please refer to the applicable bank partner's deposit sweep network disclosures for more information.`,
  },
};

export default bankAccountSectionTranslation;
