import { FC } from "react";

import IconProps from "./IconProps";

const UsersIcon: FC<IconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    className={className}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_5248_22319)">
      <rect width="20" height="20" fill="white" />
      <path
        d="M9.99907 7.30674C11.2736 7.30674 12.3067 6.27356 12.3067 4.99907C12.3067 3.72458 11.2736 2.69141 9.99907 2.69141C8.72458 2.69141 7.69141 3.72458 7.69141 4.99907C7.69141 6.27356 8.72458 7.30674 9.99907 7.30674Z"
        fill="#17AACF"
      />
      <path
        d="M10.0003 7.69141C7.8763 7.69141 6.1543 9.41341 6.1543 11.5374V13.0757C6.1543 13.9254 6.84296 14.6141 7.69263 14.6141V17.6911C7.69263 18.9657 8.72596 19.9987 10.0003 19.9987C11.2746 19.9987 12.308 18.9654 12.308 17.6911V14.6141C13.1576 14.6141 13.8463 13.9254 13.8463 13.0757V11.5374C13.8463 9.41341 12.1243 7.69141 10.0003 7.69141Z"
        fill="#17AACF"
      />
      <path
        d="M16.1534 4.61533C17.4279 4.61533 18.461 3.58216 18.461 2.30767C18.461 1.03318 17.4279 0 16.1534 0C14.8789 0 13.8457 1.03318 13.8457 2.30767C13.8457 3.58216 14.8789 4.61533 16.1534 4.61533Z"
        fill="#17AACF"
      />
      <path
        d="M19.9995 8.84633V10.3847C19.9995 11.2307 19.3072 11.923 18.4612 11.923V15C18.4612 16.277 17.4305 17.3077 16.1535 17.3077C14.8765 17.3077 13.8459 16.277 13.8459 15V14.7847C14.3152 14.3693 14.6152 13.754 14.6152 13.077V11.5387C14.6152 9.908 13.7692 8.477 12.4922 7.66167C12.9922 6.11567 14.4462 5 16.1539 5C18.2769 5 19.9999 6.723 19.9999 8.846L19.9995 8.84633Z"
        fill="#17AACF"
      />
      <path
        d="M3.84673 4.61533C5.12122 4.61533 6.1544 3.58216 6.1544 2.30767C6.1544 1.03318 5.12122 0 3.84673 0C2.57224 0 1.53906 1.03318 1.53906 2.30767C1.53906 3.58216 2.57224 4.61533 3.84673 4.61533Z"
        fill="#17AACF"
      />
      <path
        d="M5.38433 13.0767C5.38433 13.7537 5.68433 14.369 6.15367 14.7843V14.9997C6.15367 16.2767 5.123 17.3073 3.846 17.3073C2.569 17.3073 1.53833 16.2767 1.53833 14.9997V11.9227C0.692333 11.9227 0 11.2303 0 10.3843V8.846C0 6.723 1.723 5 3.846 5C5.55367 5 7.00767 6.11533 7.50767 7.66167C6.23067 8.477 5.38467 9.908 5.38467 11.5387V13.077L5.38433 13.0767Z"
        fill="#17AACF"
      />
    </g>
    <defs>
      <clipPath id="clip0_5248_22319">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UsersIcon;
