import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";
import NavigationCardWithDetails from "components/NavigationCardWithDetails";

import { ContentContainer, FullScreenLoader, PageTitleMargined } from "uikit";

import { useAdminSettingsPage } from "./useAdminSettingsPage";
import { OptionsSection } from "./styles";

interface IProps {}

const AdminSettingsPage: FC<IProps> = () => {
  const translationPrefix = `settings_pages.admin.index`;

  const { metadata, pageData } = useAdminSettingsPage();
  const { optionsList } = pageData;
  return (
    <DashboardLayout>
      {metadata.isLoading && <FullScreenLoader />}
      <ContentContainer>
        <PageTitleMargined>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </PageTitleMargined>

        <OptionsSection>
          {optionsList.map((option) => {
            return <NavigationCardWithDetails {...option} key={option.id} />;
          })}
        </OptionsSection>
      </ContentContainer>
    </DashboardLayout>
  );
};

export default AdminSettingsPage;
