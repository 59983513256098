import {
  CompanyResponseDto,
  UpdateLastActiveRoleDto,
  UserResponseDto,
} from "utils/swagger_react_query";

import * as types from "../constants/ActionTypes";

const _setUserMetaData = (values: UserResponseDto | null | undefined) => ({
  type: types.USER_METADATA_SET_METADATA,
  payload: values,
});

export const setUserMetaData = (values: UserResponseDto | null | undefined) => (dispatch: any) => {
  dispatch(_setUserMetaData(values));
};

export const updateUserMetaDataLastActiveRole =
  (values: UpdateLastActiveRoleDto["role"]) => (dispatch: any) => {
    dispatch({
      type: types.USER_METADATA_UPDATE_LAST_ACTIVE_ROLE,
      payload: values,
    });
  };

export const setCompanyData = (values: CompanyResponseDto | null) => (dispatch: any) => {
  dispatch({
    type: types.SET_COMPANY_DATA,
    payload: values,
  });
};

export const updateUserPersonalEmail =
  (values: UserResponseDto["personalEmail"]) => (dispatch: any) => {
    dispatch({
      type: types.USER_METADATA_UPDATE_PERSONAL_EMAIL,
      payload: values,
    });
  };

export const setSalsa = (values: any) => (dispatch: any) => {
  dispatch({
    type: types.SET_COMPANY_DATA,
    payload: values,
  });
};

export const setWorkerKycOnboardingInProgress = (values: any) => (dispatch: any) => {
  dispatch({
    type: types.SET_WORKER_KYC_ONBOARDING_IN_PROGRESS,
    payload: values,
  });
};
