import { FC } from "react";

import IconProps from "./IconProps";

const TransferRequestIcon: FC<IconProps> = ({ className }) => (
  <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <g clipPath="url(#clip0_2333_24181)">
      <path fillRule="evenodd" clipRule="evenodd" d="M7.38042 21.9829C7.59458 21.8374 7.86701 21.807 8.10807 21.9018L16.7183 25.2863C17.0176 25.4039 17.2143 25.6922 17.2143 26.0131V31.2196C17.2143 31.4786 17.0857 31.7207 16.8709 31.8661C16.6561 32.0115 16.3831 32.0413 16.1419 31.9455L7.53164 28.5277C7.23365 28.4094 7.03809 28.1218 7.03809 27.8018V22.6287C7.03809 22.3701 7.16625 22.1284 7.38042 21.9829ZM8.60407 23.7761V27.2718L15.6483 30.068V26.545L8.60407 23.7761Z" fill="#007D7B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M24.1984 27.9906C24.3648 28.3889 24.176 28.8463 23.7768 29.0122L16.7325 31.9408C16.3334 32.1067 15.8749 31.9184 15.7086 31.5201C15.5423 31.1218 15.7311 30.6644 16.1302 30.4985L23.1745 27.5699C23.5737 27.404 24.0321 27.5923 24.1984 27.9906Z" fill="#007D7B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.6523 17.5385C35.87 17.6834 36.0009 17.9273 36.0009 18.1885V23.409C36.0009 23.7244 35.8108 24.0089 35.519 24.1302L30.0389 26.4081C29.6397 26.5741 29.1813 26.3857 29.015 25.9874C28.8487 25.5892 29.0375 25.1318 29.4367 24.9658L34.4349 22.8882V19.3601L29.5344 21.3964C29.1352 21.5622 28.6768 21.3738 28.5106 20.9755C28.3443 20.5772 28.5332 20.1198 28.9324 19.954L34.9169 17.4673C35.1585 17.3669 35.4345 17.3936 35.6523 17.5385Z" fill="#007D7B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M23.5991 23.0173C23.7654 23.4156 23.5767 23.873 23.1775 24.039L16.7326 26.7185C16.3334 26.8844 15.875 26.6961 15.7086 26.2978C15.5423 25.8996 15.7311 25.4422 16.1302 25.2762L22.5752 22.5967C22.9743 22.4307 23.4328 22.6191 23.5991 23.0173Z" fill="#007D7B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2751 14.1126C26.4618 14.035 26.6714 14.0326 26.8599 14.1058L35.5018 17.4604C35.9049 17.6169 36.1044 18.0697 35.9477 18.4718C35.7909 18.8739 35.337 19.073 34.934 18.9166L26.586 15.676L20.8873 18.0434C20.4881 18.2093 20.0297 18.0208 19.8635 17.6225C19.6972 17.2242 19.8861 16.7669 20.2853 16.601L26.2751 14.1126Z" fill="#007D7B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.0416 19.6258C15.2079 20.024 15.0192 20.4814 14.6201 20.6474L8.12241 23.3498C7.72326 23.5158 7.26481 23.3275 7.09844 22.9292C6.93206 22.531 7.12076 22.0736 7.51991 21.9076L14.0176 19.2052C14.4167 19.0392 14.8752 19.2275 15.0416 19.6258Z" fill="#007D7B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M19.4768 16.2871C19.6635 16.2096 19.873 16.2071 20.0615 16.2802L30.0215 20.1434C30.3225 20.2602 30.5208 20.5493 30.5208 20.8716V26.2727C30.5208 26.5806 30.3396 26.8598 30.0579 26.9857L23.7958 29.7845C23.5535 29.8927 23.2728 29.8712 23.05 29.7272C22.8272 29.5832 22.6927 29.3363 22.6927 29.0715V24.0866L13.2283 20.335C12.9323 20.2177 12.737 19.9331 12.7344 19.6153C12.7318 19.2976 12.9224 19.0099 13.2164 18.8877L19.4768 16.2871ZM19.7878 17.8504L15.5953 19.592L23.7648 22.8303C24.0629 22.9485 24.2587 23.2362 24.2587 23.5564V27.8655L28.9549 25.7666V21.4061L19.7878 17.8504Z" fill="#007D7B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.26219 5.46692C6.52988 5.46692 6.77901 5.60336 6.92274 5.82869L12.4028 14.4197C12.5562 14.6602 12.5661 14.9651 12.4286 15.215C12.2911 15.465 12.028 15.6204 11.7422 15.6204H10.1749L10.1684 17.9575C10.1671 18.389 9.8156 18.7377 9.38316 18.7365C8.95073 18.7353 8.60116 18.3846 8.60238 17.9531L8.61117 14.8369C8.61238 14.4063 8.96258 14.0579 9.39416 14.0579H10.3158L6.26219 7.70316L2.2086 14.0579H3.13199C3.56442 14.0579 3.91498 14.4077 3.91498 14.8391V28.8979C3.91498 29.3293 3.56442 29.6791 3.13199 29.6791C2.69955 29.6791 2.349 29.3293 2.349 28.8979V15.6204H0.782137C0.49635 15.6204 0.233265 15.465 0.0957501 15.215C-0.0417646 14.9651 -0.0318621 14.6602 0.121585 14.4197L5.60164 5.82869C5.74537 5.60336 5.99451 5.46692 6.26219 5.46692Z" fill="#F29127" />
      <path fillRule="evenodd" clipRule="evenodd" d="M18.7865 -0.000854492C19.0542 -0.000854492 19.3034 0.135616 19.4471 0.360972L24.9271 8.95369C25.0806 9.19425 25.0904 9.4991 24.9529 9.74905C24.8154 9.999 24.5523 10.1543 24.2665 10.1543H22.7014V12.5542C22.7014 12.9856 22.3509 13.3354 21.9184 13.3354C21.486 13.3354 21.1355 12.9856 21.1355 12.5542V9.3731C21.1355 8.94164 21.486 8.59187 21.9184 8.59187H22.8402L18.7865 2.2356L14.7327 8.59187H15.6563C15.8642 8.59187 16.0635 8.67435 16.2104 8.82113C16.3573 8.96791 16.4396 9.16695 16.4393 9.37436L16.4305 14.8334C16.4298 15.2648 16.0787 15.614 15.6462 15.6133C15.2138 15.6127 14.8638 15.2623 14.8645 14.8309L14.872 10.1543H13.3064C13.0207 10.1543 12.7576 9.999 12.6201 9.74905C12.4825 9.4991 12.4924 9.19425 12.6458 8.95369L18.1259 0.360973C18.2696 0.135616 18.5188 -0.000854492 18.7865 -0.000854492Z" fill="#F29127" />
    </g>
    <defs>
      <clipPath id="clip0_2333_24181">
        <rect width="36" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TransferRequestIcon;