import styled from "styled-components";

import {
  CancelButton as CancelButtonBase,
  FormSectionTitle,
  InputContainer,
  PrimaryButton,
  RadioButton,
  Select,
} from "uikit";
import { Label as RadioLabel } from "uikit/RadioButton/styles";

export const Container = styled.div`
  height: 100%;
`;

export const ContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const FormContainer = styled.div`
  overflow: auto;
`;

export const StyledFormSectionTitle = styled(FormSectionTitle)`
  margin-bottom: 40px;
`;

export const EmploymentTypeContainer = styled(InputContainer)`
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const OvertimeEligibleContainer = styled(InputContainer)`
  margin-bottom: 76px;

  .custom-select {
    max-width: 350px;
  }
`;

export const ETContainerInner = styled(InputContainer)`
  display: flex;
  margin-top: 8px;
`;

export const ETRadioButton = styled(RadioButton)`
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }

  ${RadioLabel} {
    font-size: ${({ theme }) => theme.size.body1};
  }
`;

export const ButtonsContainer = styled.div`
  background: ${({ theme }) => theme.colors.contentBg};
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
`;

export const CancelButton = styled(CancelButtonBase)`
  width: 126px;
`;
export const AddEmployeeButton = styled(PrimaryButton)`
  width: 190px;
`;
