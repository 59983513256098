import { FC } from "react";

import { Step } from "./types";
import { Container, StepContainer, StepLabel } from "./styles";

interface Props {
  className?: string;
  steps: Step[];
  currentStep: number;
}

const StepIndicator: FC<Props> = ({ className, steps, currentStep }) => (
  <Container className={className}>
    {steps.map((item: Step) => (
      <StepContainer
        key={item.index.toString()}
        className={`${item.index === currentStep ? "active" : ""} ${
          !!item.onClick ? "clickable" : ""
        }`}
        onClick={item.onClick}
      >
        <StepLabel>{item.label}</StepLabel>
      </StepContainer>
    ))}
  </Container>
);

export default StepIndicator;
