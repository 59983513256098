import { FC, useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";

import { companyMetadataSelector } from "redux/selectors";
import { BankAccountSection, IBankAccountSectionProps } from "components/BankAccountSection";

import { PageTitle } from "uikit";

import { BCLeft, BCRight, ButtonsContainer, CancelButton } from "../styles";
import {
  AddBankAccountBtn,
  AgreementBlock,
  AgreementBlockMessage,
  AgreementBlockTitle,
  AgreementCheckbox,
  Container,
  PageHeader,
  SubmitButton,
} from "./styles";

interface Props {
  className?: string;
  bankAccountSectionProps: Required<IBankAccountSectionProps>;
  onBack: () => void;
  onAgreementSubmit: () => void;
}

const ConnectBank: FC<Props> = ({
  className,
  bankAccountSectionProps,
  onBack,
  onAgreementSubmit,
}) => {
  const translationPrefix = `admin_setup_page.steps.connect_bank`;
  const defaultAccount = (bankAccountSectionProps.bankAccounts || [])?.find((it) => it.isDefault);
  const showAgreementBlock = !!defaultAccount;

  const currentCompany = useSelector(companyMetadataSelector);

  const [isAgreementChecked, setAgreementChecked] = useState<boolean>(false);

  return (
    <Container className={className}>
      <PageHeader>
        <PageTitle>
          <Trans i18nKey={`${translationPrefix}.title`} />
        </PageTitle>

        <AddBankAccountBtn onClick={() => bankAccountSectionProps?.onConnectBankAccount()}>
          <Trans i18nKey={`${translationPrefix}.add_bank_account_btn`} />
        </AddBankAccountBtn>
      </PageHeader>

      <BankAccountSection {...bankAccountSectionProps} onConnectBankAccount={undefined} />

      {showAgreementBlock && (
        <AgreementBlock>
          <AgreementCheckbox
            checked={isAgreementChecked}
            onCheck={() => {
              setAgreementChecked(true);
            }}
            onUncheck={() => {
              setAgreementChecked(false);
            }}
            text={
              <Trans
                i18nKey={`${translationPrefix}.agreement_text`}
                components={{
                  1: <AgreementBlockTitle />,
                  2: <AgreementBlockMessage />,
                }}
                values={{
                  companyName: currentCompany?.name || "",
                }}
              />
            }
          />
        </AgreementBlock>
      )}

      <ButtonsContainer>
        <BCLeft>
          <CancelButton onClick={onBack}>
            <Trans i18nKey={`${translationPrefix}.back_button`} />
          </CancelButton>
        </BCLeft>

        {showAgreementBlock && (
          <BCRight>
            <SubmitButton onClick={onAgreementSubmit} disabled={!isAgreementChecked}>
              <Trans i18nKey={`${translationPrefix}.agreement_submit_button`} />
            </SubmitButton>
          </BCRight>
        )}
      </ButtonsContainer>
    </Container>
  );
};

export default ConnectBank;
