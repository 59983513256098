import { FC, useEffect } from "react";
import { Trans } from "react-i18next";
import { useFormik } from "formik";

import { HELP_CENTER } from "constants/";
import AuthLayout from "layouts/AuthLayout";

import { FullScreenLoader } from "uikit";

import {
  ContactDetails,
  ContactDetailsFormType,
  ContactDetailsFormTypeValidationSchema,
} from "../_shared";
import { Footer, FooterLinkExternal, StyledPrimaryBtn } from "../_shared/components/styles";
import { authDefaultLeftSectionParams } from "../_shared/constants";
// import { SubmitBtn } from "../_shared/components/styles";
import { useAdminSignUpPage } from "./useAdminSignUpPage";
import { Container } from "./styles";

const AdminSignUpPage: FC = () => {
  const { metadata, actions } = useAdminSignUpPage();

  const formikProps = useFormik<ContactDetailsFormType>({
    initialValues: metadata.formInitValues,
    onSubmit: (values) => actions.handleSubmit(values),
    validationSchema: ContactDetailsFormTypeValidationSchema,
    validateOnChange: metadata.svoc,
  });

  useEffect(() => {
    formikProps.resetForm({ values: metadata.formInitValues });
  }, [metadata.formInitValues]);

  if (metadata.pageLoader) return <FullScreenLoader />;
  return (
    <AuthLayout isLoading={metadata.isLoading} leftSectionParams={authDefaultLeftSectionParams()}>
      <Container onSubmit={formikProps.handleSubmit}>
        <ContactDetails
          values={formikProps.values}
          errors={formikProps.errors}
          handleChange={formikProps.handleChange}
          setFieldValue={async (field: string, value: any) => {
            formikProps.setFieldValue(field, value);
            return;
          }}
        />

        <StyledPrimaryBtn type="submit">
          <Trans i18nKey={`buttons.apply`} />
        </StyledPrimaryBtn>

        <Footer>
          <FooterLinkExternal href={HELP_CENTER} target="_blank">
            <Trans i18nKey={`buttons.need_help_btn`} />
          </FooterLinkExternal>
        </Footer>
      </Container>
    </AuthLayout>
  );
};

export default AdminSignUpPage;
