import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

export const TooltipContainer = styled.span`
  .__react_component_tooltip {
    &:after {
      display: none;
    }
    max-width: 275px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.contentBorderColor};
    box-shadow: 0px 0px 4px 0px ${({ theme }) => theme.colors.contentBorderColor};

    ${breakpoint("xs", "md")`
      max-width: 190px;
      padding: 10px;
    `}
  }
`;

export const Container = styled.div`
  display: inline-block;
`;
