import { BankIconPlaceholder } from "assets/svg";
import styled, { css } from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { Body3, LinkTextMixin, SecondaryButton } from "uikit";

const logoSize: string = "32px";

const LogoMixin = css`
  width: ${logoSize};
  height: ${logoSize};
  border-radius: 6px;
  margin-right: 13px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  p {
    margin-bottom: 0;
    text-align: left;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BankLogo = styled.img`
  ${LogoMixin}
`;

export const StyledBankIconPlaceholder = styled(BankIconPlaceholder)`
  ${LogoMixin}
`;

export const Row = styled.div``;

export const Text = styled(Body3)`
  width: 100%;
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.size.caption};

  ${breakpoint("xs", "xl")`
    line-height: 18px;
    font-weight: ${({ theme }) => theme.fontWeight.regular};
  `}
`;

export const PendingVerifyLink = styled.span`
  ${LinkTextMixin};
  font-style: italic;
  font-size: ${({ theme }) => theme.size.small};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  position: relative;
  z-index: 10000;
  margin-left: 4px;

  ${breakpoint("xs", "md")`
    font-size: ${({ theme }) => theme.size.small};
  `}
`;

export const VerifyBtn = styled(SecondaryButton)`
  width: auto;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  height: 32px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: 8px;
`;

export const VerificationStatusSection = styled.div``;

export const VSSText = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.danger2};
  font-style: italic;
`;
