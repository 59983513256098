import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { companyMetadataSelector, salsaSelector } from "redux/selectors";

const useAdminDocumentsPage = () => {
  const frameId = "employer-container";
  const frameSelector = `#${frameId}`;
  const salsaData = useSelector(salsaSelector);
  const { salsa, authToken } = salsaData || {};
  const currentCompany = useSelector(companyMetadataSelector);

  useEffect(() => {
    if (salsa) {
      const salsaElement = salsa.elements.create("employer-documents", {
        userToken: authToken,
        employerId: currentCompany?.salsaEmployerId,
      });

      salsa.elements.mount(salsaElement, frameSelector);
      return () => {
        salsa.elements.destroy(salsaElement);
      };
    }
  }, [salsa]);

  return {
    frameId,
  };
};

export default useAdminDocumentsPage;
