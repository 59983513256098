import styled from "styled-components";

import { breakpoint } from "helpers";

import { PrimaryButton } from "uikit";

export const Wrapper = styled.div`
   display: flex;
   margin: 0 auto;

   & > div:nth-child(1) {
      flex: 1;

      & > div {
         margin-bottom: 5px;
      }
      
      & > div:nth-child(5) {
         margin-bottom: 40px;
      }

      ${breakpoint("md", "xl")`
         max-width: 440px;
      `}

      ${breakpoint("xs", "md")`
         display: flex;
         flex-direction: column;
         max-width: 440px;
         width: 100%;   
      `}
   }

   & > div:nth-child(2) {
      flex: 1;

      ${breakpoint("xs", "md")`
         order: -1;
      `}
   }

   ${breakpoint("xs", "md")`
      min-width: 312px;
      flex-direction: column;
      align-items: center;
      width: 100%;
   `}

   ${breakpoint("xs", "xl")`
      margin-bottom: 100px;
   `}
`;

export const SubmitBtn = styled(PrimaryButton)`
   margin: 0 0 0 0;
   padding: 0;
   font-size: ${({ theme }) => theme.size.body2};
   font-weight: ${({ theme }) => theme.fontWeight.regular};
   width: 180px;

   ${breakpoint("xs", "md")`
      max-width: 360px;
      width: 100%;
      margin: 0 auto;
   `}
`;