import { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { use } from "i18next";

import actions from "redux/actions";
import { salsaSelector, userMetadataSelector } from "redux/selectors";
import { getSalsaAuthToken } from "services/salsa";
import { UserRole } from "types/BETypes";
import { SALSA_AUTH_TOKEN_REQUEST_DATETIME } from "constants/index";

interface Props {
  refreshIntervalTime?: number;
  refreshDelta?: number;
  checkInterval?: number;
}

const SalsaTokenRefresher: FC<Props> = ({
  refreshIntervalTime = Number(process.env.REACT_APP_SALSA_TOKEN_ACTIVITY_MINUTES || 60) *
    60 *
    1000,
  refreshDelta = 5 * 60 * 1000, //For requesting new token a bit earlier than expiration time comes;
  checkInterval = 10 * 1000,
}) => {
  const currentUser = useSelector(userMetadataSelector);
  const salsaData = useSelector(salsaSelector);
  const dispatch = useDispatch();
  const refreshInterval = useRef<ReturnType<typeof setInterval> | undefined>(undefined);

  const checkIfShouldRefreshToken = async () => {
    if (!currentUser) return;

    const lastRequestTime = parseInt(
      localStorage.getItem(SALSA_AUTH_TOKEN_REQUEST_DATETIME) || "0",
      10,
    );
    const refreshTime = lastRequestTime + refreshIntervalTime - refreshDelta;
    const currentDate = Date.now();

    if (!!refreshTime && refreshTime < currentDate) {
      //If token is expired
      const _tokenRes = await getSalsaAuthToken(currentUser.lastActiveRole as UserRole, true);
      if (!_tokenRes) return;

      dispatch(
        actions.salsaData.setSalsa({
          salsa: salsaData?.salsa,
          authToken: _tokenRes,
        }),
      );

      resetInterval();
      return true;
    }
    return false;
  };

  const resetInterval = () => {
    refreshInterval.current && window.clearInterval(refreshInterval.current);
    refreshInterval.current = setInterval(checkIfShouldRefreshToken, checkInterval);
  };

  const cleanUp = () => {
    refreshInterval && window.clearInterval(refreshInterval.current);
  };

  useEffect(() => {
    checkIfShouldRefreshToken();
  }, [currentUser?.lastActiveRole]);

  useEffect(() => {
    resetInterval();

    return () => {
      cleanUp();
    };
  }, []);

  return <></>;
};

export default SalsaTokenRefresher;
