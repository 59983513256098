import React, { FC,ReactNode } from "react";
import { theme } from "styles/theme";

import { Container, Dot } from "./styles";

interface Props {
  className?: string;
  children?: string | ReactNode;
  size?: number | string;
  color?: string
}

const DotComponent: FC<Props> = ({ className, children, size, color = theme.colors.titleDot }) => (
  <Container className={className} >
    {children}
    <Dot size={size} color={color}>.</Dot>
  </Container>
);

export default DotComponent;
