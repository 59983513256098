import React, { FC, useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useFileUpload from "react-use-file-upload";

import { companyMetadataSelector } from "redux/selectors";
import { RootState } from "redux/store";

import { PrimaryButton } from "uikit";
import { DotsSpinnerLoader } from "uikit/Loader";

import { CompanyResponseDto, ImportEmployeeFromCSVOutput } from "utils/swagger_react_query";

import { CongratsContent, UploadContent } from "./components";
import { LoaderContent, Modal } from "./styles";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  isUploadingCsvFile: boolean;
  currentModalStep: "upload" | "congrats";
  onDownloadCsvTemplate: () => void;
  onSubmit: (file: File) => void;
  importEmployeeResult: ImportEmployeeFromCSVOutput;
  onDownloadErrorsFile: () => void;
  onFileChange: (files: File[]) => void;
  templateWasDownloaded: boolean;
  uploadError?: string | null;
}

export const ImportEmployeesModal: FC<IProps> = (props) => {
  const { t } = useTranslation();
  const translationPrefix = "people_page.index.csv_import_modal";
  const companyInfo = useSelector(companyMetadataSelector);

  const { files, setFiles, removeFile, clearAllFiles } = useFileUpload();

  useEffect(() => {
    if (!props.isOpen) {
      clearAllFiles();
    }
  }, [props.isOpen]);

  useEffect(() => {
    props.onFileChange(files);
  }, [files]);

  const onClose = () => {
    props.onClose();
  };

  const renderLoaderContent = useMemo(
    () => (
      <LoaderContent>
        <DotsSpinnerLoader />
        {props.isUploadingCsvFile && (
          <PrimaryButton onClick={() => onClose()}>
            <Trans i18nKey={`${translationPrefix}.upload.cancelBtn`} />
          </PrimaryButton>
        )}
      </LoaderContent>
    ),
    [props.isLoading, props.isUploadingCsvFile],
  );

  const renderContentByCurrentStep = useMemo(() => {
    //Why useMemo ffs?
    if (props.currentModalStep === "upload")
      return (
        <UploadContent
          onSubmit={props.onSubmit}
          onClose={onClose}
          onDownloadCsvTemplate={props.onDownloadCsvTemplate}
          files={files}
          setFiles={setFiles}
          removeFile={removeFile}
          templateWasDownloaded={props.templateWasDownloaded}
          uploadError={props.uploadError}
        />
      );
    if (props.currentModalStep === "congrats")
      return (
        <CongratsContent
          importEmployeeResult={props.importEmployeeResult}
          onDownloadErrorsFile={props.onDownloadErrorsFile}
          onClose={onClose}
        />
      );
  }, [
    props.currentModalStep,
    props.uploadError,
    files,
    props.importEmployeeResult,
    props.templateWasDownloaded,
  ]);

  return (
    <Modal
      title={
        !props.isLoading &&
        t(
          `${translationPrefix}.${
            props.isUploadingCsvFile ? "uploadingFileState" : props.currentModalStep
          }.title`,
        )
      }
      message={
        !props.isLoading &&
        t(
          `${translationPrefix}.${
            props.isUploadingCsvFile ? "uploadingFileState" : props.currentModalStep
          }.message`,
          { companyName: companyInfo?.name },
        )
      }
      isOpen={props.isOpen}
      onClose={onClose}
    >
      {props.isLoading || props.isUploadingCsvFile
        ? renderLoaderContent
        : renderContentByCurrentStep}
    </Modal>
  );
};
