import { FC } from "react";

import IconProps from "./IconProps";

const DocumentIcon: FC<IconProps> = ({ className }) => (
  <svg className={className} width="23" height="21" viewBox="0 0 23 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2841_1608)">
      <path d="M12.4417 8.24609H3.87948C3.78586 8.24609 3.70996 8.32282 3.70996 8.41746V8.82485C3.70996 8.91949 3.78586 8.99621 3.87948 8.99621H12.4417C12.5353 8.99621 12.6112 8.91949 12.6112 8.82485V8.41746C12.6112 8.32282 12.5353 8.24609 12.4417 8.24609Z" fill="#007D7B" />
      <path d="M3.8796 9.75003C3.85722 9.74959 3.83477 9.75373 3.81401 9.76219C3.79324 9.77064 3.77455 9.78313 3.75872 9.79913C3.74289 9.81513 3.73023 9.83419 3.72186 9.85518C3.7135 9.87617 3.70956 9.8987 3.70999 9.92133V10.3255C3.70957 10.3483 3.71353 10.3709 3.72186 10.3921C3.73019 10.4133 3.74262 10.4325 3.75841 10.4488C3.77419 10.465 3.79323 10.478 3.81401 10.4868C3.83478 10.4956 3.85707 10.5001 3.8796 10.5001H11.9076L12.6497 9.75003H3.8796Z" fill="#007D7B" />
      <path d="M14.8372 19.1247C14.8372 19.2242 14.798 19.3196 14.7285 19.3899C14.6589 19.4602 14.5645 19.4998 14.4661 19.4998H1.85473C1.75633 19.4998 1.66194 19.4602 1.59236 19.3899C1.52278 19.3196 1.48366 19.2242 1.48366 19.1247V5.25088H3.72257C4.11617 5.25088 4.49374 5.09271 4.77206 4.81136C5.05038 4.53002 5.20653 4.1485 5.20653 3.75061V1.48733H14.482C14.5804 1.48733 14.6748 1.52687 14.7444 1.59721C14.814 1.66755 14.8531 1.76281 14.8531 1.86228V7.52695L16.3371 6.02683V1.50028C16.3371 1.10239 16.1809 0.720711 15.9026 0.439363C15.6243 0.158015 15.2467 0 14.8531 0H5.08191C4.68837 0.000167278 4.31092 0.158292 4.03273 0.439679L0.450413 4.06099C0.172057 4.34221 0.0157905 4.72361 0.015625 5.12143V19.4998C0.015625 19.8977 0.171782 20.2792 0.450101 20.5605C0.728419 20.8419 1.10599 21.0001 1.49959 21.0001H14.8531C15.048 21.0001 15.2409 20.9612 15.4209 20.8858C15.601 20.8104 15.7648 20.6999 15.9026 20.5605C16.0404 20.4212 16.1497 20.2558 16.2243 20.0738C16.2989 19.8918 16.3371 19.6968 16.3371 19.4998V13.4633L14.8531 14.9603L14.8372 19.1247Z" fill="#007D7B" />
      <path d="M10.4298 11.9969L10.542 11.8774L11.1817 11.25H3.8796C3.85722 11.2496 3.83477 11.2537 3.81401 11.2622C3.79324 11.2706 3.77455 11.2833 3.75872 11.2993C3.74289 11.3153 3.73023 11.3343 3.72186 11.3553C3.7135 11.3763 3.70956 11.3989 3.70999 11.4215V11.8256C3.70956 11.8483 3.7135 11.8706 3.72186 11.8916C3.73023 11.9126 3.74289 11.9318 3.75872 11.9478C3.77455 11.9638 3.79324 11.9763 3.81401 11.9848C3.83477 11.9932 3.85722 11.9974 3.8796 11.9969H10.4298Z" fill="#007D7B" />
      <path d="M12.4447 4.5H7.58944C7.49582 4.5 7.41992 4.57672 7.41992 4.67136V5.07875C7.41992 5.17339 7.49582 5.25012 7.58944 5.25012H12.4447C12.5383 5.25012 12.6142 5.17339 12.6142 5.07875V4.67136C12.6142 4.57672 12.5383 4.5 12.4447 4.5Z" fill="#007D7B" />
      <path d="M12.4447 3H7.58944C7.49582 3 7.41992 3.07672 7.41992 3.17136V3.57875C7.41992 3.67339 7.49582 3.75012 7.58944 3.75012H12.4447C12.5383 3.75012 12.6142 3.67339 12.6142 3.57875V3.17136C12.6142 3.07672 12.5383 3 12.4447 3Z" fill="#007D7B" />
      <path d="M22.8497 7.19911C22.7557 7.10493 22.6288 7.05213 22.4964 7.05213C22.3641 7.05213 22.2369 7.10493 22.1428 7.19911L21.7015 7.64526L21.1034 7.0406C21.5447 6.59118 21.823 6.31312 21.823 6.31312C21.9663 6.17513 22.0809 6.00967 22.16 5.82624C22.2391 5.64281 22.281 5.44518 22.2834 5.2451C22.2858 5.04503 22.2487 4.84645 22.1741 4.66113C22.0995 4.4758 21.9889 4.30752 21.8489 4.16604C21.709 4.02455 21.5425 3.91279 21.3592 3.83734C21.1758 3.7619 20.9792 3.72423 20.7813 3.72667C20.5834 3.72911 20.3881 3.77161 20.2066 3.85155C20.0251 3.93149 19.8615 4.04735 19.725 4.19224L12.5859 11.4055L14.684 13.5265L20.3837 7.75846L20.9819 8.36311L17.4636 11.9197C17.3702 12.0153 17.3177 12.1442 17.3177 12.2785C17.3177 12.4129 17.3702 12.5418 17.4636 12.6374C17.6587 12.8346 20.0734 10.7137 22.8528 7.90402C22.9442 7.80977 22.9952 7.68282 22.9946 7.55085C22.994 7.41889 22.9419 7.2925 22.8497 7.19911Z" fill="#F29127" />
      <path d="M8.06644 17.2358C7.90175 17.1156 7.77244 16.9525 7.69225 16.7638C7.5739 16.5439 7.51311 16.2981 7.26683 16.1688C6.79666 15.923 6.48961 16.4275 6.30729 16.7314C6.23548 16.8575 6.15534 16.9785 6.06741 17.0936C6.04182 16.8155 6.02586 16.4177 6.01306 16.0944C5.95229 14.5877 5.91062 13.5078 5.20377 13.5078C3.92439 13.5078 3.74523 16.0719 3.71324 16.8705C3.7111 16.9196 3.7187 16.9687 3.73542 17.0148C3.75215 17.061 3.77768 17.1033 3.8107 17.1394C3.84371 17.1755 3.88362 17.2045 3.92783 17.225C3.97204 17.2454 4.01979 17.2568 4.06838 17.2584C4.16637 17.2611 4.26134 17.2252 4.33357 17.1582C4.4058 17.0912 4.44961 16.9985 4.45538 16.8996C4.50336 15.6645 4.79762 14.5264 5.09507 14.313C5.20632 14.9176 5.26315 15.5312 5.26468 16.1462C5.31585 17.4395 5.33812 18.0216 5.85626 18.0216C6.37441 18.0216 6.71029 17.5171 6.93418 17.1452L7.02694 16.9965C7.05893 17.0482 7.08756 17.1032 7.11315 17.1452C7.23943 17.3944 7.42758 17.6062 7.65914 17.7598C7.89069 17.9134 8.15768 18.0037 8.43407 18.0216C8.50812 18.0221 8.5794 17.993 8.63241 17.9408L8.68676 17.8857L8.06644 17.2358Z" fill="#F29127" />
      <path d="M12.0104 11.9883L11.3292 12.6738C9.53169 14.4909 8.46961 16.2659 8.56556 17.1065L9.04533 17.5915C9.87373 17.6885 11.6297 16.6214 13.4273 14.7946L14.1085 14.106L12.0104 11.9883Z" fill="#F29127" />
    </g>
    <defs>
      <clipPath id="clip0_2841_1608">
        <rect width="23" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
);


export default DocumentIcon;