import styled from "styled-components";

import { breakpoint } from "helpers";

import { H2, NoBorderButton, PrimaryButton } from "uikit";
import UIModal from "uikit/Modal/Modal";

export const Modal = styled(UIModal)`
  overflow: hidden;
  outline: none;
  padding: 20px;
  width: 565px !important;
  height: 400px;

  ${breakpoint("md", "xl")`
    width: 500px!important;
    height: min-content;
  `}

  ${breakpoint("xs", "md")`
    max-width: 450px;
    width: 90%!important;
    min-width: 312px!important;
    height: min-content;
    padding: 20px!important;

    & > div {
      padding: 0;
    }
  `}
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 300px;

  ${breakpoint("md", "xl")`
    height: min-content;
  `}
`;

export const Title = styled(H2)`
  margin-bottom: 48px;

  ${breakpoint("xs", "xl")`
    margin-bottom: 24px;
    font-size: ${({ theme }) => theme.size.subtitleLarge};
  `}
`;

export const BtnsPairWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 90px;

  ${breakpoint("xs", "md")`
    flex-direction: column;
    align-items: center;
  `}
`;

export const SubmitBtn = styled(PrimaryButton)`
  margin: 0 0 0 0;
  padding: 0;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: 180px;

  ${breakpoint("md", "xl")`
      width: 264px;
   `}

  ${breakpoint("xs", "md")`
      width: 100%;
      order: -1;
      margin-bottom: 20px;
   `}
`;

export const CancelBtn = styled(NoBorderButton)`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  width: min-content;

  ${breakpoint("md")`
    margin-right: 60px;
  `}
`;

export const FormContainer = styled.form``;
