import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  PlaidAccount,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink as usePlaidLinkSetup,
} from "react-plaid-link";
import { useSelector } from "react-redux";

import { userMetadataSelector } from "redux/selectors";
import { AppEvents } from "services/events";
import { BankAccountVerifyStatus } from "types/BankAccountVerifyStatus";
import { ErrorConst, PayDistributionType, UserRole } from "types/BETypes";
import { PlaidExitErrors } from "types/plaid";
import { HELP_EMAIL } from "constants/index";
import { showErrorModal } from "helpers/index";

import { EModalTypes } from "uikit/Modal";

import {
  GetPlaidBankAccountsResponseDto,
  mutationPlaidControllerChangeToken,
  mutationPlaidControllerDeleteBankAccount,
  mutationPlaidControllerRenewLoginBankAccount,
  mutationPlaidControllerSetEmployerDefaultBankAccount,
  mutationPlaidControllerVerifyBankAccount,
  mutationUsersControllerUpdateDefaultPayDistribution,
  PlaidControllerGetLinkTokenQueryParams,
  queryPlaidControllerGetLinkToken,
  queryPlaidControllerListBankAccounts,
  UserResponseDto,
} from "utils/swagger_react_query";

/**
 * <script src="https://cdn.plaid.com/link/v2/stable/link-initialize.js"></script>
 * need to be added to index.html
 */

interface IParams {
  shouldFetchBankAccountsListOnMount?: boolean;
  loadingCallback: (state: boolean) => void;
  onSuccessAddedCallback?: (
    bankAccountsList?: GetPlaidBankAccountsResponseDto[],
    newBankAccount?: PlaidAccount[],
    oldBankAccountsList?: GetPlaidBankAccountsResponseDto[],
  ) => void;
}

export interface IUseBankAccountLinkReturn {
  metadata: {
    currentUser: UserResponseDto | null | undefined;
    isReady: boolean;
  };
  data: {
    bankAccounts: GetPlaidBankAccountsResponseDto[];
  };
  actions: {
    openModal: () => void;
    refetchBankAccountsList: () => void;
    handleRemoveBankAccount: (bankAccountId: string, withConfirmationModal?: boolean) => void;
    handleSetDefaultBankAccount: (bankAccountId: string) => void;
    handleVerifyBankAccount: (bankAccountId: string, accessToken: string) => void;
  };
}

export const useBankAccountLink = ({
  shouldFetchBankAccountsListOnMount = true,
  ...params
}: IParams): IUseBankAccountLinkReturn => {
  const { t } = useTranslation();
  const confirmationModalTranslationPrefix = "components.confirm_action";
  const currentUser = useSelector(userMetadataSelector);
  const [plaidPublicToken, setPlaidPublicToken] = useState<string | null>(null);
  const [bankAccounts, setBankAccounts] = useState<GetPlaidBankAccountsResponseDto[]>([]);

  //It is used to verify bank account after exit from Plaid Link
  const [targetBankAccountId, setTargetBankAccountId] = useState<string | null>(null);

  //Plaid Configuration
  const _fetchPlaidLinkToken = async (accessToken?: string) => {
    try {
      let payload: PlaidControllerGetLinkTokenQueryParams | undefined;
      if (accessToken) {
        payload = {
          accessToken,
        };
      }

      const linkTokenResponse = await queryPlaidControllerGetLinkToken(payload);
      setPlaidPublicToken(linkTokenResponse.linkToken);
    } catch (error) {
      console.log(error);
      showErrorModal(error);
    }
  };

  const _setDefaultPayMethod = async (bankAccountId: string) => {
    if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
      await mutationUsersControllerUpdateDefaultPayDistribution()({
        type: PayDistributionType.EXTERNAL_ACCOUNT,
        id: bankAccountId,
      });
    }
  };

  const _onSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    try {
      const isNeedRenewBankAccountLogin = bankAccounts.find(
        (it) => it.bankAccountId === targetBankAccountId,
      )?.renewLoginRequired;
      params?.loadingCallback(true);

      if (metadata.accounts[0].verification_status === BankAccountVerifyStatus.MANUALLY_VERIFIED) {
        const _bankAccountId = bankAccounts?.find(
          (it) => it?.plaidAccountId === metadata.accounts[0].id,
        )?.bankAccountId;
        if (!_bankAccountId) throw new Error("");

        await mutationPlaidControllerVerifyBankAccount({ accountId: _bankAccountId })();
      } else if (isNeedRenewBankAccountLogin) {
        await mutationPlaidControllerRenewLoginBankAccount({
          accountId: targetBankAccountId || "",
        })();
      } else {
        await mutationPlaidControllerChangeToken()({
          publicToken,
        });
      }

      const bankAccountsListRes = await fetchBankAccountsList();
      params?.onSuccessAddedCallback &&
        params.onSuccessAddedCallback(bankAccountsListRes, metadata.accounts, bankAccounts);
    } catch (error: any) {
      params?.loadingCallback(false);
      if (error?.data?.error === ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED) {
        showErrorModal(ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED, undefined, {
          type: EModalTypes.Error,
          message: (
            <Trans
              i18nKey={`errors.${ErrorConst.BANK_ACCOUNT_LIMIT_EXCEEDED}`}
              values={{ helpEmail: HELP_EMAIL }}
            />
          ),
        });
      } else {
        showErrorModal(undefined);
      }
    } finally {
      setTargetBankAccountId(null);
    }
  };

  const _onExit: PlaidLinkOnExit = async (error, metadata) => {
    try {
      if (error?.error_code === PlaidExitErrors.TOO_MANY_VERIFICATION_ATTEMPTS) {
        params.loadingCallback(true);
        if (!targetBankAccountId) throw new Error("");
        await mutationPlaidControllerVerifyBankAccount({ accountId: targetBankAccountId })();
        await fetchBankAccountsList();
      }
    } catch (error) {
      showErrorModal(error);
    } finally {
      setTargetBankAccountId(null);
    }
  };

  const config: PlaidLinkOptions = {
    token: plaidPublicToken,

    onSuccess: _onSuccess,
    onExit: _onExit,
  };

  const { open, ready, error } = usePlaidLinkSetup(config);
  //

  useEffect(() => {
    if (ready) open();
  }, [ready, open]);

  useEffect(() => {
    console.log("Plaid Error: ", error);
  }, [error]);

  useEffect(() => {
    if (shouldFetchBankAccountsListOnMount) {
      fetchBankAccountsList();
    }
  }, []);

  const fetchBankAccountsList = async () => {
    try {
      params?.loadingCallback(true);
      const existBankAccounts =
        (await queryPlaidControllerListBankAccounts()) as unknown as GetPlaidBankAccountsResponseDto[];
      setBankAccounts(existBankAccounts);
      return existBankAccounts;
    } catch (error) {
      console.log(error);
    } finally {
      params?.loadingCallback(false);
    }
  };

  const handleRemoveBankAccount = async (
    bankAccountId: string,
    withConfirmationModal: boolean = true,
  ) => {
    const _removeBankAccount = async (bankAccountId: string) => {
      try {
        params?.loadingCallback(true);
        await mutationPlaidControllerDeleteBankAccount({ accountId: bankAccountId })();
        await fetchBankAccountsList();
      } catch (error) {
        params?.loadingCallback(false);
        showErrorModal(error);
      }
    };

    if (withConfirmationModal) {
      AppEvents.emit("SetGlobalModal", {
        isOpen: true,
        type: EModalTypes.Warning,
        title: t(`${confirmationModalTranslationPrefix}.title`),
        mainButton: {
          text: t(`${confirmationModalTranslationPrefix}.ok`),
          onClick: () => {
            _removeBankAccount(bankAccountId);
          },
        },
        secondaryButton: {
          text: t(`${confirmationModalTranslationPrefix}.cancel`),
          onClick: () => {
            AppEvents.emit("SetGlobalModal", { isOpen: false });
            return;
          },
        },
      });
    } else {
      _removeBankAccount(bankAccountId);
    }
  };

  const handleSetDefaultBankAccount = async (bankAccountId: string) => {
    try {
      params?.loadingCallback(true);

      if (currentUser?.lastActiveRole === UserRole.EMPLOYEE) {
        await _setDefaultPayMethod(bankAccountId);
      } else {
        await mutationPlaidControllerSetEmployerDefaultBankAccount({ accountId: bankAccountId })();
      }

      await fetchBankAccountsList();
    } catch (error) {
      console.log(error);
      showErrorModal(error);
      params?.loadingCallback(false);
    }
  };

  const openPlaidModal = async () => {
    await _fetchPlaidLinkToken();
  };

  const handleVerifyBankAccount = async (bankAccountId: string, accessToken: string) => {
    await _fetchPlaidLinkToken(accessToken);
    setTargetBankAccountId(bankAccountId);
  };

  return {
    metadata: {
      currentUser,
      isReady: ready,
    },
    data: {
      bankAccounts,
    },
    actions: {
      openModal: openPlaidModal,
      refetchBankAccountsList: fetchBankAccountsList,
      handleRemoveBankAccount,
      handleSetDefaultBankAccount,
      handleVerifyBankAccount,
    },
  };
};
