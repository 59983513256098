import styled from "styled-components";

import { PageDescriptionMargined, PageTitle, SecondaryButton } from "uikit";

export const Container = styled.div``;

export const CompanyEmphasized = styled.span`
  text-transform: uppercase;
`;

export const StyledTitle = styled(PageTitle)``;

export const TitleInner = styled.span``;

export const StyledDescription = styled(PageDescriptionMargined)``;

export const ButtonsContainer = styled.div`
  margin-top: 40px;
`;

export const BackButton = styled(SecondaryButton)`
  width: auto;
  min-width: 100px;
`;
