import { FC } from "react";
import { Trans } from "react-i18next";

import DashboardLayout from "layouts/DashboardLayout";

import { ContentContainer, PageTitleMargined } from "uikit";

import useAdminPayPage from "./useAdminPayPage";
//import useEmptyPage from "./useEmptyPage";
import { Container, FrameContainer } from "./styles";

interface Props {
  className?: string;
}

const AdminPayPage: FC<Props> = ({ className }) => {
  const translationPrefix = `pay_page.index`;

  const { frameId } = useAdminPayPage();

  return (
    <DashboardLayout>
      <ContentContainer>
        <FrameContainer id={frameId}></FrameContainer>
      </ContentContainer>
    </DashboardLayout>
  );
};

export default AdminPayPage;
