import { APPLICATION_NAME } from "constants/index";

export const dashboardPageTranslation = {
  "en-US": {
    first_time: {
      title: "Welcome aboard, ",
      subtitle: "Let’s get {{company}} up and running with vesting bonus plans for your employees.",
      content_title: "Get Started Check List",
      info_card: {
        title: "Take a tour",
        text_1: "Learn about all the features",
        text_2: "Keep has to offer.",
      },
    },
    existing: {
      title: "Welcome back, {{firstName}}",
      subTitle: `${APPLICATION_NAME} is here to help you attract and retain your most valued assets, your employees.`,
      content_title: "Happy",
      citation: "Good retention can<br />maximize company profits<br />up to <1>4 times.</1>",
      vesting_title: "Attract new talent and retain employees<br />with a <1>Keep Bonus</1>.",
      create_new_bonus_btn: "Create New Bonus",
      info_card: {
        title: "Need help?",
        text: "Or visit our <1>help center.</1>",
      },
      employees_widget: {
        title: "Employees & Contractors",
        employed: "Employed",
        pending_exit: "Pending Exit",
        exited: "Exited",
        with_bonuses: "With Bonuses",
        with_balance_due: "With a Balance Due",
      },
      bonuses_widget: {
        title: "Bonuses",
        drafts: "Drafts",
        pending: "Pending",
        active: "Active",
        vesting_soon: "Vesting Soon",
        past_due: "Past Due",
        fully_vested: "Fully Vested",
      },
      balance_due_widget: {
        title: "Bonus Amounts",
        total_bonus_amount: "Total Bonus Amount",
        vested_amount: "Vested Amount",
        total_withdrawn_by_employees: "Total Withdrawn Amount by Employees",
        unvested_amount: "Unvested Amount",
        balance_due_amount: "Balance Due Amount",
        funded_amount: "Funded Amount",

        //old
        total_net_amount: "Total Net Amount",
        total_amount_assigned: "Total Amount Assigned",
        collected: "To be Collected",
        recovered: "Recovered",
      },
    },
    team_card: {
      title: "Invite your team",
      optional: "Optional",
      text: "Invite other members of your team to create, manage, and view employee<br/>bonuses. You can create administrators as well as those with view only<br/>permissions.",
      button: "Invite Team Members",
      team_member_added: "Team Members added",
      manage_team_btn: "Manage Team",
    },
    employees_card: {
      title: "Add your employees",
      text: "<1>Add your employees</1> to Keep so you can assign them a Keep Vesting Cash Plan.",
      button: "Add Employee",
      employees_added: "Employees added",
      view_employees_btn: "View Employees",
    },
    documents_card: {
      title: "Review and sign Keep Service Agreement",
      title_completed: "Complete: Signed Service Agreement",
      text: `Please review and sign the ${APPLICATION_NAME} service agreement. This agreement<br />covers the terms and conditions related to this service.`,
      text_existing: `Thank you for signing the ${APPLICATION_NAME} Service Agreement. You can find your<br/> agreement under your profile.`,
      button: "View & Sign Document",
      signed_document: "Signed Service Agreement",
      await_keep_sign: `Awaiting ${APPLICATION_NAME} Signature`,
      signature_by_both: "Signature by both parties complete",
      view_document_btn: "View Signed Contract",
      tool_tip_signed: `${APPLICATION_NAME} has received your signature. A ${APPLICATION_NAME} representative is currently reviewing the final document and will provide their corresponding signature soon.`,
      tool_tip_completed:
        "Congratulations. Both parties have signed the service agreement. You may begin creating and sending bonuses.",
      declined_title: `Please review and sign ${APPLICATION_NAME} Service Agreement`,
      declined_text: `Please review and sign the ${APPLICATION_NAME} service agreement. This agreement<br/>covers the terms and conditions related to this service.`,
      keep_sa: `${APPLICATION_NAME} Service Agreement`,
      declined_label: "Declined Agreement",
      declined_message: `Sorry to see {{company}} has declined the ${APPLICATION_NAME} Service Agreement.<br/>Would you like to <span>schedule a time</span> to discuss your contract?`,
    },
    bonus_card: {
      title: "Create and send your first bonus",
      text: "Use the Keep vesting bonus tool to create custom bonuses for employees.<br />Once created, you can invite your employees to review and accept bonuses.",
      text_existing: "Congratulations! {{fullName}} has created the first {{company}} bonus.",
      button: "Create Bonus",
      button_existing: "View All Bonuses",
      draft_bonus_created: "Draft Bonus Created",
      view_drafts_btn: "View Drafts",
      create_new_bonus: "Create New Bonus",
    },
  },
};

export default dashboardPageTranslation;
