import { FC } from "react";
import { Trans } from "react-i18next";

import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "constants/index";

import { Container, CopyrightText, Link, LinksContainer } from "./styles";

interface Props {
  className?: string;
}

const Copyright: FC<Props> = ({ className }) => {
  const prefix = "copyright";
  return (
    <Container className={className}>
      <CopyrightText>
        <Trans i18nKey={`${prefix}.copyright`} />
      </CopyrightText>
      <LinksContainer>
        <Link href={TERMS_AND_CONDITIONS} target="_blank">
          <Trans i18nKey={`${prefix}.terms_conditions`} />
        </Link>
        <Link href={PRIVACY_POLICY} target="_blank">
          <Trans i18nKey={`${prefix}.privacy_policy`} />
        </Link>
      </LinksContainer>
    </Container>
  );
};

export default Copyright;
