import { ChevronRight } from "assets/svg";
import styled from "styled-components";

import { ITheme } from "types";
import { breakpoint } from "helpers/shared/breakpoint";

export const AccordionItemContainer = styled.div`
  border: none;
  box-shadow: 0 0 4px ${({ theme }) => theme.colors.accordionCollapseContainerShadow};
  border-radius: 11px;
  margin-bottom: 32px;

  &.alt {
    border-radius: 0;
    box-shadow: none;
    border-bottom: 2px solid ${({ theme }) => theme.colors.contentBorderColor};
  }
`;

interface AccordionTitleProps extends ITheme {
  isActive?: boolean;
  isDisabled?: boolean;
  isToggleDisabled?: boolean;
}

export const AccordionTitleContainer = styled.div<AccordionTitleProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 28px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.subtitle};
  cursor: ${({ isDisabled, isToggleDisabled }) =>
    isDisabled || isToggleDisabled ? "default" : "pointer"};
  color: ${({ isDisabled, theme }) => {
    if (isDisabled) {
      return theme.colors.accordionCollapseTextDisabled;
    } else {
      return theme.colors.accordionCollapseText;
    }
  }};

  ${breakpoint("xs", "lg")`
    padding: 20px 22px 20px 16px;
  `}

  &.alt {
    padding: 15px 28px 15px 0;
    font-size: ${({ theme }) => theme.size.body1};
    ${breakpoint("xs", "lg")`

    padding: 10px 22px 10px 0px;
  `}
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${breakpoint("xs", "lg")`
    font-weight: ${({ theme }) => theme.fontWeight.medium};
    font-size: ${({ theme }) => theme.size.body2};
  `}
`;

export const MainTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 5px;
`;

export const AdditionalTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.disabled};
  font-style: italic;
`;

export const TitleInner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

interface ChevronProps extends ITheme {
  isExpanded: boolean;
}

export const StyledChevron = styled(ChevronRight)<ChevronProps>`
  width: 10px;
  height: auto;
  transform: ${({ isExpanded }) => (isExpanded ? "rotate(90deg)" : "rotate(0deg)")};
  transition: all 0.3s;

  & path {
    fill: currentColor;
  }
`;

export const AccordionItemContent = styled.div``;

export const AccordionText = styled.span`
  display: inline-block;
  font-family: ${({ theme }) => theme.fontFamily.default};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.body2};
  color: ${({ theme }) => theme.colors.accentMain};
`;

export const AccordionBtn = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  color: ${({ theme }) => theme.colors.accentMain};

  ${breakpoint("xs", "lg")`
    align-self: start;
  `}
`;

export const AccordionTitleBase = styled.span`
  font-size: ${({ theme }) => theme.size.subtitle};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.textAlt};
  font-family: ${({ theme }) => theme.fontFamily.alt};
`;
