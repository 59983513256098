import { FC, Fragment, ReactNode } from "react";
import ReactTooltip from "react-tooltip";

import MenuFooter from "./MenuFooter";
import { useMainMenu } from "./useMainMenu";
import {
  BottomCorner,
  CollapseBtn,
  CollapseBtnIcon,
  IconContainer,
  LabelContainer,
  List,
  MBMainContainer,
  MBTop,
  MenuBaseLink,
  MenuBlock,
  MenuFooterContainer,
  MenuLink,
  MiscContainer,
  StyledLogo,
  TopCorner,
} from "./styles";

export interface MenuItem {
  id: string;
  icon: ReactNode;
  label: string;
  url?: string;
  href?: string;
  hide?: boolean;
}

export interface Props {
  needRenderMenuForResponsive?: boolean;
}

const MainMenu: FC<Props> = ({ needRenderMenuForResponsive }) => {
  const { metadata, state } = useMainMenu();
  const collapsedClassName = state.isCollapsed ? "collapsed" : "";
  const classNameByRole = `role-${metadata.currentUser?.lastActiveRole} ${collapsedClassName}`;

  const menu: MenuItem[] = metadata.getMenu();

  const renderMenuLink = (item: MenuItem) => (
    <Fragment>
      <TopCorner className="corner" />
      <IconContainer>{item.icon}</IconContainer>
      {<LabelContainer className={collapsedClassName}>{item.label}</LabelContainer>}
      <MiscContainer></MiscContainer>
      <BottomCorner className="corner" />
      <ReactTooltip
        id={item.id}
        place="right"
        type="dark"
        effect="solid"
        className={`tooltip ${collapsedClassName}`}
      />
    </Fragment>
  );

  const renderMenuLinkWrapper = (item: MenuItem) => {
    const commonProps = {
      className: classNameByRole,
      key: item.id,
      ["data-for"]: item.id,
      ["data-tip"]: item.label,
    };

    if (item.href)
      return (
        <MenuBaseLink href={item.href} target="_blank" {...commonProps}>
          {renderMenuLink(item)}
        </MenuBaseLink>
      );
    if (item.url)
      return (
        <MenuLink to={item.url} {...commonProps}>
          {renderMenuLink(item)}
        </MenuLink>
      );
  };

  return (
    <MenuBlock
      className={classNameByRole}
      needRenderMenuForResponsive={!!needRenderMenuForResponsive}
    >
      {!needRenderMenuForResponsive && (
        <CollapseBtn className={classNameByRole} onClick={() => state.handleCollapse()}>
          <CollapseBtnIcon className={collapsedClassName} />
        </CollapseBtn>
      )}
      <MBMainContainer>
        <MBTop className={collapsedClassName}>
          {!needRenderMenuForResponsive && <StyledLogo className={collapsedClassName} />}
          <List>
            {menu.filter((it) => !it.hide).map((item: MenuItem) => renderMenuLinkWrapper(item))}
          </List>
        </MBTop>
        <MenuFooterContainer>
          <MenuFooter />
        </MenuFooterContainer>
      </MBMainContainer>
    </MenuBlock>
  );
};

export default MainMenu;
