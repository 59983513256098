import { FC } from "react";

import IconProps from "./IconProps";

const BinIcon: FC<IconProps> = ({ className }) => (
  <svg
    className={className}
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="#E31C1C" />
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#E31C1C" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.5418 37.0022H24.456C23.2798 37.0022 22.3019 36.0966 22.2117 34.9239L21.2461 22.3711H34.7517L33.7861 34.9239C33.6959 36.0966 32.718 37.0022 31.5418 37.0022V37.0022Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M36.0028 22.3713H19.9961"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M25.186 18.9961H30.8134C31.4349 18.9961 31.9388 19.5 31.9388 20.1216V22.3725H24.0605V20.1216C24.0605 19.5 24.5644 18.9961 25.186 18.9961Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.969 26.873V32.5004"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M26.0295 26.873V32.5004"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export default BinIcon;
