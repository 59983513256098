import { ErrorCircledCrossLarge } from "assets/svg";
import styled from "styled-components";

import CompanyContactInfo from "components/CompanyContactInfo";
import ContactInfo from "components/ContactInfo";

export const StyledTitle = styled.div`
  margin-top: 32px;
`;

export const DescriptionWithNoTitle = styled.div`
  margin-top: 32px;
`;

export const ErrorIcon = styled(ErrorCircledCrossLarge)`
  width: 75px;
  height: 75px;
`;

export const ErrorSubtitle = styled.span`
  display: block;
  margin-top: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.body1};
  color: ${({ theme }) => theme.colors.danger2};
`;

export const StyledCompanyContactInfo = styled(CompanyContactInfo)`
  margin-top: 46px;
`;

export const ImageIcon = styled.img`
  height: 130px;
`;

export const TooltipContainer = styled.div`
  position: relative;
`;

export const StyledContactInfo = styled(ContactInfo)`
  position: relative;
`;
