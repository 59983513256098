import { FC } from "react";

import IconProps from "./IconProps";

const UploadIcon: FC<IconProps> = ({ className }) => (
  <svg width="57" height="39" className={className} viewBox="0 0 57 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_17_7682)">
      <path d="M47.513 12.1957C47.2239 9.23712 45.9523 6.45554 43.8956 4.28321C41.839 2.11088 39.1125 0.669444 36.1398 0.18292C33.1672 -0.303604 30.1148 0.19202 27.4571 1.59277C24.7995 2.99351 22.6852 5.22094 21.443 7.92892C19.7458 7.25603 17.9008 7.02833 16.0878 7.26804C14.2748 7.50774 12.5557 8.20666 11.098 9.29668C9.64035 10.3867 8.49383 11.8306 7.77021 13.4878C7.04658 15.1449 6.77054 16.9587 6.96897 18.7524C4.93219 19.0486 3.08437 20.0938 1.79647 21.6782C0.50856 23.2626 -0.124106 25.2689 0.0254693 27.2943C0.175045 29.3197 1.09579 31.2144 2.60288 32.598C4.10996 33.9815 6.09183 34.7516 8.15063 34.7535L25.135 34.772V29.2056H21.1762C20.862 29.2056 20.554 29.1191 20.2869 28.9557C20.0198 28.7924 19.8043 28.5587 19.6647 28.281C19.5251 28.0033 19.4668 27.6927 19.4966 27.3841C19.5263 27.0755 19.6428 26.7812 19.833 26.5345L28.2046 15.6689C28.3621 15.4647 28.5652 15.2992 28.7983 15.1853C29.0313 15.0713 29.2878 15.0121 29.5479 15.0121C29.8079 15.0121 30.0645 15.0713 30.2975 15.1853C30.5305 15.2992 30.7337 15.4647 30.8911 15.6689L39.2628 26.5345C39.4517 26.7813 39.5672 27.0751 39.5962 27.383C39.6253 27.6909 39.5668 28.0008 39.4273 28.2777C39.2878 28.5547 39.0728 28.7878 38.8065 28.9509C38.5402 29.1141 38.233 29.2008 37.9195 29.2013H33.9573V34.7788L45.4524 34.7914C48.34 34.797 51.1249 33.7353 53.2581 31.8156C55.3914 29.8959 56.7181 27.2575 56.9767 24.4206C57.2354 21.5837 56.4071 18.7542 54.6553 16.4899C52.9035 14.2256 50.3553 12.6908 47.513 12.1881V12.1957Z" fill="#C2C1C0" />
      <path d="M29.5474 16.6748L21.1758 27.5396H26.825V38.9998H32.2699V27.5396H37.9191L29.5474 16.6748Z" fill="#F29127" />
    </g>
    <defs>
      <clipPath id="clip0_17_7682">
        <rect width="57" height="39" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UploadIcon;