import { FC, ReactNode } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { LogoFull } from "assets/svg";

import { userMetadataSelector } from "redux/selectors";
import AccountMenu from "components/AccountMenu";
import BackButton from "components/BackButton";
import MainMenu, { ResponsiveMenu } from "components/MainMenu";

import { ContentContainer, Logo } from "uikit";

import {
  Container,
  Content,
  ContentBlock,
  ContentBlockInner,
  MLCInner,
  MobileLogoContainer,
  MobileMenuContainer,
  StyledCopyright,
  TCContent,
  TCInner,
  TCInnerLeft,
  TCInnerRight,
  TCMobileContent,
  TopContent,
  TopContentWrapper,
  TopMain,
} from "./styles";

interface WrapperProps extends Props {
  backBtnText?: string;
  onBackBtnClick?: () => void;
}

interface Props {
  className?: string;
  children?: ReactNode | string;
  topContent?: ReactNode | string;
  mobileTopContent?: ReactNode | string;
  contentBg?: string;
  showUserMenu?: boolean;
  showResponsiveMenu?: boolean;
  needRenderMenuForResponsive?: boolean;
  responsivePageName?: ReactNode | string;
}

const DashboardLayout: FC<Props> = ({
  className,
  children,
  topContent,
  mobileTopContent,
  contentBg,
  showUserMenu = true,
  showResponsiveMenu = true,
  needRenderMenuForResponsive = false,
  responsivePageName,
}) => {
  const currentUser = useSelector(userMetadataSelector);
  const roleClassName = `role-${currentUser?.lastActiveRole}`;

  return (
    <Container className={`${className} ${roleClassName}`}>
      <TopMain>
        <MainMenu needRenderMenuForResponsive={needRenderMenuForResponsive} />
        <ContentBlock style={{ backgroundImage: contentBg ? `url(${contentBg})` : "none" }}>
          <ContentBlockInner>
            <TopContentWrapper>
              <MobileLogoContainer>
                <MLCInner>
                  <LogoFull />
                </MLCInner>
              </MobileLogoContainer>
              <TopContent>
                {showResponsiveMenu && (
                  <MobileMenuContainer>
                    <ResponsiveMenu pageName={responsivePageName} />
                  </MobileMenuContainer>
                )}

                <TCInner>
                  <TCInnerLeft>
                    {mobileTopContent && <TCMobileContent>{mobileTopContent}</TCMobileContent>}
                    {topContent && <TCContent>{topContent}</TCContent>}
                  </TCInnerLeft>
                  <TCInnerRight>{showUserMenu && <AccountMenu />}</TCInnerRight>
                </TCInner>
              </TopContent>
            </TopContentWrapper>
            <Content>{children}</Content>
          </ContentBlockInner>
        </ContentBlock>
      </TopMain>
    </Container>
  );
};

const DashboardLayoutWrapper: FC<WrapperProps> = ({ onBackBtnClick, backBtnText, ...rest }) => {
  const renderBackButton = () => (
    <BackButton onClick={() => (onBackBtnClick ? onBackBtnClick() : undefined)}>
      <Trans i18nKey={backBtnText || "common.back"} />
    </BackButton>
  );
  return (
    <DashboardLayout
      {...rest}
      topContent={onBackBtnClick && renderBackButton()}
      //mobileTopContent={onBackBtnClick && renderBackButton()} //According to new desings, we won't have a back button on top on mobile devices
    />
  );
};

export default DashboardLayoutWrapper;
