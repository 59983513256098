export enum EIDPsList {
  ADFSSAML = "ADFSSAML",
  AdpOidc = "AdpOidc",
  Auth0SAML = "Auth0SAML",
  AzureSAML = "AzureSAML",
  CasSAML = "CasSAML",
  ClassLinkSAML = "ClassLinkSAML",
  CloudflareSAML = "CloudflareSAML",
  CyberArkSAML = "CyberArkSAML",
  DuoSAML = "DuoSAML",
  GenericOIDC = "GenericOIDC",
  GenericSAML = "GenericSAML",
  GoogleOAuth = "GoogleOAuth",
  GoogleSAML = "GoogleSAML",
  JumpCloudSAML = "JumpCloudSAML",
  KeycloakSAML = "KeycloakSAML",
  LastPassSAML = "LastPassSAML",
  MagicLink = "MagicLink",
  MicrosoftOAuth = "MicrosoftOAuth",
  MiniOrangeSAML = "MiniOrangeSAML",
  NetIqSAML = "NetIqSAML",
  OktaSAML = "OktaSAML",
  OneLoginSAML = "OneLoginSAML",
  OracleSAML = "OracleSAML",
  PingFederateSAML = "PingFederateSAML",
  PingOneSAML = "PingOneSAML",
  RipplingSAML = "RipplingSAML",
  SalesforceSAML = "SalesforceSAML",
  ShibbolethGenericSAML = "ShibbolethGenericSAML",
  ShibbolethSAML = "ShibbolethSAML",
  SimpleSamlPhpSAML = "SimpleSamlPhpSAML",
  VMwareSAML = "VMwareSAML",
}

export const comprehensibleIDPsList = {
  [EIDPsList.ADFSSAML]: "ADFS",
  [EIDPsList.AdpOidc]: "Adp",
  [EIDPsList.Auth0SAML]: "Auth0",
  [EIDPsList.AzureSAML]: "Azure",
  [EIDPsList.CasSAML]: "Cas",
  [EIDPsList.ClassLinkSAML]: "ClassLink",
  [EIDPsList.CloudflareSAML]: "Cloudflare",
  [EIDPsList.CyberArkSAML]: "CyberArk",
  [EIDPsList.DuoSAML]: "Duo",
  [EIDPsList.GenericOIDC]: "Generic",
  [EIDPsList.GenericSAML]: "Generic",
  [EIDPsList.GoogleOAuth]: "GoogleOAuth",
  [EIDPsList.GoogleSAML]: "Google",
  [EIDPsList.JumpCloudSAML]: "JumpCloud",
  [EIDPsList.KeycloakSAML]: "Keycloak",
  [EIDPsList.LastPassSAML]: "LastPass",
  [EIDPsList.MagicLink]: "MagicLink",
  [EIDPsList.MicrosoftOAuth]: "MicrosoftOAuth",
  [EIDPsList.MiniOrangeSAML]: "MiniOrange",
  [EIDPsList.NetIqSAML]: "NetIq",
  [EIDPsList.OktaSAML]: "Okta",
  [EIDPsList.OneLoginSAML]: "OneLogin",
  [EIDPsList.OracleSAML]: "Oracle",
  [EIDPsList.PingFederateSAML]: "PingFederate",
  [EIDPsList.PingOneSAML]: "PingOne",
  [EIDPsList.RipplingSAML]: "Rippling",
  [EIDPsList.SalesforceSAML]: "Salesforce",
  [EIDPsList.ShibbolethGenericSAML]: "ShibbolethGeneric",
  [EIDPsList.ShibbolethSAML]: "Shibboleth",
  [EIDPsList.SimpleSamlPhpSAML]: "SimpleSamlPhp",
  [EIDPsList.VMwareSAML]: "VMware",
};
