import styled from "styled-components";

import { breakpoint } from "helpers/shared/breakpoint";

import { Body3, LinkTextMixin, NoBorderButton, PrimaryButton } from "uikit";

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  p {
    margin-bottom: 0;
  }

  img,
  svg {
    width: 49px;
    height: 49px;
    border-radius: 6px;
    margin-bottom: 3px;
    margin-right: 22px;

    ${breakpoint("md", "xl")`
      width: 40px;
      height: 40px;
      margin-right: 8px;
   `}
  }
`;

export const Text = styled(Body3)`
  width: 100%;
  margin-bottom: 15px;
  font-size: ${({ theme }) => theme.size.body2};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${breakpoint("xs", "lg")`
    font-size: ${({ theme }) => theme.size.caption};
  `}
`;

export const SecondaryBtn = styled(NoBorderButton)`
  padding: 0;
  margin: 0;
  color: ${({ theme }) => theme.colors.accentMain};
  font-size: ${({ theme }) => theme.size.body2};
  width: auto;
  height: auto;
`;

export const SecondaryBtnWrapper = styled.div`
  width: 53px;
  min-width: 53px;
  align-self: center;

  ${breakpoint("xs", "xl")`
    margin-left: 10px;
  `}
`;

export const VerifyBtn = styled(PrimaryButton)`
  width: 100%;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
`;

export const VerifyBtnWrapper = styled.div`
  max-width: 180px;
  width: 100%;
  margin-left: auto;
  margin-right: 20px;
  align-self: center;

  ${breakpoint("lg", "xl")`
    width: 100%;
    margin-right: 10px;
  `}

  ${breakpoint("md", "lg")`
    margin-right: 0;
  `}
`;

export const PendingVerifyLink = styled.span`
  ${LinkTextMixin};
  font-style: italic;
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;

  & > div:nth-child(2) {
    width: 280px;

    ${breakpoint("lg", "xl")`
        width: 240px;
        margin-right: 15px;
        `}

    ${breakpoint("md", "lg")`
        width: 190px;
        margin-right: 10px;
      `}
  }

  & > div {
    margin-right: 30px;

    ${breakpoint("lg", "xl")`
        margin-right: 20px;
        `}

    ${breakpoint("md", "lg")`
        margin-right: 15px;
      `}

      & > p:nth-child(1) {
      font-weight: ${({ theme }) => theme.fontWeight.bold};
    }
    & > p:nth-child(2) {
      font-weight: ${({ theme }) => theme.fontWeight.regular};
    }
  }
`;

export const BankTypeSection = styled.div``;

export const VerificationStatusSection = styled.div``;

export const VSSText = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.colors.danger2};
  font-style: italic;
`;

export const AccountNumberSection = styled.div`
  width: 120px;

  ${breakpoint("xs", "lg")`
      width: 100px;
  `}
`;
