import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import routes from "routes/routes";

import reduxActions from "redux/actions";
import { userMetadataSelector } from "redux/selectors";
import { KycStatus } from "types/BETypes";
import { showErrorModal } from "helpers";
import useAuth from "hooks/useAuth";
import {
  EAcknowledgementModalTypes,
  IKYCProfileFormRef,
  KYCProfileFormType,
  useKycStatusModals,
} from "components/KYCVerificationWidget";
import {
  IPaidCardShippingDetailsFormRef,
  PaidCardShippingDetailsFormType,
} from "components/PaidCardShippingDetailsForm";

import {
  mutationBankCardsControllerIssueBankCard,
  mutationBankCardsControllerKycVerify,
} from "utils/swagger_react_query";

import { STEPS } from "./constants";
import {
  profileFormValuesToRequestPayload,
  shippingDetailsFormValuesToRequestPayload,
} from "./helpers";
import { IUsePaidBankAccountCreatingWidget } from "./types";

export const usePaidBankAccountCreatingWidget = ({
  onKycVerificationSuccess,
  onShippingDetailsSuccess,
  onBackBtnClickOnKycStep,
  useOnboardingLogic = false, //TODO: Ducttape, fix later
  initialStep = STEPS.KYC_VERIFICATION,
}: IUsePaidBankAccountCreatingWidget) => {
  const navigate = useNavigate();
  const translationPrefix = `components.kyc_verification_widget`;

  const [isLoading, setLoading] = useState<boolean>(false);
  const currentUser = useSelector(userMetadataSelector);
  const dispatch = useDispatch();
  const { getCurrentUser } = useAuth();

  const [currentStep, setCurrentStep] = useState<STEPS>(initialStep);
  const [isPrimaryBtnDisabled, setIsPrimaryBtnDisabled] = useState<Record<STEPS, boolean>>({
    [STEPS.KYC_VERIFICATION]: false,
    [STEPS.SHIPPING_DETAILS]: true,
  });

  const profileFormRef = useRef<IKYCProfileFormRef>(null);
  const [isKycVerifying, setIsKycVerifying] = useState<boolean>(false);
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState<boolean>(false);
  const { hideModal, showModal } = useKycStatusModals(translationPrefix);
  const [kycVerificationSvoc, setKycVerificationSvoc] = useState<boolean>(false);

  const shippingDetailsFormRef = useRef<IPaidCardShippingDetailsFormRef>(null);
  const [isShippingDetailsSubmitting, setIsShippingDetailsSubmitting] = useState<boolean>(false);
  const [shippingDetailsSvoc, setShippingDetailsSvoc] = useState<boolean>(false);

  useEffect(() => {
    const isKycAccepted = currentUser?.kycStatus === KycStatus.ACCEPTED;

    if (currentStep === STEPS.KYC_VERIFICATION) {
      if (!isKycAccepted) {
        setIsPrimaryBtnDisabled((prev) => ({
          ...prev,
          [STEPS.KYC_VERIFICATION]: !isTermsAndConditionsAccepted,
        }));
      } else {
        setIsPrimaryBtnDisabled((prev) => ({
          ...prev,
          [STEPS.KYC_VERIFICATION]: false,
        }));
      }
    }

    if (currentStep === STEPS.SHIPPING_DETAILS) {
      setIsPrimaryBtnDisabled((prev) => ({
        ...prev,
        [STEPS.SHIPPING_DETAILS]: false,
      }));
    }
  }, [currentUser?.kycStatus, currentStep, isTermsAndConditionsAccepted]);

  const handleNavigateToPreviousPage = () => navigate(routes.EMPLOYEE_SETTINGS);

  const refetchUser = async () => {
    setLoading(true);
    await getCurrentUser();
    setLoading(false);
  };

  //KYC STEP
  const handleProfileFormSubmit = async (values: KYCProfileFormType) => {
    //Submit KYC verification
    try {
      showModal({ type: EAcknowledgementModalTypes.PROGRESS });
      const payload = profileFormValuesToRequestPayload(values);
      const response = await mutationBankCardsControllerKycVerify()(payload);
      hideModal();

      setTimeout(async () => {
        if (response.kycStatus === KycStatus.ACCEPTED) {
          showModal({
            type: EAcknowledgementModalTypes.SUCCESS,
          });
          if (useOnboardingLogic) {
            dispatch(reduxActions.userMetaData.setWorkerKycOnboardingInProgress(true));
          }

          onKycVerificationSuccess && onKycVerificationSuccess();
        } else if (
          [
            KycStatus.PROVIDER_FAILURE,
            KycStatus.REJECTED,
            KycStatus.UNVERIFIED,
            KycStatus.VENDOR_ERROR,
          ].includes(response.kycStatus as KycStatus)
        ) {
          showModal({ type: EAcknowledgementModalTypes.ERROR });
          //await refetchUser();
        } else if (
          [KycStatus.PENDING, KycStatus.PROVISIONAL, KycStatus.REVIEW].includes(
            response.kycStatus as KycStatus,
          )
        ) {
          showModal({
            type: EAcknowledgementModalTypes.VERIFICATION_IS_TAKING_TOO_LONG,
          });
          //await refetchUser();
        }
      });
    } catch (error) {
      hideModal();
      setTimeout(() => {
        showErrorModal(error);
      });
    } finally {
      setIsKycVerifying(false);
      await refetchUser();
      setLoading(false);
    }
  };

  const handleChangeTermsAndConditions = () => {
    setIsTermsAndConditionsAccepted((prev) => !prev);
  };

  // SHIPPING DETAILS STEP
  const handleSubmitShippingDetails = async (values: PaidCardShippingDetailsFormType) => {
    try {
      setIsShippingDetailsSubmitting(true);
      const payload = shippingDetailsFormValuesToRequestPayload(values);
      await mutationBankCardsControllerIssueBankCard()(payload);
      setIsPrimaryBtnDisabled((prev) => ({
        ...prev,
        [STEPS.SHIPPING_DETAILS]: true,
      }));
      if (useOnboardingLogic) {
        dispatch(reduxActions.userMetaData.setWorkerKycOnboardingInProgress(false));
      }
      onShippingDetailsSuccess && onShippingDetailsSuccess();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setIsShippingDetailsSubmitting(false);
    }
  };

  const formSubmitter = () => {
    //Trigger form validation for KYC verification and shipping details
    if (currentStep === STEPS.KYC_VERIFICATION) {
      setKycVerificationSvoc(true);
      profileFormRef?.current?.onSubmit();
    }
    if (currentStep === STEPS.SHIPPING_DETAILS) {
      setShippingDetailsSvoc(true);
      shippingDetailsFormRef?.current?.onSubmit();
    }
  };

  const primaryBtnClickHandler = () => {
    const isKycAccepted = currentUser?.kycStatus === KycStatus.ACCEPTED;
    if (currentStep === STEPS.KYC_VERIFICATION && isKycAccepted) {
      setCurrentStep(STEPS.SHIPPING_DETAILS);
    }
  };

  const backBtnClickHandler = () => {
    if (currentStep === STEPS.KYC_VERIFICATION) {
      if (useOnboardingLogic) {
        dispatch(reduxActions.userMetaData.setWorkerKycOnboardingInProgress(false));
      }
      onBackBtnClickOnKycStep?.();
    }
    if (currentStep === STEPS.SHIPPING_DETAILS) {
      setCurrentStep(STEPS.KYC_VERIFICATION);
    }
  };

  return {
    metadata: {
      translationPrefix,
      isLoading,
      currentUser,
      step: {
        currentStep,
        setCurrentStep,
      },
      isPrimaryBtnDisabled,
    },
    pageData: {
      verificationStep: {
        isTermsAndConditionsAccepted,
        handleChangeTermsAndConditions,
        handleProfileFormSubmit,
        profileForm: {
          ref: profileFormRef,
          isKycVerifying,
          svoc: kycVerificationSvoc,
        },
      },
      shippingDetails: {
        handleSubmitShippingDetails,
        ref: shippingDetailsFormRef,
        isShippingDetailsSubmitting,
        svoc: shippingDetailsSvoc,
      },
    },
    actions: {
      handleNavigateToPreviousPage,
      formSubmitter,
      primaryBtnClickHandler,
      backBtnClickHandler,
    },
  };
};
