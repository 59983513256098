import { FC, ReactNode } from "react";
import ReactTooltip, { TooltipProps } from "react-tooltip";

import { TooltipContainer } from "./styles";

interface Props extends TooltipProps {
  className?: string;
  id?: string;
  children?: string | ReactNode;
  tooltipHTML?: ReactNode;
}

const Tooltip: FC<Props> = ({ className, id, children, tooltipHTML, ...rest }) => (
  <TooltipContainer
    data-for={id}
    className={className}
    style={{ position: "relative" }}
    data-html={false}
    data-tip={false}
  >
    {children}
    {tooltipHTML && (
      <ReactTooltip id={id} place="top" type="light" effect="solid" delayUpdate={500} {...rest}>
        {tooltipHTML}
      </ReactTooltip>
    )}
  </TooltipContainer>
);

export default Tooltip;
