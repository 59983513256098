import styled from "styled-components";

import { PrimaryButtonIconed } from "uikit";

export const Container = styled.div``;

export const AddBankAccountButton = styled(PrimaryButtonIconed)`
  width: auto;
  min-width: auto;
`;
