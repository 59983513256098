import { ReactSwitchProps } from "react-switch";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SwitchContent = styled.div``;

export const SwitchContainer = styled.div<Partial<ReactSwitchProps>>`
  display: flex;
  justify-content: center;
  align-items: center;

  .react-switch-handle {
    box-shadow: 0px 1px 5px 0px ${({ theme }) => theme.colors.contentBoxShadow} !important;
    width: 26px !important;
    height: 26px !important;
    top: -5px !important;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  color: ${({ theme }) => theme.colors.switchText};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  font-size: ${({ theme }) => theme.size.caption};
`;
