import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "routes/routes";

import { userMetadataSelector } from "redux/selectors";
import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { showErrorModal } from "helpers/index";
import useAuth from "hooks/useAuth";

import { mutationUsersControllerAcceptTermsAndConditions } from "utils/swagger_react_query";

import { IAuthInvitationSearchParams, useCompleteSignIn } from "../_shared";

export const useEmployeeSignUp = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const { logout, isAuthenticated, getCurrentUser } = useAuth();
  const currentUser = useSelector(userMetadataSelector);
  const navigate = useNavigate();
  const { actions, metadata } = useCompleteSignIn({
    mode: EMagicLinkFlow.SIGN_UP,
    autoLogin: false,
  });

  useEffect(() => {
    autoLogin(EMagicLinkFlow.SIGN_UP, getAuthParams());
  }, []);

  const getAuthParams = (): IAuthInvitationSearchParams => {
    return {
      invitationId: searchParams.get("invitationId") || undefined,
      error: searchParams.get("error") || undefined,
    };
  };

  const autoLogin = async (mode: EMagicLinkFlow, authParams: IAuthInvitationSearchParams) => {
    if (searchParams.size || (searchParams.size && isAuthenticated())) {
      await actions.completeSignIn(
        mode,
        { error: authParams.error, code: authParams.invitationId },
        authParams.invitationId,
      );
      setLoading(false);
    } else if (!isAuthenticated()) {
      navigate(routes.SIGN_IN);
    }

    await actions.checkUserData(undefined, true);
    setLoading(false);
    actions.setLoading(false);
  };

  const handleAcceptInvitation = async () => {
    try {
      setLoading(true);
      await mutationUsersControllerAcceptTermsAndConditions()();
      const userInfoRes = await getCurrentUser();
      if (userInfoRes?.userId) {
        actions.redirectByRoleAndSalsaOnboardingStatus(userInfoRes);
      }
    } catch (error) {
      console.log(error);
      showErrorModal(error, logout);
    }
  };

  return {
    metadata: {
      isLoading,
      userInfo: currentUser,
      pageLoader: metadata.isLoading,
    },
    actions: {
      handleAcceptInvitation,
    },
  };
};
