import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { FormikErrors, FormikProps, useFormikContext } from "formik";

import SelectOption from "types/SelectOption";
import { MAX_INPUT_LENGTH } from "constants/";
import { getFieldError } from "helpers";

import { InputWithSearch } from "uikit";

import { ListEmployeesEmployeeResponseDto, UserResponseDto } from "utils/swagger_react_query";

import { BtnsPairWrapper, CancelBtn, FormContainer, SubmitBtn } from "../styles";
import { InviteTeamMemberFormType } from "./validationSchema";

interface IProps {
  translationPrefix: string;
  employeesList: ListEmployeesEmployeeResponseDto[];
  hasMore: boolean;
  isLoading: boolean;
  onCancel: () => void;
  handleSearch: (value: string) => void;
  fetchMoreUsers: () => void;
}

export const InviteTeamMemberForm: FC<IProps> = ({
  translationPrefix,
  employeesList,
  hasMore,
  isLoading,
  onCancel,
  handleSearch,
  fetchMoreUsers,
}) => {
  const { t } = useTranslation();
  const modalTranslationPrefix = `${translationPrefix}.modals.invite_team_member`;
  const formikContext = useFormikContext<InviteTeamMemberFormType>();

  const { values, setFieldValue, handleSubmit, errors } = formikContext;

  return (
    <FormContainer
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <InputWithSearch
        options={employeesList.map((it) => it.user)}
        type="userShortInfo"
        onSelect={(option) => setFieldValue("email", (option as UserResponseDto).email)}
        inputProps={{
          name: "email",
          onChange: (e) => {
            setFieldValue("email", e.currentTarget.value.trim());
            handleSearch(e.currentTarget.value);
          },
          value: values.email || "",
          label: <Trans i18nKey={`${modalTranslationPrefix}.email`} />,
          error: getFieldError("email", formikContext, {
            field: t(`${modalTranslationPrefix}.email`),
            maxInputLength: MAX_INPUT_LENGTH,
          }),
        }}
        fetchMore={fetchMoreUsers}
        hasMore={hasMore}
        isLoading={isLoading}
      />

      <BtnsPairWrapper>
        <CancelBtn onClick={() => onCancel()} type="button">
          <Trans i18nKey={"buttons.cancel"} />
        </CancelBtn>
        <SubmitBtn type="submit">
          <Trans i18nKey={`${modalTranslationPrefix}.submit_btn`} />
        </SubmitBtn>
      </BtnsPairWrapper>
    </FormContainer>
  );
};
