import { APPLICATION_NAME } from "constants/index";

export const employeeSetupPageTranslations = {
  "en-US": {
    title: "Onboarding",
    steps_template: "Step {{index}}: {{name}}",

    setup_complete_states: {
      no_physical_card: {
        title: "Onboarding Completed",
        message: `Congratulations on completing the onboarding! To start using your ${APPLICATION_NAME} Bank Account you need to request a Physical Card.`,
        main_button: "Request a Physical Card",
      },
      no_virtual_card: {
        title: "Onboarding Completed",
        message:
          "Your physical card has been successfully shipped! While you wait for its arrival, you can request a virtual card now and start using it immediately.",
        main_button: "Request a Virtual Card",
      },
      onboarding_complete: {
        title: "Onboarding Completed",
        message:
          "Congratulations on completing the onboarding! You're all set to get paid. Let's get started!",
      },
    },

    steps: {
      add_workers: {
        title: "Add Workers",
      },
      setup_payroll: {
        title: "Setup Payroll",
        back_button: "Back",
      },
      connect_bank: {
        title: "Connect Bank",
        title_inner: "Bank Info: Choose your Pay Methods ",
        add_bank_account_btn: "Add Account",
      },
    },
  },
};

export default employeeSetupPageTranslations;
