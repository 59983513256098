import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import routes from "routes/routes";

import { userMetadataSelector } from "redux/selectors";
import { EMagicLinkFlow } from "types/AuthFlowTypes";
import { getSuperAdminDetails, showErrorModal } from "helpers/index";
import useAuth from "hooks/useAuth";

import {
  CreateAdminProfileDto,
  mutationAdminsControllerCreateAdminProfile,
  mutationUsersControllerAcceptTermsAndConditions,
} from "utils/swagger_react_query";

import { ContactDetailsFormType, IAuthInvitationSearchParams, useCompleteSignIn } from "../_shared";

export const useAdminSignUpPage = () => {
  const [isLoading, setLoading] = useState<boolean>(true);
  const [searchParams] = useSearchParams();
  const { logout, isAuthenticated, getCurrentUser } = useAuth();
  const [svoc, setSvoc] = useState<boolean>(false);
  const currentUser = useSelector(userMetadataSelector);
  const navigate = useNavigate();
  const [formInitValues, setFormInitValues] = useState<ContactDetailsFormType>({
    firstName: "",
    lastName: "",
    phone: "",
    jobTitle: "",
  });

  const {
    actions,
    metadata: { isLoading: pageLoader },
  } = useCompleteSignIn({ mode: EMagicLinkFlow.SIGN_UP, autoLogin: false });
  const { setLoading: setPageLoader } = actions;

  useEffect(() => {
    autoLogin(EMagicLinkFlow.SIGN_UP, getAuthParams());
  }, []);

  const getAuthParams = (): IAuthInvitationSearchParams => {
    return {
      invitationId: searchParams.get("invitationId") || undefined,
      error: searchParams.get("error") || undefined,
    };
  };

  const autoLogin = async (mode: EMagicLinkFlow, authParams: IAuthInvitationSearchParams) => {
    if (searchParams.size || (searchParams.size && isAuthenticated())) {
      await actions.completeSignIn(
        mode,
        { error: authParams.error, code: authParams.invitationId },
        authParams.invitationId,
      );
      const userInfoRes = await getCurrentUser(false, false, false);
      setFormInitValues({
        firstName: userInfoRes?.firstName || "",
        lastName: userInfoRes?.lastName || "",
        phone: userInfoRes?.phone || "",
        jobTitle: userInfoRes?.jobTitle || "",
      });
      setLoading(false);
    } else if (!isAuthenticated()) {
      navigate(routes.SIGN_IN);
    }

    await actions.checkUserData(undefined, true);
    setLoading(false);
    setPageLoader(false);
  };

  const handleSubmit = async (values: ContactDetailsFormType) => {
    try {
      setSvoc(true);
      setLoading(true);
      const payload: CreateAdminProfileDto = getSuperAdminDetails(values);
      await mutationAdminsControllerCreateAdminProfile()(payload);
      await mutationUsersControllerAcceptTermsAndConditions()();

      const userInfoRes = await getCurrentUser();
      if (userInfoRes?.userId) {
        actions.redirectByRoleAndSalsaOnboardingStatus(userInfoRes);
      }
    } catch (error) {
      setLoading(false);
      showErrorModal(error);
    }
  };

  return {
    metadata: {
      pageLoader,
      isLoading,
      userInfo: currentUser,
      formInitValues,
      svoc,
    },
    actions: {
      handleSubmit,
    },
  };
};
