import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import routes from "routes/routes";

import { companyMetadataSelector } from "redux/selectors";
import { SalsaOnboardingStatus } from "types/BETypes";
import { showErrorModal } from "helpers";
import { getDeferredData } from "helpers/employee/employeeRequests";
import { useBankAccountLink } from "hooks";
import useAuth from "hooks/useAuth";
import ModalLikeContent, {
  IModalLikeContentProps,
} from "components/ModalLikeContent/ModalLikeContent";
import StepIndicator from "components/StepIndicator";
import { Step } from "components/StepIndicator/types";

import { EModalTypes, IModalProps } from "uikit/Modal";

import {
  CompanyResponseDto,
  mutationEmployeesControllerListEmployeesByFilter,
  mutationPlaidControllerAcceptDisclosure,
} from "utils/swagger_react_query";

import WelcomeMessage from "./components/WelcomeMessage";
import { STEPS } from "./constants";
import AddWorker from "./steps/AddWorker";
import ConnectBank from "./steps/ConnectBank";
import SetupPayroll from "./steps/SetupPayroll";
import { TabsContainer } from "./styles";

export const useAdminSetup = () => {
  const translationPrefix = `admin_setup_page`;
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [isLoading, setLoading] = useState<boolean>(true);
  const [currentStep, setCurrentStep] = useState<number | undefined>(undefined);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState<boolean>(false);
  const { getCompanyInfo, saveCompanyToStore } = useAuth();
  const [workersRequestedTotal, setWorkersRequestedTotal] = useState<number | undefined>(undefined);
  const [isContinueToOnboardingDisabled, setContinueToOnboardingDisabled] = useState<
    Record<STEPS, boolean>
  >({
    [STEPS.ADD_WORKER]: true,
    [STEPS.SETUP_PAYROLL]: true,
    [STEPS.CONNECT_BANK]: true,
  });

  const currentCompany = useSelector(companyMetadataSelector);

  const refetchCompanyData = async () => {
    await getCompanyInfo(currentCompany?.companyId || "", true);
  };

  const handleSetDefaultBankAccountCustom = async (bankAccountId: string) => {
    try {
      await bankAccountLink.actions.handleSetDefaultBankAccount(bankAccountId);
      setLoading(true);
      await refetchCompanyData();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessAddedCallback = async () => {
    try {
      setLoading(true);
      await refetchCompanyData();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const bankAccountLink = useBankAccountLink({
    loadingCallback: setLoading,
    onSuccessAddedCallback: handleSuccessAddedCallback,
  });

  const handleOnboardingDisabledChange = (isDisabled: boolean, stepIndex: STEPS) => {
    setContinueToOnboardingDisabled((prev) => ({ ...prev, [stepIndex]: isDisabled }));
  };

  const connectBankUnblockCondition = (company?: CompanyResponseDto | null) => {
    const result = company?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED;
    return result;
  };

  const handleBankAgreementSubmit = async () => {
    try {
      setLoading(true);
      await mutationPlaidControllerAcceptDisclosure()();
      await refetchCompanyData();
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (connectBankUnblockCondition(currentCompany)) {
      handleOnboardingDisabledChange(false, STEPS.SETUP_PAYROLL);
    }
  }, [currentCompany?.salsaOnboardingStatus, currentStep]);

  const moveToWorkersStep = () => {
    setCurrentStep(STEPS.ADD_WORKER);
  };
  const moveToOnboardingStep = () => {
    if (!isContinueToOnboardingDisabled[STEPS.ADD_WORKER]) {
      setCurrentStep(STEPS.SETUP_PAYROLL);
    }
  };

  const moveToBankAccountsStep = () => {
    if (!isContinueToOnboardingDisabled[STEPS.SETUP_PAYROLL]) {
      setCurrentStep(STEPS.CONNECT_BANK);
    }
  };

  const steps: Step[] = [
    {
      index: STEPS.ADD_WORKER,
      label: (
        <Trans
          i18nKey={`${translationPrefix}.steps_template`}
          values={{
            index: STEPS.ADD_WORKER,
            name: t(`${translationPrefix}.steps.add_workers.title`),
          }}
        />
      ),
      onClick: moveToWorkersStep,
    },
    {
      index: STEPS.SETUP_PAYROLL,
      label: (
        <Trans
          i18nKey={`${translationPrefix}.steps_template`}
          values={{
            index: STEPS.SETUP_PAYROLL,
            name: t(`${translationPrefix}.steps.setup_payroll.title`),
          }}
        />
      ),
      onClick: !isContinueToOnboardingDisabled[STEPS.ADD_WORKER] ? moveToOnboardingStep : undefined,
    },
    {
      index: STEPS.CONNECT_BANK,
      label: (
        <Trans
          i18nKey={`${translationPrefix}.steps_template`}
          values={{
            index: STEPS.CONNECT_BANK,
            name: t(`${translationPrefix}.steps.connect_bank.title`),
          }}
        />
      ),
      onClick: !isContinueToOnboardingDisabled[STEPS.SETUP_PAYROLL]
        ? moveToBankAccountsStep
        : undefined,
    },
  ];

  const handleContinueToBankAccount = () => {
    onSalsaOnboardingComplete();
  };

  const onSalsaOnboardingComplete = async () => {
    setLoading(true);

    const request = async () => {
      const data = await getCompanyInfo(currentCompany?.companyId || "", false);
      return data.companyInfo;
    };

    //Our company might not have updated status right away, we need to wait for it to become completed
    const result = await getDeferredData(request, connectBankUnblockCondition, 2000);
    if (connectBankUnblockCondition(result)) {
      saveCompanyToStore(result);
      setCurrentStep(STEPS.CONNECT_BANK);
    } else {
      showErrorModal();
    }

    setLoading(false);
  };

  const fetchSingleWorker = async () => {
    setLoading(true);
    const employeesListRes = await mutationEmployeesControllerListEmployeesByFilter({
      page: "1",
      perPage: "1",
      searchString: "",
    })();

    setWorkersRequestedTotal(employeesListRes?.total || 0);
    setLoading(false);
  };

  useEffect(() => {
    if (!currentStep && workersRequestedTotal !== undefined) {
      if (currentCompany?.salsaOnboardingStatus === SalsaOnboardingStatus.COMPLETED) {
        setContinueToOnboardingDisabled({
          ...isContinueToOnboardingDisabled,
          [STEPS.ADD_WORKER]: false,
          [STEPS.SETUP_PAYROLL]: false,
        });
        setCurrentStep(STEPS.CONNECT_BANK);
      } else if (workersRequestedTotal) {
        setContinueToOnboardingDisabled({
          ...isContinueToOnboardingDisabled,
          [STEPS.ADD_WORKER]: false,
        });
        setCurrentStep(STEPS.SETUP_PAYROLL);
      } else {
        setShowWelcomeMessage(true);
        setCurrentStep(STEPS.ADD_WORKER);
      }
    }
  }, [workersRequestedTotal]);

  useEffect(() => {
    if (!currentCompany?.isOnboardingComplete) {
      fetchSingleWorker();
    }
  }, []);

  const renderTabs = () => {
    if (!currentStep) return <></>;
    return (
      <>
        <TabsContainer>
          <StepIndicator steps={steps} currentStep={currentStep} />
        </TabsContainer>
        {currentStep === 1 && (
          <AddWorker
            onContinue={moveToOnboardingStep}
            onContinueDisabledChange={(state: boolean) =>
              handleOnboardingDisabledChange(state, STEPS.ADD_WORKER)
            }
          />
        )}
        {currentStep === 2 && (
          <SetupPayroll
            onBack={moveToWorkersStep}
            onContinue={handleContinueToBankAccount}
            isContinueDisabled={isContinueToOnboardingDisabled[STEPS.SETUP_PAYROLL]}
            onSalsaOnboardingComplete={onSalsaOnboardingComplete}
          />
        )}
        {currentStep === 3 && (
          <ConnectBank
            onBack={moveToOnboardingStep}
            bankAccountSectionProps={{
              onRemoveBankAccount: bankAccountLink.actions.handleRemoveBankAccount,
              onUpdateDefaultBankAccount: handleSetDefaultBankAccountCustom,
              onConnectBankAccount: bankAccountLink.actions.openModal,
              bankAccounts: bankAccountLink.data.bankAccounts,
              onVerifyBankAccount: bankAccountLink.actions.handleVerifyBankAccount,
            }}
            onAgreementSubmit={handleBankAgreementSubmit}
          />
        )}
      </>
    );
  };

  // const renderDefaultFinishedContent = () => {
  //   const _translationPrefix = `${translationPrefix}.setup_states.onboarding_complete`;
  //   const config: Partial<IModalProps> = {
  //     type: EModalTypes.SUCCESS,
  //     statusTitle: <Trans i18nKey={`${_translationPrefix}.title`} />,
  //     message: <Trans i18nKey={`${_translationPrefix}.message`} />,
  //     mainButton: {
  //       text: <Trans i18nKey={`${_translationPrefix}.button`} />,
  //       onClick: () => navigate(routes.ADMIN_PAY),
  //     },
  //   };
  //   return <ModalContent {...config} />;
  // };

  const renderDefaultFinishedContent = () => {
    const _translationPrefix = `${translationPrefix}.setup_states.onboarding_complete`;
    const config: IModalLikeContentProps = {
      title: <Trans i18nKey={`${_translationPrefix}.title`} />,
      type: EModalTypes.Success,
      message: <Trans i18nKey={`${_translationPrefix}.message`} />,
      mainButton: {
        text: <Trans i18nKey={`${_translationPrefix}.button`} />,
        onClick: () => navigate(routes.ADMIN_PAY),
      },
    };
    return <ModalLikeContent {...config} />;
  };

  const handleWelcomeComponentContinueClick = () => {
    setShowWelcomeMessage(false);
  };

  const renderWelcomeMessage = () => {
    return <WelcomeMessage onContinue={handleWelcomeComponentContinueClick} />;
  };

  const renderContent = () => {
    if (currentCompany?.isOnboardingComplete) {
      return renderDefaultFinishedContent();
    } else if (!!showWelcomeMessage && currentStep === STEPS.ADD_WORKER) {
      return renderWelcomeMessage();
    } else {
      return renderTabs();
    }
  };

  return {
    isLoading,
    renderContent,
  };
};
