import styled from "styled-components";

import { breakpoint } from "helpers";
import EmployeeInfo from "components/EmployeeInfo";
import UserStatus from "components/UserStatus";

export const Container = styled.div``;

export const FrameContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  ${breakpoint("xs", "xl")`
    flex-direction: column;
    align-items: start;
  `}
`;

export const TCLeft = styled.div``;

export const TCRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  ${breakpoint("xs", "xl")`
    order: -1;
    align-self: end;
  `}
`;

export const MenuButton = styled.div`
  display: inline-block;
  margin-left: 24px;
  cursor: pointer;

  ${breakpoint("xs", "md")`
    margin-left: 0;
  `}
`;

export const StyledEmployeeInfo = styled(EmployeeInfo)`
  margin-bottom: 48px;

  ${breakpoint("xs", "xl")`
    margin-bottom: 16px;
  `}
`;

export const StyledUserStatus = styled(UserStatus)`
  margin-right: 24px;

  ${breakpoint("xs", "md")`
    margin-right: 4px;
  `}
`;

export const StartDateLabelText = styled.span`
  font-size: ${({ theme }) => theme.size.body2};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.textAlt};
`;

export const StartDateValueText = styled.span`
  font-size: ${({ theme }) => theme.size.body1};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  color: ${({ theme }) => theme.colors.text};
  padding-left: 7px;
`;
