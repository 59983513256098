import styled from "styled-components";

interface IDotProps {
	size?: string | number;
	color?: string
}

export const Container = styled.span`
  font-family: inherit;
`;
export const Dot = styled.span<IDotProps>(({size, color}) =>`
  font-size: ${size !== undefined ? `${size}px` : "inherit"};
  line-height: 12px;
  color: ${color || "inherit"};
`);
