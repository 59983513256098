import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CopyrightText = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.textAlt};
  margin-bottom: 14px;
`;

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Link = styled.a`
  display: inline-block;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.size.caption};
  color: ${({ theme }) => theme.colors.accentMain};

  &:first-of-type {
    margin-bottom: 4px;
  }

  &:last-of-type {
    border-right: none;
  }
`;
