import { useState } from "react";
import { Trans } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { CheckmarkRoundFilled } from "assets/svg";
import routes from "routes/routes";

import { userMetadataSelector } from "redux/selectors";
import { getUser } from "services/auth";
import { AppEvents } from "services/events";
import { PayDistributionType } from "types/BETypes";
import { showErrorModal } from "helpers";
import { isKycAccepted } from "helpers/bonus/kyc";
import useAuth from "hooks/useAuth";

import { EModalTypes } from "uikit/Modal";

import {
  GetPlaidBankAccountsResponseDto,
  mutationUsersControllerUpdateDefaultPayDistribution,
} from "utils/swagger_react_query";

import { COMPANY_ACCOUNT_PROMOTION_POINTS } from "./constants";
import {
  showCompanyAccountChosenModal,
  showCompanyAccountPromotionModal,
  showPaperCheckChosenModal,
} from "./modals/showModals";
import { EOptionIds, EWidgetType, IProps } from "./types";

export const usePaymentMethods = (props: IProps) => {
  const translationPrefix = `components.payment_methods`;
  const currentUser = useSelector(userMetadataSelector);
  const { widgetType = EWidgetType.SETTINGS } = props;
  const [isLoading, setLoading] = useState<boolean>(false);

  const { getCurrentUser } = useAuth();
  const navigate = useNavigate();

  const [currentMethodFlow, setCurrentMethodFlow] = useState<EOptionIds | undefined>(undefined);
  const handleCompanyBankAccountClick = () => {
    if (!currentUser) return;
    if (isKycAccepted(currentUser)) {
      navigate(routes.EMPLOYEE_BANKING);
    } else {
      setCurrentMethodFlow(EOptionIds.COMPANY_BANK_ACCOUNT);
    }
  };

  const handleCompanyBankAccountSelect = async () => {
    try {
      setLoading(true);
      await mutationUsersControllerUpdateDefaultPayDistribution()({
        type: PayDistributionType.PARTNER_ACCOUNT,
      });
      await getCurrentUser();
      if (widgetType !== EWidgetType.ONBOARDING) {
        showCompanyAccountChosenModal();
      }
      setLoading(false);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDirectDepositClick = (bankAccounts: GetPlaidBankAccountsResponseDto[]) => {
    const callback = () => {
      setCurrentMethodFlow(EOptionIds.DIRECT_DEPOSIT);
    };
    const cancelCallback = () => {
      setCurrentMethodFlow(EOptionIds.COMPANY_BANK_ACCOUNT);
    };

    // if (!currentUser?.kycStatus && !bankAccounts?.length) {
    //   showCompanyAccountPromotionModal(callback, cancelCallback);
    // } else {
    //   callback();
    // }
    callback();
  };

  const handlePaperCheckSelect = async () => {
    try {
      setLoading(true);
      await mutationUsersControllerUpdateDefaultPayDistribution()({
        type: PayDistributionType.CHECK,
      });
      await getCurrentUser();
      if (widgetType !== EWidgetType.ONBOARDING) {
        showPaperCheckChosenModal();
      }
      setLoading(false);
    } catch (error) {
      showErrorModal(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCompanyBankAccountKycVerificationSuccess = async () => {
    if (currentUser?.defaultPayDistribution?.type !== PayDistributionType.PARTNER_ACCOUNT) {
      await mutationUsersControllerUpdateDefaultPayDistribution()({
        type: PayDistributionType.PARTNER_ACCOUNT,
      });
    }
  };

  const handleCompanyBankAccountShippingDetailsSuccess = async () => {
    await getCurrentUser();
    setCurrentMethodFlow(EOptionIds.DEFAULT);
  };

  const handleRestartKycVerification = () => {
    setCurrentMethodFlow(EOptionIds.COMPANY_BANK_ACCOUNT);
  };

  //Back buttons
  const handleDirectDepositBackButtonClick = () => {
    setCurrentMethodFlow(EOptionIds.DEFAULT);
  };

  const handleCompanyBankAccountBackButtonClick = async () => {
    await getCurrentUser();
    setCurrentMethodFlow(EOptionIds.DEFAULT);
  };

  return {
    isLoading,
    currentMethodFlow,

    //Button Actions
    handleCompanyBankAccountClick,
    handleCompanyBankAccountSelect,
    handleDirectDepositClick,
    handlePaperCheckSelect,
    handleRestartKycVerification,

    //Back Buttons
    handleCompanyBankAccountBackButtonClick,
    handleDirectDepositBackButtonClick,
    handleCompanyBankAccountKycVerificationSuccess,
    handleCompanyBankAccountShippingDetailsSuccess,
  };
};

export default usePaymentMethods;
